import React, { useEffect, useMemo } from 'react';
import {
    DateGroupBy,
    useGeneralSalesComparisonLazyQuery,
} from '../../../../services';
import {
    DBlock,
    DMonth,
    DPage,
    DPageBody,
    DPageHeader,
    DYear,
    useDMonth,
    useDYear,
} from '../../../dum';
import { Box, Typography } from '@mui/material';
import { TotalComparison } from './blocks/total-comparison';
import { AccountComparison } from './blocks/account-comparison';
import dayjs from 'dayjs';

const DATE_1_YEAR_NAME = `${GeneralSalesComparison.name}_YEAR_1`;
const DATE_2_YEAR_NAME = `${GeneralSalesComparison.name}_YEAR_2`;
const DATE_1_MONTH_NAME = `${GeneralSalesComparison.name}_MONTH_1`;
const DATE_2_MONTH_NAME = `${GeneralSalesComparison.name}_MONTH_2`;

export function GeneralSalesComparison() {
    const { year: year1, setYear: setYear1 } = useDYear({
        COMPONENT_KEY: DATE_1_YEAR_NAME,
    });
    const { year: year2, setYear: setYear2 } = useDYear({
        COMPONENT_KEY: DATE_2_YEAR_NAME,
    });
    const { month: month1, setMonth: setMonth1 } = useDMonth({
        COMPONENT_KEY: DATE_1_MONTH_NAME,
    });
    const { month: month2, setMonth: setMonth2 } = useDMonth({
        COMPONENT_KEY: DATE_2_MONTH_NAME,
    });
    const [query, { data }] = useGeneralSalesComparisonLazyQuery();

    useEffect(() => {
        void query({
            variables: {
                date1Year: year1,
                date1Month: month1,
                date2Year: year2,
                date2Month: month2,
                date1GroupBy:
                    month1 !== undefined && month1 !== null
                        ? DateGroupBy.Month
                        : DateGroupBy.Year,
                date2GroupBy:
                    month2 !== undefined && month2 !== null
                        ? DateGroupBy.Month
                        : DateGroupBy.Year,
                onlyOwnProducts: true,
            },
        });
    }, [month2, month1, year1, year2, query]);

    const date1 = useMemo(() => {
        return `${year1 != null ? year1 : ''}${
            month1 != null ? ' - ' + dayjs().month(month1).format('MMM') : ''
        }`;
    }, [year1, month1]);

    const date2 = useMemo(() => {
        return `${year2 != null ? year2 : ''}${
            month2 != null ? ' - ' + dayjs().month(month2).format('MMM') : ''
        }`;
    }, [year2, month2]);

    return (
        <DPage>
            <DPageHeader title={'Comparativo general de ventas'} />
            <DPageBody disableGutters>
                <DBlock>
                    <Box sx={{ mb: 4 }}>
                        <Box sx={{ mb: 1 }}>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant={'caption'}>
                                    Fecha 1
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ mr: 1 }}>
                                    <DYear setYear={setYear1} year={year1} />
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <DMonth
                                        setMonth={setMonth1}
                                        month={month1}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant={'caption'}>
                                    Fecha 2
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ mr: 1 }}>
                                    <DYear setYear={setYear2} year={year2} />
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <DMonth
                                        setMonth={setMonth2}
                                        month={month2}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DBlock>
                <TotalComparison data={data} date1={date1} date2={date2} />
                <AccountComparison data={data} date1={date1} date2={date2} />
            </DPageBody>
        </DPage>
    );
}
