import React from 'react';
import { useSpareCategoryInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';

interface SSingleSpareCategoryAutocompleteProps {
    name: string;
    label: string;
    disabled?: boolean;
}

export function SSingleSpareCategoryAutocomplete({
    name,
    label,
    disabled,
}: SSingleSpareCategoryAutocompleteProps) {
    const { data } = useSpareCategoryInputQuery();

    const spareCategories = data?.getSpareCategories || [];

    return (
        <RhSingleAutocomplete
            disabled={disabled}
            itemText={'name'}
            itemValue={'id'}
            name={name}
            label={label}
            options={spareCategories}
        />
    );
}
