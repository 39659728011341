import React from 'react';
import { CurrentUserQuery, RoleId } from '../../services';

export interface RouteBase {
    title: string;
    navbar?: boolean;
    roleIds?: RoleId[];
}

export interface Route extends RouteBase {
    name: string;
    path: string;
    component?: React.ReactNode;
    exact?: boolean;
    isNested?: boolean;
}

export interface RouteGroup extends RouteBase {
    routes: Route[];
}

export type RouteItem = Route | RouteGroup;

export const isRouteGroup = (b: RouteItem): b is RouteGroup => {
    return (b as RouteGroup).routes !== undefined;
};

export const isRoute = (b: RouteItem): b is Route => {
    return (b as Route).path !== undefined;
};

type CurrentUser = CurrentUserQuery['currentUser'];

export function routeAuthFilter(currentUser: CurrentUser) {
    return (item: Pick<RouteItem, 'roleIds'>) => {
        return (
            !item.roleIds ||
            item.roleIds.some((roleId) =>
                currentUser.role_ids.find(
                    (currentUserRoleId) => currentUserRoleId === roleId,
                ),
            )
        );
    };
}

export function routeNavbarFilter() {
    return (item: Pick<RouteItem, 'navbar'>) => {
        return item.navbar;
    };
}

export function routeNavbarAuthFilter(currentUser: CurrentUser) {
    return (item: RouteItem) => {
        const navbarFilter = routeNavbarFilter()(item);
        const authFilter = routeAuthFilter(currentUser)(item);
        return navbarFilter && authFilter;
    };
}
