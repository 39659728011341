import { IOrderSale, SUBTOTAL, TOTAL_WITH_TAX } from '../order-sale-form';
import { useFormContext, UseFormReturn } from 'react-hook-form';

const PRODUCT_TOTAL_FIELD_NAME: keyof IOrderSale['order_sale_products'][number] =
    'total';
const ORDER_SALE_PRODUCTS_FIELD_NAME: keyof IOrderSale = 'order_sale_products';

function setProductsTotal({
    orderSaleProducts,
    orderSaleReceiptTypeId,
    formContext,
    index,
}: {
    orderSaleProducts: IOrderSale['order_sale_products'];
    orderSaleReceiptTypeId: IOrderSale['receipt_type_id'];
    formContext: UseFormReturn<any>;
    index?: number;
}) {
    if (orderSaleProducts && Array.isArray(orderSaleProducts)) {
        const productsTotal = (
            orderSaleProducts as IOrderSale['order_sale_products']
        ).reduce((acc, orderSaleProduct, currentIndex) => {
            const kilos =
                Number.isNaN(orderSaleProduct.kilos) || !orderSaleProduct.kilos
                    ? 0
                    : orderSaleProduct.kilos;
            const kiloPrice =
                Number.isNaN(orderSaleProduct.kilo_price) ||
                !orderSaleProduct.kilo_price
                    ? 0
                    : orderSaleProduct.kilo_price;

            const groupPrice =
                Number.isNaN(orderSaleProduct.group_price) ||
                !orderSaleProduct.group_price
                    ? 0
                    : orderSaleProduct.group_price;

            const groups =
                Number.isNaN(orderSaleProduct.groups) ||
                !orderSaleProduct.groups
                    ? 0
                    : orderSaleProduct.groups;

            const kiloTotal = kilos * kiloPrice;

            const groupsTotal = groupPrice * groups;

            const productTotal = kiloTotal + groupsTotal;

            const discountTotal =
                (orderSaleProduct.discount / 100) * productTotal;

            const productTotalMinusDiscount = productTotal - discountTotal;

            if (index !== undefined && index === currentIndex) {
                formContext.setValue(
                    `${ORDER_SALE_PRODUCTS_FIELD_NAME}.${index}.${PRODUCT_TOTAL_FIELD_NAME}`,
                    Math.round(productTotalMinusDiscount * 100) / 100,
                    {
                        shouldValidate: true,
                    },
                );
            }

            return acc + productTotal;
        }, 0);

        formContext.setValue(SUBTOTAL, Math.round(productsTotal * 100) / 100, {
            shouldValidate: true,
        });

        const totalWithTax =
            productsTotal * (orderSaleReceiptTypeId === 1 ? 1 : 1.16);

        formContext.setValue(
            TOTAL_WITH_TAX,
            Math.round(totalWithTax * 100) / 100,
            {
                shouldValidate: true,
            },
        );
    }
}

const receipt_type_id_FIELD_NAME: keyof IOrderSale = 'receipt_type_id';

export function useCalculateProductsTotal() {
    const formContext = useFormContext<IOrderSale>();

    const calculateProductsTotal = function (
        params: { index?: number } = { index: undefined },
    ) {
        const index =
            params && params.index !== undefined ? params.index : undefined;

        const orderSaleProducts = formContext.getValues(
            ORDER_SALE_PRODUCTS_FIELD_NAME,
        ) as IOrderSale['order_sale_products'];
        const orderSaleReceiptTypeId = formContext.getValues(
            receipt_type_id_FIELD_NAME,
        ) as IOrderSale['receipt_type_id'];
        setProductsTotal({
            orderSaleProducts,
            orderSaleReceiptTypeId,
            formContext,
            index,
        });
    };

    return {
        calculateProductsTotal: calculateProductsTotal,
    };
}
