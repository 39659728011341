import React from 'react';
import { animated, Spring } from 'react-spring';
import { Box, SxProps } from '@mui/material';

interface AnimatedDivProps {
    children: React.ReactNode;
    sx?: SxProps;
}

export function DAnimatedBox(props: AnimatedDivProps) {
    const { children, sx = {} } = props;

    const AnimatedBox = animated(Box);

    return (
        <Spring
            config={{
                duration: 500,
            }}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
        >
            {(styles: any) => {
                return (
                    <AnimatedBox
                        sx={sx}
                        style={{
                            ...styles,
                        }}
                    >
                        {children}
                    </AnimatedBox>
                );
            }}
        </Spring>
    );
}
