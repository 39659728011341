import { ApolloCache } from '@apollo/client';
import { Query } from './inopack-graphql-schema';

export function clearClientQueryCache(
    cache: ApolloCache<any>,
    key: keyof Query,
) {
    cache.evict({
        id: 'ROOT_QUERY',
        fieldName: key,
        broadcast: true,
    });
}

export function clearMaintenanceModule(cache: ApolloCache<any>) {
    clearClientQueryCache(cache, 'getMachines');
    clearClientQueryCache(cache, 'getMachine');
    clearClientQueryCache(cache, 'getMachinePart');
    clearClientQueryCache(cache, 'getMachineParts');
    clearClientQueryCache(cache, 'getMachineSection');
    clearClientQueryCache(cache, 'getMachineSections');
    clearClientQueryCache(cache, 'getSpares');
    clearClientQueryCache(cache, 'getSpare');
    clearClientQueryCache(cache, 'getSpareCategories');
    clearClientQueryCache(cache, 'getSpareCategory');
    clearClientQueryCache(cache, 'getSpareAdjustments');
    clearClientQueryCache(cache, 'getSpareOperation');
    clearClientQueryCache(cache, 'getSpareTransactions');
    cache.gc();
}

export function clearProductionModule(cache: ApolloCache<any>) {
    // order production
    clearClientQueryCache(cache, 'getOrderProduction');
    clearClientQueryCache(cache, 'getMachine');
    clearClientQueryCache(cache, 'getMachines');
    clearClientQueryCache(cache, 'paginatedMachines');
    clearClientQueryCache(cache, 'getOrderProductionProducts');
    clearClientQueryCache(cache, 'paginatedOrderProductions');

    // order adjustments
    clearClientQueryCache(cache, 'getOrderAdjustment');
    clearClientQueryCache(cache, 'getOrderAdjustments');
    clearClientQueryCache(cache, 'paginatedOrderAdjustments');

    //employees
    clearClientQueryCache(cache, 'getEmployee');
    clearClientQueryCache(cache, 'getEmployees');

    // summary
    clearClientQueryCache(cache, 'getProductionSummary');

    // products
    clearProducts(cache);
    cache.gc();
}

export function clearSalesModule(cache: ApolloCache<any>) {
    // order-sale
    clearClientQueryCache(cache, 'getAccount');
    clearClientQueryCache(cache, 'getAccounts');

    // order requests
    clearClientQueryCache(cache, 'getOrderRequests');
    clearClientQueryCache(cache, 'getOrderRequest');
    clearClientQueryCache(cache, 'paginatedOrderRequests');
    clearClientQueryCache(cache, 'getOrderRequestMaxOrderCode');

    // order sales
    clearClientQueryCache(cache, 'getOrderSale');
    clearClientQueryCache(cache, 'getOrderSales');
    clearClientQueryCache(cache, 'getOrderSalesWithDisparities');
    clearClientQueryCache(cache, 'paginatedOrderSales');
    clearClientQueryCache(cache, 'getOrderSaleMaxOrderCode');

    // summary
    clearClientQueryCache(cache, 'getSalesSummary');

    clearProducts(cache);
    cache.gc();
}

function clearProducts(cache: ApolloCache<any>) {
    clearClientQueryCache(cache, 'getProducts');
    clearClientQueryCache(cache, 'getProduct');
    clearClientQueryCache(cache, 'paginatedProducts');
    clearClientQueryCache(cache, 'getProductsInventory');
    clearClientQueryCache(cache, 'getOptimizedRequestProducts');
}

export function clearManagement(cache: ApolloCache<any>) {
    clearClientQueryCache(cache, 'paginatedTransfers');
    clearClientQueryCache(cache, 'getTransfer');
    clearClientQueryCache(cache, 'getTransfers');
    clearClientQueryCache(cache, 'paginatedExpenses');
    clearClientQueryCache(cache, 'getExpenses');
    clearClientQueryCache(cache, 'getExpense');
    clearClientQueryCache(cache, 'paginatedResources');
    clearClientQueryCache(cache, 'getResources');
    clearClientQueryCache(cache, 'getResource');
    clearClientQueryCache(cache, 'getAccountTransferSummary');

    clearClientQueryCache(cache, 'getRawMaterialAdditions');
    clearClientQueryCache(cache, 'paginatedRawMaterialAdditions');
    clearClientQueryCache(cache, 'getRawMaterialAddition');
    clearClientQueryCache(cache, 'getRawMaterialAdditionsWithDisparities');

    clearSalesModule(cache);
}

export function clearActivitiesModule(cache: ApolloCache<any>) {
    clearClientQueryCache(cache, 'getActivities');
    cache.gc();
}

export function clearAuthModule(cache: ApolloCache<any>) {
    clearClientQueryCache(cache, 'getUser');
    clearClientQueryCache(cache, 'users');
    cache.gc();
}
