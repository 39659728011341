import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { useUserDialogQuery } from '../../../../../../services';
import { EmployeeIcon } from '../../../../icons';
import { UserForm } from './user-form';

interface ISUserFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    userId?: number | null;
    disabled?: boolean;
}

export const SUpsertUserDialog = (props: ISUserFormDialogProps) => {
    const { open, setOpen, userId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useUserDialogQuery({
        skip: !userId || !open,
        variables: {
            UserId: userId!,
        },
    });

    const user = useMemo(() => {
        return userId && data && data.getUser && open
            ? data.getUser
            : undefined;
    }, [userId, data, open]);

    return (
        <DModal
            icon={<EmployeeIcon />}
            title={`Usuario`}
            open={open}
            setOpen={setOpen}
            width={'800px'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <UserForm
                    disabled={isModalClosing}
                    user={user}
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                />
            )}
        </DModal>
    );
};
