import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { TransferForm } from './transfer-form';
import { useTransferDialogQuery } from '../../../../../../services';

interface ISTransferFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    transferId?: number | null;
    disabled?: boolean;
}

export const SUpsertTransferDialog = (props: ISTransferFormDialogProps) => {
    const { open, setOpen, transferId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useTransferDialogQuery({
        skip: !transferId || !open,
        variables: {
            TransferId: transferId!,
        },
    });

    const transfer = useMemo(() => {
        return transferId && data && data.getTransfer && open
            ? data.getTransfer
            : undefined;
    }, [transferId, data, open]);

    return (
        <DModal
            title={`Transferencia`}
            open={open}
            setOpen={setOpen}
            width={'800px'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <TransferForm
                    disabled={isModalClosing}
                    transfer={transfer}
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                />
            )}
        </DModal>
    );
};
