import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './services';
import { GlobalMessagesSnackbar } from './features/global-messages/global-messages-snackbar';
import { Providers } from './app/app/providers/providers';
import { AuthorizationWrapper } from './app/app/authorization-wrapper/authorization-wrapper';
import { SubscriptionsProvider } from './app/app/providers/subscriptions-provider';

dayjs.extend(isTomorrow);
dayjs.extend(utcPlugin);
dayjs.extend(relativeTime);

dayjs.locale('es');

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <Providers>
                <AuthorizationWrapper>
                    <App />
                </AuthorizationWrapper>
                <GlobalMessagesSnackbar />
                <SubscriptionsProvider />
            </Providers>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
