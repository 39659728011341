import { Box, Icon } from '@mui/material';
import React, { useContext } from 'react';
import { LightContext } from './parent-component';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

export function GrandChildComponent() {
    const light = useContext(LightContext); // lightContextValue

    return (
        <Box>
            <Icon>{light ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}</Icon>
        </Box>
    );
}
