import React, { useEffect, useMemo, useState } from 'react';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPaginator,
    DTextField,
    DYear,
    EditIcon,
    SortedOrder,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../dum';
import { Badge, Box, IconButton } from '@mui/material';
import {
    ColumnOrder,
    ExpensesPageQuery,
    ExpensesSortableFields,
    useExpensesPageLazyQuery,
} from '../../../../../services';
import {
    formatDate,
    formatFloat,
    useBoolean,
    useNumber,
    useString,
} from '../../../../../helpers';
import { SDeleteExpenseDialog, SUpsertExpenseDialog } from '../../../../smart';
import { DCheckbox } from '../../../../dum/simple/inputs/d-checkbox';
import { ContentPaste } from '@mui/icons-material';

export function ExpensesPage() {
    const [query, { data, previousData }] = useExpensesPageLazyQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [expenseId, setExpensesId] = useState<null | number>(null);

    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [filter, setFilter] = useString({
        KEY: ExpensesPage.name + '_Filter',
    });

    const { year, setYear } = useDYear({
        COMPONENT_KEY: ExpensesPage.name + '_Year',
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: ExpensesPage.name + '_Month',
    });
    const [accountId, setAccountId] = useNumber({
        KEY: ExpensesPage.name + '_AccountId',
    });

    const [receiptTypeId, setReceiptTypeId] = useNumber({
        KEY: ExpensesPage.name + '_ReceiptTypeId',
    });

    const [noReceipt, setNoReceipt] = useBoolean({
        KEY: ExpensesPage.name + '_NoReceipt',
        defaultValue: false,
    });

    const [isTransferComplete, setIsTransferComplete] = useBoolean({
        KEY: ExpensesPage.name + '_isTransferComplete',
        defaultValue: false,
    });

    const [noSupplement, setNoSupplement] = useBoolean({
        KEY: ExpensesPage.name + '_NoSupplement',
        defaultValue: false,
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: ExpensesPage.name + '_Paginator',
        },
        [month, year, filter],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                filter: filter,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
                accountId: accountId,
                sortField:
                    sortedIndex === 0 ? ExpensesSortableFields.Date : null,
                noReceipt: noReceipt,
                noSupplement: noSupplement,
                receiptTypeId: receiptTypeId,
                isTransferComplete: isTransferComplete,
            },
        });
    }, [
        query,
        skip,
        take,
        year,
        month,
        filter,
        sortedOrder,
        accountId,
        sortedIndex,
        noReceipt,
        noSupplement,
        receiptTypeId,
        isTransferComplete,
    ]);

    const [defaultCollapseOpen] = useBoolean({
        KEY: ExpensesPage.name + '_DefaultOpen',
        defaultValue: false,
    });

    const count =
        data && data?.paginatedExpenses.count >= 0
            ? data?.paginatedExpenses.count
            : previousData?.paginatedExpenses.count;

    const expenses: ExpensesPageQuery['paginatedExpenses']['docs'] =
        data?.paginatedExpenses.docs ||
        previousData?.paginatedExpenses.docs ||
        [];

    const handleEditExpenses = useMemo(() => {
        return (
            expense: ExpensesPageQuery['paginatedExpenses']['docs'][number],
        ) => {
            setExpensesId(expense.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteExpenses = useMemo(() => {
        return (
            expense: ExpensesPageQuery['paginatedExpenses']['docs'][number],
        ) => {
            setExpensesId(expense.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DBlock>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <DTextField
                        label={'Filtro'}
                        helperText={
                            'Filtro para el folio del pedido o las notas del pedido'
                        }
                        value={filter}
                        onChange={(val) => {
                            setFilter(val);
                        }}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <IconButton
                        onClick={() => {
                            const text =
                                data?.paginatedExpenses.docs
                                    .filter(
                                        (expense) =>
                                            expense.transfer_receipts.length >
                                            0,
                                    )
                                    .map((expense) => {
                                        const transfersText =
                                            expense.transfer_receipts
                                                .map((tr) => {
                                                    return `${formatDate(
                                                        tr.transfer
                                                            ?.transferred_date,
                                                    )} - $${formatFloat(
                                                        tr.amount,
                                                    )}`;
                                                })
                                                .join(', ');
                                        return `${expense.account?.compound_name}: ${expense.order_code} (${transfersText})`;
                                    })
                                    .join('\n') || '';

                            var textarea = document.createElement('textarea');
                            textarea.textContent = text;
                            textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
                            document.body.appendChild(textarea);
                            textarea.select();
                            try {
                                return document.execCommand('copy'); // Security exception may be thrown by some browsers.
                            } catch (ex) {
                                console.warn('Copy to clipboard failed.', ex);
                                return prompt(
                                    'Copy to clipboard: Ctrl+C, Enter',
                                    text,
                                );
                            } finally {
                                document.body.removeChild(textarea);
                            }
                        }}
                    >
                        <ContentPaste />
                    </IconButton>
                </Box>
                <Box sx={{ ml: 2 }}>
                    <CVerticalDropdown
                        items={[
                            {
                                component: (
                                    <DYear setYear={setYear} year={year} />
                                ),
                            },
                            {
                                component: (
                                    <DMonth setMonth={setMonth} month={month} />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={accountId}
                                        onChange={setAccountId}
                                        itemText={'compound_name'}
                                        label={'Proveedor'}
                                        options={data?.getAccounts || []}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={receiptTypeId}
                                        onChange={setReceiptTypeId}
                                        itemText={'name'}
                                        label={'Tipo'}
                                        options={data?.getReceiptTypes || []}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        align={'left'}
                                        disableGutters
                                        onChange={(val) => {
                                            setNoReceipt(val);
                                        }}
                                        value={noReceipt}
                                        label={'Sin Recibo'}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        align={'left'}
                                        disableGutters
                                        onChange={(val) => {
                                            setNoSupplement(val);
                                        }}
                                        value={noSupplement}
                                        label={
                                            'Faltantes por recibir complemento'
                                        }
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        align={'left'}
                                        disableGutters
                                        onChange={(val) => {
                                            setIsTransferComplete(val);
                                        }}
                                        value={isTransferComplete}
                                        label={'Pendientes por transferir'}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>

            <DPaginator
                skip={skip}
                setSkip={setSkip}
                take={take}
                count={count}
            />

            <CTableWrapper
                onClick={(expense) => {
                    handleEditExpenses(expense);
                }}
                contextItems={[
                    {
                        title: 'Editar',
                        callback: (expense) => {
                            handleEditExpenses(expense);
                        },
                    },
                    {
                        title: 'Borrar',
                        disable: (expense) => {
                            return !expense.is_deletable;
                        },
                        callback: (expense) => {
                            handleDeleteExpenses(expense);
                        },
                    },
                ]}
                sortedIndex={sortedIndex}
                sortedOrder={sortedOrder}
                setSortedIndex={setSortedIndex}
                setSortedOrder={setSortedOrder}
                defaultOpenCollapse={defaultCollapseOpen}
                items={[
                    {
                        title: 'Fecha',
                        sortable: true,
                        content: (expense) => {
                            return formatDate(expense.date);
                        },
                    },
                    {
                        title: 'Proveedor',
                        cellGrow: 3,
                        content: (expense) => {
                            return expense.account?.compound_name;
                        },
                    },
                    {
                        title: 'Tipo',
                        cellGrow: 3,
                        content: (expense) => {
                            return expense.receipt_type?.name;
                        },
                    },
                    {
                        title: 'Notas',
                        content: (expense) => {
                            return expense.notes;
                        },
                    },
                    {
                        title: 'Folio',
                        content: (expense) => {
                            return expense.order_code;
                        },
                    },
                    {
                        title: 'Total con IVA',
                        textAlign: 'right',
                        content: (expense) => {
                            return formatFloat(expense.total_with_tax);
                        },
                    },
                    {
                        title: 'Transferencias',
                        textAlign: 'right',
                        content: (expense) => {
                            return (
                                <Box>
                                    {expense.total_with_tax -
                                        expense?.transfer_receipts_total ===
                                    0 ? (
                                        <Badge
                                            sx={{ mr: 1 }}
                                            variant={'dot'}
                                            badgeContent={' '}
                                            color={'success'}
                                        />
                                    ) : (
                                        formatFloat(
                                            expense?.transfer_receipts_total,
                                        )
                                    )}{' '}
                                </Box>
                            );
                        },
                    },
                    {
                        title: 'Acciones',
                        content: (expense) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <IconButton
                                        disabled={false}
                                        onClick={() => {
                                            handleEditExpenses(expense);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!expense.is_deletable}
                                        onClick={() => {
                                            handleDeleteExpenses(expense);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
                data={expenses}
            />
            <SUpsertExpenseDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                expenseId={expenseId}
            />
            <SDeleteExpenseDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                expenseId={expenseId}
            />
        </DBlock>
    );
}
