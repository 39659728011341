import { IOrderRequest } from '../order-request-form';
import { ValidationError } from 'yup';

export function areRequestProductsMoreThanProductsSold(
    value: any,
    context: any,
): boolean | ValidationError {
    const { product, kilos, groups } = context.from[0]
        .value as IOrderRequest['order_request_products'][number];

    if (!product) return true;

    const orderRequest = context.from[1].value as IOrderRequest;
    const orderSaleSoldProducts = orderRequest.order_sale_sold_products;

    const foundSaleSoldProduct = orderSaleSoldProducts.find(
        (saleProduct) => saleProduct.product_id === product.id,
    );

    if (!foundSaleSoldProduct) return true;

    const kilosValid = !(kilos < foundSaleSoldProduct.kilos);
    const groupsValid = !(groups < foundSaleSoldProduct.groups);

    if (!(kilosValid && groupsValid)) {
        return context.createError({
            message: `No se puede reducir la cantidad de kilos porque ya se vendieron`,
        });
    }

    return true;
}
