import React, { useMemo } from 'react';
import { GeneralSalesComparisonQuery } from '../../../../../services';
import { formatGeneralComparisonResponse } from '../helpers/format-general-comparison-response';
import {
    DBlock,
    DTable,
    DTableBody,
    DTableBodyCell,
    DTableBodyRow,
    DTableHead,
    DTableHeadCell,
    DTableHeadRow,
} from '../../../../dum';
import { formatFloat } from '../../../../../helpers';

interface ITotalComparison {
    data?: GeneralSalesComparisonQuery;
    date1: string;
    date2: string;
}

export function TotalComparison({ data, date1, date2 }: ITotalComparison) {
    const {
        date1KilosSold,
        date1Total,
        date1Tax,
        date1TotalWithTax,
        date1NotesKilosSold,
        date1InvoiceKilosSold,
        date2KilosSold,
        date2Total,
        date2Tax,
        date2TotalWithTax,
        date2NotesKilosSold,
        date2InvoiceKilosSold,
        kilosDifference,
        notesKilosDifference,
        invoiceKilosDifference,
        totalWithTaxDifference,
        taxDifference,
        totalDifference,
    } = useMemo(() => {
        const {
            kilosSold: date1KilosSold,
            total: date1Total,
            tax: date1Tax,
            totalWithTax: date1TotalWithTax,
            notesKilosSold: date1NotesKilosSold,
            invoiceKilosSold: date1InvoiceKilosSold,
        } = formatGeneralComparisonResponse({
            totalSales: data?.getDate1TotalSalesSummary,
            receiptSales: data?.getDate1ReceiptSalesSummary,
        });

        const {
            kilosSold: date2KilosSold,
            total: date2Total,
            tax: date2Tax,
            totalWithTax: date2TotalWithTax,
            notesKilosSold: date2NotesKilosSold,
            invoiceKilosSold: date2InvoiceKilosSold,
        } = formatGeneralComparisonResponse({
            totalSales: data?.getDate2TotalSalesSummary,
            receiptSales: data?.getDate2ReceiptSalesSummary,
        });

        return {
            date1KilosSold,
            date1Total,
            date1Tax,
            date1TotalWithTax,
            date1NotesKilosSold,
            date1InvoiceKilosSold,
            date2KilosSold,
            date2Total,
            date2Tax,
            date2TotalWithTax,
            date2NotesKilosSold,
            date2InvoiceKilosSold,
            kilosDifference: date2KilosSold - date1KilosSold,
            notesKilosDifference: date2NotesKilosSold - date1NotesKilosSold,
            invoiceKilosDifference:
                date2InvoiceKilosSold - date1InvoiceKilosSold,
            totalDifference: date2Total - date1Total,
            taxDifference: date2Tax - date1Tax,
            totalWithTaxDifference: date2TotalWithTax - date1TotalWithTax,
        };
    }, [data]);

    return (
        <DBlock>
            <DTable>
                <DTableHead>
                    <DTableHeadRow>
                        <DTableHeadCell width={20} />
                        <DTableHeadCell width={20} />
                        <DTableHeadCell width={20}>{date1}</DTableHeadCell>
                        <DTableHeadCell width={20}>{date2}</DTableHeadCell>
                        <DTableHeadCell width={20}>Diferencia</DTableHeadCell>
                    </DTableHeadRow>
                </DTableHead>
                <DTableBody>
                    <DTableBodyRow>
                        <DTableBodyCell fontWeight={'bold'}>
                            Kilos
                        </DTableBodyCell>
                        <DTableBodyCell />
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(date1KilosSold)} kg
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(date2KilosSold)} kg
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(kilosDifference)} kg
                        </DTableBodyCell>
                    </DTableBodyRow>
                    <DTableBodyRow>
                        <DTableBodyCell />
                        <DTableBodyCell fontWeight={'bold'}>
                            Notas
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(date1NotesKilosSold)} kg
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(date2NotesKilosSold)} kg
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(notesKilosDifference)} kg
                        </DTableBodyCell>
                    </DTableBodyRow>
                    <DTableBodyRow>
                        <DTableBodyCell />
                        <DTableBodyCell fontWeight={'bold'}>
                            Facturas
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(date1InvoiceKilosSold)} kg
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(date2InvoiceKilosSold)} kg
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            {formatFloat(invoiceKilosDifference)} kg
                        </DTableBodyCell>
                    </DTableBodyRow>
                    <DTableBodyRow>
                        <DTableBodyCell fontWeight={'bold'}>
                            Total sin iva
                        </DTableBodyCell>
                        <DTableBodyCell />
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(date1Total)}
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(date2Total)}
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(totalDifference)}
                        </DTableBodyCell>
                    </DTableBodyRow>
                    <DTableBodyRow>
                        <DTableBodyCell fontWeight={'bold'}>iva</DTableBodyCell>
                        <DTableBodyCell />
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(date1Tax)}
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(date2Tax)}
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(taxDifference)}
                        </DTableBodyCell>
                    </DTableBodyRow>
                    <DTableBodyRow>
                        <DTableBodyCell fontWeight={'bold'}>
                            Total con iva
                        </DTableBodyCell>
                        <DTableBodyCell />
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(date1TotalWithTax)}
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(date2TotalWithTax)}
                        </DTableBodyCell>
                        <DTableBodyCell textAlign={'right'}>
                            $ {formatFloat(totalWithTaxDifference)}
                        </DTableBodyCell>
                    </DTableBodyRow>
                </DTableBody>
            </DTable>
        </DBlock>
    );
}
