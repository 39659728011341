import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../dum';
import { GroupsInput } from './groups/groups-input';
import { KilosInput } from './kilos/kilos-input';
import { OrderProductionProductInput } from './product/product-input';
import { IOrderAdjustment } from '../order-adjustment-form';

const ARRAY_NAME: keyof IOrderAdjustment = 'order_adjustment_products';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'group_weight';

const defaultValue: IOrderAdjustment['order_adjustment_products'][number] = {
    product: null,
    kilos: 0,
    groups: 0,
    group_weight: 0,
};

export function OrderAdjustmentProductsColumns({
    initialValues,
}: {
    initialValues: IOrderAdjustment;
}) {
    return (
        <RhColumns
            title={'Productos'}
            name={ARRAY_NAME}
            defaultValue={defaultValue}
            items={[
                {
                    cellGrow: 3,
                    getField: (index) => {
                        return (
                            <OrderProductionProductInput
                                index={index}
                                initialValues={initialValues}
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return <GroupsInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`}
                                label={'Peso'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return <KilosInput index={index} />;
                    },
                },
            ]}
        />
    );
}
