import React from 'react';
import { RhCheckbox } from '../../../../../../../dum';
import { IOrderProduction } from '../../order-production-form';
import { useFormContext } from 'react-hook-form';

const ARRAY_NAME: keyof IOrderProduction = 'order_production_employees';
const IS_LEADER_FIELD_NAME: keyof IOrderProduction['order_production_employees'][number] =
    'is_leader';

interface IInputProps {
    index: number;
}

export function OrderProductionIsLeaderInput({ index }: IInputProps) {
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${IS_LEADER_FIELD_NAME}`;
    const formContext = useFormContext();

    return (
        <RhCheckbox
            name={INPUT_NAME}
            label={'Operador'}
            disableGutters
            onChange={() => {
                const orderProductionEmployees = formContext.getValues(
                    ARRAY_NAME,
                ) as IOrderProduction['order_production_employees'];
                orderProductionEmployees.forEach((e, i) => {
                    void formContext.trigger(
                        `${ARRAY_NAME}.${i}.${IS_LEADER_FIELD_NAME}`,
                    );
                });
            }}
        />
    );
}
