import React from 'react';
import { TableCell } from '@mui/material';

interface ICustomTableHeadCellProps {
    children?: React.ReactNode;
    width?: number;
    onClick?: () => void;
}

export function DTableHeadCell({
    children,
    width,
    onClick,
}: ICustomTableHeadCellProps) {
    return (
        <TableCell
            sx={{
                textAlign: 'center',
                width: width ? `${width}%` : 'initial',
                backgroundColor: 'primary',
                fontWeight: 700,
                color: onClick ? 'primary.main' : 'inherit',
                cursor: onClick ? 'pointer' : 'inherit',
            }}
            onClick={onClick}
        >
            {children}
        </TableCell>
    );
}
