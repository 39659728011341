import React, { useEffect, useMemo } from 'react';
import {
    TransferReceiptExpenseInputQuery,
    useTransferReceiptExpenseInputLazyQuery,
} from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { ITransfer, TO_ACCOUNT } from '../../transfer-form';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    AMOUNT,
    EXPENSE,
    TOTAL,
    TRANSFER_RECEIPTS,
} from '../transfer-receipt-columns';
import { useCalculateTransferReceiptsTotal } from '../../use-calculate-transfer-receipt-total';

interface IResourceInput {
    index: number;
}

export function ExpensesInput({ index }: IResourceInput) {
    const [query, { data }] = useTransferReceiptExpenseInputLazyQuery();
    const { calculateTransferReceiptsTotal } =
        useCalculateTransferReceiptsTotal();
    const INPUT_NAME = `${TRANSFER_RECEIPTS}.${index}.${EXPENSE}`;
    const toAccount = useWatch({
        name: TO_ACCOUNT,
    }) as ITransfer['to_account'];
    const rx = useFormContext();

    useEffect(() => {
        if (toAccount?.is_supplier) {
            void query({
                variables: {
                    account_id: toAccount.id,
                },
            });
        }
    }, [query, toAccount]);

    const expenses: TransferReceiptExpenseInputQuery['getExpenses'] =
        useMemo(() => {
            return data?.getExpenses || [];
        }, [data]);
    return (
        <RhSingleAutocomplete
            itemText={'compound_order_code'}
            itemValue={'id'}
            options={expenses}
            name={INPUT_NAME}
            label={'Gasto'}
            disableGutters
            returnObject
            onChange={(val: typeof expenses[number]) => {
                rx.setValue(
                    `${TRANSFER_RECEIPTS}.${index}.${TOTAL}`,
                    val.total_with_tax || 0,
                    {
                        shouldValidate: true,
                    },
                );
                rx.setValue(
                    `${TRANSFER_RECEIPTS}.${index}.${AMOUNT}`,
                    val.total_with_tax || 0,
                    {
                        shouldValidate: true,
                    },
                );
                calculateTransferReceiptsTotal({ index });
            }}
        />
    );
}
