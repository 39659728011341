import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrderAdjustmentPageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { SUpsertOrderAdjustmentDialog } from '../../../../smart';

type TOrderAdjustmentPageParams = {
    orderAdjustmentId: string;
};

export function OrderAdjustmentPage() {
    const params = useParams<TOrderAdjustmentPageParams>();
    const [orderAdjustmentDialog, setOrderAdjustmentDialog] = useState(false);
    const { data, loading, error } = useOrderAdjustmentPageQuery({
        variables: {
            OrderAdjustmentId: params.orderAdjustmentId
                ? Number(params.orderAdjustmentId)
                : 0,
        },
    });

    if (loading) {
        return <DPageLoader />;
    }

    if (error || !data) {
        return <DErrorCard />;
    }

    const orderAdjustment = data.getOrderAdjustment!;

    return (
        <DPage animated>
            <DPageHeader title={'Orden de ajuste'}>
                <DRowButtons
                    rowItems={[
                        {
                            tooltip: 'Editar order de ajuste',
                            icon: <EditIcon />,
                            callback: () => {
                                setOrderAdjustmentDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <Box>{orderAdjustment?.date}</Box>
            </DPageBody>
            <SUpsertOrderAdjustmentDialog
                open={orderAdjustmentDialog}
                setOpen={setOrderAdjustmentDialog}
                orderAdjustmentId={orderAdjustment.id}
            />
        </DPage>
    );
}
