import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage } from '../../../dum';
import { SUpsertProductDialog } from '../../../smart';
import { ProductPage } from './product/product-page';
import { ProductsPage } from './products/products-page';
import { DFab } from '../../../dum/simple/button/d-fab';

const productsPages: Route[] = [
    {
        name: 'ProductsListPage',
        path: '/',
        component: <ProductsPage />,
        title: 'Productos',
        navbar: false,
    },
    {
        name: 'ProductPage',
        path: '/:productId',
        component: <ProductPage />,
        title: 'Productos',
        navbar: false,
    },
];

export function ProductsHomePage() {
    const [productDialog, setProductDialog] = useState(false);

    return (
        <DPage>
            <DFab
                onClick={() => {
                    setProductDialog(true);
                }}
            />
            <ReactRouterRoutes>
                {productsPages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertProductDialog
                open={productDialog}
                setOpen={setProductDialog}
            />
        </DPage>
    );
}
