import { GeneralSalesComparisonFragment } from '../../../../../services';

export function formatGeneralComparisonResponse({
    totalSales,
    receiptSales,
}: {
    totalSales: GeneralSalesComparisonFragment | undefined;
    receiptSales: GeneralSalesComparisonFragment | undefined;
}): {
    kilosSold: number;
    total: number;
    tax: number;
    totalWithTax: number;
    notesKilosSold: number;
    invoiceKilosSold: number;
} {
    const kilosSold =
        totalSales && totalSales.sales[0] ? totalSales.sales[0].kilos_sold : 0;
    const total =
        totalSales && totalSales?.sales[0] ? totalSales.sales[0].total : 0;
    const tax = totalSales && totalSales.sales[0] ? totalSales.sales[0].tax : 0;
    const totalWithTax =
        totalSales && totalSales.sales[0]
            ? totalSales.sales[0].total_with_tax
            : 0;

    const notesKilosSold =
        receiptSales && receiptSales?.sales
            ? receiptSales.sales.find((el) => el.receipt_type_id === 1)
                  ?.kilos_sold || 0
            : 0;
    const invoiceKilosSold =
        receiptSales && receiptSales?.sales
            ? receiptSales.sales.find((el) => el.receipt_type_id === 2)
                  ?.kilos_sold || 0
            : 0;

    return {
        kilosSold,
        total,
        tax,
        totalWithTax,
        notesKilosSold,
        invoiceKilosSold,
    };
}
