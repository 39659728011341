import React from 'react';
import { TableHead } from '@mui/material';

interface ICustomTableHeaderProps {
    children: React.ReactNode;
}

export function DTableHead({ children }: ICustomTableHeaderProps) {
    return <TableHead>{children}</TableHead>;
}
