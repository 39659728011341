import {
    DSection,
    RhCheckbox,
    RhDateInput,
    RhFormWrapper,
    RHTextField,
} from '../../../../../dum';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    getDateFromString,
    getStringFromDate,
    transformToNumber,
} from '../../../../../../helpers';
import { useUpsertExpensesDialogMutation } from '../../../../../../services';
import { ExpenseAccountIdInput } from './account-id/purchase-account-id-input';
import { ExpenseReceiptTypeIdInput } from './receipt-type-id/receipt-type-id-input';
import { Box } from '@mui/material';
import { TaxInput } from './tax/tax';
import { TaxRetainedInput } from './tax-retained/tax-retained';
import { SupplementCodeInput } from './supplement-code/supplement-code';
import { NonTaxRetainedInput } from './non-tax-retained/non-tax-retained';
import { ExpenseRawMaterialAdditionsColumns } from './expense-raw-material-additions-columns/expense-raw-material-additions-columns';
import { SubtotalInput } from './subtotal/subtotal';
import { TransferReceiptColumns } from './transfer-receipts/transfer-receipt-columns';
import { OrderCodeInput } from './order-code/order-code';

export interface IExpense {
    id?: number | null;
    locked: boolean;
    require_supplement: boolean;
    canceled: boolean;
    supplement_code?: string | null;
    date?: string | null;
    expected_payment_date?: string | null;
    account_id?: number | null;
    notes?: string;
    receipt_type_id?: number | null;
    require_order_code: boolean;
    order_code?: string | null;
    tax: number;
    subtotal: number;
    tax_retained: number;
    non_tax_retained: number;
    expense_raw_material_additions: {
        id?: number | null;
        amount: number;
        raw_material_addition_id?: number | null;
    }[];
    expense_raw_material_additions_total: number;
    total_with_tax: number;
    transfer_receipts?: {
        id?: number | null;
        amount: number;
        transferred_date?: string | null;
        transfer?: {
            transferred_date: string;
            from_account?: {
                compound_name: string;
            } | null;
        } | null;
        compound_name?: string | null;
    }[];
    transfer_receipts_total_no_adjustments?: number | null;
}

export const TAX: keyof IExpense = 'tax';
export const TAX_RETAINED: keyof IExpense = 'tax_retained';
export const NON_TAX_RETAINED: keyof IExpense = 'non_tax_retained';
export const TOTAL_WITH_TAX: keyof IExpense = 'total_with_tax';
export const TRANSFER_RECEIPTS_TOTAL: keyof IExpense =
    'transfer_receipts_total_no_adjustments';
export const SUBTOTAL: keyof IExpense = 'subtotal';
export const REQUIRE_SUPPLEMENT: keyof IExpense = 'require_supplement';
export const REQUIRE_ORDER_CODE: keyof IExpense = 'require_order_code';
export const ORDER_CODE: keyof IExpense = 'order_code';
export const CANCELED: keyof IExpense = 'canceled';
export const EXPENSE_RAW_MATERIAL_ADDITIONS: keyof IExpense =
    'expense_raw_material_additions';
export const RAW_MATERIAL_ADDITION_ID: keyof IExpense['expense_raw_material_additions'][number] =
    'raw_material_addition_id';
export const EXPENSE_RAW_MATERIAL_ADDITIONS_TOTAL: keyof IExpense =
    'expense_raw_material_additions_total';

interface IExpensesForm {
    expense?: IExpense | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function ExpenseForm({
    expense,
    onSuccess,
    disabled = false,
}: IExpensesForm) {
    const [mutation, { loading }] = useUpsertExpensesDialogMutation();

    const initialValues = useMemo<IExpense>(() => {
        return {
            id: expense?.id || undefined,
            date:
                expense && expense.date
                    ? getDateFromString(expense.date)
                    : null,
            notes: expense?.notes || '',
            tax: expense?.tax || 0,
            total_with_tax: expense?.total_with_tax || 0,
            subtotal: expense?.subtotal || 0,
            non_tax_retained: expense?.non_tax_retained || 0,
            tax_retained: expense?.tax_retained || 0,
            expected_payment_date:
                expense && expense.expected_payment_date
                    ? getDateFromString(expense.expected_payment_date)
                    : null,
            account_id: expense ? expense.account_id : null,
            receipt_type_id: expense ? expense.receipt_type_id : null,
            locked: expense?.locked || false,
            require_supplement: expense?.require_supplement || false,
            require_order_code: expense ? expense.require_order_code : true,
            canceled: expense?.canceled || false,
            expense_raw_material_additions:
                expense?.expense_raw_material_additions || [],
            order_code: expense ? expense.order_code : '',
            supplement_code: expense ? expense.supplement_code : '',
            expense_raw_material_additions_total: expense
                ? expense.expense_raw_material_additions_total
                : 0,
            transfer_receipts: expense?.transfer_receipts?.map((tr) => {
                return {
                    id: tr.id,
                    transferred_date: tr.transfer?.transferred_date || null,
                    amount: tr.amount || 0,
                    to_account: null,
                    compound_name:
                        tr.transfer?.from_account?.compound_name || '',
                };
            }),
            transfer_receipts_total_no_adjustments:
                expense?.transfer_receipts_total_no_adjustments || 0,
        };
    }, [expense]);

    const validationSchema: SchemaOf<IExpense> = yup.object({
        id: yup.number().notRequired().nullable(),
        date: yup.string().nullable().required(),
        expected_payment_date: yup.string().nullable(),
        order_code: yup.string().nullable().notRequired(),
        supplement_code: yup.string().nullable().notRequired(),
        require_supplement: yup.boolean().required(),
        require_order_code: yup.boolean().required(),
        canceled: yup.boolean().required(),
        subtotal: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required('`Subtotal` es obligatorio'),
        total_with_tax: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required('`Total` es obligatorio'),
        tax: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required('`IVA` es obligatorio'),
        tax_retained: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required('`IVA retenido` es obligatorio'),
        non_tax_retained: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required('`ISR retenido` es obligatorio'),
        locked: yup.boolean().required(),
        notes: yup.string(),
        account_id: yup
            .number()
            .transform(transformToNumber)
            .required(`'Proveedor' es obligatorio`)
            .nullable(),
        receipt_type_id: yup
            .number()
            .transform(transformToNumber)
            .required(`'Tipo de recibo' es obligatorio`)
            .nullable(),
        expense_raw_material_additions: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    amount: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required('`Cantidad` es obligatorio'),
                    raw_material_addition_id: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required('`Adquicision` es obligatorio'),
                }),
            )
            .required('Required'),
        expense_raw_material_additions_total: yup
            .number()
            .transform(transformToNumber)
            .required()
            .nullable(),
        transfer_receipts: yup.mixed().nullable(),
        transfer_receipts_total_no_adjustments: yup.mixed().notRequired(),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            ExpenseUpsertInput: {
                                id: expense?.id || null,
                                date: getStringFromDate(data.date!),
                                expected_payment_date:
                                    data.expected_payment_date
                                        ? getStringFromDate(
                                              data.expected_payment_date!,
                                          )
                                        : null,
                                notes: data.notes || '',
                                locked: data.locked || false,
                                require_supplement:
                                    data.require_supplement || false,
                                require_order_code:
                                    data.require_order_code || false,
                                canceled: data.canceled || false,
                                subtotal: data?.subtotal || 0,
                                supplement_code: data.supplement_code || '',
                                account_id: data.account_id || null,
                                receipt_type_id: data.receipt_type_id || null,
                                order_code: data?.order_code || '',
                                tax: data?.tax || 0,
                                tax_retained: data?.tax_retained || 0,
                                non_tax_retained: data?.non_tax_retained || 0,
                                expense_raw_material_additions:
                                    data?.expense_raw_material_additions.map(
                                        (erma) => {
                                            return {
                                                id: erma.id,
                                                amount: erma.amount,
                                                raw_material_addition_id:
                                                    erma.raw_material_addition_id,
                                            };
                                        },
                                    ) || [],
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <ExpenseReceiptTypeIdInput />
            <DSection alwaysOpen>
                <RhDateInput name={'date'} label={'Fecha en que se realizo'} />
                <ExpenseAccountIdInput />
                <SubtotalInput />
                <RHTextField
                    disabled
                    name={TOTAL_WITH_TAX}
                    label={'Total (iva incluido)'}
                />
            </DSection>
            <DSection title={'Cantidades (avanzado)'}>
                <SubtotalInput />
                <TaxInput />
                <TaxRetainedInput />
                <NonTaxRetainedInput />
                <RHTextField disabled name={TOTAL_WITH_TAX} label={'Total'} />
            </DSection>

            <DSection title={'Datos opcionales'}>
                <RhCheckbox name={REQUIRE_ORDER_CODE} label={'Require folio'} />
                <OrderCodeInput />
                <RhDateInput
                    name={'expected_payment_date'}
                    label={'Fecha del pago'}
                />
                <RHTextField name={'notes'} label={'Notas'} rows={3} />
                <RhCheckbox
                    name={REQUIRE_SUPPLEMENT}
                    label={'Require complemento'}
                />
                <SupplementCodeInput />
                <RhCheckbox name={CANCELED} label={'Cancelada'} />
            </DSection>

            <DSection title={'Entregas de almacén'}>
                <ExpenseRawMaterialAdditionsColumns />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'stretch',
                    }}
                >
                    <Box sx={{ ml: 1, mr: 1, flex: 1 }}>
                        <RHTextField
                            name={EXPENSE_RAW_MATERIAL_ADDITIONS_TOTAL}
                            label={'Total de entregas de almacén'}
                            disabled
                        />
                    </Box>
                </Box>
            </DSection>
            <DSection title={'Transferencias'}>
                <TransferReceiptColumns />
                <RHTextField
                    disabled
                    name={TRANSFER_RECEIPTS_TOTAL}
                    label={'Transferido total'}
                />
            </DSection>
            {/*<RhCheckbox name={'locked'} label={'Bloquear'} />*/}
        </RhFormWrapper>
    );
}
