import React from 'react';
import { useMachineInputQuery } from '../../../../../services';
import { RhSingleAutocomplete } from '../../../../dum';

interface ISSingleMachineAutocompleteProps {
    name: string;
    label: string;
    disabled?: boolean;
}

export function SSingleMachineAutocomplete({
    name,
    label,
    disabled,
}: ISSingleMachineAutocompleteProps) {
    const { data } = useMachineInputQuery();

    const machines = data?.getMachines || [];

    return (
        <RhSingleAutocomplete
            disabled={disabled}
            itemText={'name'}
            itemValue={'id'}
            name={name}
            label={label}
            options={machines}
        />
    );
}
