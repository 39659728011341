import { ExpenseSummaryWorkSheets } from './expenses-summary';
import { Button } from '@mui/material';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import React from 'react';

export function ExpenseSummaryExcelExportButton({
    worksheets,
    disabled,
}: {
    worksheets: ExpenseSummaryWorkSheets;
    disabled: boolean;
}) {
    return (
        <Button
            disabled={disabled}
            onClick={async () => {
                const workbook = new Excel.Workbook();
                const {
                    accountsPages,
                    orderSalePage,
                    expensePage,
                    canceledExpensePage,
                } = worksheets;
                accountsPages.forEach((accountPage, index) => {
                    const { data: accountPageData, accountName } = accountPage;
                    const ws = workbook.addWorksheet(accountName);
                    ws.addRow([
                        '#',
                        'Tipo',
                        'De',
                        'Para',
                        'Dia/hora de la transferencia',
                        'Folio de la factura',
                        'Fecha de emision de la factura',
                        'Cantidad transferida',
                        'Cantidad de la factura',
                        'IVA de la factura',
                        'Iva retenido',
                        'Isr Retenido',
                        'Notas',
                        'Complemento',
                        'Nota de credito',
                    ]);
                    accountPageData.forEach((data) => {
                        ws.addRow([
                            data.transferIndex,
                            data.transferType,
                            data.fromAccountName,
                            data.toAccountName,
                            data.transferDate,
                            data.orderCode,
                            data.receiptIssueDate,
                            data.transferredAmount,
                            data.receiptTotal,
                            data.receiptTax,
                            data.taxRetained,
                            data.nonTaxRetained,
                            data.notes,
                            data.supplement,
                            data.creditNote,
                        ]);
                    });

                    ws.columns.forEach((c, i) => {
                        let maxColumnLength = 6;
                        const column = ws.getColumn(i);
                        if (column) {
                            column.eachCell({ includeEmpty: true }, (cell) => {
                                maxColumnLength = Math.max(
                                    maxColumnLength,
                                    cell.value
                                        ? cell.value.toString().length
                                        : 0,
                                );
                            });

                            column.width = maxColumnLength;
                        }
                    });

                    ws.getColumn(6).alignment = {
                        horizontal: 'right',
                    };
                    ws.getColumn(8).alignment = {
                        horizontal: 'right',
                    };
                    ws.getColumn(9).alignment = {
                        horizontal: 'right',
                    };
                    ws.getColumn(10).alignment = {
                        horizontal: 'right',
                    };
                    ws.getColumn(11).alignment = {
                        horizontal: 'right',
                    };
                    ws.getColumn(12).alignment = {
                        horizontal: 'right',
                    };
                });

                const orderSalesWorksheet = workbook.addWorksheet('Facturas');

                orderSalesWorksheet.addRow([
                    'Folio',
                    'Fecha de emisión',
                    'Cliente',
                    'Total (con descuentos e IVA)',
                    'IVA',
                    'Importe de la factura (sin descuentos)',
                    'Cantidad transferida',
                    'Transferencias',
                    'Complemento',
                    'Nota de credito',
                    'Notas',
                    'Cancelada',
                ]);

                orderSalePage?.forEach((orderSale) => {
                    orderSalesWorksheet.addRow([
                        orderSale.invoiceCode,
                        orderSale.date,
                        orderSale.accountName,
                        orderSale.total,
                        orderSale.tax,
                        orderSale.invoiceTotal,
                        orderSale.transferReceiptsTotal,
                        orderSale.transfers,
                        orderSale.supplement,
                        orderSale.credit_note,
                        orderSale.notes,
                        orderSale.canceled,
                    ]);
                });

                orderSalesWorksheet.getColumn(4).alignment = {
                    horizontal: 'right',
                };
                orderSalesWorksheet.getColumn(5).alignment = {
                    horizontal: 'right',
                };
                orderSalesWorksheet.getColumn(6).alignment = {
                    horizontal: 'right',
                };
                orderSalesWorksheet.getColumn(7).alignment = {
                    horizontal: 'right',
                };

                orderSalesWorksheet.columns.forEach((c, i) => {
                    let maxColumnLength = 6;
                    const column = orderSalesWorksheet.getColumn(i + 1);
                    if (column) {
                        column.eachCell({ includeEmpty: true }, (cell) => {
                            maxColumnLength = Math.max(
                                maxColumnLength,
                                cell.value ? cell.value.toString().length : 0,
                            );
                        });

                        column.width = maxColumnLength;
                    }
                });

                const expensesWorksheet = workbook.addWorksheet('Gastos');

                expensesWorksheet.addRow([
                    'Folio',
                    'Fecha de emisión',
                    'Cliente',
                    'IVA',
                    'IVA retenido',
                    'Total con IVA',
                    'Cantidad transferida',
                    'Transferencias',
                    'Complemento',
                    'Notas',
                    'Cancelada',
                ]);

                expensePage?.forEach((expense) => {
                    expensesWorksheet.addRow([
                        expense.orderCode,
                        expense.date,
                        expense.accountName,
                        expense.tax,
                        expense.taxRetained,
                        expense.expenseResourcesTotalWithTax,
                        expense.transferReceiptsTotal,
                        expense.transfers,
                        expense.supplement,
                        expense.notes,
                        expense.canceled,
                    ]);
                });

                expensesWorksheet.getColumn(4).alignment = {
                    horizontal: 'right',
                };
                expensesWorksheet.getColumn(5).alignment = {
                    horizontal: 'right',
                };
                expensesWorksheet.getColumn(6).alignment = {
                    horizontal: 'right',
                };
                expensesWorksheet.getColumn(7).alignment = {
                    horizontal: 'right',
                };

                expensesWorksheet.columns.forEach((c, i) => {
                    let maxColumnLength = 6;
                    const column = expensesWorksheet.getColumn(i + 1);
                    if (column) {
                        column.eachCell({ includeEmpty: true }, (cell) => {
                            maxColumnLength = Math.max(
                                maxColumnLength,
                                cell.value ? cell.value.toString().length : 0,
                            );
                        });

                        column.width = maxColumnLength;
                    }
                });

                const canceledExpensesWorksheet = workbook.addWorksheet(
                    'Facturas canceladas (Proveedores)',
                );

                canceledExpensesWorksheet.addRow([
                    'Folio',
                    'Fecha de emisión',
                    'Cliente',
                    'IVA',
                    'IVA retenido',
                    'Total con IVA',
                ]);

                canceledExpensePage?.forEach((expense) => {
                    canceledExpensesWorksheet.addRow([
                        expense.orderCode,
                        expense.date,
                        expense.accountName,
                        expense.tax,
                        expense.taxRetained,
                        expense.expenseResourcesTotalWithTax,
                    ]);
                });

                canceledExpensesWorksheet.getColumn(4).alignment = {
                    horizontal: 'right',
                };
                canceledExpensesWorksheet.getColumn(5).alignment = {
                    horizontal: 'right',
                };
                canceledExpensesWorksheet.getColumn(6).alignment = {
                    horizontal: 'right',
                };
                canceledExpensesWorksheet.getColumn(7).alignment = {
                    horizontal: 'right',
                };

                canceledExpensesWorksheet.columns.forEach((c, i) => {
                    let maxColumnLength = 6;
                    const column = expensesWorksheet.getColumn(i + 1);
                    if (column) {
                        column.eachCell({ includeEmpty: true }, (cell) => {
                            maxColumnLength = Math.max(
                                maxColumnLength,
                                cell.value ? cell.value.toString().length : 0,
                            );
                        });

                        column.width = maxColumnLength;
                    }
                });

                const buffer = await workbook.xlsx.writeBuffer();

                FileSaver.saveAs(
                    new Blob([buffer]),
                    'resumen-contabilidad.xlsx',
                );
            }}
        >
            Descargar reporte
        </Button>
    );
}
