import React from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    clearMaintenanceModule,
    useUpsertSpareCategoryMutation,
} from '../../../../../../services';
import { DModal, RhFormWrapper, RHTextField } from '../../../../../dum';
import { SpareIcon } from '../../../../icons';

export interface ISpareCategoryForm {
    name: string;
}

interface ISpareCategory extends ISpareCategoryForm {
    id?: number;
}

interface IFormDialogProps {
    spareCategory?: ISpareCategory;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SSpareCategoryFormDialog = (props: IFormDialogProps) => {
    const { open, setOpen, spareCategory } = props;

    const [mutation, { loading }] = useUpsertSpareCategoryMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    const initialValues: ISpareCategoryForm = {
        name: spareCategory?.name || '',
    };

    const validationSchema: SchemaOf<ISpareCategoryForm> = yup.object({
        name: yup.string().required(),
    });

    return (
        <DModal
            icon={<SpareIcon />}
            title={`${!!spareCategory?.id ? 'Editar' : 'Crear'} categoria`}
            open={open}
            setOpen={setOpen}
        >
            <RhFormWrapper
                onSubmit={async (data) => {
                    await mutation({
                        variables: {
                            SpareCategoryUpsertInput: {
                                id: spareCategory?.id || null,
                                name: data?.name!,
                            },
                        },
                    });
                    setOpen(false);
                    return;
                }}
                validationSchema={validationSchema}
                initialValues={initialValues}
                disabled={loading}
            >
                <RHTextField name={'name'} label={'Nombre'} />
            </RhFormWrapper>
        </DModal>
    );
};
