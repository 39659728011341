import React from 'react';
import { useWatch } from 'react-hook-form';
import { RhSingleAutocomplete } from '../../../../../../../../dum';
import { IOrderSale } from '../../../order-sale-form';

export function EditOrderRequestInput() {
    const inputName: keyof IOrderSale = 'order_request';

    const inputValue = useWatch({
        name: inputName,
    }) as IOrderSale['order_request'];

    const options = inputValue ? [inputValue] : [];

    return (
        <RhSingleAutocomplete
            disabled
            itemText={'order_code'}
            itemValue={'id'}
            name={inputName}
            label={'Pedido'}
            options={options}
            returnObject
        />
    );
}
