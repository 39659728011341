import React from 'react';
import { Box, Paper, SxProps } from '@mui/material';

interface ICustomPageHeaderLayout {
    children: React.ReactNode | React.ReactNode[];
    sx?: SxProps;
}

export function DBlock(props: ICustomPageHeaderLayout) {
    const { children, sx } = props;
    return (
        <Box
            sx={{ py: 4, px: 4, mb: 4, ...sx }}
            component={Paper}
            variant={'outlined'}
        >
            {children}
        </Box>
    );
}
