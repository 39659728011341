import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteRawMaterialAdditionMutation } from '../../../../../../services';

interface IDeleteRawMaterialAdditionProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    rawMaterialAdditionId: number | null;
}

export function SDeleteRawMaterialAdditionDialog({
    open,
    setOpen,
    rawMaterialAdditionId,
}: IDeleteRawMaterialAdditionProps) {
    const [deleteMutation] = useDeleteRawMaterialAdditionMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (rawMaterialAdditionId) {
                    try {
                        await deleteMutation({
                            variables: {
                                RawMaterialAdditionId: rawMaterialAdditionId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
