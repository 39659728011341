import { ValidationError } from 'yup';
import { IOrderAdjustment } from '../order-adjustment-form';

export function isProductInOrderSales(
    value: any,
    context: any,
): boolean | ValidationError {
    const { product } = context.from[0]
        .value as IOrderAdjustment['order_adjustment_products'][number];

    if (!product) return true;

    const { order_adjustment_type_id, order_sale_products } = context.from[1]
        .value as IOrderAdjustment;

    if (order_adjustment_type_id !== 6) {
        return true;
    }

    const res = order_sale_products?.filter((osp) => {
        return osp.product_id === product.id;
    });

    if (res?.length === 0) {
        return context.createError({
            message: 'El producto no esta en las ventas',
        });
    }

    return true;
}
