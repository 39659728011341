import React, { useMemo } from 'react';
import { useProductCategoryInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IProduct } from '../../product-form';
import { useWatch } from 'react-hook-form';

const ORDER_PRODUCTION_TYPE_ID_FIELD_NAME: keyof IProduct =
    'order_production_type_id';

export function ProductCategoryInput() {
    const inputName: keyof IProduct = 'product_category';

    const { data } = useProductCategoryInputQuery();
    const orderProductionTypeId: IProduct['order_production_type_id'] =
        useWatch({
            name: ORDER_PRODUCTION_TYPE_ID_FIELD_NAME,
        });

    const options = useMemo(() => {
        return (
            data?.getProductCategories.filter((pc) => {
                return orderProductionTypeId
                    ? orderProductionTypeId === pc.order_production_type_id
                    : !pc.order_production_type_id;
            }) || []
        );
    }, [data, orderProductionTypeId]);

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Categoria del producto'}
            options={options}
            returnObject
        />
    );
}
