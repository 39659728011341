import React from 'react';
import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import {
    LinkProps as RouterLinkProps,
    NavLink,
    useMatch,
} from 'react-router-dom';

interface INavListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    exact?: boolean;
    onClick: () => void;
}

export function DNavListItemLink(props: INavListItemLinkProps) {
    const { icon, primary, to, exact = false } = props;
    const theme = useTheme();

    const match = useMatch({
        path: to,
        end: exact,
    });

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
                function Link(linkProps, ref) {
                    return <NavLink ref={ref} to={to} {...linkProps} />;
                },
            ),
        [to],
    );

    return (
        <ListItem
            component={CustomLink}
            onClick={props.onClick}
            sx={{
                pl: 4,
                color: !!match
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                '&:hover': {
                    color: theme.palette.primary.main,
                },
            }}
        >
            {icon ? (
                <ListItemIcon
                    sx={{
                        color: 'inherit',
                    }}
                >
                    {icon}
                </ListItemIcon>
            ) : null}
            <ListItemText
                sx={{
                    color: 'inherit',
                }}
                primary={primary}
            />
        </ListItem>
    );
}
