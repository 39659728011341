import React from 'react';
import {
    Box,
    Button,
    FormHelperText,
    IconButton,
    Typography,
} from '@mui/material';
import { CreateIcon, DeleteIcon } from '../../../icon';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { useRhCustomContext } from '../rh-form-wrapper';

export interface IRhColumnsItem {
    getField: (index: number) => React.ReactElement<any, any>;
    cellGrow?: number;
}

interface IRhColumns {
    title?: string;
    name: string;
    defaultValue: Record<string, any>;
    disableDelete?: (index: number) => boolean;
    items: IRhColumnsItem[];
    viewOnly?: boolean;
    onDelete?: (index: number) => void;
}

export function RhColumns({
    title,
    name,
    defaultValue,
    items,
    viewOnly = false,
    disableDelete,
    onDelete,
}: IRhColumns) {
    const { fields, append, remove } = useFieldArray({ name: name });
    const { errors } = useFormState({ name: name });
    const rhContext = useFormContext();
    const { disabled: disabledFromContext } = useRhCustomContext();

    const error =
        errors[name] && errors[name]?.message ? errors[name]?.message : null;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, mt: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ flexGrow: 1, mx: 1 }}>
                    {title ? (
                        <Typography variant={'subtitle2'}>{title}</Typography>
                    ) : null}
                    {error ? (
                        <FormHelperText sx={{ color: 'error.main' }}>
                            {error}
                        </FormHelperText>
                    ) : null}
                </Box>
                {!viewOnly ? (
                    <Button
                        size={'small'}
                        variant="outlined"
                        startIcon={<CreateIcon />}
                        disabled={disabledFromContext}
                        onClick={() => {
                            append({ ...defaultValue });
                            void rhContext.trigger(name);
                        }}
                    >
                        Agregar
                    </Button>
                ) : null}
            </Box>
            {!viewOnly || title ? <Box sx={{ mt: 2 }} /> : null}

            <Box>
                {Array.isArray(fields) &&
                    fields.map((val, rowIndex) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mb: 2,
                                    justifyContent: 'stretch',
                                    alignItems: 'center',
                                }}
                                key={val.id}
                            >
                                {items.map((item, itemIndex) => {
                                    return (
                                        <Box
                                            sx={{
                                                mr: 2,
                                                flex: item.cellGrow || 1,
                                            }}
                                            key={itemIndex}
                                        >
                                            {item.getField(rowIndex)}
                                        </Box>
                                    );
                                })}
                                {!viewOnly ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <IconButton
                                            disabled={
                                                disabledFromContext ||
                                                (disableDelete &&
                                                    disableDelete(rowIndex)) ||
                                                false
                                            }
                                            onClick={() => {
                                                remove(rowIndex);
                                                if (onDelete) {
                                                    onDelete(rowIndex);
                                                }
                                                void rhContext.trigger(name);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
}
