import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSparePageQuery } from '../../../../../services';
import {
    CTableWrapper,
    DBlock,
    DBlockHeader,
    DeleteIcon,
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import {
    SDeleteSpareDialog,
    SpareTransactionIcon,
    SSpareFormDialog,
} from '../../../../smart';

type TSparePageParams = {
    spareId: string;
};

export function SparePage() {
    const params = useParams<TSparePageParams>();
    const [spareDialog, setSpareDialog] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const { data, loading, error } = useSparePageQuery({
        variables: {
            SpareId: params.spareId ? Number(params.spareId) : 0,
        },
    });

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }

    const spare = data!.getSpare;

    return (
        <DPage animated>
            <DPageHeader title={`${spare.name}`}>
                <DRowButtons
                    rowItems={[
                        {
                            icon: <EditIcon />,
                            tooltip: 'Refacción',
                            callback: () => {
                                setSpareDialog(true);
                            },
                        },
                        {
                            icon: <DeleteIcon />,
                            color: 'error',
                            disabled: !data || !data?.getSpare.is_deletable,
                            tooltip: 'Eliminar refacción',
                            callback: () => {
                                setConfirmationDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <DBlock>
                    <DBlockHeader
                        subtitle={'Transacciones'}
                        icon={<SpareTransactionIcon />}
                        iconTooltip={'Transaccion'}
                    />
                    <CTableWrapper
                        items={[
                            {
                                title: 'Fecha',
                                content: (transaction) =>
                                    transaction.spare_operation?.date,
                            },
                            {
                                title: 'Cantidad',
                                content: (transaction) => transaction.quantity,
                                textAlign: 'right',
                            },
                            {
                                title: 'Operacion',
                                content: (transaction) =>
                                    transaction.spare_operation?.description,
                                textAlign: 'right',
                            },
                        ]}
                        data={spare.spare_transactions}
                    />
                </DBlock>
            </DPageBody>
            <SSpareFormDialog
                open={spareDialog}
                setOpen={setSpareDialog}
                spare={spare}
            />
            <SDeleteSpareDialog
                open={confirmationDialog}
                setOpen={setConfirmationDialog}
                spare={spare}
            />
        </DPage>
    );
}
