import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '../../app/hooks';

export const GlobalMessagesSnackbar = () => {
    const globalMessages = useAppSelector(
        (state) => state.globalMessages.value,
    );

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (globalMessages.length > 0) {
            const { message, options } =
                globalMessages[globalMessages.length - 1];

            if (message !== '') {
                enqueueSnackbar(message, {
                    variant:
                        options && options.variant ? options.variant : 'info',
                });
            }
        }
    }, [globalMessages, enqueueSnackbar]);

    return null;
};
