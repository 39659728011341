import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { ExpenseForm } from './expense-form';
import { useExpensesDialogQuery } from '../../../../../../services';

interface ISExpensesFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    expenseId?: number | null;
    disabled?: boolean;
}

export const SUpsertExpenseDialog = (props: ISExpensesFormDialogProps) => {
    const { open, setOpen, expenseId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useExpensesDialogQuery({
        skip: !expenseId || !open,
        variables: {
            ExpenseId: expenseId!,
        },
    });

    const expense = useMemo(() => {
        return expenseId && data && data.getExpense && open
            ? data.getExpense
            : undefined;
    }, [expenseId, data, open]);

    return (
        <DModal
            title={`Gasto`}
            open={open}
            setOpen={setOpen}
            width={'1000px'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <ExpenseForm
                    disabled={isModalClosing}
                    expense={expense}
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                />
            )}
        </DModal>
    );
};
