import React from 'react';
import { Box, List, Menu, MenuItem } from '@mui/material';

type ContextMenu = {
    mouseX: number;
    mouseY: number;
};

export type DContextMenuItem = {
    title: string;
    callback: () => void;
    disable?: () => boolean;
};

interface IDContextMenu {
    contextMenu: ContextMenu | null;
    setContextMenu: (contextMenu: ContextMenu | null) => void;
    onClose?: () => void;
    contextMenuItems?: DContextMenuItem[];
}

export const handleMouseEvent = (
    event: React.MouseEvent,
    setContextMenu: (contextMenu: ContextMenu | null) => void,
    contextMenu: ContextMenu | null,
) => {
    event.preventDefault();
    setContextMenu(
        contextMenu === null
            ? {
                  mouseX: event.clientX - 2,
                  mouseY: event.clientY - 4,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
    );
};

export const useDContextMenu = function () {
    return React.useState<ContextMenu | null>(null);
};

export const DContextMenu = (props: IDContextMenu) => {
    const { contextMenu, setContextMenu, onClose, contextMenuItems } = props;

    const handleClose = () => {
        setContextMenu(null);
        if (onClose) {
            onClose();
        }
    };

    return (
        <Box sx={{ mx: 2, my: 1, display: 'flex', justifyContent: 'center' }}>
            <Box
                onClick={(e) => {
                    handleMouseEvent(e, setContextMenu, contextMenu);
                }}
                sx={{ cursor: 'pointer', maxWidth: '5rem' }}
            >
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? {
                                  top: contextMenu.mouseY,
                                  left: contextMenu.mouseX,
                              }
                            : undefined
                    }
                >
                    <List sx={{ py: 0 }}>
                        {contextMenuItems?.map((item, index) => {
                            return (
                                <MenuItem
                                    disabled={
                                        item.disable
                                            ? item.disable()
                                            : (() => false)()
                                    }
                                    key={index}
                                    onClick={() => {
                                        item.callback();
                                        handleClose();
                                    }}
                                >
                                    {item.title}
                                </MenuItem>
                            );
                        })}
                    </List>
                </Menu>
            </Box>
        </Box>
    );
};
