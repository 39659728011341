import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { OrderSaleForm } from './order-sale-form';
import { useOrderSaleDialogQuery } from '../../../../../../services';

interface ISOrderSaleFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderSaleId?: number | null;
    disabled?: boolean;
}

export const SUpsertOrderSale = (props: ISOrderSaleFormDialogProps) => {
    const { open, setOpen, orderSaleId, disabled = false } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useOrderSaleDialogQuery({
        skip: !orderSaleId || !open,
        variables: {
            OrderSaleId: orderSaleId!,
        },
    });

    const orderSale = useMemo(() => {
        return orderSaleId && data && data.getOrderSale && open
            ? data.getOrderSale
            : undefined;
    }, [orderSaleId, data, open]);

    return (
        <DModal
            title={`Venta`}
            open={open}
            setOpen={setOpen}
            width={'95rem'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <OrderSaleForm
                    disabled={
                        disabled ||
                        isModalClosing ||
                        (orderSale && !orderSale?.is_editable)
                    }
                    onSuccess={() => {
                        setOpen(false);
                        setIsModalClosing(true);
                    }}
                    orderSale={orderSale}
                />
            )}
        </DModal>
    );
};
