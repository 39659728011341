import * as React from 'react';
import { RefCallback, useMemo, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { CloseIcon } from '../icon';
import { DateTimePicker as DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IInputDefaultProps } from './i-input-default-props';

type Views = 'year' | 'month' | 'day' | 'hours' | 'minutes' | 'seconds';

interface EnhancedDatePickerProps extends IInputDefaultProps {
    onChange: (date: string | null) => void;
    value: string | null;
    views: readonly Views[];
    mask: string;
    inputFormat: string;
    localFormat?: string;
    disabled?: boolean;
    inputRef?: RefCallback<any>;
    clearable?: boolean;
}

export function DDatePicker(props: EnhancedDatePickerProps) {
    const [open, setOpen] = useState(false);

    const {
        onChange,
        disabled = false,
        label,
        value,
        views,
        mask,
        inputFormat,
        helperText,
        error,
        disableGutters = false,
        inputRef,
        clearable = true,
        localFormat,
    } = props;

    const localValue = useMemo<string | null>(() => {
        return value
            ? dayjs(value, inputFormat).format(localFormat || inputFormat)
            : '';
    }, [value, inputFormat, localFormat]);

    return (
        <DatePicker
            inputRef={inputRef}
            views={views}
            mask={mask}
            inputFormat={inputFormat}
            value={value}
            disabled={disabled}
            open={open}
            onChange={(newValue) => {
                if (dayjs.isDayjs(newValue)) {
                    onChange(dayjs(newValue).format(inputFormat));
                } else {
                    onChange(newValue);
                }
            }}
            PopperProps={{
                className: 'DDatePicker',
                placement: 'bottom-start',
            }}
            onAccept={() => {
                setOpen(false);
            }}
            renderInput={({ InputProps, ...params }) => {
                return (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            value: localValue,
                            readOnly: true,
                            tabIndex: -1,
                        }}
                        sx={{
                            my: !disableGutters ? 2 : 0,
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        color={'primary'}
                        focused={false}
                        margin={'dense'}
                        size={'small'}
                        label={label}
                        helperText={helperText}
                        error={error}
                        disabled={disabled}
                        onClick={() => {
                            setOpen(!open);
                        }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {!!value && clearable && !disabled ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onChange(null);
                                                    setOpen(false);
                                                }}
                                                edge="end"
                                                disabled={!value}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null}
                                    {InputProps?.endAdornment}
                                </>
                            ),
                        }}
                    />
                );
            }}
        ></DatePicker>
    );
}
