import React, { useMemo, useState } from 'react';
import { useOrderProductionFormQuery } from '../../../../../../services';
import { DModal, DPageLoader } from '../../../../../dum';
import { ProductIcon } from '../../../../icons';
import { OrderProductionForm } from './order-production-form';

interface IOrderProductionFormDialog {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderProductionId?: number | null;
}

export const SUpsertOrderProductionDialog = (
    props: IOrderProductionFormDialog,
) => {
    const { open, setOpen, orderProductionId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useOrderProductionFormQuery({
        skip: !orderProductionId || !open,
        variables: {
            OrderProductionId: orderProductionId!,
        },
    });

    const orderProduction = useMemo(() => {
        return orderProductionId && data && data.getOrderProduction && open
            ? data.getOrderProduction
            : undefined;
    }, [data, orderProductionId, open]);

    return (
        <DModal
            icon={<ProductIcon />}
            title={`Orden de producción`}
            open={open}
            setOpen={setOpen}
            width={'60rem'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <OrderProductionForm
                    disabled={isModalClosing}
                    onSuccess={() => {
                        setOpen(false);
                        setIsModalClosing(true);
                    }}
                    orderProduction={orderProduction}
                />
            )}
        </DModal>
    );
};
