import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    getDateFromString,
    getStringFromDate,
    transformToNumber,
} from '../../../../../../helpers';
import { useUpsertRawMaterialAdditionMutation } from '../../../../../../services';
import { RhDateInput, RhFormWrapper, RHTextField } from '../../../../../dum';
import { RawMaterialAdditionItemsColumns } from './raw-material-addition-items/raw-material-addition-items-columns';
import { AccountInput } from './account/account-input';

export interface IRawMaterialAddition {
    id?: number | null;
    date?: string | null;
    account_id?: number | null;
    total?: number;
    raw_material_addition_items: {
        id?: number | null;
        amount: number;
        unit_price: number;
        resource_id?: number | null;
        total?: number;
    }[];
}

export const TOTAL: keyof IRawMaterialAddition = 'total';
export const ID: keyof IRawMaterialAddition = 'id';
export const ACCOUNT_ID: keyof IRawMaterialAddition = 'account_id';
export const DATE: keyof IRawMaterialAddition = 'date';
export const RAW_MATERIAL_ADDITION_ITEMS: keyof IRawMaterialAddition =
    'raw_material_addition_items';
export const RAW_MATERIAL_ADDITION_ITEMS_AMOUNT: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'amount';
export const RAW_MATERIAL_ADDITION_ITEMS_UNIT_PRICE: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'unit_price';
export const RAW_MATERIAL_ADDITION_ITEMS_RESOURCE_ID: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'resource_id';
export const RAW_MATERIAL_ADDITION_ITEMS_TOTAL: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'total';
interface TRawMaterialAddition extends IRawMaterialAddition {
    id?: number | null;
}

interface IRawMaterialAdditionForm {
    rawMaterialAddition?: TRawMaterialAddition;
    onSuccess: () => void;
    disabled?: boolean;
}

export const RawMaterialAdditionForm = ({
    rawMaterialAddition,
    onSuccess,
    disabled = false,
}: IRawMaterialAdditionForm) => {
    const [mutation, { loading }] = useUpsertRawMaterialAdditionMutation();

    const initialValues = useMemo<IRawMaterialAddition>(() => {
        return {
            id: rawMaterialAddition?.id || undefined,
            date:
                rawMaterialAddition && rawMaterialAddition.date
                    ? getDateFromString(rawMaterialAddition.date)
                    : null,
            account_id: rawMaterialAddition
                ? rawMaterialAddition.account_id
                : null,
            total: rawMaterialAddition ? rawMaterialAddition.total : 0,
            raw_material_addition_items:
                rawMaterialAddition?.raw_material_addition_items || [
                    {
                        amount: 0,
                        unit_price: 0,
                        resource_id: null,
                        total: 0,
                    },
                ],
        };
    }, [rawMaterialAddition]);

    const validationSchema: SchemaOf<IRawMaterialAddition> = yup.object({
        id: yup.number().notRequired().nullable(),
        date: yup.string().required(`'Fecha' es obligatorio`).nullable(),
        account_id: yup.number().required(`'Cuenta' es obligatorio`).nullable(),
        total: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Total' es obligatorio`),
        raw_material_addition_items: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    amount: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Cantidad' es obligatorio`),
                    resource_id: yup
                        .number()
                        .required(`'Recurso' es obligatorio`)
                        .nullable(),
                    unit_price: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Precio' es obligatorio`),
                    total: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Total' es obligatorio`),
                }),
            )
            .required(`'Elementos' es obligatorio`),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            RawMaterialAdditionInput: {
                                id: rawMaterialAddition?.id || null,
                                account_id: data.account_id,
                                date:
                                    data && data.date
                                        ? getStringFromDate(data.date)
                                        : null,
                                raw_material_addition_items:
                                    data.raw_material_addition_items.map(
                                        (rawMaterialAdditionItem) => {
                                            return {
                                                id: rawMaterialAdditionItem.id,
                                                amount: rawMaterialAdditionItem.amount,
                                                resource_id:
                                                    rawMaterialAdditionItem.resource_id,
                                                unit_price:
                                                    rawMaterialAdditionItem.unit_price,
                                            };
                                        },
                                    ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RhDateInput name={'date'} label={'Fecha'} />
            <AccountInput />
            <RawMaterialAdditionItemsColumns />
            <RHTextField name={'total'} label={'Total'} disabled />
        </RhFormWrapper>
    );
};
