import React from 'react';
import { DRouterLink } from '../../../../dum';
import { getSpareCategoryPagePath } from '../../../../../services/navigation/maintenance/get-route-path';

interface ISSpareCategoryLinkProps {
    spareCategoryId: number;
    spareCategoryName: string;
}

export function SSpareCategoryLink({
    spareCategoryId,
    spareCategoryName,
}: ISSpareCategoryLinkProps) {
    return (
        <DRouterLink
            to={getSpareCategoryPagePath({
                spareCategoryId: spareCategoryId,
            })}
        >
            {spareCategoryName}
        </DRouterLink>
    );
}
