import React from 'react';
import { SSingleMachineSectionAutocomplete } from '../../../../../inputs';
import { IMachinePartForm } from '../s-machine-part-form-dialog';
import { useWatch } from 'react-hook-form';

const machineName: keyof IMachinePartForm = 'machine_id';
const machineSectionInputName: keyof IMachinePartForm = 'machine_section_id';

interface IMachineSectionInputProps {
    disabled?: boolean;
}

export function MachineSectionInput({ disabled }: IMachineSectionInputProps) {
    const machineId = useWatch({ name: machineName });

    return (
        <SSingleMachineSectionAutocomplete
            disabled={disabled}
            name={machineSectionInputName}
            label={'Seccion'}
            machineId={machineId}
        />
    );
}
