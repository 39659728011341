import { IOrderRequest } from '../order-request-form';
import { ValidationError } from 'yup';

export function isProductUnique(
    value: any,
    context: any,
): boolean | ValidationError {
    const { product } = context.from[0]
        .value as IOrderRequest['order_request_products'][number];

    if (!product) return true;

    const orderRequest = context.from[1].value as IOrderRequest;
    const order_request_products = orderRequest.order_request_products;

    const product_id = product.id;

    const results = order_request_products.filter((pProduct: any) => {
        const product_id_2 = pProduct.product ? pProduct.product.id : null;
        return product_id === product_id_2;
    });

    if (!(results.length <= 1)) {
        return context.createError({
            message: `El producto no es unico`,
        });
    }

    return true;
}
