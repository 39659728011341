import React from 'react';
import { animated, useSpring } from 'react-spring';
import {
    Backdrop,
    Box,
    IconButton,
    Modal,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import { DAvatarIcon } from '../icon';

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref,
) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        config: {
            duration: 200,
        },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

interface ICustomModalProps {
    children: React.ReactNode | React.ReactNode[];
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    icon?: React.ReactElement<any, any>;
    width?: string;
    height?: string;
    keepMounted?: boolean;
    onExited?: () => void;
}

export function DModal(props: ICustomModalProps) {
    const theme = useTheme();
    const isLgAndDown = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.lg),
    );

    const {
        children,
        open,
        setOpen,
        title,
        icon,
        width = '40rem',
        height = '40rem',
        keepMounted = false,
        onExited,
    } = props;
    return (
        <Modal
            onKeyDown={(event) => {
                const code = (event as unknown as { code: string }).code;
                if (code === 'Escape') {
                    setOpen(false);
                }
            }}
            open={open}
            keepMounted={keepMounted}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
        >
            <Fade in={open} onExited={onExited}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.default',
                        borderRadius: '0.5rem',
                        boxShadow: 24,
                        overflow: 'hidden',
                        width: isLgAndDown ? '95vw' : width,
                    }}
                >
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            bgcolor: 'primary.main',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                mr: 6,
                                alignItems: 'center',
                            }}
                        >
                            {icon ? (
                                <Box sx={{ mr: 1 }}>
                                    <DAvatarIcon size={'small'}>
                                        {icon}
                                    </DAvatarIcon>
                                </Box>
                            ) : null}
                            <Typography variant={'h5'} sx={{ flexGrow: 1 }}>
                                {title}
                            </Typography>
                        </Box>

                        <IconButton
                            color="inherit"
                            size={'small'}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Toolbar>
                    <Box
                        sx={{
                            p: 2,
                            height: height,
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
