import {
    createTheme,
    Theme as MuiTheme,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import * as colors from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';

interface ICustomThemeProviderProps {
    children: React.ReactNode;
}

export const CustomThemeProvider = (props: ICustomThemeProviderProps) => {
    const muiTheme: MuiTheme = React.useMemo(() => {
        const theme = createTheme({
            palette: {
                background: {
                    default: colors['grey']['300'],
                },
            },
        });

        theme.components = {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontWeight: 'inherit',
                        borderBottom: '1px solid transparent',
                        textAlign: 'center',
                        borderBottomColor: theme.palette.grey.A200,
                        '&.MuiTableCell-head': {
                            fontWeight: 'bold',
                        },
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {},
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.background.paper,
                    },
                },
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        lineHeight: '2rem',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    clearIndicator: {
                        visibility: 'visible',
                        marginRight: 6,
                        marginBottom: 2,
                    },
                    popupIndicator: {
                        marginRight: 0,
                        marginBottom: 2,
                    },
                },
            },
        };

        return theme;
    }, []);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            {props.children}
        </MuiThemeProvider>
    );
};
