import React from 'react';
import { Box } from '@mui/material';

export function CardContainer({
    children,
    mb,
    ml,
    mr,
    alignItems,
    justifyContent,
    flexDirection = 'row',
    flexGrow,
}: {
    children: React.ReactNode;
    mb?: number;
    ml?: number;
    mr?: number;
    alignItems?: React.CSSProperties['alignItems'];
    justifyContent?: React.CSSProperties['justifyContent'];
    flexDirection?: React.CSSProperties['flexDirection'];
    flexGrow?: React.CSSProperties['flexGrow'];
}) {
    return (
        <Box
            sx={{
                mb: mb,
                ml: ml,
                mr: mr,
                display: 'flex',
                flex: 1,
                justifyContent: justifyContent,
                alignItems: alignItems,
                flexDirection: flexDirection,
                flexGrow: flexGrow,
            }}
        >
            {children}
        </Box>
    );
}
