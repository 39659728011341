import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import {
    clearMaintenanceModule,
    useDeleteMachineMutation,
} from '../../../../../../services';

interface IDeleteMachineProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    machineId?: number | null;
}

export function SDeleteMachineDialog({
    open,
    setOpen,
    machineId,
}: IDeleteMachineProps) {
    const [deleteMutation] = useDeleteMachineMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (machineId) {
                    await deleteMutation({
                        variables: {
                            MachineId: machineId,
                        },
                    });
                    setOpen(false);
                }
            }}
        />
    );
}
