import dayjs from 'dayjs';

export const formatDate = (date: string | null): string => {
    return date ? dayjs(date).utc().format(DATE_FORMAT) : '-';
};

export const formatDateTime = (date: string | null): string => {
    return date ? dayjs(date).utc().format(DATE_TIME_FORMAT) : '-';
};

export const formatRelativeDate = (date: string): string => {
    return dayjs(date).utc().fromNow();
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_MASK = '____-__-__';

export const YEAR_FORMAT = 'YYYY';
export const YEAR_MASK = '____';

export const MONTH_FORMAT = 'YYYY-MM';
export const MONTH_HUMAN_FORMAT = 'MMMM';
export const MONTH_MASK = '____-__';

export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const YEAR_MONTH_MASK = '____-__';

const TIME_FORMAT = 'HH:mm:ss';
const TIME_MASK = '__:__:__';

export const DATE_TIME_MASK = DATE_MASK + ' ' + TIME_MASK;
export const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;

export function getDateFromString(str: string) {
    return dayjs(str).utc().format('YYYY-MM-DD');
}

export function getDateTimeFromString(str: string) {
    return dayjs(str).utc().format(DATE_TIME_FORMAT);
}

export function getStringFromDate(date: string, time: string = '00:00:00') {
    return `${date}T${time}.000Z`;
}

export function getStringFromDateTime(datetime: string) {
    const datetimeDayjs = dayjs(datetime, DATE_TIME_FORMAT);
    const date = datetimeDayjs.format(DATE_FORMAT);
    const time = datetimeDayjs.format(TIME_FORMAT);

    return `${date}T${time}.000Z`;
}
