export function getMachineSectionPagePath({
    machineId,
    machineSectionId,
}: {
    machineId: number;
    machineSectionId: number;
}): string {
    return `/machines/${machineId}/machineSections/${machineSectionId}`;
}

export function getMachinePartPagePath({
    machineId,
    machinePartId,
}: {
    machineId: number;
    machinePartId: number;
}): string {
    return `/machines/${machineId}/machineParts/${machinePartId}`;
}

export function getMachinePagePath({
    machineId,
}: {
    machineId: number;
}): string {
    return `/machines/${machineId}`;
}

export function getMachinesPagePath(): string {
    return `/machines`;
}

export function getSparesPagePath(): string {
    return `/spares`;
}

export function getSparePagePath({ spareId }: { spareId: number }): string {
    return `/spares/${spareId}`;
}

export function getSpareCategoriesPagePath(): string {
    return `/spares/spareCategories`;
}

export function getSpareTransactionsPagePath(): string {
    return `/spareTransactions`;
}

export function getSpareAdjustmentsPagePath(): string {
    return `/spareTransactions/spareAdjustments`;
}

export function getSpareCategoryPagePath({
    spareCategoryId,
}: {
    spareCategoryId: number;
}): string {
    return `/spares/spareCategories/${spareCategoryId}`;
}

export function getSpareAdjustmentPagePath({
    spareAdjustmentId,
}: {
    spareAdjustmentId: number;
}): string {
    return `/spareTransactions/spareAdjustments/${spareAdjustmentId}`;
}
