import React, { useEffect, useMemo, useState } from 'react';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPaginator,
    DYear,
    EditIcon,
    SortedOrder,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../../dum';
import { Box, IconButton } from '@mui/material';
import {
    ColumnOrder,
    PaginatedRawMaterialAdditionsSortableFields,
    RawMaterialAdditionsPageQuery,
    useRawMaterialAdditionsPageLazyQuery,
} from '../../../../../../services';
import {
    SDeleteRawMaterialAdditionDialog,
    SUpsertRawMaterialAdditionDialog,
} from '../../../../../smart';
import { formatDate, formatFloat, useNumber } from '../../../../../../helpers';
import { DCheckbox } from '../../../../../dum/simple/inputs/d-checkbox';

export function RawMaterialAdditionsListBlock() {
    const [query, { data, previousData }] =
        useRawMaterialAdditionsPageLazyQuery();
    const [rawMaterialAdditionId, setRawMaterialAdditionId] = useState<
        number | null
    >(null);

    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const { year, setYear } = useDYear({
        COMPONENT_KEY: RawMaterialAdditionsListBlock.name + '_Year',
    });
    const [accountId, setAccountId] = useNumber({
        KEY: RawMaterialAdditionsListBlock.name + '_AccountId',
    });
    const [pendingRawMaterialAdditions, setPendingRawMaterialAdditions] =
        useState(false);
    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: RawMaterialAdditionsListBlock.name + '_Month',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: RawMaterialAdditionsListBlock.name + '_Paginator',
        },
        [month, year],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                accountId: accountId,
                filter: '',
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
                sortField:
                    sortedIndex === 0
                        ? PaginatedRawMaterialAdditionsSortableFields.Date
                        : null,
            },
        });
    }, [query, skip, take, year, month, accountId, sortedOrder, sortedIndex]);

    const handleEditRawMaterialAddition = useMemo(() => {
        return (
            rawMaterialAddition: RawMaterialAdditionsPageQuery['paginatedRawMaterialAdditions']['docs'][number],
        ) => {
            setRawMaterialAdditionId(rawMaterialAddition.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteRawMaterialAddition = useMemo(() => {
        return (
            rawMaterialAddition: RawMaterialAdditionsPageQuery['paginatedRawMaterialAdditions']['docs'][number],
        ) => {
            setRawMaterialAdditionId(rawMaterialAddition.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DBlock>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ flex: 1, mr: 1 }}>
                    <DYear
                        disabled={pendingRawMaterialAdditions}
                        setYear={setYear}
                        year={!pendingRawMaterialAdditions ? year : null}
                    />
                </Box>
                <Box sx={{ flex: 1, ml: 1 }}>
                    <DMonth
                        disabled={pendingRawMaterialAdditions}
                        setMonth={setMonth}
                        month={!pendingRawMaterialAdditions ? month : null}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <CVerticalDropdown
                        items={[
                            {
                                component: (
                                    <DCheckbox
                                        label={'Pendientes'}
                                        onChange={(val) => {
                                            setPendingRawMaterialAdditions(val);
                                        }}
                                        value={pendingRawMaterialAdditions}
                                    />
                                ),
                            },

                            {
                                component: (
                                    <DIdAutocomplete
                                        disabled={pendingRawMaterialAdditions}
                                        options={data?.getAccounts || []}
                                        itemText={'compound_name'}
                                        label={'Cuenta'}
                                        id={
                                            !pendingRawMaterialAdditions
                                                ? accountId
                                                : null
                                        }
                                        onChange={setAccountId}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>
            {!pendingRawMaterialAdditions ? (
                <DPaginator
                    skip={skip}
                    take={take}
                    setSkip={setSkip}
                    count={
                        data && data?.paginatedRawMaterialAdditions.count >= 0
                            ? data?.paginatedRawMaterialAdditions.count
                            : previousData?.paginatedRawMaterialAdditions.count
                    }
                />
            ) : null}
            <CTableWrapper
                sortedIndex={sortedIndex}
                sortedOrder={sortedOrder}
                setSortedIndex={setSortedIndex}
                setSortedOrder={setSortedOrder}
                contextItems={[
                    {
                        title: 'Editar',
                        callback: (rawMaterialAddition) => {
                            handleEditRawMaterialAddition(rawMaterialAddition);
                        },
                    },
                    {
                        title: 'Borrar',
                        callback: (rawMaterialAddition) => {
                            handleDeleteRawMaterialAddition(
                                rawMaterialAddition,
                            );
                        },
                    },
                ]}
                items={[
                    {
                        title: 'Fecha',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (rawMaterialAddition) => {
                            return formatDate(rawMaterialAddition.date);
                        },
                        sortable: true,
                    },
                    {
                        title: 'Proveedor',
                        content: (rawAddition) => {
                            return rawAddition.account?.compound_name;
                        },
                    },
                    {
                        title: 'Elementos',
                        cellGrow: 10,
                        textAlign: 'right',
                        content: (rawMaterialAddition) => {
                            return rawMaterialAddition.raw_material_addition_items
                                .map((item) => {
                                    return `${item.resource?.name} (${item.amount})`;
                                })
                                .join(', ');
                        },
                    },
                    {
                        title: 'Total',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (rawMaterialAddition) => {
                            return formatFloat(rawMaterialAddition.total);
                        },
                    },
                    {
                        title: 'Acciones',
                        content: (rawMaterialAddition) => {
                            return (
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        onClick={() => {
                                            handleEditRawMaterialAddition(
                                                rawMaterialAddition,
                                            );
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            handleDeleteRawMaterialAddition(
                                                rawMaterialAddition,
                                            );
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
                data={
                    !pendingRawMaterialAdditions
                        ? data?.paginatedRawMaterialAdditions.docs ||
                          previousData?.paginatedRawMaterialAdditions.docs ||
                          []
                        : data?.getRawMaterialAdditionsWithDisparities ||
                          previousData?.getRawMaterialAdditionsWithDisparities ||
                          []
                }
            />
            <SUpsertRawMaterialAdditionDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                rawMaterialAdditionId={rawMaterialAdditionId}
            />
            <SDeleteRawMaterialAdditionDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                rawMaterialAdditionId={rawMaterialAdditionId}
            />
        </DBlock>
    );
}
