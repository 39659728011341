import React from 'react';
import { Typography } from '@mui/material';
import { DPage } from '../../dum';

export const NotFoundPage = () => {
    return (
        <DPage centered>
            <Typography variant={'h3'}>Not found</Typography>
        </DPage>
    );
};
