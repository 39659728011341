import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteOrderRequestMutation } from '../../../../../../services';

interface IDeleteOrderRequestProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderRequestId: number | null;
}

export function SDeleteOrderRequestDialog({
    open,
    setOpen,
    orderRequestId,
}: IDeleteOrderRequestProps) {
    const [deleteMutation] = useDeleteOrderRequestMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (orderRequestId) {
                    try {
                        await deleteMutation({
                            variables: {
                                OrderRequestId: orderRequestId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
