import * as React from 'react';
import { ReactElement } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

export interface ICustomSpeedDialProps {
    items: {
        icon: ReactElement<any, any>;
        text: string;
        callback: () => void | Promise<void>;
    }[];
}

export function DSpeedDial({ items }: ICustomSpeedDialProps) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'absolute', bottom: 32, right: 32 }}
            icon={<SpeedDialIcon />}
            onClick={() => {
                if (open) {
                    handleClose();
                } else {
                    handleOpen();
                }
            }}
            open={open}
        >
            {items.map((action) => (
                <SpeedDialAction
                    key={action.text}
                    icon={action.icon}
                    tooltipTitle={action.text}
                    onClick={() => {
                        handleClose();
                        void action.callback();
                    }}
                />
            ))}
        </SpeedDial>
    );
}
