import React from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { DModal, RHTextField } from '../../../../../dum';
import {
    clearMaintenanceModule,
    useUpsertMachineSectionMutation,
} from '../../../../../../services';
import { SSingleMachineAutocomplete } from '../../../../inputs';
import { RhFormWrapper } from '../../../../../dum';

export interface IMachineSectionForm {
    name: string;
    machine_id?: number | null;
}

interface TMachineSection extends IMachineSectionForm {
    id?: number | null;
}

interface IMachineFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    machineSection?: TMachineSection;
}

export const SMachineSectionFormDialog = (props: IMachineFormDialogProps) => {
    const { open, setOpen, machineSection } = props;

    const [mutation, { loading }] = useUpsertMachineSectionMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    const initialValues: IMachineSectionForm = {
        name: machineSection?.name || '',
        machine_id: machineSection?.machine_id || null,
    };

    const validationSchema: SchemaOf<IMachineSectionForm> = yup.object({
        name: yup.string().required(),
        machine_id: yup.number().required().nullable(),
    });

    return (
        <DModal open={open} setOpen={setOpen} title={'Crear seccion'}>
            <RhFormWrapper
                onSubmit={async (data, event) => {
                    await mutation({
                        variables: {
                            MachineSectionUpsertInput: {
                                id: machineSection?.id,
                                name: data.name,
                                machine_id: data.machine_id!,
                            },
                        },
                    });
                    setOpen(false);
                }}
                validationSchema={validationSchema}
                initialValues={initialValues}
                disabled={loading}
            >
                <RHTextField name={'name'} label={'Nombre'} />
                <SSingleMachineAutocomplete
                    name={'machine_id'}
                    label={'Maquina'}
                    disabled={!!machineSection?.id}
                />
            </RhFormWrapper>
        </DModal>
    );
};
