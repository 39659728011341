import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import {
    IsEmailOccupiedQuery,
    IsOrderRequestCodeOccupiedQuery,
    IsOrderSaleCodeOccupiedQuery,
    QueryIsEmailOccupiedArgs,
    QueryIsOrderRequestCodeOccupiedArgs,
    QueryIsOrderSaleCodeOccupiedArgs,
    refetchIsEmailOccupiedQuery,
    refetchIsOrderRequestCodeOccupiedQuery,
    refetchIsOrderSaleCodeOccupiedQuery,
} from './inopack-graphql-schema';

type QueryDerivativeIsOrderCodeOccupiedType = (
    client: ApolloClient<object>,
    variables: QueryIsOrderRequestCodeOccupiedArgs,
) => Promise<ApolloQueryResult<IsOrderRequestCodeOccupiedQuery>>;

export const queryIsOrderRequestCodeOccupied: QueryDerivativeIsOrderCodeOccupiedType =
    async (client, variables) => {
        return client.query(refetchIsOrderRequestCodeOccupiedQuery(variables));
    };

type QueryDerivativeIsOrderSaleCodeOccupiedType = (
    client: ApolloClient<object>,
    variables: QueryIsOrderSaleCodeOccupiedArgs,
) => Promise<ApolloQueryResult<IsOrderSaleCodeOccupiedQuery>>;

export const queryIsOrderSaleCodeOccupied: QueryDerivativeIsOrderSaleCodeOccupiedType =
    async (client, variables) => {
        return client.query(refetchIsOrderSaleCodeOccupiedQuery(variables));
    };

type QueryDerivativeIsEmailOccupiedType = (
    client: ApolloClient<object>,
    variables: QueryIsEmailOccupiedArgs,
) => Promise<ApolloQueryResult<IsEmailOccupiedQuery>>;

export const queryIsEmailOccupied: QueryDerivativeIsEmailOccupiedType = async (
    client,
    variables,
) => {
    return client.query(refetchIsEmailOccupiedQuery(variables));
};
