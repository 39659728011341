import {
    clearActivitiesModule,
    clearAuthModule,
    clearManagement,
    clearProductionModule,
    clearSalesModule,
    useActivitySubscription,
    useAccountSubscription,
    useEmployeeSubscription,
    useOrderAdjustmentSubscription,
    useOrderProductionSubscription,
    useOrderRequestSubscription,
    useOrderSaleSubscription,
    useProductSubscription,
    useTransferSubscription,
    useUserSubscription,
    useExpensesSubscription,
    useResourcesSubscription,
    useRawMaterialAdditionSubscription,
    useMachineSubscription,
} from '../../../services';

export function SubscriptionsProvider() {
    useProductSubscription({
        onSubscriptionData: ({ client }) => {
            clearProductionModule(client.cache);
        },
    });

    useOrderProductionSubscription({
        onSubscriptionData: ({ client }) => {
            clearProductionModule(client.cache);
        },
    });

    useEmployeeSubscription({
        onSubscriptionData: ({ client }) => {
            clearProductionModule(client.cache);
        },
    });

    useOrderAdjustmentSubscription({
        onSubscriptionData: ({ client }) => {
            clearProductionModule(client.cache);
        },
    });

    useOrderSaleSubscription({
        onSubscriptionData: ({ client }) => {
            clearSalesModule(client.cache);
        },
    });

    useOrderRequestSubscription({
        onSubscriptionData: ({ client }) => {
            clearSalesModule(client.cache);
        },
    });

    useAccountSubscription({
        onSubscriptionData: ({ client }) => {
            clearSalesModule(client.cache);
        },
    });

    useUserSubscription({
        onSubscriptionData: ({ client }) => {
            clearAuthModule(client.cache);
        },
    });

    useActivitySubscription({
        onSubscriptionData: ({ client }) => {
            clearActivitiesModule(client.cache);
        },
    });

    useTransferSubscription({
        onSubscriptionData: ({ client }) => {
            clearManagement(client.cache);
        },
    });

    useExpensesSubscription({
        onSubscriptionData: ({ client }) => {
            clearManagement(client.cache);
        },
    });

    useResourcesSubscription({
        onSubscriptionData: ({ client }) => {
            clearManagement(client.cache);
        },
    });

    useMachineSubscription({
        onSubscriptionData: ({ client }) => {
            clearProductionModule(client.cache);
        },
    });

    useRawMaterialAdditionSubscription({
        onSubscriptionData: ({ client, subscriptionData }) => {
            clearManagement(client.cache);
        },
    });

    return null;
}
