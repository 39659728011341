import { Button } from '@mui/material';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import React from 'react';
import { SalesSummaryPageQuery } from '../../../../services';
import dayjs from 'dayjs';

export function SalesSummaryExcelExportButton({
    sales,
    disabled,
}: {
    sales?: SalesSummaryPageQuery['getProductSalesSummary']['sales'];
    disabled: boolean;
}) {
    return (
        <Button
            disabled={disabled}
            onClick={async () => {
                const workbook = new Excel.Workbook();
                const salesWs = workbook.addWorksheet();
                salesWs.addRow([
                    'Cliente',
                    'Año',
                    'Mes',
                    'Mes 2',
                    'Producto',
                    'Ancho',
                    'Largo',
                    'Calibre',
                    'Kilos',
                    'Total sin iva',
                    'Total con iva',
                ]);
                sales?.forEach((sale, index) => {
                    salesWs.addRow([
                        sale.account_name,
                        sale.year,
                        sale.month !== null && sale.month !== undefined
                            ? dayjs()
                                  .set('month', sale.month - 1)
                                  .format('MMMM')
                            : '',
                        sale.month,
                        sale.product_name,
                        sale.width,
                        sale.length,
                        sale.calibre,
                        sale.kilos_sold,
                        sale.total,
                        sale.total_with_tax,
                    ]);

                    salesWs.columns.forEach((c, i) => {
                        let maxColumnLength = 6;
                        const column = salesWs.getColumn(i);
                        if (column) {
                            column.eachCell({ includeEmpty: true }, (cell) => {
                                maxColumnLength = Math.max(
                                    maxColumnLength,
                                    cell.value
                                        ? cell.value.toString().length
                                        : 0,
                                );
                            });

                            column.width = maxColumnLength;
                        }
                    });

                    salesWs.getColumn(6).alignment = {
                        horizontal: 'right',
                    };
                });

                const buffer = await workbook.xlsx.writeBuffer();

                FileSaver.saveAs(
                    new Blob([buffer]),
                    'ventas-por-producto.xlsx',
                );
            }}
        >
            Descargar reporte
        </Button>
    );
}
