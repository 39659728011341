import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import {
    clearMaintenanceModule,
    navigateToSpareAdjustmentsPage,
    useDeleteSpareOperationMutation,
} from '../../../../../../services';
import { useNavigate } from 'react-router-dom';

interface ISDeleteSpareOperationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    spareOperation: {
        id: number;
        description: string;
    };
}

export function SDeleteSpareOperationDialog({
    open,
    setOpen,
    spareOperation,
}: ISDeleteSpareOperationDialogProps) {
    const navigate = useNavigate();
    const [deleteMutation] = useDeleteSpareOperationMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                await deleteMutation({
                    variables: {
                        SpareOperationId: spareOperation.id,
                    },
                });
                navigateToSpareAdjustmentsPage({ navigate, replace: true });
            }}
        />
    );
}
