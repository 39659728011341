import React from 'react';
import { useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { IExpense } from '../expense-form';
import { RhColumns, RhDateInput, RHTextField } from '../../../../../../dum';

type TransferReceiptColumn = NonNullable<IExpense['transfer_receipts']>[number];

const defaultValue: TransferReceiptColumn = {
    transferred_date: null,
    transfer: null,
    amount: 0,
};

export const TRANSFER_RECEIPTS: keyof IExpense = 'transfer_receipts';
export const TRANSFER: keyof TransferReceiptColumn = 'transfer';
export const TRANSFERRED_DATE: keyof TransferReceiptColumn = 'transferred_date';
export const AMOUNT: keyof TransferReceiptColumn = 'amount';
export const COMPOUND_NAME: keyof TransferReceiptColumn = 'compound_name';

export function TransferReceiptColumns() {
    const transferReceipts = useWatch({
        name: TRANSFER_RECEIPTS,
    }) as IExpense['transfer_receipts'];
    return (
        <RhColumns
            viewOnly
            name={TRANSFER_RECEIPTS}
            defaultValue={defaultValue}
            items={[
                {
                    getField: (index) => {
                        const transferReceipt = transferReceipts?.at(index);

                        if (transferReceipt && !transferReceipt.compound_name) {
                            return <Box />;
                        }

                        return (
                            <RHTextField
                                disabled
                                name={`${TRANSFER_RECEIPTS}.${index}.${COMPOUND_NAME}`}
                                label={'Banco'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RhDateInput
                                disabled
                                name={`${TRANSFER_RECEIPTS}.${index}.${TRANSFERRED_DATE}`}
                                label={'Fecha'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${TRANSFER_RECEIPTS}.${index}.${AMOUNT}`}
                                label={'Cantidad'}
                                disableGutters
                            />
                        );
                    },
                },
            ]}
        />
    );
}
