import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { IInputDefaultProps } from './i-input-default-props';

interface IDCheckboxProps extends IInputDefaultProps {
    uncheckedIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    checkedIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    align?: 'left' | 'right';
    onChange: (val: boolean) => void;
    value: boolean;
}

export const DCheckbox = ({
    checkedIcon,
    uncheckedIcon,
    align = 'left',
    helperText,
    disableGutters = false,
    disabled = false,
    label,
    onChange,
    value,
    error,
}: IDCheckboxProps) => {
    const CheckedIcon = checkedIcon || CheckBoxIcon;
    const UncheckedIcon = uncheckedIcon || CheckBoxOutlineBlankIcon;

    return (
        <FormGroup
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
                flexDirection: 'row',
                mb: !disableGutters ? 1 : 0,
            }}
        >
            <FormControl
                size={'small'}
                margin={'none'}
                disabled={disabled}
                sx={{ width: 'auto' }}
            >
                <FormControlLabel
                    checked={value}
                    disabled={disabled}
                    control={
                        <Checkbox
                            disabled={disabled}
                            color={error ? 'error' : undefined}
                            sx={{ py: 1 }}
                            checkedIcon={<CheckedIcon />}
                            icon={<UncheckedIcon />}
                        />
                    }
                    label={label || ''}
                    onChange={(e, checked) => {
                        onChange(checked);
                    }}
                    value={value}
                />
                <FormHelperText sx={{ my: 0 }} error={error}>
                    {helperText}
                </FormHelperText>
            </FormControl>
        </FormGroup>
    );
};
