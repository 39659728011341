import { RHTextField } from '../../../../../../dum';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { IExpense, REQUIRE_SUPPLEMENT } from '../expense-form';

export function SupplementCodeInput() {
    const requireSupplement = useWatch({
        name: REQUIRE_SUPPLEMENT,
    }) as IExpense['require_supplement'];

    return (
        <RHTextField
            disabled={!requireSupplement}
            name={'supplement_code'}
            label={'Folio del complemento'}
        />
    );
}
