import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../../dum';
import { OrderAdjustmentProductInput } from './product/product-input';
import { IOrderSale } from '../../order-sale-form';

type OrderAdjustmentColumn = NonNullable<
    IOrderSale['order_adjustment_products']
>[number];

const defaultValue: OrderAdjustmentColumn = {
    product_id: null,
    kilos: 0,
    groups: 0,
};

export const ORDER_ADJUSTMENT_PRODUCTS: keyof IOrderSale =
    'order_adjustment_products';
export const KILOS: keyof OrderAdjustmentColumn = 'kilos';
export const GROUPS: keyof OrderAdjustmentColumn = 'groups';

export function OrderAdjustmentProductsColumns() {
    return (
        <RhColumns
            viewOnly
            name={ORDER_ADJUSTMENT_PRODUCTS}
            defaultValue={defaultValue}
            items={[
                {
                    cellGrow: 5,
                    getField: (index) => {
                        return <OrderAdjustmentProductInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ORDER_ADJUSTMENT_PRODUCTS}.${index}.${GROUPS}`}
                                label={'Bultos'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ORDER_ADJUSTMENT_PRODUCTS}.${index}.${KILOS}`}
                                label={'Kilos'}
                                disableGutters
                            />
                        );
                    },
                },
            ]}
        />
    );
}
