import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import {
    clearMaintenanceModule,
    navigateToSpareCategoriesPage,
    useDeleteSpareCategoryMutation,
} from '../../../../../../services';
import { useNavigate } from 'react-router-dom';

interface ISDeleteSpareCategoryDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    spareCategory: {
        id: number;
        name: string;
    };
}

export function SDeleteSpareCategoryDialog({
    open,
    setOpen,
    spareCategory,
}: ISDeleteSpareCategoryDialogProps) {
    const navigate = useNavigate();
    const [deleteMutation] = useDeleteSpareCategoryMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                await deleteMutation({
                    variables: {
                        SpareCategoryId: spareCategory.id,
                    },
                });
                navigateToSpareCategoriesPage({ navigate, replace: true });
            }}
        />
    );
}
