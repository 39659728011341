import { ValidationError } from 'yup';

export function doesEmployeeHaveUpStatus(
    value: any,
    context: any,
): boolean | ValidationError {
    const { employee } = context.from[0].value;
    if (!employee) return true;
    if (!(employee.employee_status_id === 1)) {
        return context.createError({
            message: `El empleado no esta dado de alta`,
        });
    }

    return true;
}
