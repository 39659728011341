import { Box } from '@mui/material';
import { WidthInput } from './width/width-input';
import { LengthInput } from './length/length-input';
import { CalibreInput } from './calibre/calibre-input';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { IProduct } from '../../product-form';
import { isCalibreRequired } from '../../validation/is-calibre-required';
import { isLengthRequired } from '../../validation/is-length-required';

const ORDER_PRODUCTION_TYPE_ID: keyof IProduct = 'order_production_type_id';

export function MeasurementsInput() {
    const order_production_type_id: IProduct['order_production_type_id'] =
        useWatch({
            name: ORDER_PRODUCTION_TYPE_ID,
        });

    const calibreRequired = useMemo(() => {
        return isCalibreRequired({ order_production_type_id });
    }, [order_production_type_id]);

    const lengthRequired = useMemo(() => {
        return isLengthRequired({ order_production_type_id });
    }, [order_production_type_id]);

    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Box sx={{ flex: 1, mr: lengthRequired ? 1 : 0 }}>
                <WidthInput />
            </Box>
            {lengthRequired ? (
                <Box
                    sx={{
                        flex: 1,
                        mr: calibreRequired ? 1 : 0,
                        ml: lengthRequired ? 1 : 0,
                    }}
                >
                    <LengthInput />
                </Box>
            ) : null}

            {calibreRequired ? (
                <Box sx={{ flex: 1, ml: 1 }}>
                    <CalibreInput />
                </Box>
            ) : null}
        </Box>
    );
}
