import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { RhDefaultProps } from '../common/rh-default-props';
import { useRhCustomContext } from '../rh-form-wrapper';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

interface IRHTextFieldProps extends RhDefaultProps {
    type?: 'text' | 'password';
    InputProps?: Partial<StandardInputProps>;
    rows?: number;
}

export function RHTextField({
    name,
    label,
    type,
    helperText,
    disableGutters = false,
    onChange,
    disabled = false,
    InputProps,
    rows,
    ...rest
}: IRHTextFieldProps) {
    const { control } = useFormContext();

    const { field, fieldState } = useController({
        name,
        control,
    });

    const { disabled: disabledFromContext } = useRhCustomContext();

    return (
        <TextField
            {...rest}
            sx={{ my: !disableGutters ? 2 : 0, width: '100%' }}
            label={label}
            onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                    onChange(e.target.value);
                }
            }} //
            InputProps={InputProps}
            inputRef={field.ref}
            onBlur={field.onBlur}
            value={field.value}
            name={name}
            type={type}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message || helperText || ''}
            margin={'dense'}
            size={'small'}
            disabled={disabledFromContext || disabled}
            rows={rows}
            multiline={(rows && rows > 0) || false}
        />
    );
}
