import { ValidationError } from 'yup';
import { IOrderAdjustment } from '../order-adjustment-form';

export function areUnitsLessThanOrderSaleProduct(unit: 'kilos' | 'groups') {
    return function (value: any, context: any): boolean | ValidationError {
        const { kilos, groups, id, product } = context.from[0]
            .value as IOrderAdjustment['order_adjustment_products'][number];

        if (!product) return true;

        const {
            order_sale,
            order_adjustment_type_id,
            order_sale_adjustment_products,
            order_sale_products,
        } = context.from[1].value as IOrderAdjustment;

        const product_id = product.id;

        if (!order_sale || order_adjustment_type_id !== 6) {
            return true;
        }

        const units = unit === 'kilos' ? kilos : groups;

        if (units < 0) {
            return context.createError({
                message: `${
                    unit === 'kilos' ? 'Kilos' : 'Bultos'
                } debe de ser mayor a 0`,
            });
        }

        const foundOrderSale = order_sale_products?.find((osp) => {
            return osp.product_id === product_id;
        });
        if (!foundOrderSale) {
            return true;
        }

        const filteredOrderAdjustments = order_sale_adjustment_products?.filter(
            (osap) => {
                return osap.id !== id && osap.product_id === product_id;
            },
        );

        const res = filteredOrderAdjustments?.reduce((acc, curr) => {
            return acc + (unit === 'kilos' ? curr.kilos : curr.groups);
        }, 0);

        const otherAdjustmentUnits = res || 0;

        const totalAdjustmentUnits =
            otherAdjustmentUnits + Number(unit === 'kilos' ? kilos : groups);

        const foundOrderSaleUnits =
            unit === 'kilos' ? foundOrderSale.kilos : foundOrderSale.groups;

        if (foundOrderSaleUnits < totalAdjustmentUnits) {
            return context.createError({
                message: `${
                    unit === 'kilos' ? 'Kilos' : 'Bultos'
                } del ajuste ${totalAdjustmentUnits} son mas que los de la venta ${foundOrderSaleUnits}`,
            });
        }

        return true;
    };
}
