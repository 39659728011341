import React, { useEffect, useMemo, useState } from 'react';
import {
    AccountsPageQuery,
    AccountsSortableFields,
    ColumnOrder,
    useAccountsPageLazyQuery,
} from '../../../../../services';
import {
    CTableWrapper,
    DBlock,
    DeleteIcon,
    DPage,
    DPageBody,
    DPageHeader,
    DPaginator,
    DTextField,
    EditIcon,
    SortedOrder,
    usePaginator,
} from '../../../../dum';
import { SDeleteAccountDialog, SUpsertAccountDialog } from '../../../../smart';
import { Box, IconButton } from '@mui/material';
import { useString } from '../../../../../helpers';

export function AccountsPage() {
    const [query, { data, previousData }] = useAccountsPageLazyQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [accountId, setAccountId] = useState<number | null>(null);

    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [filter, setFilter] = useString({
        KEY: AccountsPage.name + '_Filter',
    });

    const { setSkip, skip, take } = usePaginator(
        {
            KEY: AccountsPage.name + '_Paginator',
        },
        [filter],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                filter: filter,
                sortField:
                    sortedIndex === 0
                        ? AccountsSortableFields.Name
                        : sortedIndex === 1
                        ? AccountsSortableFields.Abbreviation
                        : null,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
            },
        });
    }, [query, skip, take, filter, sortedOrder, sortedIndex]);

    const accounts =
        data?.paginatedAccounts.docs ||
        previousData?.paginatedAccounts.docs ||
        [];

    const count =
        data && data?.paginatedAccounts.count >= 0
            ? data?.paginatedAccounts.count
            : previousData?.paginatedAccounts.count;

    const handleEditAccount = useMemo(() => {
        return (
            account: AccountsPageQuery['paginatedAccounts']['docs'][number],
        ) => {
            setAccountId(account.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteAccount = useMemo(() => {
        return (
            account: AccountsPageQuery['paginatedAccounts']['docs'][number],
        ) => {
            setAccountId(account.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DPage>
            <DPageHeader title={'Cuentas'} />
            <DPageBody disableGutters>
                <DBlock>
                    <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <DTextField
                                label={'Filtro'}
                                value={filter}
                                onChange={(value) => {
                                    setFilter(value || '');
                                }}
                            />
                        </Box>
                    </Box>

                    <DPaginator
                        skip={skip}
                        take={take}
                        setSkip={setSkip}
                        count={count}
                    />
                    <CTableWrapper
                        sortedIndex={sortedIndex}
                        setSortedIndex={setSortedIndex}
                        sortedOrder={sortedOrder}
                        setSortedOrder={setSortedOrder}
                        contextItems={[
                            {
                                title: 'Editar',
                                callback: (account) => {
                                    handleEditAccount(account);
                                },
                            },
                            {
                                title: 'Borrar',
                                disable: (account) => {
                                    return !account.is_deletable;
                                },
                                callback: (account) => {
                                    handleDeleteAccount(account);
                                },
                            },
                        ]}
                        items={[
                            {
                                title: 'Nombre',
                                cellGrow: 45,
                                sortable: true,
                                content: (account) => {
                                    return account.name;
                                },
                            },
                            {
                                title: 'Abreviación',
                                cellGrow: 45,
                                sortable: true,
                                content: (account) => {
                                    return account.abbreviation;
                                },
                            },
                            {
                                title: 'Tipo',
                                cellGrow: 25,
                                content: (account) => {
                                    const statuses: string[] = [];
                                    if (account.is_client) {
                                        statuses.push('Cliente');
                                    }
                                    if (account.is_own) {
                                        statuses.push('Cuenta propia');
                                    }

                                    if (account.is_supplier) {
                                        statuses.push('Proveedor');
                                    }
                                    return statuses.join(', ');
                                },
                            },
                            {
                                title: 'Acciones',
                                textAlign: 'center',
                                content: (account) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    handleEditAccount(account);
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={
                                                    !account.is_deletable ||
                                                    true
                                                }
                                                onClick={() => {
                                                    handleDeleteAccount(
                                                        account,
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        data={accounts}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertAccountDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                accountId={accountId}
            />
            <SDeleteAccountDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                accountId={accountId}
            />
        </DPage>
    );
}
