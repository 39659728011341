import { IOrderSale } from '../../order-sale-form';
import { ValidationError } from 'yup';

export function isProductUnique(
    value: any,
    context: any,
): ValidationError | boolean {
    const { product } = context.from[0]
        .value as IOrderSale['order_sale_products'][number];

    if (!product) return true;

    const orderSale = context.from[1].value as IOrderSale;
    const order_sale_products = orderSale.order_sale_products;

    const product_id = product.id;

    const results = order_sale_products.filter((pProduct: any) => {
        const product_id_2 = pProduct.product ? pProduct.product.id : null;
        return product_id === product_id_2;
    });

    if (results.length > 1) {
        return context.createError({
            message: `El producto no es unico`,
        });
    }

    return true;
}
