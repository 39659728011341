import React from 'react';
import { Box, Tooltip } from '@mui/material';

interface ICustomTooltipProps {
    text?: string;
    children: React.ReactNode;
}

export function DTooltip({ text, children }: ICustomTooltipProps) {
    return (
        <Tooltip title={text || ''} followCursor>
            <Box>{children}</Box>
        </Tooltip>
    );
}
