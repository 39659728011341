import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SPARES_PLURAL_NAME } from '../../../../../constants';
import { useSpareCategoryPageQuery } from '../../../../../services';
import {
    CTableWrapper,
    DBlock,
    DBlockHeader,
    DeleteIcon,
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import {
    SDeleteSpareCategoryDialog,
    SSpareCategoryFormDialog,
} from '../../../../smart';

type TSpareCategoryPageParams = {
    spareCategoryId: string;
};

export function SpareCategoryPage() {
    const params = useParams<TSpareCategoryPageParams>();
    const [open, setOpen] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const { data, loading, error } = useSpareCategoryPageQuery({
        variables: {
            SpareCategoryId: params.spareCategoryId
                ? Number(params.spareCategoryId)
                : 0,
        },
    });

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }

    const spareCategory = data!.getSpareCategory;

    return (
        <DPage animated>
            <DPageHeader title={`${spareCategory.name}`}>
                <DRowButtons
                    rowItems={[
                        {
                            icon: <EditIcon />,
                            tooltip: 'Editar categoria',
                            callback: () => {
                                setOpen(true);
                            },
                        },
                        {
                            icon: <DeleteIcon />,
                            tooltip: 'Eliminar categoria',
                            color: 'error',
                            disabled:
                                !data || !data.getSpareCategory.is_deletable,
                            callback: () => {
                                setConfirmationDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <DBlock>
                    <DBlockHeader subtitle={SPARES_PLURAL_NAME} />
                    <CTableWrapper
                        items={[
                            {
                                title: 'Nombre',
                                content: (spare) => spare.name,
                            },
                            {
                                title: 'Cantidad actual',
                                textAlign: 'right',
                                content: (spare) => spare.current_quantity,
                            },
                        ]}
                        data={spareCategory.spares}
                    />
                </DBlock>
            </DPageBody>
            <SSpareCategoryFormDialog
                open={open}
                setOpen={setOpen}
                spareCategory={spareCategory}
            />
            <SDeleteSpareCategoryDialog
                open={confirmationDialog}
                setOpen={setConfirmationDialog}
                spareCategory={spareCategory}
            />
        </DPage>
    );
}
