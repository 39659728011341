import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    OrderProductionProductInputQuery,
    useOrderProductionProductInputQuery,
} from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderProduction } from '../../order-production-form';

const ARRAY_NAME: keyof IOrderProduction = 'order_production_products';
const PRODUCT_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'product';
const MACHINE_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'machine';

const GROUP_WEIGHT_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'group_weight';
const ORDER_PRODUCTION_TYPE_FIELD_NAME: keyof IOrderProduction =
    'order_production_type_id';
const ID_FIELD_NAME: keyof IOrderProduction = 'id';
const KILOS_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'kilos';
const GROUPS_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'groups';

interface IProductsInput {
    index: number;
}

export function OrderProductionProductInput({ index }: IProductsInput) {
    const id: IOrderProduction['id'] = useWatch({
        name: ID_FIELD_NAME,
    });

    const { data: productsData } = useOrderProductionProductInputQuery({
        variables: {
            excludeDiscontinued: !id,
        },
    });
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${PRODUCT_FIELD_NAME}`;
    const formContext = useFormContext();

    const orderProductionTypeId: IOrderProduction['order_production_type_id'] =
        useWatch({
            name: ORDER_PRODUCTION_TYPE_FIELD_NAME,
        });

    const filteredProducts: OrderProductionProductInputQuery['getProducts'] =
        useMemo(() => {
            return (
                productsData?.getProducts?.filter((product) => {
                    return (
                        product.order_production_type_id ===
                        orderProductionTypeId
                    );
                }) || []
            );
        }, [productsData, orderProductionTypeId]);

    return (
        <RhSingleAutocomplete
            itemText={'compound_description'}
            itemValue={'id'}
            returnObject
            options={filteredProducts}
            name={INPUT_NAME}
            label={'Producto'}
            disableGutters
            onChange={(inputValue) => {
                if (productsData && !!inputValue) {
                    const orderProductionProducts = formContext.getValues(
                        ARRAY_NAME,
                    ) as IOrderProduction['order_production_products'];
                    if (orderProductionProducts) {
                        orderProductionProducts.forEach((p, i) => {
                            void formContext.trigger(
                                `${ARRAY_NAME}.${i}.${PRODUCT_FIELD_NAME}`,
                            );
                            void formContext.trigger(
                                `${ARRAY_NAME}.${i}.${MACHINE_FIELD_NAME}`,
                            );
                        });
                    }
                    const currentProduct = productsData.getProducts.find(
                        (p) => p.id === inputValue.id,
                    );
                    if (currentProduct) {
                        formContext.setValue(
                            `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
                            currentProduct.current_group_weight,
                        );
                        if (inputValue && currentProduct.current_group_weight) {
                            const groups =
                                (formContext.getValues(
                                    `${ARRAY_NAME}.${index}.${GROUPS_FIELD_NAME}`,
                                ) as IOrderProduction['order_production_products'][number]['groups']) ||
                                0;

                            formContext.setValue(
                                `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`,
                                groups * currentProduct.current_group_weight,
                            );
                        }
                    }
                }
            }}
        />
    );
}
