import { ValidationError } from 'yup';

export function doesProductBelongToOrderProductionType(
    value: any,
    context: any,
): boolean | ValidationError {
    const { product } = context.from[0].value;
    const { order_production_type_id } = context.from[1].value;

    if (!order_production_type_id || !product) return true;

    if (!(product.order_production_type_id === order_production_type_id)) {
        return context.createError({
            message: `El producto no pertenece a la orden de producción`,
        });
    }

    return true;
}
