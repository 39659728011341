import React, { useEffect } from 'react';
import { ISpareAdjustmentForm } from '../s-spare-adjustment-form-dialog';
import { useFormContext, useWatch } from 'react-hook-form';
import { RhColumns, RHTextField } from '../../../../../../dum';
import { SSingleSparesAutocomplete } from '../../../../../inputs';

const SPARE_TRANSACTIONS_FIELD_NAME: keyof ISpareAdjustmentForm =
    'spare_transactions';
const SPARE_ID_FIELD_NAME: keyof ISpareAdjustmentForm['spare_transactions'][number] =
    'spare_id';
const QUANTITY_FIELD_NAME: keyof ISpareAdjustmentForm['spare_transactions'][number] =
    'quantity';
const defaultValue: ISpareAdjustmentForm['spare_transactions'][number] = {
    spare_id: null,
    quantity: 0,
};

export function SpareTransactionsColumnsInput() {
    const { trigger } = useFormContext();
    const spareTransactions = useWatch({ name: SPARE_TRANSACTIONS_FIELD_NAME });

    useEffect(() => {
        void trigger(SPARE_TRANSACTIONS_FIELD_NAME);
    }, [spareTransactions, trigger]);

    return (
        <RhColumns
            title={'Transacciones'}
            name={SPARE_TRANSACTIONS_FIELD_NAME}
            defaultValue={defaultValue}
            items={[
                {
                    getField: (index) => {
                        return (
                            <SSingleSparesAutocomplete
                                name={`${SPARE_TRANSACTIONS_FIELD_NAME}.${index}.${SPARE_ID_FIELD_NAME}`}
                                label={'Refacción'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${SPARE_TRANSACTIONS_FIELD_NAME}.${index}.${QUANTITY_FIELD_NAME}`}
                                label={'Cantidad'}
                                disableGutters
                            />
                        );
                    },
                },
            ]}
        />
    );
}
