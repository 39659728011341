import React from 'react';
import { useMachineBranchInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IMachine } from '../s-machine-form';

export function BranchInput() {
    const { data } = useMachineBranchInputQuery();
    const INPUT_NAME: keyof IMachine = 'branch_id';

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Sucursal'}
            options={data?.getBranches || []}
        />
    );
}
