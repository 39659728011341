import React, { useMemo, useState } from 'react';
import { useBalancesPageQuery } from '../../../../services';
import {
    CTableWrapper,
    DBlock,
    DPage,
    DPageBody,
    DPageHeader,
    EditIcon,
} from '../../../dum';
import {
    DATE_FORMAT,
    formatFloat,
    getDateFromString,
    useBoolean,
} from '../../../../helpers';
import dayjs, { Dayjs } from 'dayjs';
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    Switch,
    Theme,
    Typography,
} from '@mui/material';
import { DCheckbox } from '../../../dum/simple/inputs/d-checkbox';
import { SUpsertExpenseDialog, SUpsertOrderSale } from '../../../smart';
import * as colors from '@mui/material/colors';
import { SxProps } from '@mui/system';

interface PendingItem {
    id?: number | null;
    account_name: string;
    expected_payment_date: Dayjs | null;
    date: Dayjs | null;
    order_code: string;
    type?: 'sale' | 'expense';
    cumulative_amount: number;
    to_be_charged: number;
    to_be_paid: number;
    notes: string;
}

function filterByExpectedDate({
    expected_payment_date: expectedPaymentDateDayjs,
    today: todayDayJs,
    include_after: includeAfter,
}: {
    expected_payment_date: Dayjs;
    today: Dayjs;
    include_after: boolean;
}): boolean {
    if (includeAfter) {
        return true;
    }
    const isExpectedAfter = expectedPaymentDateDayjs
        .startOf('month')
        .isAfter(todayDayJs.startOf('month'));
    return isExpectedAfter ? false : true;
}

export function BalancesPage() {
    const { data } = useBalancesPageQuery();
    const [openSale, setOpenSale] = useState(false);
    const [openExpense, setOpenExpense] = useState(false);
    const [pendingItemSelected, setPendingItemSelected] =
        useState<PendingItem | null>(null);
    const [view, setView] = useBoolean({
        KEY: BalancesPage.name + '_view',
        defaultValue: true,
    });

    const [includeNextMonthsSales, setIncludeNextMonthSales] = useBoolean({
        KEY: BalancesPage.name + '_IncludeNextMonthSales',
        defaultValue: false,
    });
    const [includeNextMonthExpenses, setIncludeNextMonthExpenses] = useBoolean({
        KEY: BalancesPage.name + '_IncludeNextMonthExpenses',
        defaultValue: true,
    });

    const pendingItems: PendingItem[] = useMemo(() => {
        const items: PendingItem[] = [];

        const todayDayjs = dayjs().utc();

        if (data) {
            items.push(
                ...data.getExpensesWithDisparities
                    .filter((ex) => view)
                    .filter((ex) => {
                        if (!ex.expected_payment_date) {
                            return true;
                        }
                        return filterByExpectedDate({
                            expected_payment_date: dayjs(
                                ex.expected_payment_date,
                            ).utc(),
                            today: todayDayjs,
                            include_after: includeNextMonthExpenses,
                        });
                    })
                    .map((ex) => {
                        return {
                            id: ex.id,
                            account_name: ex.account?.name || '',
                            to_be_charged:
                                ex.total_with_tax - ex.transfer_receipts_total,
                            to_be_paid: 0,
                            expected_payment_date: ex.expected_payment_date
                                ? dayjs(
                                      getDateFromString(
                                          ex.expected_payment_date,
                                      ),
                                  ).utc()
                                : null,
                            order_code: ex.order_code,
                            type: 'expense' as PendingItem['type'],
                            cumulative_amount: 0,
                            notes: ex.notes,
                            date: ex.date ? dayjs(ex.date).utc() : null,
                        };
                    }),
            );

            items.push(
                ...data.getOrderSalesWithDisparities
                    .filter((os) =>
                        view
                            ? os.receipt_type_id === 2
                            : os.receipt_type_id === 1,
                    )
                    .filter((os) => {
                        if (!os.expected_payment_date) {
                            return true;
                        }
                        return filterByExpectedDate({
                            expected_payment_date: dayjs(
                                os.expected_payment_date,
                            ).utc(),
                            today: todayDayjs,
                            include_after: includeNextMonthsSales,
                        });
                    })
                    .map((os) => {
                        return {
                            id: os.id,
                            account_name: os.account?.name || '',
                            to_be_paid:
                                os.total_with_tax - os.transfer_receipts_total,
                            expected_payment_date: os.expected_payment_date
                                ? dayjs(
                                      getDateFromString(
                                          os.expected_payment_date,
                                      ),
                                  ).utc()
                                : null,
                            order_code:
                                os.receipt_type_id === 2
                                    ? String(os.invoice_code)
                                    : String(os.order_code),
                            to_be_charged: 0,
                            type: 'sale' as PendingItem['type'],
                            cumulative_amount: 0,
                            notes: os.notes,
                            date: os.date ? dayjs(os.date).utc() : null,
                        };
                    }),
            );

            items.sort((a, b) => {
                if (
                    a.expected_payment_date === null &&
                    b.expected_payment_date === null
                ) {
                    if (a.type === 'sale' && b.type === 'expense') {
                        return 1;
                    } else if (a.type === 'expense' && b.type === 'sale') {
                        return -1;
                    } else {
                        if (a.account_name < b.account_name) {
                            return -1;
                        } else if (a.account_name > b.account_name) {
                            return 1;
                        } else {
                            if (a.date && b.date) {
                                return a.date.isBefore(b.date)
                                    ? -1
                                    : a.date.isAfter(b.date)
                                    ? 1
                                    : 0;
                            } else if (a.date) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                    }
                } else if (a.expected_payment_date === null) {
                    return 1;
                } else if (b.expected_payment_date === null) {
                    return -1;
                } else {
                    return a.expected_payment_date.isBefore(
                        b.expected_payment_date,
                    )
                        ? -1
                        : a.expected_payment_date.isAfter(
                              b.expected_payment_date,
                          )
                        ? 1
                        : 0;
                }
            });

            const initialCumulativeAmount: number =
                data.getAccountTransferSummary
                    .filter((account) => {
                        return view
                            ? account.account_id !== 38
                            : account.account_id === 38;
                    })
                    .reduce((acc, curr) => {
                        return acc + (curr.current_amount || 0);
                    }, 0);
            let toBeCharged: number = 0;
            let toBePaid: number = 0;

            let cumulativeAmount = initialCumulativeAmount;

            items.forEach((item) => {
                cumulativeAmount =
                    cumulativeAmount + item.to_be_paid - item.to_be_charged;
                item.cumulative_amount = cumulativeAmount;
                toBePaid += item.type === 'sale' ? item.to_be_paid : 0;
                toBeCharged += item.type === 'expense' ? item.to_be_charged : 0;
            });

            items.push({
                id: null,
                account_name: '',
                notes: '',
                to_be_paid: toBePaid,
                to_be_charged: toBeCharged,
                expected_payment_date: null,
                order_code: '',
                cumulative_amount: cumulativeAmount,
                date: null,
            });
        }

        return items;
    }, [data, view, includeNextMonthsSales, includeNextMonthExpenses]);

    return (
        <DPage>
            <DPageHeader title={'Saldos y pagos'} />
            <DPageBody disableGutters>
                <DBlock>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ mb: 0, ml: 1 }}>
                                Cuentas:
                            </Typography>
                            <Typography sx={{ mb: 2, ml: 1 }}>
                                <List dense>
                                    {data?.getAccountTransferSummary
                                        .filter((account) => {
                                            return view
                                                ? account.account_id !== 38
                                                : account.account_id === 38;
                                        })
                                        .map((ats) => {
                                            return (
                                                <ListItem key={ats.account_id}>
                                                    {`${
                                                        ats.account?.name
                                                    }: ${formatFloat(
                                                        ats.current_amount,
                                                    )}`}
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Switch
                                sx={{
                                    alignSelf: 'flex-end',
                                    mb: 2,
                                }}
                                onChange={(val) => {
                                    setView(!view);
                                }}
                                value={view}
                            />
                            <DCheckbox
                                align={'left'}
                                disableGutters
                                onChange={(val) => {
                                    setIncludeNextMonthSales(
                                        !includeNextMonthsSales,
                                    );
                                }}
                                value={includeNextMonthsSales}
                                label={'Incluir ventas de los proximos meses'}
                            />
                            <DCheckbox
                                align={'left'}
                                disableGutters
                                onChange={(val) => {
                                    setIncludeNextMonthExpenses(
                                        !includeNextMonthExpenses,
                                    );
                                }}
                                value={includeNextMonthExpenses}
                                label={'Incluir gastos de los proximos meses'}
                            />
                        </Box>
                    </Box>

                    <Divider sx={{ mb: 2 }} />
                    <Typography variant={'body2'} sx={{ mb: 1 }}>
                        <b>Tabla de saldos y pagos</b>
                    </Typography>
                    <CTableWrapper
                        onClick={(item) => {
                            if (item.type === 'sale') {
                                setOpenSale(true);
                                setPendingItemSelected(item);
                            } else if (item.type === 'expense') {
                                setOpenExpense(true);
                                setPendingItemSelected(item);
                            }
                        }}
                        padding={false}
                        contextItems={[
                            {
                                title: 'Editar',
                                callback: (item) => {
                                    if (item.type === 'sale') {
                                        setOpenSale(true);
                                        setPendingItemSelected(item);
                                    } else if (item.type === 'expense') {
                                        setOpenExpense(true);
                                        setPendingItemSelected(item);
                                    }
                                },
                            },
                        ]}
                        items={[
                            {
                                title: 'Folio',
                                content: (pi) => {
                                    return pi.order_code;
                                },
                            },
                            {
                                title: 'Cliente/Proveedor',
                                cellGrow: 3,
                                textAlign: 'left',
                                content: (pi) => {
                                    return (
                                        (pi.account_name.length > 29
                                            ? pi.account_name.slice(0, 30)
                                            : pi.account_name) +
                                        (pi.account_name.length > 29
                                            ? '...'
                                            : '')
                                    );
                                },
                            },
                            {
                                title: 'Fecha de emisión',
                                textAlign: 'center',
                                content: (pi) => {
                                    return pi.date !== null
                                        ? pi.date.format(DATE_FORMAT)
                                        : '';
                                },
                                cellSx: () => {
                                    return {
                                        whiteSpace: 'nowrap',
                                    };
                                },
                            },
                            {
                                title: 'Notas',
                                textAlign: 'left',
                                cellGrow: 3,
                                content: (pi) => {
                                    return pi.notes;
                                },
                            },

                            {
                                title: 'Fecha esperada',
                                content: (pi) => {
                                    return pi.expected_payment_date !== null
                                        ? pi.expected_payment_date.format(
                                              DATE_FORMAT,
                                          )
                                        : '';
                                },
                                cellSx: () => {
                                    return {
                                        whiteSpace: 'nowrap',
                                    };
                                },
                            },
                            {
                                title: 'Por cobrar',
                                textAlign: 'right',
                                content: (pi, i) => {
                                    const amount = formatFloat(pi.to_be_paid);
                                    return i !== pendingItems.length - 1 ? (
                                        amount
                                    ) : (
                                        <b>{amount}</b>
                                    );
                                },
                            },
                            {
                                title: 'Por pagar',
                                textAlign: 'right',
                                content: (pi, i) => {
                                    const amount = formatFloat(
                                        pi.to_be_charged,
                                    );
                                    return i !== pendingItems.length - 1 ? (
                                        amount
                                    ) : (
                                        <b>{amount}</b>
                                    );
                                },
                            },
                            {
                                title: 'Acumulado',
                                textAlign: 'right',
                                content: (pi, i) => {
                                    const amount = formatFloat(
                                        pi.cumulative_amount,
                                    );
                                    return i !== pendingItems.length - 1 ? (
                                        amount
                                    ) : (
                                        <b>{amount}</b>
                                    );
                                },
                            },
                            {
                                title: '',
                                textAlign: 'center',
                                content: (item, i) => {
                                    return (
                                        <Box>
                                            <IconButton
                                                onClick={() => {
                                                    if (item.type === 'sale') {
                                                        setOpenSale(true);
                                                        setPendingItemSelected(
                                                            item,
                                                        );
                                                    } else if (
                                                        item.type === 'expense'
                                                    ) {
                                                        setOpenExpense(true);
                                                        setPendingItemSelected(
                                                            item,
                                                        );
                                                    }
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        rowSx={(item) => {
                            const todaySx: SxProps<Theme> = {
                                borderBottomWidth: 4,
                                borderBottomColor: colors.blue['700'],
                                borderBottomStyle: 'solid',
                            };

                            if (!item.expected_payment_date) {
                                return {};
                            }

                            const expectedPaymentDateItemDayjs =
                                item.expected_payment_date;
                            const today = dayjs();

                            const isAfterToday =
                                expectedPaymentDateItemDayjs.isAfter(today);

                            if (isAfterToday) {
                                return {};
                            }
                            const dataItemIndex = pendingItems.findIndex(
                                (pendingItem) => {
                                    return pendingItem === item;
                                },
                            );
                            const nextItem = pendingItems[dataItemIndex + 1];
                            if (
                                expectedPaymentDateItemDayjs.isBefore(today) &&
                                (!nextItem ||
                                    !nextItem.expected_payment_date ||
                                    nextItem.expected_payment_date.isAfter(
                                        today,
                                    ))
                            ) {
                                return todaySx;
                            } else if (
                                today.isSame(
                                    item.expected_payment_date,
                                    'date',
                                ) &&
                                (!nextItem ||
                                    !nextItem.expected_payment_date ||
                                    nextItem.expected_payment_date.isAfter(
                                        expectedPaymentDateItemDayjs,
                                    ))
                            ) {
                                return todaySx;
                            }
                            return {};
                        }}
                        data={pendingItems || []}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertOrderSale
                open={openSale}
                setOpen={setOpenSale}
                orderSaleId={pendingItemSelected?.id}
            />
            <SUpsertExpenseDialog
                open={openExpense}
                setOpen={setOpenExpense}
                expenseId={pendingItemSelected?.id}
            />
        </DPage>
    );
}
