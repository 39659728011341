import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage, DPageHeader } from '../../../dum';
import { TransfersPage } from './transfers/transfers-page';
import { DFab } from '../../../dum/simple/button/d-fab';
import { SUpsertTransferDialog } from '../../../smart';

const routes: Route[] = [
    {
        name: 'TransfersPage',
        path: '/',
        component: <TransfersPage />,
        title: 'Administración',
        navbar: false,
    },
];

export function TransfersHomePage() {
    const [transferUpsertDialog, setTransferUpsertDialog] = useState(false);

    return (
        <DPage>
            <DPageHeader title={'Transferencias'} />
            <DFab
                onClick={() => {
                    setTransferUpsertDialog(true);
                }}
            />
            <ReactRouterRoutes>
                {routes.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertTransferDialog
                open={transferUpsertDialog}
                setOpen={setTransferUpsertDialog}
            />
        </DPage>
    );
}
