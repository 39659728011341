import React from 'react';
import { useSparesInputQuery } from '../../../../../services';
import { RhSingleAutocomplete } from '../../../../dum';

interface ISSingleSparesAutocompleteProps {
    name: string;
    label: string;
    disableGutters?: boolean;
}

export function SSingleSparesAutocomplete({
    name,
    label,
    disableGutters = false,
}: ISSingleSparesAutocompleteProps) {
    const { data } = useSparesInputQuery();

    const spares = data?.getSpares || [];

    return (
        <RhSingleAutocomplete
            disableGutters={disableGutters}
            itemText={'name'}
            itemValue={'id'}
            name={name}
            label={label}
            options={spares}
        />
    );
}
