import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RHTextField } from '../../../../../../../../dum';
import { IOrderRequest } from '../../../order-request-form';
import { useCalculateProductsTotal } from '../../../helpers/use-calculate-products-total';

const ARRAY_NAME: keyof IOrderRequest = 'order_request_products';
const GROUPS_FIELD_NAME: keyof IOrderRequest['order_request_products'][number] =
    'groups';
const KILOS_FIELD_NAME: keyof IOrderRequest['order_request_products'][number] =
    'kilos';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderRequest['order_request_products'][number] =
    'group_weight';

interface IGroupsInputProps {
    index: number;
}

export function GroupsInput({ index }: IGroupsInputProps) {
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${GROUPS_FIELD_NAME}`;

    const formContext = useFormContext();
    const { calculateProductsTotal } = useCalculateProductsTotal();

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'Bultos'}
            disableGutters
            onChange={(groups) => {
                const groupWeight = formContext.getValues(
                    `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
                ) as IOrderRequest['order_request_products'][number]['group_weight'];
                if (!!groupWeight) {
                    formContext.setValue(
                        `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`,
                        (!!groups ? groups : 0) * groupWeight,
                    );
                }
                calculateProductsTotal();
            }}
        />
    );
}
