import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage, DSpeedDial, ICustomSpeedDialProps } from '../../../dum';
import {
    MachineIcon,
    SMachinePartFormDialog,
    SMachineSectionFormDialog,
    SUpsertMachineDialog,
} from '../../../smart';
import { MachineSectionPage } from './machine-section/machine-section-page';
import { MachinePage } from './machine/machine-page';
import { MachinesPage } from './machines/machines-page';

const machinesPages: Route[] = [
    {
        name: 'MachinesListPage',
        path: '/',
        component: <MachinesPage />,
        title: 'Maquinas',
        navbar: false,
    },
    {
        name: 'MachinePage',
        path: '/:machineId',
        component: <MachinePage />,
        title: 'Maquina',
        navbar: false,
    },
    {
        name: 'MachineSectionPage',
        path: '/:machineId/machineSections/:machineSectionId',
        component: <MachineSectionPage />,
        title: 'Seccion',
        navbar: false,
    },
];

export function MachinesHomePage() {
    const [machinesDialog, setMachinesDialog] = useState(false);
    const [machineSectionDialog, setMachineSectionDialog] = useState(false);
    const [machinePartDialog, setMachinePartDialog] = useState(false);

    const actions: ICustomSpeedDialProps['items'] = [
        {
            icon: <MachineIcon />,
            text: 'Nueva maquina',
            callback: () => {
                setMachinesDialog(true);
            },
        },
    ];

    return (
        <DPage>
            <DSpeedDial items={actions} />
            <ReactRouterRoutes>
                {machinesPages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertMachineDialog
                open={machinesDialog}
                setOpen={setMachinesDialog}
            />
            <SMachinePartFormDialog
                open={machinePartDialog}
                setOpen={setMachinePartDialog}
            />
            <SMachineSectionFormDialog
                open={machineSectionDialog}
                setOpen={setMachineSectionDialog}
            />
        </DPage>
    );
}
