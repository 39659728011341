import React, { useEffect, useMemo, useState } from 'react';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPaginator,
    DTextField,
    DYear,
    EditIcon,
    SortedOrder,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../dum';
import { Box, IconButton, Typography } from '@mui/material';
import {
    ColumnOrder,
    TransfersPageQuery,
    TransfersSortableFields,
    useTransfersPageLazyQuery,
} from '../../../../../services';
import {
    formatDateTime,
    formatFloat,
    useNumber,
    useString,
} from '../../../../../helpers';
import {
    SDeleteTransferDialog,
    SUpsertTransferDialog,
} from '../../../../smart';

export function TransfersPage() {
    const [query, { data, previousData }] = useTransfersPageLazyQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [transferId, setTransferId] = useState<null | number>(null);

    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [fromAccountId, setFromAccountIdId] = useNumber({
        KEY: TransfersPage.name + '_FromAccountIdId',
    });
    const [toAccountId, setToAccountId] = useNumber({
        KEY: TransfersPage.name + '_ToAccountIdId',
    });

    const [filter, setFilter] = useString({
        KEY: TransfersPage.name + '_Filter',
    });

    const { year, setYear } = useDYear({
        COMPONENT_KEY: TransfersPage.name + '_Year',
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: TransfersPage.name + '_Month',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: TransfersPage.name + '_Paginator',
        },
        [month, year, filter, fromAccountId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                fromAccountId: fromAccountId,
                toAccountId: toAccountId,
                filter: filter,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
                sortField:
                    sortedIndex === 0
                        ? TransfersSortableFields.TransferredDate
                        : null,
            },
        });
    }, [
        query,
        skip,
        take,
        year,
        month,
        filter,
        sortedOrder,
        sortedIndex,
        fromAccountId,
        toAccountId,
    ]);

    const count =
        data && data?.paginatedTransfers.count >= 0
            ? data?.paginatedTransfers.count
            : previousData?.paginatedTransfers.count;

    const transfers: TransfersPageQuery['paginatedTransfers']['docs'] =
        data?.paginatedTransfers.docs ||
        previousData?.paginatedTransfers.docs ||
        [];

    const handleEditTransfer = useMemo(() => {
        return (
            transfer: TransfersPageQuery['paginatedTransfers']['docs'][number],
        ) => {
            setTransferId(transfer.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteTransfer = useMemo(() => {
        return (
            transfer: TransfersPageQuery['paginatedTransfers']['docs'][number],
        ) => {
            setTransferId(transfer.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <>
            <DBlock>
                <Box sx={{ mb: 2 }}>
                    <Typography>
                        <b>Cuentas propias: </b>
                        {data?.getAccountTransferSummary.map((ats, index) => {
                            return (
                                <span key={ats.account_id}>
                                    {`${ats.account?.name}: ${formatFloat(
                                        ats.current_amount,
                                    )}${
                                        index ===
                                        data?.getAccountTransferSummary
                                            ?.length -
                                            1
                                            ? ''
                                            : ', '
                                    } `}
                                </span>
                            );
                        })}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Box>
                            <DTextField
                                label={'Filtro'}
                                helperText={
                                    'Filtro para el folio del pedido o las notas del pedido'
                                }
                                value={filter}
                                onChange={(val) => {
                                    setFilter(val);
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <CVerticalDropdown
                            items={[
                                {
                                    component: (
                                        <DYear setYear={setYear} year={year} />
                                    ),
                                },
                                {
                                    component: (
                                        <DMonth
                                            setMonth={setMonth}
                                            month={month}
                                        />
                                    ),
                                },
                                {
                                    component: (
                                        <DIdAutocomplete
                                            id={fromAccountId}
                                            onChange={setFromAccountIdId}
                                            itemText={'compound_name'}
                                            label={'Cuenta de retiro'}
                                            options={data?.getAccounts || []}
                                        />
                                    ),
                                },
                                {
                                    component: (
                                        <DIdAutocomplete
                                            id={toAccountId}
                                            onChange={setToAccountId}
                                            itemText={'compound_name'}
                                            label={'Cuenta de abono'}
                                            options={data?.getAccounts || []}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Box>
                </Box>

                <DPaginator
                    skip={skip}
                    setSkip={setSkip}
                    take={take}
                    count={count}
                />

                <CTableWrapper
                    onClick={(transfer) => {
                        handleEditTransfer(transfer);
                    }}
                    contextItems={[
                        {
                            title: 'Editar',
                            disable: (transfer) => {
                                return false;
                            },
                            callback: (transfer) => {
                                handleEditTransfer(transfer);
                            },
                        },
                        {
                            title: 'Borrar',
                            disable: (transfer) => {
                                return false;
                            },
                            callback: (transfer) => {
                                handleDeleteTransfer(transfer);
                            },
                        },
                    ]}
                    sortedIndex={sortedIndex}
                    sortedOrder={sortedOrder}
                    setSortedIndex={setSortedIndex}
                    setSortedOrder={setSortedOrder}
                    items={[
                        {
                            title: 'Fecha de realizacion',
                            cellGrow: 2,
                            sortable: true,
                            content: (transfer) => {
                                return formatDateTime(
                                    transfer.transferred_date,
                                );
                            },
                        },
                        {
                            title: 'Cuenta de retiro',
                            cellGrow: 2,
                            textAlign: 'center',
                            content: (transfer) => {
                                return transfer.from_account?.name;
                            },
                        },
                        {
                            cellGrow: 2,
                            title: 'Cuenta de ingreso',
                            textAlign: 'center',
                            content: (transfer) => {
                                return transfer.to_account?.name;
                            },
                        },
                        {
                            title: 'Folios',
                            textAlign: 'right',
                            content: (transfer) => {
                                return transfer.transfer_receipts.reduce(
                                    (acc, tr, index) => {
                                        return (
                                            acc +
                                            `${tr.expense?.order_code || ''}${
                                                tr.order_sale
                                                    ?.compound_order_code || ''
                                            }${
                                                index !==
                                                transfer.transfer_receipts
                                                    .length -
                                                    1
                                                    ? ', '
                                                    : ''
                                            }`
                                        );
                                    },
                                    '',
                                );
                            },
                        },

                        {
                            title: 'Cantidad',
                            textAlign: 'right',
                            content: (transfer) => {
                                const difference =
                                    transfer.amount -
                                    transfer.transfer_receipts_total;
                                const hasDifference = difference !== 0;

                                return `${formatFloat(transfer.amount)}${
                                    hasDifference ? ' (' + difference + ')' : ''
                                }`;
                            },
                        },
                        {
                            title: 'Acciones',
                            content: (transfer) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <IconButton
                                            disabled={false}
                                            onClick={() => {
                                                handleEditTransfer(transfer);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={false}
                                            onClick={() => {
                                                handleDeleteTransfer(transfer);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                );
                            },
                        },
                    ]}
                    data={transfers}
                />
                <SUpsertTransferDialog
                    open={upsertOpen}
                    setOpen={setUpsertOpen}
                    transferId={transferId}
                />
                <SDeleteTransferDialog
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    transferId={transferId}
                />
            </DBlock>
        </>
    );
}
