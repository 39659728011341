import React from 'react';
import { useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../../dum';
import { isWidthRequired } from './width-validation';
import { IProduct } from '../../../product-form';

export function WidthInput() {
    const inputName: keyof IProduct = 'width';

    const orderProductionTypeIdName: keyof IProduct =
        'order_production_type_id';
    const orderProductionTypeId = useWatch({
        name: orderProductionTypeIdName,
    });

    if (!isWidthRequired({ order_production_type_id: orderProductionTypeId })) {
        return null;
    }

    return <RHTextField name={inputName} label={'Ancho'} />;
}
