import { RHTextField } from '../../../../../../dum';
import React from 'react';
import { SUBTOTAL } from '../expense-form';
import { useCalculateExpenseTotal } from '../use-calculate-expense-total';

export function SubtotalInput() {
    const { calculateExpenseTotal } = useCalculateExpenseTotal();
    return (
        <RHTextField
            name={SUBTOTAL}
            label={'Subtotal'}
            onChange={() => {
                calculateExpenseTotal();
            }}
        />
    );
}
