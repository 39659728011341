import React from 'react';
import { TableCell, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export type TCustomTableBodyCellTextAlign = 'left' | 'center' | 'right';

interface ICustomTableBodyCellProps {
    children?: React.ReactNode;
    textAlign?: TCustomTableBodyCellTextAlign;
    colSpan?: number;
    noBorder?: boolean;
    py?: number;
    px?: number;
    pt?: number;
    pb?: number;
    fontWeight?: 'normal' | 'bold';
    sx?: SxProps<Theme>;
}

export function DTableBodyCell({
    children,
    textAlign = 'left',
    colSpan = 1,
    noBorder = false,
    fontWeight = 'normal',
    py,
    px,
    pt,
    pb,
    sx = {},
}: ICustomTableBodyCellProps) {
    return (
        <TableCell
            sx={{
                ...sx,
                textAlign: textAlign,
                borderBottom: noBorder ? 'none' : undefined,
                py: py ? py : undefined,
                px: px ? px : undefined,
                fontWeight: fontWeight,
                pt: pt ? pt : undefined,
                pb: pb ? pb : undefined,
            }}
            colSpan={colSpan}
        >
            {children}
        </TableCell>
    );
}
