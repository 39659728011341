import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { AccountIcon } from '../../../../icons';
import { AccountForm } from './account-form';
import { useAccountDialogQuery } from '../../../../../../services';

interface ISAccountFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    accountId?: number | null;
    disabled?: boolean;
}

export const SUpsertAccountDialog = (props: ISAccountFormDialogProps) => {
    const { open, setOpen, accountId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useAccountDialogQuery({
        skip: !accountId || !open,
        variables: {
            AccountId: accountId!,
        },
    });

    const account = useMemo(() => {
        return accountId && data && data.getAccount && open
            ? data.getAccount
            : undefined;
    }, [accountId, data, open]);

    return (
        <DModal
            icon={<AccountIcon />}
            title={`Cliente`}
            open={open}
            setOpen={setOpen}
            width={'800px'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <AccountForm
                    disabled={isModalClosing}
                    account={account}
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                />
            )}
        </DModal>
    );
};
