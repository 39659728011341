import { Autocomplete, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ArrowDropDownIcon, CloseIcon } from '../../../icon';
import { RhDefaultProps } from '../common/rh-default-props';
import { useRhCustomContext } from '../rh-form-wrapper';

interface IRhAutocompleteProps<T> extends RhDefaultProps {
    itemText: keyof T;
    itemValue: keyof T;
    returnObject?: boolean;
    options: T[];
}

function getAutocompleteValue<T>({
    options,
    fieldValue,
    itemValue,
    returnObject,
}: {
    fieldValue: any;
} & Pick<
    IRhAutocompleteProps<T>,
    'options' | 'itemValue' | 'returnObject'
>): T | null {
    return (
        options.find((option) => {
            if (!option) return false;
            if (returnObject) {
                const value = !!fieldValue ? fieldValue[itemValue] : null;
                return value === option[itemValue];
            } else {
                return option[itemValue] === fieldValue;
            }
        }) || null
    );
}

export function RhSingleAutocomplete<T>({
    name,
    label,
    itemValue,
    itemText,
    options,
    helperText,
    disableGutters = false,
    returnObject = false,
    onChange,
    disabled = false,
    ...rest
}: IRhAutocompleteProps<T>) {
    const { control } = useFormContext();
    const { field, fieldState } = useController({
        name,
        control,
    });

    const [autocompleteValue, setAutocompleteValue] = useState<T | null>(() => {
        return getAutocompleteValue({
            options,
            fieldValue: field.value,
            itemValue,
            returnObject,
        });
    });
    const { disabled: disabledFromContext } = useRhCustomContext();

    useEffect(() => {
        setAutocompleteValue(
            getAutocompleteValue({
                options,
                fieldValue: field.value,
                itemValue,
                returnObject,
            }),
        );
    }, [returnObject, options, itemValue, field.value]);

    return (
        <Autocomplete
            {...rest}
            disabled={disabled || disabledFromContext}
            isOptionEqualToValue={(option, value) => {
                if (!value) return false;
                return option[itemValue] === value[itemValue];
            }}
            value={autocompleteValue}
            onChange={(event: any, val: T | null) => {
                const value = val
                    ? returnObject
                        ? val
                        : val[itemValue]
                    : null;
                field.onChange({
                    target: {
                        value: value,
                    },
                });
                if (onChange) {
                    onChange(value);
                }
            }}
            onBlur={field.onBlur}
            getOptionLabel={(val) => {
                if (!val) return '';
                return String(val[itemText]);
            }}
            options={options}
            renderOption={(props, option) => {
                if (!option || !option[itemValue]) return null;
                const val = option[itemText];
                return (
                    <li {...props} key={String(option[itemValue])}>
                        {val}
                    </li>
                );
            }}
            disableClearable={disabled || disabledFromContext}
            clearIcon={<CloseIcon fontSize={'medium'} />}
            popupIcon={<ArrowDropDownIcon fontSize={'medium'} />}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        disabled={disabledFromContext || disabled}
                        sx={{ my: !disableGutters ? 2 : 0, width: '100%' }}
                        label={label}
                        inputRef={field.ref}
                        error={Boolean(fieldState.error)}
                        helperText={
                            fieldState.error?.message || helperText || ''
                        }
                        margin={'dense'}
                        size={'small'}
                    />
                );
            }}
        />
    );
}
