import React from 'react';
import { useProductMaterialInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IProduct } from '../../product-form';

export function ProductMaterialInput() {
    const inputName: keyof IProduct = 'product_material_id';

    const { data } = useProductMaterialInputQuery();

    const options = data?.getProductMaterials || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Material del producto'}
            options={options}
        />
    );
}
