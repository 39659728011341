import { DDatePicker } from './d-date-picker';
import { useNumber, YEAR_FORMAT, YEAR_MASK } from '../../../../helpers';
import React, { useState } from 'react';
import dayjs from 'dayjs';

interface IDYear {
    setYear: (year: number | null) => void;
    year: number | null;
    clearable?: boolean;
    disableGutters?: boolean;
    disabled?: boolean;
}

interface IUseDYear {
    COMPONENT_KEY: string;
    defaultValue?: number | null;
}

export const useDYear = function ({
    COMPONENT_KEY,
    defaultValue = null,
}: IUseDYear) {
    const [year, setYear] = useNumber({
        KEY: COMPONENT_KEY,
        defaultValue: defaultValue,
    });

    return {
        year,
        setYear,
    };
};

export function DYear({
    setYear,
    year,
    clearable = true,
    disableGutters = true,
    disabled = false,
}: IDYear) {
    const [localYear, setLocalYear] = useState<string | null>(() => {
        return year !== undefined && year !== null
            ? dayjs().utc().set('year', year).format(YEAR_FORMAT)
            : null;
    });

    return (
        <DDatePicker
            disabled={disabled}
            onChange={(val) => {
                setLocalYear(val);
                setYear(
                    val !== null ? dayjs(val, YEAR_FORMAT).utc().year() : null,
                );
            }}
            label={'Año'}
            value={localYear}
            views={['year']}
            mask={YEAR_MASK}
            inputFormat={YEAR_FORMAT}
            disableGutters={disableGutters}
            clearable={clearable}
        />
    );
}
