import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrderProductionPageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { SUpsertOrderProductionDialog } from '../../../../smart';

type TOrderProductionPageParams = {
    orderProductionId: string;
};

export function OrderProductionPage() {
    const params = useParams<TOrderProductionPageParams>();
    const [orderProductionDialog, setOrderProductionDialog] = useState(false);
    const { data, loading, error } = useOrderProductionPageQuery({
        variables: {
            OrderProductionId: params.orderProductionId
                ? Number(params.orderProductionId)
                : 0,
        },
    });

    if (loading) {
        return <DPageLoader />;
    }

    if (error || !data) {
        return <DErrorCard />;
    }

    const orderProduction = data.getOrderProduction;

    return (
        <DPage animated>
            <DPageHeader title={'Orden de produccion'}>
                <DRowButtons
                    rowItems={[
                        {
                            tooltip: 'Editar producto',
                            icon: <EditIcon />,
                            callback: () => {
                                setOrderProductionDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <Box>{orderProduction.start_date}</Box>
            </DPageBody>
            <SUpsertOrderProductionDialog
                open={orderProductionDialog}
                setOpen={setOrderProductionDialog}
                orderProductionId={orderProduction.id}
            />
        </DPage>
    );
}
