import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../dum';
import { IRawMaterialAddition } from '../raw-material-addition-form';
import { ResourceInput } from './resource/resource-input';
import { useCalculateTotal } from '../use-calculate-total';

const ARRAY_NAME: keyof IRawMaterialAddition = 'raw_material_addition_items';
const AMOUNT_NAME: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'amount';
const UNIT_PRICE_NAME: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'unit_price';

const RESOURCE_NAME: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'resource_id';

const TOTAL_NAME: keyof IRawMaterialAddition['raw_material_addition_items'][number] =
    'total';

const defaultValue: IRawMaterialAddition['raw_material_addition_items'][number] =
    {
        amount: 0,
        unit_price: 0,
        resource_id: null,
        total: 0,
    };

export function RawMaterialAdditionItemsColumns() {
    const { calculateTotal } = useCalculateTotal();

    return (
        <RhColumns
            title={'Elementos'}
            name={ARRAY_NAME}
            defaultValue={defaultValue}
            items={[
                {
                    getField: (index) => {
                        return (
                            <ResourceInput
                                name={`${ARRAY_NAME}.${index}.${RESOURCE_NAME}`}
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${AMOUNT_NAME}`}
                                label={'Cantidad'}
                                disableGutters
                                onChange={() => {
                                    calculateTotal({ index });
                                }}
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${UNIT_PRICE_NAME}`}
                                label={'Precio'}
                                disableGutters
                                onChange={() => {
                                    calculateTotal({ index });
                                }}
                            />
                        );
                    },
                },

                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ARRAY_NAME}.${index}.${TOTAL_NAME}`}
                                label={'Total'}
                                disableGutters
                            />
                        );
                    },
                },
            ]}
        />
    );
}
