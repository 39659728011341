import {
    isBagProduct,
    isOthersProduct,
} from '../../../../../../../services/entities';

export function isCurrentGroupWeightRequired({
    order_production_type_id,
}: {
    order_production_type_id: number;
}): boolean {
    return (
        isBagProduct({ order_production_type_id }) ||
        isOthersProduct({ order_production_type_id })
    );
}
