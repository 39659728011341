import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { ChildComponent } from './child-component';

export const LightContext = React.createContext<undefined | boolean>(undefined);

export function ParentComponent() {
    const [light, setLight] = useState(false);

    return (
        <LightContext.Provider value={light}>
            <Box>
                <Button
                    sx={{ my: 2 }}
                    variant={'contained'}
                    onClick={() => {
                        setLight(!light);
                    }}
                >
                    Change light
                </Button>
                <ChildComponent />
            </Box>
        </LightContext.Provider>
    );
}
