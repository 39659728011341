import React from 'react';
import { DPage, DPageBody, DPageHeader } from '../../../../dum';
import { OrderRequestsListBlock } from './blocks/order-requests-list-block';

export function OrderRequestsPage() {
    return (
        <DPage>
            <DPageHeader title={'Pedidos'} />
            <DPageBody disableGutters>
                <OrderRequestsListBlock />
            </DPageBody>
        </DPage>
    );
}
