import { RhFormWrapper, RHTextField } from '../../../../../dum';
import { OrderProductionTypeInput } from './inputs/order-production-type/order-production-type-input';
import { BranchInput } from './inputs/branch/branch-input';
import { EmployeeStatusInput } from './inputs/employee-status/employee-status-input';
import React, { useMemo } from 'react';
import { useUpsertEmployeeDialogMutation } from '../../../../../../services';
import * as yup from 'yup';
import { SchemaOf } from 'yup';

export interface IEmployee {
    id?: number | null;
    first_name: string;
    last_name: string;
    order_production_type_id?: number | null;
    employee_status_id?: number | null;
    branch_id?: number | null;
}

interface IEmployeeForm {
    employee?: IEmployee | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function EmployeeForm({
    employee,
    onSuccess,
    disabled = false,
}: IEmployeeForm) {
    const [mutation, { loading }] = useUpsertEmployeeDialogMutation();

    const initialValues: IEmployee = useMemo<IEmployee>(() => {
        return {
            id: employee?.id || undefined,
            first_name: employee?.first_name || '',
            last_name: employee?.last_name || '',
            order_production_type_id:
                employee?.order_production_type_id || null,
            employee_status_id: employee?.employee_status_id || null,
            branch_id: employee?.branch_id || null,
        };
    }, [employee]);

    const validationSchema: SchemaOf<IEmployee> = yup.object({
        id: yup.number().notRequired().nullable(),
        first_name: yup.string().required(`'Nombre' es obligatorio`),
        last_name: yup.string().required(`'Apellidos' es obligatorio`),
        order_production_type_id: yup.number().notRequired().nullable(),
        employee_status_id: yup.number().notRequired().nullable(),
        branch_id: yup.number().notRequired().nullable(),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            EmployeeUpsertInput: {
                                id: employee?.id || null,
                                first_name: data.first_name,
                                last_name: data.last_name,
                                employee_status_id: data.employee_status_id,
                                branch_id: data.branch_id,
                                order_production_type_id:
                                    data.order_production_type_id,
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RHTextField name={'first_name'} label={'Nombre'} />
            <RHTextField name={'last_name'} label={'Apellidos'} />
            <OrderProductionTypeInput />
            <BranchInput />
            <EmployeeStatusInput />
        </RhFormWrapper>
    );
}
