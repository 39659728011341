import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteOrderProductionMutation } from '../../../../../../services';

interface IDeleteOrderProductionProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderProductionId: number | null;
}

export function SDeleteOrderProductionDialog({
    open,
    setOpen,
    orderProductionId,
}: IDeleteOrderProductionProps) {
    const [deleteMutation] = useDeleteOrderProductionMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (orderProductionId) {
                    try {
                        await deleteMutation({
                            variables: {
                                OrderProductionId: orderProductionId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
