import React, { useMemo, useRef, useState } from 'react';
import {
    AscSort,
    DCollapseButton,
    DContextMenu,
    DContextMenuItem,
    DescSort,
    DTable,
    DTableBody,
    DTableBodyCell,
    DTableBodyRow,
    DTableHead,
    DTableHeadCell,
    DTableHeadRow,
    handleMouseEvent,
    NeutralSort,
    TCustomTableBodyCellTextAlign,
    useDContextMenu,
} from '../../index';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export type SortedOrder = 'asc' | 'desc' | null;

export type CustomTableContextMenuItem<T> = {
    title: string;
    callback: (item: T) => void;
    disable?: (item: T) => boolean;
};

export interface ICustomTableWrapperProps<T> {
    sortedIndex?: number | null;
    setSortedIndex?: (index: number | null) => void;
    sortedOrder?: SortedOrder;
    setSortedOrder?: (sortedOrder: SortedOrder) => void;
    items: {
        title: string;
        content: (
            dataItem: T,
            index: number,
        ) => React.ReactElement<any, any> | string | number | undefined | null;
        cellGrow?: number;
        textAlign?: TCustomTableBodyCellTextAlign;
        sortable?: boolean;
        cellSx?: (item: T) => SxProps<Theme>;
    }[];
    data: T[];
    stickyHeader?: boolean;
    collapse?: (
        dataItem: T,
    ) => React.ReactElement<any, any> | string | number | undefined | null;
    contextItems?: CustomTableContextMenuItem<T>[];
    defaultOpenCollapse?: boolean;
    padding?: boolean;
    onClick?: (item: T) => void;
    rowSx?: (item: T) => SxProps<Theme>;
}

export type ICustomTableWrapperItem = ICustomTableWrapperProps<
    Record<any, any>
>['items'][number];

export function CTableWrapper<T>({
    items,
    data,
    stickyHeader = false,
    collapse,
    setSortedIndex,
    sortedIndex,
    setSortedOrder,
    sortedOrder,
    contextItems,
    defaultOpenCollapse = false,
    padding = true,
    onClick,
    rowSx,
}: ICustomTableWrapperProps<T>) {
    const cellGrowthTotal = items.reduce(
        (acc, item) => acc + (item?.cellGrow || 1),
        0,
    );
    const [collapseOpen, setCollapseOpen] = useState<null | number>(null);

    const [contextMenu, setContextMenu] = useDContextMenu();
    const clickedItem = useRef<T | null>(null);

    const contextMenuItems: DContextMenuItem[] = useMemo(() => {
        if (!contextItems || !clickedItem) {
            return [];
        }

        return contextItems.map((item) => {
            return {
                ...item,
                callback: () => {
                    if (clickedItem.current) {
                        item.callback(clickedItem.current!);
                    }
                },
                disable: () => {
                    return item.disable && clickedItem.current
                        ? item.disable(clickedItem.current)
                        : (() => false)();
                },
            };
        });
    }, [contextItems]);

    return (
        <>
            <DTable padding={padding} stickyHeader={stickyHeader}>
                <DTableHead>
                    <DTableHeadRow>
                        {collapse && !defaultOpenCollapse ? (
                            <DTableHeadCell />
                        ) : null}
                        {items.map((item, index) => {
                            const cellGrowth = item.cellGrow || 1;
                            const cellWidth = Math.floor(
                                (cellGrowth / cellGrowthTotal) * 100,
                            );
                            return (
                                <DTableHeadCell
                                    key={index}
                                    width={cellWidth}
                                    onClick={
                                        item.sortable &&
                                        setSortedOrder &&
                                        setSortedIndex
                                            ? function () {
                                                  if (item.sortable) {
                                                      if (
                                                          sortedIndex ===
                                                              null ||
                                                          sortedIndex !== index
                                                      ) {
                                                          setSortedIndex(index);
                                                          setSortedOrder('asc');
                                                      } else if (
                                                          sortedIndex ===
                                                              index &&
                                                          sortedOrder === 'asc'
                                                      ) {
                                                          setSortedOrder(
                                                              'desc',
                                                          );
                                                      } else if (
                                                          sortedIndex ===
                                                              index &&
                                                          sortedOrder === 'desc'
                                                      ) {
                                                          setSortedOrder(null);
                                                          setSortedIndex(null);
                                                      }
                                                  }
                                              }
                                            : undefined
                                    }
                                >
                                    <Box
                                        component={'span'}
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {item.title}
                                        {item.sortable ? (
                                            <>
                                                <Box
                                                    component={'span'}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        ml: 2,
                                                    }}
                                                >
                                                    {sortedOrder === 'asc' &&
                                                    sortedIndex === index ? (
                                                        <AscSort
                                                            fontSize={'small'}
                                                        />
                                                    ) : sortedOrder ===
                                                          'desc' &&
                                                      sortedIndex === index ? (
                                                        <DescSort
                                                            fontSize={'small'}
                                                        />
                                                    ) : (
                                                        <NeutralSort
                                                            fontSize={'small'}
                                                        />
                                                    )}
                                                </Box>
                                            </>
                                        ) : null}
                                    </Box>
                                </DTableHeadCell>
                            );
                        })}
                    </DTableHeadRow>
                </DTableHead>
                <DTableBody>
                    {data.length > 0 ? (
                        data.map((dataItem, dataIndex) => {
                            return (
                                <React.Fragment key={dataIndex}>
                                    <DTableBodyRow
                                        onClick={
                                            onClick
                                                ? () => {
                                                      if (onClick) {
                                                          onClick(dataItem);
                                                      }
                                                  }
                                                : undefined
                                        }
                                        alternate={dataIndex % 2 === 1}
                                        onContextMenu={
                                            contextItems
                                                ? (e) => {
                                                      handleMouseEvent(
                                                          e,
                                                          setContextMenu,
                                                          contextMenu,
                                                      );
                                                      clickedItem.current =
                                                          dataItem;
                                                  }
                                                : undefined
                                        }
                                        sx={rowSx ? rowSx(dataItem) : undefined}
                                    >
                                        {collapse && !defaultOpenCollapse ? (
                                            <DTableBodyCell
                                                noBorder={
                                                    dataIndex + 1 ===
                                                    data.length
                                                }
                                            >
                                                <DCollapseButton
                                                    open={
                                                        collapseOpen ===
                                                        dataIndex
                                                    }
                                                    setOpen={() => {
                                                        setCollapseOpen(
                                                            collapseOpen !==
                                                                dataIndex
                                                                ? dataIndex
                                                                : null,
                                                        );
                                                    }}
                                                />
                                            </DTableBodyCell>
                                        ) : null}
                                        {items.map((item, index) => {
                                            return (
                                                <DTableBodyCell
                                                    key={index}
                                                    textAlign={
                                                        item.textAlign ||
                                                        'center'
                                                    }
                                                    noBorder={
                                                        dataIndex + 1 ===
                                                        data.length
                                                    }
                                                    sx={
                                                        item.cellSx
                                                            ? item.cellSx(
                                                                  dataItem,
                                                              )
                                                            : undefined
                                                    }
                                                >
                                                    {item.content(
                                                        dataItem,
                                                        dataIndex,
                                                    )}
                                                </DTableBodyCell>
                                            );
                                        })}
                                    </DTableBodyRow>
                                    {collapse &&
                                    (defaultOpenCollapse ||
                                        collapseOpen === dataIndex) ? (
                                        <DTableBodyRow
                                            onContextMenu={
                                                contextItems
                                                    ? (e) => {
                                                          handleMouseEvent(
                                                              e,
                                                              setContextMenu,
                                                              contextMenu,
                                                          );
                                                          clickedItem.current =
                                                              dataItem;
                                                      }
                                                    : undefined
                                            }
                                            sx={
                                                rowSx
                                                    ? rowSx(dataItem)
                                                    : undefined
                                            }
                                        >
                                            <DTableBodyCell
                                                colSpan={
                                                    items.length +
                                                    (!defaultOpenCollapse
                                                        ? 1
                                                        : 0)
                                                }
                                                pt={2}
                                                pb={8}
                                                px={8}
                                            >
                                                {collapse(dataItem)}
                                            </DTableBodyCell>
                                        </DTableBodyRow>
                                    ) : null}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <DTableBodyRow>
                            <DTableBodyCell
                                colSpan={items.length}
                                textAlign={'center'}
                                noBorder
                            >
                                No hay resultados
                            </DTableBodyCell>
                        </DTableBodyRow>
                    )}
                </DTableBody>
            </DTable>
            {contextItems && contextItems.length > 0 ? (
                <DContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    contextMenuItems={contextMenuItems}
                    onClose={() => {
                        // clickedItem.current = null;
                    }}
                />
            ) : null}
        </>
    );
}
