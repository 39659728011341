import React from 'react';
import { useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../../dum';
import { isLengthRequired } from '../../../validation/is-length-required';
import { IProduct } from '../../../product-form';

export function LengthInput() {
    const inputName: keyof IProduct = 'length';
    const orderProductionTypeIdName: keyof IProduct =
        'order_production_type_id';
    const orderProductionTypeId = useWatch({
        name: orderProductionTypeIdName,
    });

    if (
        !isLengthRequired({ order_production_type_id: orderProductionTypeId })
    ) {
        return null;
    }

    return <RHTextField name={inputName} label={'Largo'} />;
}
