import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAccountPageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { SUpsertAccountDialog } from '../../../../smart';

type TAccountPageParams = {
    accountId: string;
};

export function AccountPage() {
    const params = useParams<TAccountPageParams>();
    const { data, loading, error } = useAccountPageQuery({
        variables: {
            AccountId: params.accountId ? Number(params.accountId) : 0,
        },
    });
    const [upsertDialog, setUpsertDialog] = useState(false);

    if (loading) {
        return <DPageLoader />;
    }

    if (error || !data) {
        return <DErrorCard />;
    }

    const account = data.getAccount!;

    return (
        <DPage animated>
            <DPageHeader title={account.name}>
                <DRowButtons
                    rowItems={[
                        {
                            tooltip: 'Editar accounto',
                            icon: <EditIcon />,
                            callback: () => {
                                setUpsertDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <Box>{account.name}</Box>
            </DPageBody>
            <SUpsertAccountDialog
                open={upsertDialog}
                setOpen={setUpsertDialog}
                accountId={account.id}
            />
        </DPage>
    );
}
