import React from 'react';
import { useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../dum';
import { IOrderAdjustment } from '../../order-adjustment-form';

const ARRAY_NAME: keyof IOrderAdjustment = 'order_adjustment_products';
const KILOS_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'kilos';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'group_weight';

interface IKilosInputProps {
    index: number;
}

export function KilosInput({ index }: IKilosInputProps) {
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`;
    const groupWeightValue = useWatch({
        name: `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
    });

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'Kilos'}
            disableGutters
            disabled={!!groupWeightValue}
        />
    );
}
