import dayjs from 'dayjs';
import { DATE_FORMAT } from '../formatting/format-date';

interface DateRangeBase {
    month: number;
    year: number;
}

export interface DayRangeBase extends DateRangeBase {
    day: number;
}

export function getDayRange<T>({
    month,
    year,
    content,
}: {
    month: number;
    year: number;
    content: T;
}): (DayRangeBase & T & DateRangeBase)[] {
    let currentStart = dayjs()
        .utc()
        .set('year', year)
        .set('month', month)
        .startOf('month');
    const endDate = currentStart.clone().add(1, 'month').startOf('month');
    const days = [];
    while (currentStart.isBefore(endDate)) {
        days.push({
            day: currentStart.date(),
            year: year,
            month: month + 1,
            ...content,
        });
        currentStart = currentStart.add(1, 'day');
    }
    return days;
}

export interface MonthRangeBase extends DateRangeBase {
    monthYear: string;
    date: string;
}

export function isDayRange(
    item: MonthRangeBase | DayRangeBase,
): item is DayRangeBase {
    return (item as DayRangeBase).day !== undefined;
}

export function isMonthRange(
    item: MonthRangeBase | DayRangeBase,
): item is MonthRangeBase {
    return (item as MonthRangeBase).date !== undefined;
}

export function getMonthRange<T>({
    year,
    content,
}: {
    year: number;
    content: T;
}): (MonthRangeBase & T & DateRangeBase)[] {
    let currentStart = dayjs().utc().set('year', year).startOf('year');
    const endDate = currentStart.clone().add(1, 'year').startOf('year');
    const months = [];
    while (currentStart.isBefore(endDate)) {
        months.push({
            ...content,
            month: currentStart.month() + 1,
            year: year,
            monthYear: currentStart.format('YYYY-MM'),
            date: currentStart.format(DATE_FORMAT),
        });
        currentStart = currentStart.add(1, 'month');
    }
    return months;
}

export function getDateRange<T>({
    content,
    year,
    month,
}: {
    content: T;
    year: number;
    month: number | null;
}): (DateRangeBase & T & (MonthRangeBase | DayRangeBase))[] {
    if (month !== null) {
        return getDayRange({
            month,
            year,
            content,
        });
    } else {
        return getMonthRange({
            year,
            content,
        });
    }
}
