import React, { useContext, useEffect } from 'react';
import {
    DeepPartial,
    FieldErrors,
    FormProvider,
    UnpackNestedValue,
    useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';

export interface IRhFormWrapperProps<T> {
    initialValues?: UnpackNestedValue<DeepPartial<T>>;
    validationSchema: any;
    onSubmit: (
        data: UnpackNestedValue<T>,
        event?: React.BaseSyntheticEvent,
    ) => Promise<void>;
    onInvalid?: (errors: FieldErrors<T>) => Promise<void>;
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
}

interface IRhCustomContext {
    disabled: boolean;
}

const RhCustomContext = React.createContext<IRhCustomContext>({
    disabled: false,
});

export const useRhCustomContext = () => {
    return useContext(RhCustomContext);
};

export function RhFormWrapper<T>({
    initialValues,
    onSubmit,
    validationSchema,
    onInvalid,
    children,
    disabled = false,
}: IRhFormWrapperProps<T>) {
    const rhObject = useForm<T>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange',
        mode: 'onChange',
        shouldFocusError: true,
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        void rhObject.reset(initialValues);
    }, [rhObject, initialValues]);

    return (
        <RhCustomContext.Provider value={{ disabled }}>
            <FormProvider {...rhObject}>
                <Box
                    component={'form'}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'strech',
                    }}
                    onSubmit={rhObject.handleSubmit(onSubmit, onInvalid)}
                >
                    <Box sx={{ flex: 1 }}>{children}</Box>
                    <Box sx={{ alignSelf: 'flex-end', mt: 2 }}>
                        <Box
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                sx={{ mr: 2 }}
                                disabled={disabled}
                                variant={'text'}
                                onClick={() => {
                                    void rhObject.reset();
                                }}
                            >
                                Reiniciar
                            </Button>
                            <Button
                                disabled={disabled}
                                variant={'contained'}
                                type={'submit'}
                            >
                                Aceptar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </FormProvider>
        </RhCustomContext.Provider>
    );
}
