import React, { useEffect, useState } from 'react';
import { ProductInventoryPageQuery } from '../../../../../services';
import { CTableWrapper, DBlock, DBlockHeader } from '../../../../dum';
import { formatFloat } from '../../../../../helpers';

interface IInventoryByProduct {
    productsInventory: ProductInventoryPageQuery['getProductsInventory'];
}

export function InventoryByTotals({ productsInventory }: IInventoryByProduct) {
    const [totals, setTotals] = useState<
        { total_key: 'k' | 'g'; total_name: string; total_value: number }[]
    >([
        {
            total_key: 'k',
            total_name: 'kilos',
            total_value: 0,
        },
        {
            total_key: 'g',
            total_name: 'grupos',
            total_value: 0,
        },
    ]);

    useEffect(() => {
        let kilos = 0;
        let groups = 0;
        productsInventory.forEach((pi) => {
            kilos += pi?.kilos || 0;
            groups += pi?.groups || 0;
        });
        setTotals([
            {
                total_key: 'k',
                total_name: 'Kilos',
                total_value: kilos,
            },
            {
                total_key: 'g',
                total_name: 'Grupos',
                total_value: groups,
            },
        ]);
    }, [productsInventory, setTotals]);

    return (
        <DBlock>
            <DBlockHeader subtitle={'Totales'} />
            <CTableWrapper
                items={[
                    {
                        title: 'Nombre',
                        textAlign: 'left',
                        content: (item) => {
                            return item.total_name;
                        },
                    },
                    {
                        title: 'Valor',
                        content: (item) => {
                            return formatFloat(item.total_value);
                        },
                        textAlign: 'right',
                    },
                ]}
                data={totals}
            />
        </DBlock>
    );
}
