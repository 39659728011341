import React from 'react';
import { DPage, DPageBody, DPageHeader } from '../../../../dum';
import { OrderProductionsListBlock } from './blocks/order-productions-block';

export function OrderProductionsPage() {
    return (
        <DPage animated>
            <DPageHeader title={'Ordenes de producción'} />
            <DPageBody disableGutters>
                <OrderProductionsListBlock />
            </DPageBody>
        </DPage>
    );
}
