import {
    isBagProduct,
    isOthersProduct,
    isRollProduct,
} from '../../../../../../../../../services/entities';

export function isWidthRequired({
    order_production_type_id,
}: {
    order_production_type_id: number;
}): boolean {
    return (
        isBagProduct({ order_production_type_id }) ||
        isRollProduct({ order_production_type_id }) ||
        isOthersProduct({ order_production_type_id })
    );
}
