import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    getDateFromString,
    getStringFromDate,
    transformToNumber,
} from '../../../../../../helpers';
import { useUpsertOrderProductionMutation } from '../../../../../../services';
import { RhDateInput, RhFormWrapper, RHTextField } from '../../../../../dum';
import { BranchInput } from './branch/branch-input';
import { OrderProductionEmployeesColumns } from './order-production-employees/order-production-employees-columns';
import { OrderProductionProductsColumns } from './order-production-products/order-production-products-columns';
import { OrderProductionTypeInput } from './order-production-type/order-production-type-input';
import { doesEmployeeBelongToBranch } from './validation/does-employee-belong-to-branch';
import { doesEmployeeBelongToOrderProductionType } from './validation/does-employee-belong-to-order-production-type';
import { doesEmployeeHaveUpStatus } from './validation/does-employee-have-up-status';
import { doesMachineBelongToBranch } from './validation/does-machine-belong-to-branch';
import { doesMachineBelongToOrderProductionType } from './validation/does-machine-belong-to-order-production-type';
import { doesProductBelongToOrderProductionType } from './validation/does-product-belong-to-order-production-type';
import { isEmployeeUnique } from './validation/is-employee-unique';
import { isMachineProductUnique } from './validation/is-machine-product-unique';
import { isThereOnlyOneEmployeeLeader } from './validation/is-there-only-one-employee-leader';

export interface IOrderProduction {
    id?: number | null;
    start_date: string;
    branch_id?: number | null;
    order_production_type_id?: number | null;
    waste?: number;
    order_production_products: {
        kilos: number;
        machine?: {
            id: number;
            name: string;
            branch_id?: number | null;
        } | null;
        product?: {
            id: number;
            compound_description: string;
            current_group_weight?: number | null;
        } | null;
        groups?: number | null;
        group_weight?: number | null;
        hours?: number | null;
    }[];
    order_production_employees: {
        employee?: {
            id: number;
            fullname: string;
            order_production_type_id?: number | null;
        } | null;
        is_leader: boolean | number;
    }[];
}

interface TOrderProduction extends IOrderProduction {
    id?: number | null;
}

interface IOrderProductionForm {
    orderProduction?: TOrderProduction;
    onSuccess: () => void;
    disabled?: boolean;
}

export const OrderProductionForm = ({
    orderProduction,
    onSuccess,
    disabled = false,
}: IOrderProductionForm) => {
    const [mutation, { loading }] = useUpsertOrderProductionMutation();

    const initialValues = useMemo<IOrderProduction>(() => {
        return {
            id: orderProduction?.id || undefined,
            start_date: orderProduction
                ? getDateFromString(orderProduction.start_date)
                : '',
            order_production_type_id:
                orderProduction?.order_production_type_id || null,
            waste:
                orderProduction &&
                orderProduction.waste !== undefined &&
                orderProduction?.waste >= 0
                    ? orderProduction.waste
                    : 0,
            branch_id: orderProduction ? orderProduction.branch_id : null,
            order_production_products:
                orderProduction?.order_production_products || [
                    {
                        group_weight: 0,
                        groups: 0,
                        kilos: 0,
                        machine: null,
                        product: null,
                        hours: 8,
                    },
                ],
            order_production_employees:
                orderProduction?.order_production_employees.map((employee) => {
                    return {
                        ...employee,
                        is_leader: employee.is_leader === 1,
                    };
                }) || [],
        };
    }, [orderProduction]);

    const validationSchema: SchemaOf<IOrderProduction> = yup.object({
        id: yup.number().notRequired().nullable(),
        start_date: yup.string().required(`'Fecha' es obligatorio`).nullable(),
        branch_id: yup
            .number()
            .required(`'Sucursal' es obligatorio`)
            .nullable(),
        order_production_type_id: yup
            .number()
            .required(`'Orden de producción' es obligatorio`)
            .nullable(),
        waste: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Deperdicio' es obligatorio`),
        order_production_products: yup
            .array()
            .of(
                yup.object().shape({
                    machine: yup
                        .mixed()
                        .required(`'Máquina' es obligatorio`)
                        .nullable()
                        .test(
                            'is-machine-employee-unique',
                            isMachineProductUnique,
                        )
                        .test(
                            'does-machine-belong-to-branch',
                            doesMachineBelongToBranch,
                        )
                        .test(
                            'does-machine-belong-to-order-production-type',
                            doesMachineBelongToOrderProductionType,
                        ),
                    product: yup
                        .mixed()
                        .required(`'Producto' es obligatorio`)
                        .nullable()
                        .test(
                            'is-machine-employee-unique',
                            isMachineProductUnique,
                        )
                        .test(
                            'does-product-belong-to-order-production-type',
                            doesProductBelongToOrderProductionType,
                        ),
                    kilos: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Kilos' es obligatorio`),
                    groups: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Grupos' es obligatorio`),
                    group_weight: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Peso del grupo' es obligatorio`),
                    hours: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .notRequired(),
                }),
            )
            .required(`'Productos' es obligatorio`),
        order_production_employees: yup
            .array()
            .of(
                yup.object().shape({
                    employee: yup
                        .mixed()
                        .required(`'Empleado' es obligatorio`)
                        .nullable()
                        .test('is-employee-unique', isEmployeeUnique)
                        .test(
                            'does-employee-belong-to-order-production-type',
                            doesEmployeeBelongToOrderProductionType,
                        )
                        .test(
                            'does-employee-belong-to-branch',
                            doesEmployeeBelongToBranch,
                        )
                        .test(
                            'does-employee-have-up-status',
                            doesEmployeeHaveUpStatus,
                        ),

                    is_leader: yup
                        .mixed()
                        .notRequired()
                        .test(
                            'is-there-only-one-employee-leader',
                            isThereOnlyOneEmployeeLeader,
                        ),
                }),
            )
            .required(`'Empleado' es obligatorio`),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            OrderProductionInput: {
                                id: orderProduction?.id || null,
                                start_date: getStringFromDate(data.start_date),
                                branch_id: data?.branch_id || null,
                                order_production_type_id:
                                    data?.order_production_type_id || null,
                                waste: data?.waste || 0,
                                order_production_products:
                                    data.order_production_products.map(
                                        (productionProduct) => {
                                            return {
                                                kilos: productionProduct.kilos,
                                                product_id:
                                                    productionProduct.product!
                                                        .id,
                                                machine_id:
                                                    productionProduct.machine!
                                                        .id,
                                                group_weight:
                                                    productionProduct.group_weight!,
                                                groups:
                                                    productionProduct.groups ||
                                                    0,
                                                hours: productionProduct.hours,
                                            };
                                        },
                                    ),
                                order_production_employees:
                                    data.order_production_employees.map(
                                        (employee) => {
                                            return {
                                                employee_id:
                                                    employee.employee!.id,
                                                is_leader: employee.is_leader
                                                    ? 1
                                                    : 0,
                                            };
                                        },
                                    ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RhDateInput name={'start_date'} label={'Fecha'} />
            <BranchInput />
            <OrderProductionTypeInput />
            <RHTextField name={'waste'} label={'Desperdicio'} />
            <OrderProductionProductsColumns />
            <OrderProductionEmployeesColumns />
        </RhFormWrapper>
    );
};
