import { RHTextField } from '../../../../../../dum';
import React from 'react';
import { TAX } from '../expense-form';
import { useCalculateExpenseTotal } from '../use-calculate-expense-total';

export function TaxInput() {
    const { calculateExpenseTotal } = useCalculateExpenseTotal();
    return (
        <RHTextField
            name={TAX}
            label={'IVA'}
            onChange={() => {
                calculateExpenseTotal();
            }}
        />
    );
}
