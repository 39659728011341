import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOrderProductionBranchInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IOrderProduction } from '../order-production-form';

export function BranchInput() {
    const { data } = useOrderProductionBranchInputQuery();
    const { trigger } = useFormContext();
    const INPUT_NAME: keyof IOrderProduction = 'branch_id';
    const ORDER_PRODUCTION_PRODUCTS_FIELD_NAME: keyof IOrderProduction =
        'order_production_products';
    const ORDER_PRODUCTION_EMPLOYEES_FIELD_NAME: keyof IOrderProduction =
        'order_production_employees';

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Sucursal'}
            options={data?.getBranches || []}
            onChange={() => {
                void trigger(ORDER_PRODUCTION_PRODUCTS_FIELD_NAME);
                void trigger(ORDER_PRODUCTION_EMPLOYEES_FIELD_NAME);
            }}
        />
    );
}
