import React from 'react';
import { TableBody } from '@mui/material';

interface ICustomTableBodyProps {
    children: React.ReactNode;
}

export function DTableBody({ children }: ICustomTableBodyProps) {
    return <TableBody>{children}</TableBody>;
}
