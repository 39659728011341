import { Table, TableContainer } from '@mui/material';
import * as colors from '@mui/material/colors';
import React from 'react';

interface ICustomTableProps {
    size?: 'small' | 'medium';
    children: React.ReactNode | React.ReactNode[];
    stickyHeader?: boolean;
    padding?: boolean;
}

export function DTable({
    size = 'small',
    stickyHeader = false,
    children,
    padding = true,
}: ICustomTableProps) {
    return (
        <TableContainer
            sx={{
                border: `1px solid ${colors.grey['200']}`,
                borderRadius: '5px',
            }}
        >
            <Table
                size={size}
                stickyHeader={stickyHeader}
                padding={padding ? 'normal' : 'none'}
                sx={{
                    '&  td': {
                        padding: '0 4px',
                    },
                }}
            >
                {children}
            </Table>
        </TableContainer>
    );
}
