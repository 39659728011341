import { RhCheckbox, RhFormWrapper, RHTextField } from '../../../../../dum';
import { AccountContactsColumns } from './account-contacts/account-contacts-columns';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { useUpsertAccountDialogMutation } from '../../../../../../services';

export interface IAccount {
    id?: number | null;
    name: string;
    abbreviation?: string;
    is_own: boolean;
    is_supplier: boolean;
    requires_order_request: boolean;
    is_client: boolean;
    account_contacts: {
        id?: number | null;
        first_name: string;
        last_name: string;
        cellphone?: string | null;
        email?: string | null;
    }[];
}

interface IAccountForm {
    account?: IAccount | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function AccountForm({
    account,
    onSuccess,
    disabled = false,
}: IAccountForm) {
    const [mutation, { loading }] = useUpsertAccountDialogMutation();

    const initialValues = useMemo<IAccount>(() => {
        return {
            id: account?.id || undefined,
            name: account?.name || '',
            abbreviation: account?.abbreviation || '',
            is_client: account ? account.is_client : false,
            is_own: account ? account.is_own : false,
            is_supplier: account ? account.is_supplier : false,
            requires_order_request: account
                ? account.requires_order_request
                : true,
            account_contacts: account?.account_contacts || [],
        };
    }, [account]);

    const validationSchema: SchemaOf<IAccount> = yup.object({
        id: yup.number().notRequired().nullable(),
        name: yup.string().required(`'Nombre' es obligatorio`),
        abbreviation: yup.string(),
        is_client: yup.boolean().required(),
        is_supplier: yup.boolean().required(),
        is_own: yup.boolean().required(),
        requires_order_request: yup.boolean().required(),
        account_contacts: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    first_name: yup
                        .string()
                        .required(`'Nombre' es obligatorio`),
                    last_name: yup
                        .string()
                        .required(`'Apellidos' es obligatorio`),
                    cellphone: yup.string().notRequired().nullable(),
                    email: yup.string().notRequired().nullable(),
                }),
            )
            .required('`Contactos` es obligatorio'),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            AccountUpsertInput: {
                                id: account?.id || null,
                                name: data.name,
                                abbreviation: data.abbreviation || '',
                                is_client: data ? data.is_client : false,
                                is_own: data ? data.is_own : false,
                                requires_order_request: data
                                    ? data.requires_order_request
                                    : false,
                                is_supplier: data ? data.is_supplier : false,
                                account_contacts: data.account_contacts.map(
                                    (contact) => {
                                        return {
                                            id: contact.id || null,
                                            first_name: contact.first_name,
                                            last_name: contact.last_name,
                                            cellphone: contact.cellphone!,
                                            email: contact.email!,
                                        };
                                    },
                                ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RHTextField name={'name'} label={'Nombre'} />
            <RHTextField name={'abbreviation'} label={'Abreviación'} />
            <AccountContactsColumns />
            <RhCheckbox name={'is_client'} label={'Cliente'} />
            <RhCheckbox name={'is_supplier'} label={'Proveedor'} />
            <RhCheckbox name={'is_own'} label={'Cuenta propia'} />
            <RhCheckbox
                name={'requires_order_request'}
                label={'Esta cuenta require de un pedido para crear una venta'}
            />
        </RhFormWrapper>
    );
}
