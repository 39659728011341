import React from 'react';
import { REQUIRE_CREDIT_NOTE } from '../../order-sale-form';
import { RhCheckbox } from '../../../../../../../dum';

export function RequireCreditNoteInput() {
    return (
        <RhCheckbox
            name={REQUIRE_CREDIT_NOTE}
            label={'Requiere nota de credito?'}
        />
    );
}
