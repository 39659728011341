import React from 'react';
import { useOrderSaleOrderSaleReceiptTypeInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderSale } from '../../order-sale-form';
import { useCalculateProductsTotal } from '../../helpers/use-calculate-products-total';

export function OrderSaleReceiptTypeInput() {
    const inputName: keyof IOrderSale = 'receipt_type_id';
    const { data } = useOrderSaleOrderSaleReceiptTypeInputQuery();
    const { calculateProductsTotal } = useCalculateProductsTotal();

    const orderSaleReceiptTypes = data?.getReceiptTypes || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Tipo'}
            options={orderSaleReceiptTypes}
            onChange={() => {
                calculateProductsTotal();
            }}
        />
    );
}
