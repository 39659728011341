import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    navigateToMachinePage,
    useMachineSectionPageQuery,
} from '../../../../../services';
import {
    DeleteIcon,
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { MachineIcon, SMachineSectionFormDialog } from '../../../../smart';
import { MachinePartBlock } from './blocks/machine-part-block';

type TMachineSectionPageParams = {
    machineSectionId: string;
};

export function MachineSectionPage() {
    const params = useParams<TMachineSectionPageParams>();
    const navigate = useNavigate();
    const { data, loading, error } = useMachineSectionPageQuery({
        variables: {
            MachineSectionId: params.machineSectionId
                ? Number(params.machineSectionId)
                : 0,
        },
    });
    const [machineSectionDialog, setMachineSectionDialog] = useState(false);

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }

    const machineSection = data!.getMachineSection;

    return (
        <DPage animated>
            <DPageHeader title={machineSection.name}>
                <DRowButtons
                    rowItems={[
                        {
                            icon: <MachineIcon />,
                            callback: () => {
                                if (
                                    machineSection &&
                                    machineSection.machine_id
                                ) {
                                    navigateToMachinePage({
                                        navigate,
                                        machineId: machineSection.machine_id,
                                    });
                                }
                            },
                        },
                        {
                            icon: <EditIcon />,
                            tooltip: 'Editar seccion',
                            callback: () => {
                                setMachineSectionDialog(true);
                            },
                        },
                        {
                            icon: <DeleteIcon />,
                            color: 'error',
                            tooltip: 'Eliminar seccion',
                            disabled:
                                !data || !data.getMachineSection.is_deletable,
                        },
                    ]}
                />
            </DPageHeader>
            <SMachineSectionFormDialog
                open={machineSectionDialog}
                setOpen={setMachineSectionDialog}
                machineSection={machineSection}
            />
            <DPageBody>
                {machineSection.machine_parts.map((part) => {
                    return (
                        <MachinePartBlock key={part.id} machinePart={part} />
                    );
                })}
            </DPageBody>
        </DPage>
    );
}
