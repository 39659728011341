import { IconButton } from '@mui/material';
import {
    CTableWrapper,
    DBlock,
    DPage,
    DPageBody,
    DPageHeader,
    EditIcon,
} from '../../../../dum';
import { UsersPageQuery, useUsersPageQuery } from '../../../../../services';
import { useState } from 'react';
import { SUpsertUserDialog } from '../../../../smart';

export function UsersPage() {
    const { data } = useUsersPageQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [userId, setUserId] = useState<number | null>(null);

    const users: UsersPageQuery['users'] = data?.users || [];

    return (
        <DPage>
            <DPageHeader title={'Usuarios'} />
            <DPageBody>
                <DBlock>
                    <CTableWrapper
                        items={[
                            {
                                cellGrow: 10,
                                title: 'Nombre',
                                content: (user) => user.fullname,
                            },
                            {
                                cellGrow: 10,
                                title: 'Roles',
                                content: (user) =>
                                    user.roles
                                        .map((role) => role.name)
                                        .join(', '),
                            },
                            {
                                title: 'Acciones',
                                content: (user) => (
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                setUpsertOpen(true);
                                                setUserId(user.id);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </>
                                ),
                            },
                        ]}
                        data={users}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertUserDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                userId={userId}
            />
        </DPage>
    );
}
