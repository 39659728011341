import React, { useEffect, useState } from 'react';
import { ProductInventoryPageQuery } from '../../../../../services';
import { CTableWrapper, DBlock, DBlockHeader } from '../../../../dum';
import { formatFloat } from '../../../../../helpers';

interface IInventoryByProduct {
    productsInventory: ProductInventoryPageQuery['getProductsInventory'];
}

interface GroupedInventoryByProductCategory {
    product_category_id: number;
    product_category:
        | NonNullable<
              ProductInventoryPageQuery['getProductsInventory'][number]['product']
          >['product_category']
        | null
        | undefined;
    kilos: number;
    groups: number;
}

export function InventoryByProductCategory({
    productsInventory,
}: IInventoryByProduct) {
    const [groupedInventory, setGroupedInventory] = useState<
        GroupedInventoryByProductCategory[]
    >([]);

    useEffect(() => {
        let temporaryGroupedInventory: GroupedInventoryByProductCategory[] = [];
        // let totalKilos = 0;
        // let totalGroups = 0;

        productsInventory.forEach((pi) => {
            // totalKilos += pi.kilos || 0;
            // totalGroups += pi.groups || 0;

            const temporaryGroupedItem = temporaryGroupedInventory.find(
                (tgi) =>
                    pi.product?.product_category_id === tgi.product_category_id,
            );
            if (temporaryGroupedItem) {
                temporaryGroupedItem.groups += pi.groups ? pi.groups : 0;
                temporaryGroupedItem.kilos += pi.kilos ? pi.kilos : 0;
            } else if (pi.product?.product_category_id) {
                temporaryGroupedInventory.push({
                    product_category_id:
                        pi.product && pi.product.product_category_id
                            ? pi.product.product_category_id
                            : 0,
                    product_category: pi.product?.product_category,
                    groups: pi.groups ? pi.groups : 0,
                    kilos: pi.kilos ? pi.kilos : 0,
                });
            }
        });

        // console.log('product_categories_kilos ', totalKilos);
        // console.log('product_categories_groups ', totalGroups);

        setGroupedInventory(temporaryGroupedInventory);
    }, [productsInventory, setGroupedInventory]);

    return (
        <DBlock>
            <DBlockHeader subtitle={'Por categoria del producto'} />
            <CTableWrapper
                items={[
                    {
                        title: 'Categoria del producto',
                        textAlign: 'left',
                        content: (item) => {
                            return item.product_category?.name;
                        },
                    },
                    {
                        title: 'Kilos',
                        content: (item) => {
                            return formatFloat(item.kilos);
                        },
                        textAlign: 'right',
                    },
                    {
                        title: 'Grupos',
                        content: (item) => {
                            return formatFloat(item.groups);
                        },
                        textAlign: 'right',
                    },
                ]}
                data={groupedInventory}
            />
        </DBlock>
    );
}
