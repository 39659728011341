import React from 'react';
import { useMachineOrderProductionTypeInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IMachine } from '../s-machine-form';

export function OrderProductionTypeInput() {
    const { data } = useMachineOrderProductionTypeInputQuery();
    const INPUT_NAME: keyof IMachine = 'order_production_type_id';

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Tipo de producción'}
            options={data?.getOrderProductionTypes || []}
        />
    );
}
