import React from 'react';
import { RhSingleAutocomplete } from '../../../../../../../../dum';
import {
    OrderSaleOrderAdjustmentProductInputQuery,
    useOrderSaleOrderAdjustmentProductInputQuery,
} from '../../../../../../../../../services';
import { IOrderSale } from '../../../order-sale-form';

const ORDER_ADJUSTMENT_PRODUCTS: keyof IOrderSale = 'order_adjustment_products';
const PRODUCT_FIELD_NAME: keyof NonNullable<
    IOrderSale['order_adjustment_products']
>[number] = 'product_id';
interface IProductsInput {
    index: number;
}

export function OrderAdjustmentProductInput({ index }: IProductsInput) {
    const INPUT_NAME = `${ORDER_ADJUSTMENT_PRODUCTS}.${index}.${PRODUCT_FIELD_NAME}`;
    const { data } = useOrderSaleOrderAdjustmentProductInputQuery();

    const products: OrderSaleOrderAdjustmentProductInputQuery['getProducts'] =
        data?.getProducts || [];

    return (
        <RhSingleAutocomplete
            disabled
            itemText={'compound_description'}
            itemValue={'id'}
            options={products}
            name={INPUT_NAME}
            label={'Producto'}
            disableGutters
        />
    );
}
