import React from 'react';
import { useNavigate } from 'react-router-dom';
import { navigateToSpareAdjustmentsPage } from '../../../../../services';
import { DPage, DPageBody, DPageHeader, DRowButtons } from '../../../../dum';
import { SpareAdjustmentIcon } from '../../../../smart';

import { SpareTransitionsBlock } from './block/spare-transitions-block';

export function SpareTransactionsPage() {
    const navigate = useNavigate();

    return (
        <DPage animated>
            <DPageHeader title={'Transacciones'}>
                <DRowButtons
                    rowItems={[
                        {
                            icon: <SpareAdjustmentIcon />,
                            tooltip: 'Ajuste',
                            callback: () => {
                                navigateToSpareAdjustmentsPage({ navigate });
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <SpareTransitionsBlock />
            </DPageBody>
        </DPage>
    );
}
