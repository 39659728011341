import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GrandChildComponent } from './grand-child-component';

export function ChildComponent() {
    return (
        <Box>
            <Typography>Child</Typography>
            <GrandChildComponent />
        </Box>
    );
}
