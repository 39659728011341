import React from 'react';
import { DRouterLink } from '../../../../dum';
import { getMachinePartPagePath } from '../../../../../services/navigation/maintenance/get-route-path';

interface ISMachinePartLinkProps {
    machineId: number;
    machinePartId: number;
    partName: string;
}

export function SMachinePartLink({
    machineId,
    partName,
    machinePartId,
}: ISMachinePartLinkProps) {
    return (
        <DRouterLink
            to={getMachinePartPagePath({
                machineId: machineId,
                machinePartId: machinePartId,
            })}
        >
            {partName}
        </DRouterLink>
    );
}
