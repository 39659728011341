import React, { useMemo, useState } from 'react';
import { useMachineFormQuery } from '../../../../../../services';
import { DModal, DPageLoader } from '../../../../../dum';
import { MachineIcon } from '../../../../icons';
import { MachineForm } from './s-machine-form';

interface ISMachineFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    machineId?: number | null;
}

export const SUpsertMachineDialog = (props: ISMachineFormDialogProps) => {
    const { open, setOpen, machineId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useMachineFormQuery({
        skip: !machineId || !open,
        variables: {
            MachineId: machineId!,
        },
    });

    const machine = useMemo(() => {
        return machineId && data && data.getMachine && open
            ? data.getMachine
            : undefined;
    }, [machineId, data, open]);

    return (
        <DModal
            icon={<MachineIcon />}
            title={`Maquina`}
            open={open}
            setOpen={setOpen}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <MachineForm
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                    machine={machine}
                    disabled={isModalClosing}
                />
            )}
        </DModal>
    );
};
