import React from 'react';
import { useSpareAdjustmentsPageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
} from '../../../../dum';
import { SpareAdjustmentBlock } from './blocks/spare-adjustment-block';

export function SpareAdjustmentsPage() {
    const { data, loading, error } = useSpareAdjustmentsPageQuery();

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }
    const spareAdjustments = data!.getSpareAdjustments;

    return (
        <DPage animated>
            <DPageHeader title={'Ajustes'} />
            <DPageBody>
                {spareAdjustments.map((spareAdjustment) => {
                    return (
                        <SpareAdjustmentBlock
                            key={spareAdjustment.id}
                            spareAdjustment={spareAdjustment}
                        />
                    );
                })}
            </DPageBody>
        </DPage>
    );
}
