import React, { useEffect, useMemo, useState } from 'react';
import {
    ColumnOrder,
    EmployeesPageQuery,
    PaginatedEmployeesSortableFields,
    useEmployeesPageLazyQuery,
} from '../../../../../services';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DPage,
    DPageBody,
    DPageHeader,
    DPaginator,
    DTextField,
    EditIcon,
    SortedOrder,
    usePaginator,
} from '../../../../dum';
import {
    SDeleteEmployeeDialog,
    SUpsertEmployeeDialog,
} from '../../../../smart';
import { Box, IconButton } from '@mui/material';
import { useNumber, useString } from '../../../../../helpers';

export function EmployeesPage() {
    const [query, { data, previousData }] = useEmployeesPageLazyQuery();
    const [employeeId, setEmployeeId] = useState<null | number>(null);
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [branchId, setBranchId] = useNumber({
        KEY: EmployeesPage.name + '_BranchId',
        defaultValue: null,
    });
    const [orderProductionTypeId, setOrderProductionTypeId] = useNumber({
        KEY: EmployeesPage.name + '_OrderProductionTypeId',
        defaultValue: null,
    });

    const [filter, setFilter] = useString({
        KEY: EmployeesPage.name + '_Filter',
    });

    const { setSkip, skip, take } = usePaginator(
        {
            KEY: 'ProductsPage',
        },
        [filter, orderProductionTypeId, branchId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                filter: filter,
                sortField:
                    sortedIndex === 0
                        ? PaginatedEmployeesSortableFields.FirstName
                        : sortedIndex === 1
                        ? PaginatedEmployeesSortableFields.LastName
                        : null,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
                branchId: branchId,
                orderProductionTypeId: orderProductionTypeId,
            },
        });
    }, [
        query,
        skip,
        take,
        filter,
        sortedOrder,
        sortedIndex,
        orderProductionTypeId,
        branchId,
    ]);

    const employees =
        data?.paginatedEmployees.docs ||
        previousData?.paginatedEmployees.docs ||
        [];

    const count =
        data && data?.paginatedEmployees.count >= 0
            ? data?.paginatedEmployees.count
            : previousData?.paginatedEmployees.count;

    const handleEditEmployee = useMemo(() => {
        return (
            employee: EmployeesPageQuery['paginatedEmployees']['docs'][number],
        ) => {
            setEmployeeId(employee.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteEmployee = useMemo(() => {
        return (
            employee: EmployeesPageQuery['paginatedEmployees']['docs'][number],
        ) => {
            setEmployeeId(employee.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DPage>
            <DPageHeader title={'Empleados'} />
            <DPageBody>
                <DBlock>
                    <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <DTextField
                                label={'Filtro'}
                                value={filter}
                                onChange={(value) => {
                                    setFilter(value || '');
                                }}
                            />
                        </Box>
                        <CVerticalDropdown
                            items={[
                                {
                                    component: (
                                        <DIdAutocomplete
                                            id={branchId}
                                            onChange={setBranchId}
                                            itemText={'name'}
                                            label={'Sucursal'}
                                            options={data?.getBranches || []}
                                        />
                                    ),
                                },
                                {
                                    component: (
                                        <DIdAutocomplete
                                            id={orderProductionTypeId}
                                            onChange={setOrderProductionTypeId}
                                            itemText={'name'}
                                            label={
                                                'Tipo de orden de producción'
                                            }
                                            options={
                                                data?.getOrderProductionTypes ||
                                                []
                                            }
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Box>
                    <DPaginator
                        skip={skip}
                        take={take}
                        setSkip={setSkip}
                        count={count}
                    />
                    <CTableWrapper
                        onClick={(employee) => {
                            handleEditEmployee(employee);
                        }}
                        sortedIndex={sortedIndex}
                        setSortedIndex={setSortedIndex}
                        sortedOrder={sortedOrder}
                        setSortedOrder={setSortedOrder}
                        contextItems={[
                            {
                                title: 'Editar',
                                callback: (employee) => {
                                    handleEditEmployee(employee);
                                },
                            },
                            {
                                title: 'Borrar',
                                disable: (employee) => {
                                    return !employee.is_deletable;
                                },
                                callback: (employee) => {
                                    handleDeleteEmployee(employee);
                                },
                            },
                        ]}
                        items={[
                            {
                                title: 'Nombre',
                                cellGrow: 25,
                                sortable: true,
                                content: (employee) => {
                                    return employee.first_name;
                                },
                            },
                            {
                                title: 'Apellidos',
                                cellGrow: 25,
                                sortable: true,
                                content: (employee) => {
                                    return employee.last_name;
                                },
                            },
                            {
                                title: 'Sucursal',
                                cellGrow: 20,
                                content: (employee) => {
                                    return employee.branch?.name;
                                },
                            },
                            {
                                title: 'Tipo de orden de producción',
                                cellGrow: 20,
                                content: (employee) => {
                                    return employee.order_production_type?.name;
                                },
                            },
                            {
                                title: 'Acciones',
                                textAlign: 'center',
                                content: (employee) => {
                                    return (
                                        <Box sx={{ display: 'flex' }}>
                                            <IconButton
                                                onClick={() => {
                                                    handleEditEmployee(
                                                        employee,
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={
                                                    !employee.is_deletable
                                                }
                                                onClick={() => {
                                                    handleDeleteEmployee(
                                                        employee,
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        data={employees}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertEmployeeDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                employeeId={employeeId}
            />
            <SDeleteEmployeeDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                employeeId={employeeId}
            />
        </DPage>
    );
}
