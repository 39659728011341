import { ValidationError } from 'yup';
import { IProduct } from '../product-form';

export function isKiloPriceAndGroupPriceDifferentThanZero(
    value: any,
    context: any,
): boolean | ValidationError {
    const { current_kilo_price, current_group_price } = context.from[0]
        .value as IProduct;

    const isKiloPriceNumber = !Number.isNaN(Number(current_kilo_price));
    const isGroupPriceNumber = !Number.isNaN(Number(current_group_price));

    if (isKiloPriceNumber && isGroupPriceNumber) {
        if (
            Number(current_group_price) !== 0 &&
            Number(current_kilo_price) !== 0
        ) {
            return context.createError({
                message: `Solo se puede escoger un precio por producto`,
            });
        }

        if (
            Number(current_group_price) === 0 &&
            Number(current_kilo_price) === 0
        ) {
            return context.createError({
                message: `Uno de los precios tiene que ser diferente de 0`,
            });
        }
    }

    return true;
}
