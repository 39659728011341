import React from 'react';
import { useExpenseAccountIdInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IExpense } from '../expense-form';

export function ExpenseAccountIdInput() {
    const inputName: keyof IExpense = 'account_id';
    const { data } = useExpenseAccountIdInputQuery();

    const accounts = data?.getAccounts || [];

    return (
        <RhSingleAutocomplete
            itemText={'compound_name'}
            itemValue={'id'}
            name={inputName}
            label={'Proveedor'}
            options={accounts}
        />
    );
}
