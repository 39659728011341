export function createError({
    context,
    message,
}: {
    context: any;
    message: string;
}) {
    return context.createError({
        message,
    });
}
