import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMachinePageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { SUpsertMachineDialog } from '../../../../smart';
import { MachineSectionList } from './blocks/machine-section-list';
import { MachineUnassignedPartsList } from './blocks/machine-unassigned-parts-list';

type TMachinePageParams = {
    machineId: string;
};

export function MachinePage() {
    const params = useParams<TMachinePageParams>();

    const [machineDialog, setMachineDialog] = useState(false);
    const { data, loading, error } = useMachinePageQuery({
        variables: {
            machineId: params.machineId ? Number(params.machineId) : 0,
        },
    });

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }

    const machineSections = data?.getMachine?.machine_sections;
    const machine = data?.getMachine;

    return (
        <DPage animated>
            <DPageHeader title={machine?.name || ''}>
                <DRowButtons
                    rowItems={[
                        {
                            icon: <EditIcon />,
                            callback: () => {
                                setMachineDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <SUpsertMachineDialog
                open={machineDialog}
                setOpen={setMachineDialog}
            />
            <DPageBody>
                <MachineUnassignedPartsList
                    unassigned_parts={machine?.unassigned_parts || []}
                />
                {machineSections?.map((section, index) => {
                    return (
                        <MachineSectionList
                            key={index}
                            machine_section={section}
                        />
                    );
                })}
            </DPageBody>
        </DPage>
    );
}
