import React from 'react';
import { TableRow, Theme } from '@mui/material';
import * as colors from '@mui/material/colors';
import { SxProps } from '@mui/system';

interface ICustomTableBodyRowProps {
    children: React.ReactNode;
    onContextMenu?: (e: React.MouseEvent) => void;
    alternate?: boolean;
    sx?: SxProps<Theme>;
    onClick?: () => void;
}

export function DTableBodyRow({
    children,
    onContextMenu,
    alternate = false,
    sx,
    onClick,
}: ICustomTableBodyRowProps) {
    return (
        <TableRow
            onDoubleClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            sx={{
                ...sx,
                fontWeight: 'regular',
                backgroundColor: alternate ? colors.grey['300'] : undefined,
                cursor: onClick ? 'pointer' : undefined,
            }}
            onContextMenu={onContextMenu}
        >
            {children}
        </TableRow>
    );
}
