import { useFormContext, UseFormReturn } from 'react-hook-form';
import { ITransfer, TRANSFER_RECEIPTS } from './transfer-form';
import { useMemo } from 'react';
import { AMOUNT } from './transfer-receipt-columns/transfer-receipt-columns';

function setTransferReceiptsTotal({
    transferReceipts,
    formContext,
    index,
}: {
    transferReceipts: ITransfer['transfer_receipts'];
    formContext: UseFormReturn<any>;
    index?: number;
}) {
    if (transferReceipts && Array.isArray(transferReceipts)) {
        const total =
            Math.round(
                (transferReceipts as ITransfer['transfer_receipts']).reduce(
                    (acc, transfer, currentIndex) => {
                        const amount = !Number.isNaN(Number(transfer.amount))
                            ? Number(transfer.amount)
                            : 0;
                        return acc + amount;
                    },
                    0,
                ) * 100,
            ) / 100;
        formContext.setValue(AMOUNT, total, {
            shouldValidate: true,
        });
    }
}

export function useCalculateTransferReceiptsTotal() {
    const formContext = useFormContext<ITransfer>();

    const calculateTransferReceiptsTotal = useMemo(() => {
        return function (params: { index?: number } = { index: undefined }) {
            const index =
                params && params.index !== undefined ? params.index : undefined;

            const transferReceipts = formContext.getValues(
                TRANSFER_RECEIPTS,
            ) as ITransfer['transfer_receipts'];

            setTransferReceiptsTotal({
                transferReceipts,
                formContext,
                index,
            });
        };
    }, [formContext]);

    return {
        calculateTransferReceiptsTotal: calculateTransferReceiptsTotal,
    };
}
