import React from 'react';
import { Avatar, Box, useTheme } from '@mui/material';

interface ICustomIconWrapperProps {
    children: React.ReactNode;
    size?: 'medium' | 'small';
}

export function DAvatarIcon(props: ICustomIconWrapperProps) {
    const { children, size = 'medium' } = props;
    const theme = useTheme();

    const scale = size === 'medium' ? 1.3 : 1;
    const width = size === 'medium' ? '40px' : '32px';
    const height = size === 'medium' ? '40px' : '32px';

    return (
        <Box sx={{ px: 1 }}>
            <Avatar
                sx={{
                    transform: `scale(${scale})`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: width,
                    height: height,
                    color: theme.palette.text.primary,
                    bgcolor: 'transparent',
                }}
            >
                {children}
            </Avatar>
        </Box>
    );
}
