import { DATE_FORMAT } from '../formatting/format-date';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export function useBoolean({
    KEY,
    defaultValue = false,
}: {
    KEY: string;
    defaultValue?: boolean;
}) {
    const state = useState<boolean>(() => {
        if (KEY && getBoolean(KEY) !== null) {
            return getBoolean(KEY) as boolean;
        } else if (defaultValue !== undefined) {
            return defaultValue;
        } else {
            return true;
        }
    });

    const [value] = state;

    useEffect(() => {
        if (value !== null) {
            saveBoolean(value, KEY);
        } else {
            removeFromLocalStorage(KEY);
        }
    }, [value, KEY]);

    return state;
}

export function saveBoolean(value: boolean, key: string) {
    window.localStorage.setItem(key, String(value));
}

export function getBoolean(key: string): boolean | null {
    if (window.localStorage.getItem(key) === null) {
        return null;
    }

    return window.localStorage.getItem(key) !== 'false';
}

export function useString({
    KEY,
    defaultValue = '',
}: {
    KEY: string;
    defaultValue?: string;
}) {
    const state = useState<string>(
        KEY ? getString(KEY) || defaultValue : defaultValue,
    );

    const [value] = state;

    useEffect(() => {
        if (value !== '') {
            saveString(value, KEY);
        } else {
            removeFromLocalStorage(KEY);
        }
    }, [value, KEY]);

    return state;
}

export function saveString(value: string, key: string) {
    window.localStorage.setItem(key, value);
}

export function getString(key: string): string | null {
    return window.localStorage.getItem(key);
}

export function saveNumber(value: number, key: string) {
    window.localStorage.setItem(key, value.toString());
}

export function useNumber({
    KEY,
    defaultValue = null,
}: {
    KEY: string;
    defaultValue?: number | null;
}) {
    const state = useState<number | null>(
        KEY ? getNumber(KEY) || defaultValue : defaultValue,
    );

    const [value] = state;

    useEffect(() => {
        if (value !== null) {
            saveNumber(value, KEY);
        } else {
            removeFromLocalStorage(KEY);
        }
    }, [value, KEY]);

    return state;
}

export function getNumber(key: string): number | null {
    return window.localStorage.getItem(key) !== null
        ? Number(window.localStorage.getItem(key))
        : null;
}

export function saveDayjsDate(
    value: string | null,
    key: string,
    format: string = DATE_FORMAT,
) {
    if (dayjs.isDayjs(value)) {
        window.localStorage.setItem(key, value.format(format));
    } else if (typeof value === 'string') {
        window.localStorage.setItem(key, value);
    } else {
        window.localStorage.removeItem(key);
    }
}

export function getDayjsDate(key: string, format: string = DATE_FORMAT) {
    return window.localStorage.getItem(key) !== null
        ? dayjs(window.localStorage.getItem(key)).format(format)
        : null;
}

export function removeFromLocalStorage(key: string) {
    window.localStorage.removeItem(key);
}
