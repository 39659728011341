import React from 'react';
import { useOrderSaleOrderSaleStatusInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderSale } from '../../order-sale-form';

export function OrderSaleStatusInput() {
    const inputName: keyof IOrderSale = 'order_sale_status_id';
    const { data } = useOrderSaleOrderSaleStatusInputQuery();

    const orderSaleStatuses = data?.getOrderSaleStatuses || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Status'}
            options={orderSaleStatuses}
        />
    );
}
