import React, { useMemo, useState } from 'react';
import { useOrderAdjustmentFormQuery } from '../../../../../../services';
import { DModal, DPageLoader } from '../../../../../dum';
import { ProductIcon } from '../../../../icons';
import { OrderAdjustmentForm } from './order-adjustment-form';

interface IOrderAdjustmentFormDialog {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderAdjustmentId?: number | null;
}

export const SUpsertOrderAdjustmentDialog = (
    props: IOrderAdjustmentFormDialog,
) => {
    const { open, setOpen, orderAdjustmentId } = props;

    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useOrderAdjustmentFormQuery({
        skip: !orderAdjustmentId || !open,
        variables: {
            OrderAdjustmentId: orderAdjustmentId!,
        },
    });

    const orderAdjustment = useMemo(() => {
        return orderAdjustmentId && data && data.getOrderAdjustment && open
            ? data.getOrderAdjustment
            : undefined;
    }, [orderAdjustmentId, data, open]);

    return (
        <DModal
            icon={<ProductIcon />}
            title={`Ajuste`}
            open={open}
            setOpen={setOpen}
            width={'60rem'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <OrderAdjustmentForm
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                    orderAdjustment={orderAdjustment}
                    disabled={isModalClosing}
                />
            )}
        </DModal>
    );
};
