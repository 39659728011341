import React from 'react';
import { IOrderSale } from '../../order-sale-form';
import { useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../dum';

const receipt_type_id: keyof IOrderSale = 'receipt_type_id';

export function InvoiceCodeInput() {
    const INPUT_NAME: keyof IOrderSale = 'invoice_code';
    const orderSaleReceiptTypeId = useWatch({
        name: receipt_type_id,
    }) as IOrderSale['receipt_type_id'];

    return orderSaleReceiptTypeId === 2 ? (
        <RHTextField name={INPUT_NAME} label={'Factura'} />
    ) : null;
}
