import { ExpenseSummaryWorkSheets } from './expenses-summary';
import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { CTableWrapper } from '../../../dum';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export function ExpenseSummaryTabs({
    worksheets,
}: {
    worksheets: ExpenseSummaryWorkSheets;
}) {
    const { accountsPages, orderSalePage, expensePage, canceledExpensePage } =
        worksheets;
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
            >
                {accountsPages.map(({ accountName }) => {
                    return <Tab key={accountName} label={accountName} />;
                })}
                <Tab label={'Facturas emitidas'} />
                <Tab label={'Facturas de proveedores'} />
                <Tab label={'Facturas canceladas (proveedores)'} />
            </Tabs>
            <Box sx={{ overflowY: 'scroll', height: '70vh', mt: 2 }}>
                {accountsPages.map(({ accountName, data }, index) => {
                    return (
                        <CustomTabPanel
                            key={accountName}
                            value={value}
                            index={index}
                        >
                            <CTableWrapper
                                stickyHeader
                                items={[
                                    {
                                        title: '#',
                                        content: (val) => {
                                            return val.transferIndex;
                                        },
                                        textAlign: 'center',
                                    },
                                    {
                                        title: 'De',
                                        content: (val) => val.fromAccountName,
                                    },
                                    {
                                        title: 'Para',
                                        content: (val) => val.toAccountName,
                                    },
                                    {
                                        title: 'Fecha',
                                        content: (val) => val.transferDate,
                                    },
                                    {
                                        title: 'Folio',
                                        content: (val) => val.orderCode,
                                    },
                                    {
                                        title: 'Fecha del recibo',
                                        content: (val) => val.receiptIssueDate,
                                    },
                                    {
                                        title: 'Cantidad transferida',
                                        content: (val) => val.transferredAmount,
                                        textAlign: 'right',
                                    },
                                    {
                                        title: 'Total',
                                        content: (val) => val.receiptTotal,
                                        textAlign: 'right',
                                    },
                                    {
                                        title: 'IVA',
                                        content: (val) => val.receiptTax,
                                        textAlign: 'right',
                                    },
                                    {
                                        title: 'IVA retenido',
                                        content: (val) => val.taxRetained,
                                        textAlign: 'right',
                                    },
                                    {
                                        title: 'ISR retenido',
                                        content: (val) => val.nonTaxRetained,
                                    },
                                    {
                                        title: 'Notas',
                                        content: (val) => val.notes,
                                    },
                                    {
                                        title: 'Complemento',
                                        content: (val) => val.supplement,
                                    },
                                    {
                                        title: 'Nota de credito',
                                        content: (val) => val.creditNote,
                                    },
                                ]}
                                data={data}
                            />
                        </CustomTabPanel>
                    );
                })}
                <CustomTabPanel index={accountsPages.length} value={value}>
                    <CTableWrapper
                        stickyHeader
                        items={[
                            {
                                title: 'Folio',
                                content: (val) => val.invoiceCode,
                            },
                            {
                                title: 'Fecha de emisión',
                                content: (val) => val.date,
                            },
                            {
                                title: 'Cliente',
                                content: (val) => val.accountName,
                            },
                            {
                                title: 'Total',
                                content: (val) => val.total,
                                textAlign: 'right',
                            },
                            {
                                title: 'IVA',
                                content: (val) => val.tax,
                                textAlign: 'right',
                            },
                            {
                                title: 'Importe de la factura (sin descuentos)',
                                content: (val) => val.invoiceTotal,
                                textAlign: 'right',
                            },
                            {
                                title: 'Cantidad transferida',
                                content: (val) => val.transferReceiptsTotal,
                                textAlign: 'right',
                            },
                            {
                                title: 'Transferencias',
                                content: (val) => val.transfers,
                            },
                            {
                                title: 'Complemento',
                                content: (val) => val.supplement,
                            },
                            {
                                title: 'Nota de credito',
                                content: (val) => val.credit_note,
                            },
                            {
                                title: 'Notas',
                                content: (val) => val.notes,
                            },
                            {
                                title: 'Cancelada',
                                content: (val) => val.canceled,
                            },
                        ]}
                        data={orderSalePage}
                    />
                </CustomTabPanel>
                <CustomTabPanel index={accountsPages.length + 1} value={value}>
                    <CTableWrapper
                        stickyHeader
                        items={[
                            {
                                title: 'Folio',
                                content: (val) => val.orderCode,
                            },
                            {
                                title: 'Fecha de emisión',
                                content: (val) => val.date,
                            },
                            {
                                title: 'Cliente',
                                content: (val) => val.accountName,
                            },
                            {
                                title: 'IVA',
                                content: (val) => val.tax,
                                textAlign: 'right',
                            },
                            {
                                title: 'IVA retenido',
                                content: (val) => val.taxRetained,
                                textAlign: 'right',
                            },
                            {
                                title: 'Importe de la factura',
                                content: (val) =>
                                    val.expenseResourcesTotalWithTax,
                                textAlign: 'right',
                            },
                            {
                                title: 'Cantidad transferida',
                                content: (val) => val.transferReceiptsTotal,
                                textAlign: 'right',
                            },
                            {
                                title: 'Transferencias',
                                content: (val) => val.transfers,
                            },
                            {
                                title: 'Complemento',
                                content: (val) => val.supplement,
                            },
                            {
                                title: 'Notas',
                                content: (val) => val.notes,
                            },
                            {
                                title: 'Cancelada',
                                content: (val) => val.canceled,
                            },
                        ]}
                        data={expensePage}
                    />
                </CustomTabPanel>
                <CustomTabPanel index={accountsPages.length + 2} value={value}>
                    <CTableWrapper
                        stickyHeader
                        items={[
                            {
                                title: 'Folio',
                                content: (val) => val.orderCode,
                            },
                            {
                                title: 'Fecha de emisión',
                                content: (val) => val.date,
                            },
                            {
                                title: 'Cliente',
                                content: (val) => val.accountName,
                            },
                            {
                                title: 'IVA',
                                content: (val) => val.tax,
                                textAlign: 'right',
                            },
                            {
                                title: 'IVA retenido',
                                content: (val) => val.taxRetained,
                                textAlign: 'right',
                            },
                            {
                                title: 'Importe de la factura',
                                content: (val) =>
                                    val.expenseResourcesTotalWithTax,
                                textAlign: 'right',
                            },
                        ]}
                        data={canceledExpensePage}
                    />
                </CustomTabPanel>
            </Box>
        </>
    );
}
