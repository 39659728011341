import React from 'react';
import { DPage, DPageBody, DPageHeader } from '../../../../dum';
import { RawMaterialAdditionsListBlock } from './blocks/raw-material-additions-block';

export function RawMaterialAdditionsPage() {
    return (
        <DPage animated>
            <DPageHeader title={'Entrega de almacén'} />
            <DPageBody disableGutters>
                <RawMaterialAdditionsListBlock />
            </DPageBody>
        </DPage>
    );
}
