export const colors = [
    '#354259',
    '#874C62',
    '#7F669D',
    '#F7A4A4',
    '#FBFACD',
    '#B6E2A1',
    '#E97777',
    '#829460',
    '#EEEEEE',
    '#815B5B',
    '#FDFDBD',
    '#B8E8FC',
    '#FF8DC7',
    '#F2D388',
    '#DEBACE',
    '#FEBE8C',
];
