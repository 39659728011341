import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

interface ICustomPageHeaderLayout {
    children?: React.ReactNode | React.ReactNode[];
    title: string;
}

export function DPageHeader(props: ICustomPageHeaderLayout) {
    const { children, title } = props;
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 16,
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={'h4'}
                        sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                {children}
            </Box>
            <Divider sx={{ mb: 4 }} />
        </>
    );
}
