import { ValidationError } from 'yup';

export function doesMachineBelongToOrderProductionType(
    value: any,
    context: any,
): boolean | ValidationError {
    const { machine } = context.from[0].value;
    const { order_production_type_id } = context.from[1].value;

    if (!order_production_type_id || !machine) return true;

    if (!(machine.order_production_type_id === order_production_type_id)) {
        return context.createError({
            message: `La máquina no pertenece a la orden de producción`,
        });
    }

    return true;
}
