import { IOrderSale } from '../../order-sale-form';
import { ValidationError } from 'yup';

export function doGroupPriceMatchOrderRequestProductGroupPrice(
    value: any,
    context: any,
): ValidationError | boolean {
    const { group_price, product } = context.from[0]
        .value as IOrderSale['order_sale_products'][number];
    const { order_request } = context.from[1].value as IOrderSale;
    const orderRequestRemainingProduct =
        order_request?.order_request_remaining_products.find(
            (rp) => rp?.product?.id === product?.id,
        );
    if (!orderRequestRemainingProduct) return true;

    const currentGroupPriceInOrderRequest =
        orderRequestRemainingProduct?.group_price;

    const selectedGroupPrice = Number(group_price);

    if (selectedGroupPrice !== currentGroupPriceInOrderRequest) {
        return context.createError({
            message: `El precio de los producto no es igual al establecido en el pedido (precio en el pedido: ${currentGroupPriceInOrderRequest})`,
        });
    }

    return true;
}
