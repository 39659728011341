import React, { useMemo, useState } from 'react';
import {
    ActivityEntityName,
    ActivityTypeName,
    useActivitiesPageQuery,
} from '../../../services';
import {
    CTableWrapper,
    DBlock,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    EditIcon,
} from '../../dum';
import { IconButton } from '@mui/material';
import {
    SUpsertAccountDialog,
    SUpsertEmployeeDialog,
    SUpsertOrderAdjustmentDialog,
    SUpsertOrderProductionDialog,
    SUpsertOrderRequestDialog,
    SUpsertOrderSale,
    SUpsertProductDialog,
} from '../../smart';
import dayjs from 'dayjs';

interface DialogState {
    open: boolean;
    id: null | number;
    entityName: ActivityEntityName | null;
}

export function ActivitiesPage() {
    const { data, error, loading } = useActivitiesPageQuery();
    const [dialog, setDialog] = useState<DialogState>({
        entityName: null,
        open: false,
        id: null,
    });

    const resetDialog = useMemo(() => {
        return () => {
            setDialog({
                entityName: null,
                open: false,
                id: null,
            });
        };
    }, [setDialog]);

    if (loading) return <DPageLoader />;

    if (error || !data) return null;

    return (
        <DPage>
            <DPageHeader title={'Actividad reciente'} />
            <DPageBody>
                <DBlock>
                    <CTableWrapper
                        items={[
                            {
                                cellGrow: 4,
                                title: 'Fecha',
                                content: (dataItem) => {
                                    return dayjs(dataItem.created_at).fromNow();
                                },
                            },
                            {
                                cellGrow: 4,
                                title: 'Actividad',
                                content: (dataItem) => {
                                    switch (dataItem.entity_name) {
                                        case ActivityEntityName.OrderProduction: {
                                            return `Orden de producción`;
                                        }

                                        case ActivityEntityName.OrderSale: {
                                            return `Venta`;
                                        }

                                        case ActivityEntityName.Product: {
                                            return `Producto`;
                                        }

                                        case ActivityEntityName.Account: {
                                            return `Cliente`;
                                        }

                                        case ActivityEntityName.User: {
                                            return `Usuario`;
                                        }

                                        case ActivityEntityName.Employee: {
                                            return `Empleado`;
                                        }

                                        case ActivityEntityName.Equipments: {
                                            return `Refacción`;
                                        }

                                        case ActivityEntityName.Machine: {
                                            return `Maquina`;
                                        }

                                        case ActivityEntityName.OrderAdjustment: {
                                            return `Ajuste`;
                                        }

                                        case ActivityEntityName.OrderRequest: {
                                            return `Pedido`;
                                        }

                                        default:
                                            return null;
                                    }
                                },
                            },
                            {
                                cellGrow: 6,
                                title: 'Descripción',
                                content: (dataItem) => {
                                    return dataItem.description;
                                },
                            },
                            {
                                cellGrow: 3,
                                title: 'Tipo de actividad',
                                content: (dataItem) => {
                                    switch (dataItem.type) {
                                        case ActivityTypeName.Create: {
                                            return `Creación`;
                                        }

                                        case ActivityTypeName.Update: {
                                            return `Actualización`;
                                        }

                                        case ActivityTypeName.Delete: {
                                            return `Eliminación`;
                                        }
                                        default:
                                            return null;
                                    }
                                },
                            },
                            {
                                title: 'Acciones',
                                textAlign: 'center',
                                content: (dataItem) => {
                                    return (
                                        <IconButton
                                            disabled={
                                                dataItem.type ===
                                                    ActivityTypeName.Delete ||
                                                ActivityEntityName.User ===
                                                    dataItem.entity_name
                                            }
                                            onClick={() => {
                                                switch (dataItem.entity_name) {
                                                    case ActivityEntityName.OrderProduction: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.OrderProduction,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.OrderSale: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.OrderSale,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.Product: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.Product,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.Account: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.Account,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.Employee: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.Employee,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.Equipments: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.Equipments,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.Machine: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.Machine,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.OrderAdjustment: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.OrderAdjustment,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    case ActivityEntityName.OrderRequest: {
                                                        setDialog({
                                                            id: dataItem.entity_id,
                                                            entityName:
                                                                ActivityEntityName.OrderRequest,
                                                            open: true,
                                                        });
                                                        break;
                                                    }

                                                    default:
                                                        return null;
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    );
                                },
                            },
                        ]}
                        data={data.getActivities}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertOrderSale
                open={
                    dialog.entityName === ActivityEntityName.OrderSale &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                orderSaleId={dialog.id}
            />
            <SUpsertOrderRequestDialog
                open={
                    dialog.entityName === ActivityEntityName.OrderRequest &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                orderRequestId={dialog.id}
            />

            <SUpsertAccountDialog
                open={
                    dialog.entityName === ActivityEntityName.Account &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                accountId={dialog.id}
            />

            <SUpsertEmployeeDialog
                open={
                    dialog.entityName === ActivityEntityName.Employee &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                employeeId={dialog.id}
            />
            <SUpsertOrderAdjustmentDialog
                open={
                    dialog.entityName === ActivityEntityName.OrderAdjustment &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                orderAdjustmentId={dialog.id}
            />

            <SUpsertOrderProductionDialog
                open={
                    dialog.entityName === ActivityEntityName.OrderProduction &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                orderProductionId={dialog.id}
            />

            <SUpsertProductDialog
                open={
                    dialog.entityName === ActivityEntityName.Product &&
                    dialog.open
                }
                setOpen={(_) => {
                    resetDialog();
                }}
                productId={dialog.id}
            />
        </DPage>
    );
}
