import React from 'react';
import {
    useOrderAdjustmentGetOrderSaleLazyQuery,
    useOrderAdjustmentOrderSaleInputQuery,
} from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import {
    IOrderAdjustment,
    ORDER_SALE_ADJUSTMENT_PRODUCTS,
    ORDER_SALE_PRODUCTS,
} from '../order-adjustment-form';
import { useFormContext, useWatch } from 'react-hook-form';

const ORDER_ADJUSTMENT_TYPE_ID: keyof IOrderAdjustment =
    'order_adjustment_type_id';

export function OrderSaleInput() {
    const { data: dataOrderSales } = useOrderAdjustmentOrderSaleInputQuery();
    const [query] = useOrderAdjustmentGetOrderSaleLazyQuery();
    const rx = useFormContext();

    const INPUT_NAME: keyof IOrderAdjustment = 'order_sale';

    const order_adjustment_type_id = useWatch({
        name: ORDER_ADJUSTMENT_TYPE_ID,
    }) as IOrderAdjustment['order_adjustment_type_id'];

    return order_adjustment_type_id === 6 ? (
        <RhSingleAutocomplete
            itemText={'compound_order_code'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Venta'}
            options={dataOrderSales?.getOrderSales || []}
            returnObject
            onChange={async (osp: IOrderAdjustment['order_sale']) => {
                if (osp && osp.id) {
                    const { data } = await query({
                        variables: {
                            OrderSaleId: osp.id,
                        },
                    });
                    if (data && data.getOrderSale) {
                        const {
                            order_sale_products,
                            order_adjustment_products,
                        } = data.getOrderSale;
                        rx.setValue(ORDER_SALE_PRODUCTS, order_sale_products);
                        rx.setValue(
                            ORDER_SALE_ADJUSTMENT_PRODUCTS,
                            order_adjustment_products,
                        );
                    }
                }
            }}
        />
    ) : null;
}
