import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ICustomVerticalDropdownProps {
    items: {
        component: React.ReactNode;
        callback?: () => void;
        color?: 'primary' | 'error';
        disabled?: boolean;
    }[];
}

export function CVerticalDropdown({ items }: ICustomVerticalDropdownProps) {
    const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement>(
        null,
    );

    function handleCloseDropdown() {
        setDropdownAnchor(null);
    }

    return (
        <Box>
            <IconButton
                color={'primary'}
                size={'small'}
                onClick={(e) => {
                    setDropdownAnchor(e.currentTarget);
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={dropdownAnchor}
                open={Boolean(dropdownAnchor)}
                onClose={handleCloseDropdown}
                variant={'selectedMenu'}
                onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                        e.stopPropagation();
                    }
                }}
            >
                {items.map(
                    (
                        { color, callback, component, disabled = false },
                        index,
                    ) => {
                        return (
                            <MenuItem
                                autoFocus={false}
                                disableRipple={!callback}
                                disableTouchRipple={!callback}
                                color={color}
                                key={index}
                                disabled={disabled}
                                sx={{
                                    cursor: !!callback ? 'pointer' : 'default',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key !== 'Escape') {
                                        e.stopPropagation();
                                    }
                                }}
                            >
                                {typeof component === 'string' ? (
                                    <Typography variant={'body1'} color={color}>
                                        {component}
                                    </Typography>
                                ) : (
                                    component
                                )}
                            </MenuItem>
                        );
                    },
                )}
            </Menu>
        </Box>
    );
}
