import packageJson from '../../../package.json';
import { useMemo } from 'react';

export function useDevState(): boolean {
    return useMemo<boolean>(() => {
        // const isDevelopmentEnvironment =
        //     process?.env?.NODE_ENV === 'development';
        const packageJsonName = packageJson.name.replace('-', '_').trim();
        const isWebpackRunning = !!(window as any)[
            `webpackHotUpdate${packageJsonName}`
        ];
        return isWebpackRunning; // && isDevelopmentEnvironment;
    }, []);
}
