import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { NEW_FEMININE_NAME, SPARES_SINGULAR_NAME } from '../../../../constants';
import { Route } from '../../../../types';
import { DPage, DSpeedDial, ICustomSpeedDialProps } from '../../../dum';
import {
    SpareCategoryIcon,
    SpareIcon,
    SSpareCategoryFormDialog,
    SSpareFormDialog,
} from '../../../smart';
import { SpareCategoriesPage } from './spare-categories/spare-categories-page';
import { SpareCategoryPage } from './spare-category-page/spare-category-page';
import { SparePage } from './spare/spare-page';
import { SparesPage } from './spares/spares-page';

const sparesPages: Route[] = [
    {
        name: 'SparesPage',
        path: '/',
        component: <SparesPage />,
        title: 'Refaccións',
        navbar: false,
    },
    {
        name: 'SpareCategoriesPage',
        path: '/spareCategories',
        component: <SpareCategoriesPage />,
        title: 'Categoria',
        navbar: false,
    },
    {
        name: 'SpareCategoryPage',
        path: '/spareCategories/:spareCategoryId',
        component: <SpareCategoryPage />,
        title: 'Categoria',
        navbar: false,
    },
    {
        name: 'SparePage',
        path: '/:spareId',
        component: <SparePage />,
        title: 'Refacción',
        navbar: false,
    },
];

export function SparesHomePage() {
    const [sparesDialog, setSparesDialog] = useState(false);
    const [spareCategoryDialog, setSpareCategoryDialog] = useState(false);

    const actions: ICustomSpeedDialProps['items'] = [
        {
            icon: <SpareIcon />,
            text: `${NEW_FEMININE_NAME} ${SPARES_SINGULAR_NAME}`,
            callback: () => {
                setSparesDialog(true);
            },
        },
        {
            icon: <SpareCategoryIcon />,
            text: 'Nueva categoria',
            callback: () => {
                setSpareCategoryDialog(true);
            },
        },
    ];

    return (
        <DPage>
            <DSpeedDial items={actions} />
            <ReactRouterRoutes>
                {sparesPages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SSpareFormDialog open={sparesDialog} setOpen={setSparesDialog} />
            <SSpareCategoryFormDialog
                open={spareCategoryDialog}
                setOpen={setSpareCategoryDialog}
            />
        </DPage>
    );
}
