import React, { useEffect } from 'react';
import { formatDate } from '../../../../../../helpers';
import { useSpareTransactionsPageLazyQuery } from '../../../../../../services';
import {
    CTableWrapper,
    DBlock,
    DMonth,
    DYear,
    useDMonth,
    useDYear,
} from '../../../../../dum';
import { SSpareAdjustmentLink, SSpareLink } from '../../../../../smart';
import { Box } from '@mui/material';

const SpareTransitionsBlock_YEAR_KEY = 'SpareTransitionsBlock_YEAR_KEY';
const SpareTransitionsBlock_MONTH_KEY = 'SpareTransitionsBlock_MONTH_KEY';

export function SpareTransitionsBlock() {
    const [query, { data }] = useSpareTransactionsPageLazyQuery();

    const { year, setYear } = useDYear({
        COMPONENT_KEY: SpareTransitionsBlock_YEAR_KEY,
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: SpareTransitionsBlock_MONTH_KEY,
    });

    useEffect(() => {
        if (year) {
            void query({
                variables: {
                    year: year,
                    month: month,
                },
            });
        }
    }, [query, year, month]);

    return (
        <DBlock>
            <Box>
                <Box sx={{ mr: 2 }}>
                    <DYear setYear={setYear} year={year} />
                </Box>
                <Box>
                    <DMonth setMonth={setMonth} month={month} />
                </Box>
            </Box>
            <CTableWrapper
                items={[
                    {
                        title: 'Refacción',
                        content: (transaction) => {
                            if (transaction.spare) {
                                return (
                                    <SSpareLink
                                        spareId={transaction.spare.id}
                                        spareName={transaction.spare.name}
                                    />
                                );
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Fecha',
                        content: (transaction) => {
                            return formatDate(
                                transaction.spare_operation?.date,
                            );
                        },
                    },
                    {
                        title: 'Cantidad agregada',
                        textAlign: 'right',
                        content: (transaction) => transaction.quantity,
                    },
                    {
                        title: 'Operacion',
                        textAlign: 'right',
                        content: (transaction) => {
                            const spareOperation = transaction.spare_operation;
                            if (spareOperation?.is_adjustment) {
                                return 'Ajuste';
                            } else if (spareOperation?.is_withdrawal) {
                                return 'Retiro';
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Descripcion de la operacion',
                        textAlign: 'right',
                        content: (transaction) => {
                            const spareOperation = transaction.spare_operation;
                            if (spareOperation?.is_adjustment) {
                                return (
                                    <SSpareAdjustmentLink
                                        spareAdjustmentId={spareOperation.id}
                                        spareAdjustmentName={
                                            spareOperation.description
                                        }
                                    />
                                );
                            } else if (spareOperation?.is_withdrawal) {
                                return spareOperation.description;
                            }
                            return '-';
                        },
                    },
                ]}
                data={year && data ? data.getSpareTransactions : []}
            />
        </DBlock>
    );
}
