import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    SPARES_PLURAL_NAME,
    SPARES_SINGULAR_NAME,
} from '../../../../../constants';
import {
    navigateToSpareCategoriesPage,
    useSparesPageQuery,
} from '../../../../../services';
import {
    CTableWrapper,
    DBlock,
    DBlockHeader,
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
} from '../../../../dum';
import { SSpareCategoryLink, SSpareLink } from '../../../../smart';

export function SparesPage() {
    const navigate = useNavigate();
    const { data, loading, error } = useSparesPageQuery();

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }

    return (
        <DPage animated>
            <DPageHeader title={SPARES_PLURAL_NAME}>
                <DRowButtons
                    rowItems={[
                        {
                            tooltip: 'Ir a categorias',
                            callback: () => {
                                navigateToSpareCategoriesPage({
                                    navigate,
                                });
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <DBlock>
                    <DBlockHeader subtitle={'Lista de refacciónes'} />
                    <CTableWrapper
                        data={data!.getSpares}
                        items={[
                            {
                                title: `Nombre de la ${SPARES_SINGULAR_NAME}`,
                                content: (spare) => (
                                    <SSpareLink
                                        spareId={spare.id}
                                        spareName={spare.name}
                                    />
                                ),
                            },
                            {
                                title: 'Categoria',
                                content: (spare) => {
                                    if (!spare.spare_category) return '-';
                                    return (
                                        <SSpareCategoryLink
                                            spareCategoryId={
                                                spare.spare_category.id
                                            }
                                            spareCategoryName={
                                                spare.spare_category.name
                                            }
                                        />
                                    );
                                },
                            },
                            {
                                title: 'Cantidad en inventario',
                                textAlign: 'right',
                                content: (spare) => spare?.current_quantity,
                            },
                            {
                                title: 'Cantidad requerida',
                                textAlign: 'right',
                                content: (spare) =>
                                    spare.total_required_quantity,
                            },
                            {
                                title: 'Se puede borrar?',
                                textAlign: 'right',
                                content: (spare) =>
                                    spare.is_deletable ? 'si' : 'no',
                            },
                        ]}
                    />
                </DBlock>
            </DPageBody>
        </DPage>
    );
}
