import { RhDateInput, RhFormWrapper } from '../../../../../dum';
import {
    getDateFromString,
    getStringFromDate,
    transformToNumber,
} from '../../../../../../helpers';
import { OrderAdjustmentTypeInput } from './order-adjustment-type/order-adjustment-type-input';
import { OrderAdjustmentProductsColumns } from './order-adjustment-products/order-adjustment-products-columns';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { isProductUnique } from './validation/is-product-unique';
import { useUpsertOrderAdjustmentMutation } from '../../../../../../services';
import { OrderSaleInput } from './order-sale/order-sale-input';
import { areUnitsLessThanOrderSaleProduct } from './validation/are-units-less-than-order-sale-product';
import { isProductInOrderSales } from './validation/is-product-in-order-sales';

export interface IOrderAdjustment {
    id?: number | null;
    date: string;
    order_adjustment_type_id?: number | null;
    order_sale?: {
        id?: number | null;
        compound_order_code: string;
    } | null;
    order_sale_adjustment_products?: {
        id?: number | null;
        kilos: number;
        product_id?: number | null;
        groups: number;
    }[];
    order_sale_products?: {
        id?: number | null;
        kilos: number;
        product_id?: number | null;
        groups: number;
    }[];
    order_adjustment_products: {
        id?: number | null;
        kilos: number;
        product?: {
            id: number;
            compound_description: string;
            current_group_weight?: number | null;
        } | null;
        groups: number;
        group_weight?: number | null;
    }[];
}

interface IOrderAdjustmentForm {
    orderAdjustment?: IOrderAdjustment | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export const ORDER_ADJUSTMENT_PRODUCTS: keyof IOrderAdjustment =
    'order_adjustment_products';

export const ORDER_SALE_PRODUCTS: keyof IOrderAdjustment =
    'order_sale_products';
export const ORDER_SALE_ADJUSTMENT_PRODUCTS: keyof IOrderAdjustment =
    'order_sale_adjustment_products';

export function OrderAdjustmentForm({
    orderAdjustment,
    onSuccess,
    disabled = false,
}: IOrderAdjustmentForm) {
    const [mutation, { loading }] = useUpsertOrderAdjustmentMutation();

    const initialValues: IOrderAdjustment = useMemo<IOrderAdjustment>(() => {
        return {
            id: orderAdjustment?.id || undefined,
            date: orderAdjustment
                ? getDateFromString(orderAdjustment.date)
                : '',
            order_adjustment_type_id:
                orderAdjustment?.order_adjustment_type_id || null,
            order_sale: orderAdjustment?.order_sale || null,
            order_sale_adjustment_products:
                orderAdjustment?.order_sale_adjustment_products || [],
            order_sale_products: orderAdjustment?.order_sale_products || [],
            order_adjustment_products:
                orderAdjustment?.order_adjustment_products || [
                    {
                        product: null,
                        group_weight: 0,
                        groups: 0,
                        kilos: 0,
                    },
                ],
        };
    }, [orderAdjustment]);

    const validationSchema: SchemaOf<IOrderAdjustment> = yup.object({
        id: yup.number().notRequired().nullable(),
        date: yup.string().required(`'Fecha' es obligatorio`).nullable(),
        order_adjustment_type_id: yup
            .number()
            .required(`'Tipo de ajuste' es obligatorio`)
            .nullable(),
        order_sale: yup.mixed().when('order_adjustment_type_id', {
            is: (val: any) => {
                return val === 6;
            },
            then: (schema) => schema.required(`'Venta' es obligatorio`),
            otherwise: (schema) => schema.notRequired(),
        }),
        order_sale_adjustment_products: yup.mixed().notRequired(),
        order_sale_products: yup.mixed().notRequired(),
        order_adjustment_products: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    product: yup
                        .mixed()
                        .required(`'Producto' es obligatorio`)
                        .nullable()
                        .test('is-product-unique', isProductUnique)
                        .test(
                            'is-product-in-order-sales',
                            isProductInOrderSales,
                        ),
                    kilos: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Cantidad' es obligatorio`)
                        .test(
                            `are-units-less-than-the-order-sale-product`,
                            areUnitsLessThanOrderSaleProduct('kilos'),
                        ),
                    groups: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Grupos' es obligatorio`)
                        .test(
                            `are-units-less-than-the-order-sale-product`,
                            areUnitsLessThanOrderSaleProduct('groups'),
                        ),
                    group_weight: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Peso' es obligatorio`),
                }),
            )
            .required(`'Productos' es obligatorio`),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            OrderAdjustmentInput: {
                                id: orderAdjustment?.id || null,
                                date: getStringFromDate(data.date),
                                order_adjustment_type_id:
                                    data?.order_adjustment_type_id || null,
                                order_sale_id: data?.order_sale?.id || null,
                                order_adjustment_products:
                                    data.order_adjustment_products.map(
                                        (productionProduct) => {
                                            return {
                                                kilos: productionProduct.kilos,
                                                product_id:
                                                    productionProduct.product!
                                                        .id,
                                                group_weight:
                                                    productionProduct.group_weight!,
                                                groups: productionProduct.groups,
                                            };
                                        },
                                    ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RhDateInput name={'date'} label={'Fecha'} />
            <OrderAdjustmentTypeInput />
            <OrderSaleInput />
            <OrderAdjustmentProductsColumns initialValues={initialValues} />
        </RhFormWrapper>
    );
}
