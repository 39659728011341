import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteOrderAdjustmentMutation } from '../../../../../../services';

interface IDeleteOrderAdjustmentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderAdjustmentId: number | null;
}

export function SDeleteOrderAdjustmentDialog({
    open,
    setOpen,
    orderAdjustmentId,
}: IDeleteOrderAdjustmentProps) {
    const [deleteMutation] = useDeleteOrderAdjustmentMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (orderAdjustmentId) {
                    try {
                        await deleteMutation({
                            variables: {
                                OrderAdjustmentId: orderAdjustmentId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
