import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalMessage } from '../../types';

export interface GlobalMessagesState {
    value: GlobalMessage[];
}

const initialState: GlobalMessagesState = {
    value: [],
};

export const globalMessagesSlice = createSlice({
    name: 'globalMessages',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        pushMessage: (state, action: PayloadAction<GlobalMessage>) => {
            state.value.push(action.payload);
        },
    },
});

export const { pushMessage } = globalMessagesSlice.actions;

export default globalMessagesSlice.reducer;
