import React, { useEffect } from 'react';
import { useMachineSectionInputLazyQuery } from '../../../../../services';
import { RhSingleAutocomplete } from '../../../../dum';

interface ISSingleMachineSectionAutocompleteProps {
    name: string;
    label: string;
    machineId?: number | null;
    disabled?: boolean;
}

export function SSingleMachineSectionAutocomplete({
    name,
    label,
    machineId,
    disabled,
}: ISSingleMachineSectionAutocompleteProps) {
    const [query, { data }] = useMachineSectionInputLazyQuery();

    useEffect(() => {
        if (machineId) {
            void query({
                variables: {
                    MachineId: machineId,
                },
            });
        }
    }, [machineId, query]);

    const machineSections = data?.getMachineSections || [];

    return (
        <RhSingleAutocomplete
            disabled={disabled}
            itemText={'name'}
            itemValue={'id'}
            name={name}
            label={label}
            options={machineSections}
        />
    );
}
