import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEmployeePageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { SUpsertEmployeeDialog } from '../../../../smart';

type TProductPageParams = {
    employeeId: string;
};

export function EmployeePage() {
    const params = useParams<TProductPageParams>();
    const { data, loading, error } = useEmployeePageQuery({
        variables: {
            EmployeeId: params.employeeId ? Number(params.employeeId) : 0,
        },
    });
    const [upsertDialog, setUpsertDialog] = useState(false);

    if (loading) {
        return <DPageLoader />;
    }

    if (error || !data) {
        return <DErrorCard />;
    }

    const employee = data!.getEmployee!;

    return (
        <DPage animated>
            <DPageHeader title={employee.fullname}>
                <DRowButtons
                    rowItems={[
                        {
                            tooltip: 'Editar Empleado',
                            icon: <EditIcon />,
                            callback: () => {
                                setUpsertDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <Box>{employee.fullname}</Box>
            </DPageBody>
            <SUpsertEmployeeDialog
                open={upsertDialog}
                setOpen={setUpsertDialog}
                employeeId={employee.id}
            />
        </DPage>
    );
}
