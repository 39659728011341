import PetsIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useLoginMutation } from '../../../services';
import { DAnimatedBox } from '../../dum';
import { useString } from '../../../helpers';

// const theme-selector = createTheme();

export default function LoginForm() {
    const [isDisabled, setIsDisabled] = useState(false);

    const [username, setUsername] = useString({
        KEY: LoginForm.name + '_Username',
        defaultValue: '',
    });
    const [password, setPassword] = useString({
        KEY: LoginForm.name + '_Password',
        defaultValue: '',
    });

    const [loginMutation] = useLoginMutation();

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsDisabled(true);

        try {
            const options = {
                loginInput: {
                    email: username,
                    password: password,
                },
            };

            const res = await loginMutation({
                variables: {
                    ...options,
                },
            });

            const accessToken = res.data?.login?.accessToken;

            if (accessToken) {
                window.localStorage.setItem('token', accessToken);
                window.location.reload();
            }
        } catch (e: unknown) {
            setIsDisabled(false);
            console.error(e);
        }
    }

    return (
        <DAnimatedBox>
            <Grid
                container
                spacing={0}
                sx={{
                    minHeight: '100vh',
                    flexDirection: ['column', 'column', 'row'],
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid
                    item
                    container
                    xs
                    sx={{
                        alignSelf: ['center', 'center', 'auto'],
                        flexDirection: 'column',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <PetsIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Ingresar
                        </Typography>
                        <Box
                            component={'form'}
                            onSubmit={handleSubmit}
                            noValidate
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="color"
                                label="Email"
                                name="username"
                                autoFocus
                                autoComplete={'email'}
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                autoComplete={'password'}
                                id="password"
                                type={'password'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            <Button
                                disabled={isDisabled}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Aceptar
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </DAnimatedBox>
    );
}
