import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOrderProductionOrderProductionTypeInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IOrderProduction } from '../order-production-form';

export function OrderProductionTypeInput() {
    const { data } = useOrderProductionOrderProductionTypeInputQuery();
    const { trigger } = useFormContext();
    const INPUT_NAME: keyof IOrderProduction = 'order_production_type_id';
    const ARRAY_NAME: keyof IOrderProduction = 'order_production_products';

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Tipo de producción'}
            options={data?.getOrderProductionTypes || []}
            onChange={() => {
                void trigger(ARRAY_NAME);
            }}
        />
    );
}
