import { ValidationError } from 'yup';
import { IOrderSale } from '../order-sale-form';

export function isAccountRequired(
    value: any,
    context: any,
): ValidationError | boolean {
    const orderSale = context.from[0].value as IOrderSale;

    const account = orderSale.account;

    console.log(account);

    if (account && account.requires_order_request && !orderSale.order_request) {
        return context.createError({
            message: `El cliente seleccionado require que se seleccione un pedido.`,
        });
    }

    return true;
}
