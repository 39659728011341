import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteProductMutation } from '../../../../../../services';

interface IDeleteProductProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    productId: number | null;
}

export function SDeleteProductDialog({
    open,
    setOpen,
    productId,
}: IDeleteProductProps) {
    const [deleteMutation] = useDeleteProductMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (productId) {
                    try {
                        await deleteMutation({
                            variables: {
                                ProductId: productId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
