import * as React from 'react';
import {
    SPARE_TRANSACTION_PLURAL_NAME,
    SPARES_PLURAL_NAME,
} from '../constants';
import { isRouteGroup, Route, RouteItem } from '../types';
import {
    MachinesHomePage,
    SparesHomePage,
    SpareTransactionsHomePage,
} from './pages/maintenance';
import {
    EmployeesHomePage,
    OrderAdjustmentsHomePage,
    OrderProductionsHomePage,
    ProductInventory,
    ProductsHomePage,
    ProductsToProduce,
} from './pages/production';
import {
    GeneralSalesComparison,
    OrderRequestsHomePage,
    OrderSalesHomePage,
} from './pages/sales';
import { ProductionSummary } from './pages/production/production-summary/production-summary';
import { ActivitiesPage } from './pages/activities/activities';
import { UsersHomePage } from './pages/auth/users/users-home-page';
import { RoleId } from '../services';
import { TestingPage } from './pages/testing/testing-page';
import { SalesSummary } from './pages/sales/sales-summary/sales-summary';
import {
    AccountsHomePage,
    ExpensesHomePage,
    TransfersHomePage,
} from './pages/management';
import { ResourcesHomePage } from './pages/management/resources/resources-home-page';
import { BalancesPage } from './pages/management/balances/balances-page';
import { ExpensesSummary } from './pages/management/expenses-summary/expenses-summary';
import { RawMaterialAdditionHomePage } from './pages/management/raw-material-additions-home/raw-material-addition-home-page';

export const routeItems: RouteItem[] = [
    {
        title: 'Mantenimiento',
        navbar: true,
        routes: [
            {
                name: 'MachinesHome',
                path: '/machines',
                isNested: true,
                component: <MachinesHomePage />,
                title: 'Maquinas',
                navbar: true,
            },
            {
                name: 'SparesHome',
                path: '/spares',
                isNested: true,
                component: <SparesHomePage />,
                title: SPARES_PLURAL_NAME,
                navbar: true,
            },
            {
                name: 'SpareTransactionsHome',
                path: '/spareTransactions',
                isNested: true,
                component: <SpareTransactionsHomePage />,
                title: SPARE_TRANSACTION_PLURAL_NAME,
                navbar: true,
            },
        ],
    },
    {
        title: 'Produccion',
        navbar: true,
        routes: [
            {
                name: 'ProductsHome',
                path: '/products',
                isNested: true,
                component: <ProductsHomePage />,
                title: 'Productos',
                navbar: true,
                roleIds: [RoleId.Production],
            },
            {
                name: 'OrderProductionProductsHomePage',
                path: '/orderProductionProducts',
                isNested: true,
                component: <OrderProductionsHomePage />,
                title: 'Produccion',
                navbar: true,
                roleIds: [RoleId.Production],
            },
            {
                name: 'OrderAdjustmentsHomePage',
                path: '/orderAdjustments',
                isNested: true,
                component: <OrderAdjustmentsHomePage />,
                title: 'Ajustes',
                navbar: true,
                roleIds: [RoleId.Production],
            },
            {
                name: 'EmployeesHomePage',
                path: '/employees',
                isNested: true,
                component: <EmployeesHomePage />,
                title: 'Empleados',
                navbar: true,
                roleIds: [RoleId.Production],
            },

            {
                name: 'ProductInventoryPage',
                path: '/productInventory',
                isNested: false,
                component: <ProductInventory />,
                title: 'Inventario',
                navbar: true,
            },
            {
                name: 'ProductsToProducePage',
                path: '/productsToProduce',
                isNested: false,
                component: <ProductsToProduce />,
                title: 'Productos por producir',
                navbar: true,
            },
            {
                name: 'ProductionSummaryPage',
                path: '/productionSummary',
                isNested: false,
                component: <ProductionSummary />,
                title: 'Resumen de producción',
                navbar: true,
            },
        ],
    },
    {
        title: 'Ventas',
        navbar: true,
        routes: [
            {
                name: 'OrderRequestsHome',
                path: '/orderRequests',
                isNested: true,
                component: <OrderRequestsHomePage />,
                title: 'Pedidos',
                navbar: true,
            },
            {
                name: 'OrderSalesHome',
                path: '/orderSales',
                isNested: true,
                component: <OrderSalesHomePage />,
                title: 'Ventas',
                navbar: true,
            },
            {
                name: 'GeneralSalesComparison',
                path: '/generalSalesComparison',
                isNested: false,
                component: <GeneralSalesComparison />,
                title: 'Comparativo general',
                navbar: true,
            },
            {
                name: 'SalesSummary',
                path: '/salesSummary',
                component: <SalesSummary />,
                title: 'Resumen de ventas',
                navbar: true,
            },
        ],
    },
    {
        title: 'Administración',
        navbar: true,
        routes: [
            {
                name: 'TransfersHome',
                path: '/transfers',
                isNested: true,
                component: <TransfersHomePage />,
                title: 'Transferencias',
                navbar: true,
            },
            {
                name: 'ExpensesHome',
                path: '/expenses',
                isNested: true,
                component: <ExpensesHomePage />,
                title: 'Gastos',
                navbar: true,
            },
            {
                name: 'AccountsHome',
                path: '/accounts',
                isNested: true,
                component: <AccountsHomePage />,
                title: 'Cuentas',
                navbar: true,
            },
            {
                name: 'ResourcesHome',
                path: '/resources',
                isNested: true,
                component: <ResourcesHomePage />,
                title: 'Recursos',
                navbar: true,
            },
            {
                name: 'BalancesHome',
                path: '/balances',
                isNested: false,
                component: <BalancesPage />,
                title: 'Saldos y pagos',
                navbar: true,
            },
            {
                name: 'ExpenseSummaryHome',
                path: '/expenseSummary',
                isNested: false,
                component: <ExpensesSummary />,
                title: 'Contabilidad',
                navbar: true,
            },
            {
                name: 'RawMaterialAdditionHomePage',
                path: '/rawMaterialAdditions',
                isNested: true,
                component: <RawMaterialAdditionHomePage />,
                title: 'Entrega de almacén',
                navbar: true,
            },
        ],
    },
    {
        title: 'Usuarios',
        path: '/admin',
        name: 'UsersHomePage',
        navbar: true,
        roleIds: [RoleId.Super],
        component: <UsersHomePage />,
        isNested: true,
    },
    {
        name: 'TestingPage',
        path: '/testing',
        component: <TestingPage />,
        title: 'Testing',
        navbar: false,
    },
    {
        name: 'ActivitiesPage',
        path: '/activities',
        component: <ActivitiesPage />,
        title: 'Actividades',
        navbar: false,
    },
];

export const pages: Route[] = routeItems.reduce((acc, curr) => {
    if (isRouteGroup(curr)) {
        const routes = curr.routes;
        return acc.concat(routes);
    }
    acc.push(curr);
    return acc;
}, [] as Route[]);
