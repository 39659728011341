import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomThemeProvider } from './custom-theme-provider';
import { SnackbarProvider } from 'notistack';
import { store } from '../../store';
import es from 'dayjs/locale/es';

interface ProvidersProps {
    children: React.ReactNode[] | React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps) => {
    return (
        <StoreProvider store={store}>
            <Router>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={es}>
                    <CustomThemeProvider>
                        <SnackbarProvider
                            maxSnack={6}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                        >
                            {children}
                        </SnackbarProvider>
                    </CustomThemeProvider>
                </LocalizationProvider>
            </Router>
        </StoreProvider>
    );
};
