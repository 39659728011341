import React from 'react';
import { useCurrentUserQuery } from '../../../services';
import DFullScreenLoader from '../../dum/simple/loader/d-full-screen-loader';
import LoginForm from './login-form';

interface AuthorizationWrapperProps {
    children: React.ReactElement<any, any> | null;
}

export const AuthorizationWrapper = (props: AuthorizationWrapperProps) => {
    const { loading: currentUserLoading, data } = useCurrentUserQuery({
        notifyOnNetworkStatusChange: true,
    });

    return currentUserLoading ? (
        <DFullScreenLoader />
    ) : !data?.currentUser ? (
        <LoginForm />
    ) : (
        props.children
    );
};
