import React, { useMemo } from 'react';
import {
    ProductsToProducePageQuery,
    useProductsToProducePageQuery,
} from '../../../../services';
import { DPage, DPageBody, DPageHeader, DPageLoader } from '../../../dum';
import { ProductsToProduceByProduct } from './blocks/product-to-produce-by-product';
import { ProductsToProduceByWidthCalibreCategory } from './blocks/product-to-produce-by-width-calibre-category';
import { ProductsToProduceByProductCategory } from './blocks/product-to-produce-by-product-category';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { useBoolean } from '../../../../helpers';

type OptimizedRequestProducts = (NonNullable<
    NonNullable<ProductsToProducePageQuery['getOptimizedRequestProducts']>
>[number] & {
    product: ProductsToProducePageQuery['getProductsInventory'][number]['product'];
})[];

export type ProductToProduce = {
    product_id: number | null;
    product: ProductsToProducePageQuery['getProductsInventory'][number]['product'];
    kilos_in_inventory: number;
    kilos_to_produce: number;
    kilos_requested: number;
    kilos_sold: number;
    groups_in_inventory: number;
    groups_to_produce: number;
    groups_requested: number;
    groups_sold: number;
    optimized_request_products: OptimizedRequestProducts;
};

export function ProductsToProduce() {
    const { data, loading } = useProductsToProducePageQuery();

    const [isGroups, setIsGroups] = useBoolean({
        KEY: ProductsToProduce.name + 'IsGroups',
        defaultValue: false,
    });

    const [includePending, setIncludePending] = useBoolean({
        KEY: ProductsToProduce.name + 'IncludePending',
        defaultValue: true,
    });

    const productsToProduce = useMemo<ProductToProduce[]>(() => {
        const temporaryProductsToProduce: ProductToProduce[] = [];

        if (data?.getProductsInventory && data?.getOptimizedRequestProducts) {
            data?.getProductsInventory.forEach((productInventory) => {
                const productToProduce: ProductToProduce = {
                    product: productInventory.product,
                    product_id: productInventory.product?.id || null,
                    kilos_in_inventory: productInventory.kilos || 0,
                    kilos_requested: 0,
                    kilos_sold: 0,
                    kilos_to_produce: productInventory.kilos
                        ? -productInventory.kilos
                        : 0,
                    groups_in_inventory: productInventory.groups || 0,
                    groups_requested: 0,
                    groups_to_produce: productInventory.groups
                        ? -productInventory.groups
                        : 0,
                    groups_sold: 0,
                    optimized_request_products: [],
                };

                data?.getOptimizedRequestProducts.forEach((requestProduct) => {
                    if (
                        requestProduct.product_id ===
                        productInventory.product?.id
                    ) {
                        productToProduce.kilos_requested +=
                            requestProduct.order_request_kilos || 0;
                        productToProduce.groups_requested +=
                            requestProduct.order_request_groups || 0;
                        productToProduce.optimized_request_products.push({
                            ...requestProduct,
                            product: productInventory.product,
                        });
                        productToProduce.groups_to_produce +=
                            (requestProduct.order_request_groups || 0) -
                            (requestProduct.order_sale_delivered_groups || 0);

                        productToProduce.kilos_to_produce +=
                            (requestProduct.order_request_kilos || 0) -
                            (requestProduct.order_sale_delivered_kilos || 0);

                        productToProduce.kilos_sold +=
                            requestProduct.order_sale_delivered_kilos || 0;
                        productToProduce.groups_sold +=
                            requestProduct.order_sale_delivered_groups || 0;

                        if (
                            requestProduct.order_request_status_id === 1 &&
                            !includePending
                        ) {
                            productToProduce.kilos_requested -=
                                requestProduct.order_request_kilos || 0;
                            productToProduce.groups_requested -=
                                requestProduct.order_request_groups || 0;

                            productToProduce.groups_to_produce -=
                                requestProduct.order_request_groups || 0;
                            productToProduce.kilos_to_produce -=
                                requestProduct.order_request_kilos || 0;
                        }
                    }
                });

                temporaryProductsToProduce.push(productToProduce);
            });
        }

        return temporaryProductsToProduce;
    }, [data, includePending]);

    return (
        <DPage>
            <DPageHeader title={'Productos por producir'}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl>
                        <FormControlLabel
                            labelPlacement={'end'}
                            control={
                                <Switch
                                    onChange={() => {
                                        setIsGroups(!isGroups);
                                    }}
                                    checked={isGroups}
                                />
                            }
                            label={isGroups ? 'Kilos' : 'Bultos'}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    onChange={() => {
                                        setIncludePending(!includePending);
                                    }}
                                    checked={includePending}
                                />
                            }
                            label={'Incluir pedidos pendientes'}
                        />
                    </FormControl>
                </Box>
            </DPageHeader>
            <DPageBody disableGutters>
                {loading ? (
                    <DPageLoader />
                ) : (
                    <>
                        <ProductsToProduceByProduct
                            productsToProduce={productsToProduce}
                            useGroups={!isGroups}
                        />
                        <ProductsToProduceByWidthCalibreCategory
                            productsToProduce={productsToProduce}
                            useGroups={!isGroups}
                        />
                        <ProductsToProduceByProductCategory
                            productsToProduce={productsToProduce}
                            useGroups={!isGroups}
                        />
                    </>
                )}
            </DPageBody>
        </DPage>
    );
}
