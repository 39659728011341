import React, { DependencyList, useEffect, useState } from 'react';
import { Box, Pagination } from '@mui/material';
import { getNumber } from '../../../../helpers';

interface IUsePaginator {
    KEY: string;
}

export const usePaginator = function (
    { KEY }: IUsePaginator,
    deps: DependencyList = [],
) {
    const [take] = useState(KEY ? getNumber(`${KEY}_TAKE_KEY`) || 10 : 10);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        setSkip(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [KEY, ...deps]);

    return {
        take,
        skip,
        setSkip,
    };
};

interface ICustomPaginatorProps {
    count?: number;
    skip: number;
    setSkip: (skip: number) => void;
    take: number;
    disableGutters?: boolean;
}

export function DPaginator({
    count = 0,
    take,
    skip,
    setSkip,
    disableGutters = false,
}: ICustomPaginatorProps) {
    const pages = Math.floor(count / take) + (count % take === 0 ? 0 : 1);
    const currentPage = Math.floor(skip / take) + 1;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: !disableGutters ? 2 : 0,
            }}
        >
            <Pagination
                count={pages}
                page={currentPage}
                onChange={(
                    event: React.ChangeEvent<unknown>,
                    value: number,
                ) => {
                    event.preventDefault();
                    setSkip((value - 1) * take);
                }}
            />
        </Box>
    );
}
