import React from 'react';
import { DModal } from './d-modal';
import { Box, Button, Typography } from '@mui/material';

interface ICustomConfirmationModal {
    open: boolean;
    setOpen: (open: boolean) => void;
    callback: (() => void) | (() => Promise<void>);
}

export function DConfirmationModal({
    open,
    setOpen,
    callback,
}: ICustomConfirmationModal) {
    return (
        <DModal
            open={open}
            setOpen={setOpen}
            title={'Confirmacion'}
            height={'10rem'}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'strech',
                }}
            >
                <Typography>
                    ¿Estas seguro que quieres borrar esta entidad?
                </Typography>
                <Box sx={{ flex: 1 }} />
                <Button
                    sx={{ alignSelf: 'flex-end' }}
                    variant={'outlined'}
                    type={'submit'}
                    onClick={callback}
                >
                    Confirmar
                </Button>
            </Box>
        </DModal>
    );
}
