import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    EXPENSE_RAW_MATERIAL_ADDITIONS,
    EXPENSE_RAW_MATERIAL_ADDITIONS_TOTAL,
    IExpense,
} from './expense-form';
import { isNumber, round } from '../../../../../../helpers';

export function useCalculateRawMaterialAdditionTotal() {
    const rx = useFormContext();
    const calculateRawMaterialAdditionTotal = useMemo(() => {
        return () => {
            const expenseRawMaterialAdditions = rx.getValues(
                EXPENSE_RAW_MATERIAL_ADDITIONS,
            ) as IExpense['expense_raw_material_additions'];
            const subtotal = expenseRawMaterialAdditions.reduce(
                (acc, resource) => {
                    return (
                        acc +
                        (isNumber(Number(resource.amount))
                            ? Number(resource.amount)
                            : 0)
                    );
                },
                0,
            );

            const total = round(subtotal);
            rx.setValue(EXPENSE_RAW_MATERIAL_ADDITIONS_TOTAL, total);
        };
    }, [rx]);
    return { calculateRawMaterialAdditionTotal };
}
