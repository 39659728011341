import { ApolloClient, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import apiUrl from '../../constants/api-url';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
import { store } from '../../app/store';
import { pushMessage } from '../../features/global-messages/global-messages-slice';

// const defaultOptions = {
//     watchQuery: {
//         fetchPolicy: 'no-cache',
//         errorPolicy: 'ignore',
//     },
//     query: {
//         fetchPolicy: 'no-cache',
//         errorPolicy: 'all',
//     },
// }

const httpProtocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
let httpLink = createUploadLink({
    uri: `${httpProtocol}://${apiUrl}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
    const token = localStorage.getItem('token');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        },
    };
});

const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                const error = err as unknown as {
                    extensions?: {
                        code: string;
                    };
                    message: string | string[];
                };

                if (
                    error.extensions?.code.toLowerCase() ===
                        'unauthenticated' ||
                    (typeof error.message === 'string' &&
                        err.message.toLowerCase() === 'unauthorized')
                ) {
                    if (window.localStorage.getItem('token')) {
                        const message = 'Sesión expirada';
                        store.dispatch(
                            pushMessage({
                                message,
                                options: { variant: 'error' },
                            }) as any,
                        );
                        window.localStorage.removeItem('token');
                        const oldHeaders = operation.getContext().headers;
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: null,
                            },
                        });

                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                    return forward(operation);
                } else {
                    if (Array.isArray(error.message)) {
                        error.message.forEach((message) => {
                            store.dispatch(
                                pushMessage({
                                    message,
                                    options: { variant: 'error' },
                                }),
                            );
                        });
                    } else {
                        store.dispatch(
                            pushMessage({
                                message: err.message,
                                options: { variant: 'error' },
                            }),
                        );
                    }
                }
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) {
            networkError.message = 'Connection error, try again later.';
        }
    },
);

const webSocketProtocol = process.env.NODE_ENV === 'development' ? 'ws' : 'wss';

const wsClient = createClient({
    url: `${webSocketProtocol}://${apiUrl}/graphql`,
    lazy: true,
    connectionParams: () => {
        const token = window.localStorage.getItem('token');
        return {
            isWebSocket: true,
            authorization: token ? `Bearer ${token}` : null,
        };
    },
});

const wsLink = new GraphQLWsLink(wsClient);

// const linkMiddleware = new ApolloLink((operation, forward) => {
//     return forward(operation);
// })

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    from([authLink, errorLink, httpLink]),
);

export class ApolloClientWs extends ApolloClient<any> {
    constructor(props: any) {
        super(props);

        this.dispose = this.dispose.bind(this);
    }

    async dispose() {
        // await wsClient.terminate();
    }
}

export const apolloClient = new ApolloClientWs({
    link: splitLink,
    cache: new InMemoryCache(),
});
