import React, { useEffect, useMemo, useState } from 'react';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPaginator,
    DTextField,
    DYear,
    EditIcon,
    SortedOrder,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../../dum';
import { Box, IconButton } from '@mui/material';
import {
    ColumnOrder,
    OrderRequestsPageQuery,
    OrderRequestsSortableFields,
    useOrderRequestsPageLazyQuery,
} from '../../../../../../services';
import {
    SDeleteOrderRequestDialog,
    SUpsertOrderRequestDialog,
} from '../../../../../smart';
import {
    formatDate,
    formatFloat,
    useBoolean,
    useNumber,
    useString,
} from '../../../../../../helpers';
import { DCheckbox } from '../../../../../dum/simple/inputs/d-checkbox';

export function OrderRequestsListBlock() {
    const [query, { data, previousData }] = useOrderRequestsPageLazyQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [orderRequestId, setOrderRequestId] = useState<null | number>(null);

    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [orderRequestStatusId, setOrderRequestStatusId] = useNumber({
        KEY: OrderRequestsListBlock.name + '_OrderRequestStatusId',
    });

    const [accountId, setAccountId] = useNumber({
        KEY: OrderRequestsListBlock.name + '_AccountId',
    });

    const [filter, setFilter] = useString({
        KEY: OrderRequestsListBlock.name + '_Filter',
    });

    const { year, setYear } = useDYear({
        COMPONENT_KEY: OrderRequestsListBlock.name + '_Year',
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: OrderRequestsListBlock.name + '_Month',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: OrderRequestsListBlock.name + '_Paginator',
        },
        [month, year, filter, accountId, orderRequestStatusId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                accountId: accountId,
                filter: filter,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
                sortField:
                    sortedIndex === 0
                        ? OrderRequestsSortableFields.OrderCode
                        : sortedIndex === 1
                        ? OrderRequestsSortableFields.EstimatedDeliveryDate
                        : null,
                orderRequestStatusId: orderRequestStatusId,
            },
        });
    }, [
        query,
        skip,
        take,
        year,
        month,
        filter,
        sortedOrder,
        sortedIndex,
        accountId,
        orderRequestStatusId,
    ]);

    const [defaultCollapseOpen, setDefaultCollapseOpen] = useBoolean({
        KEY: OrderRequestsListBlock.name + '_DefaultOpen',
        defaultValue: false,
    });

    const count =
        data && data?.paginatedOrderRequests.count >= 0
            ? data?.paginatedOrderRequests.count
            : previousData?.paginatedOrderRequests.count;

    const orderRequests: OrderRequestsPageQuery['paginatedOrderRequests']['docs'] =
        data?.paginatedOrderRequests.docs ||
        previousData?.paginatedOrderRequests.docs ||
        [];

    const handleEditOrderRequest = useMemo(() => {
        return (
            orderRequest: OrderRequestsPageQuery['paginatedOrderRequests']['docs'][number],
        ) => {
            setOrderRequestId(orderRequest.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteOrderRequest = useMemo(() => {
        return (
            orderRequest: OrderRequestsPageQuery['paginatedOrderRequests']['docs'][number],
        ) => {
            setOrderRequestId(orderRequest.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DBlock>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <DTextField
                        label={'Filtro'}
                        helperText={
                            'Filtro para el folio del pedido o las notas del pedido'
                        }
                        value={filter}
                        onChange={(val) => {
                            setFilter(val);
                        }}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <CVerticalDropdown
                        items={[
                            {
                                component: (
                                    <DYear setYear={setYear} year={year} />
                                ),
                            },
                            {
                                component: (
                                    <DMonth setMonth={setMonth} month={month} />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={accountId}
                                        onChange={setAccountId}
                                        itemText={'name'}
                                        label={'Cliente'}
                                        options={data?.getAccounts || []}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={orderRequestStatusId}
                                        onChange={setOrderRequestStatusId}
                                        itemText={'name'}
                                        label={'Estatus del pedido'}
                                        options={
                                            data?.getOrderRequestStatuses || []
                                        }
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        onChange={(val) => {
                                            setDefaultCollapseOpen(val);
                                        }}
                                        value={defaultCollapseOpen}
                                        label={'Abrir filas'}
                                        disableGutters
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>

            <DPaginator
                skip={skip}
                setSkip={setSkip}
                take={take}
                count={count}
            />

            <CTableWrapper
                onClick={(orderRequest) => {
                    handleEditOrderRequest(orderRequest);
                }}
                contextItems={[
                    {
                        title: 'Editar',
                        disable: (orderRequest) => {
                            return !orderRequest?.is_editable;
                        },
                        callback: (orderRequest) => {
                            handleEditOrderRequest(orderRequest);
                        },
                    },
                    {
                        title: 'Borrar',
                        disable: (orderRequest) => {
                            return !orderRequest?.is_deletable;
                        },
                        callback: (orderRequest) => {
                            handleDeleteOrderRequest(orderRequest);
                        },
                    },
                ]}
                sortedIndex={sortedIndex}
                sortedOrder={sortedOrder}
                setSortedIndex={setSortedIndex}
                setSortedOrder={setSortedOrder}
                defaultOpenCollapse={defaultCollapseOpen}
                items={[
                    {
                        cellGrow: 4,
                        title: 'Codigo',
                        textAlign: 'right',
                        sortable: true,
                        content: (orderRequest) => {
                            return orderRequest.order_code;
                        },
                    },
                    {
                        cellGrow: 4,
                        title: 'Fecha estimada de entrega',
                        sortable: true,
                        textAlign: 'center',
                        content: (orderRequest) => {
                            return formatDate(
                                orderRequest.estimated_delivery_date,
                            );
                        },
                    },
                    {
                        title: 'Estatus',
                        content: (orderRequest) => {
                            return orderRequest.order_request_status?.name;
                        },
                    },
                    {
                        cellGrow: 4,
                        title: 'Cliente',
                        content: (orderRequest) => {
                            return orderRequest.account?.name;
                        },
                    },
                    {
                        cellGrow: 4,
                        title: 'Notas',
                        content: (orderRequest) => {
                            return orderRequest.notes;
                        },
                    },
                    {
                        title: 'Acciones',
                        content: (orderRequest) => {
                            return (
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        disabled={!orderRequest.is_editable}
                                        onClick={() => {
                                            handleEditOrderRequest(
                                                orderRequest,
                                            );
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!orderRequest.is_deletable}
                                        onClick={() => {
                                            handleDeleteOrderRequest(
                                                orderRequest,
                                            );
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
                data={orderRequests}
                collapse={(orderRequest) => {
                    const products = orderRequest.order_request_products?.map(
                        (orp) => {
                            const foundProductSold =
                                orderRequest.order_sale_sold_products?.find(
                                    (ossp) => {
                                        return (
                                            ossp.product_id === orp.product_id
                                        );
                                    },
                                );
                            return {
                                ...orp,
                                kilos_sold: foundProductSold
                                    ? foundProductSold.kilos
                                    : 0,
                                groups_sold: foundProductSold
                                    ? foundProductSold.groups
                                    : 0,
                                total_sold: foundProductSold
                                    ? foundProductSold.kilos *
                                      foundProductSold.kilo_price
                                    : 0,
                            };
                        },
                    );

                    return (
                        <CTableWrapper
                            padding={false}
                            items={[
                                {
                                    title: 'Producto',
                                    cellGrow: 45,
                                    content: (osp) =>
                                        osp.product?.external_description,
                                },
                                {
                                    title: 'Bultos',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) => formatFloat(osp.groups),
                                },
                                {
                                    title: 'Kilos',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) => formatFloat(osp.kilos),
                                },
                                {
                                    title: 'total',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) =>
                                        formatFloat(osp.kilos * osp.kilo_price),
                                },
                                {
                                    title: 'Bultos vendidos',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) =>
                                        formatFloat(osp.groups_sold),
                                },
                                {
                                    title: 'Kilos vendidos',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) =>
                                        formatFloat(osp.kilos_sold),
                                },
                                {
                                    title: 'Total vendido',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) =>
                                        formatFloat(osp.total_sold),
                                },
                            ]}
                            data={products}
                        />
                    );
                }}
            />
            <SUpsertOrderRequestDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                orderRequestId={orderRequestId}
            />
            <SDeleteOrderRequestDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                orderRequestId={orderRequestId}
            />
        </DBlock>
    );
}
