import { NavigateFunction } from 'react-router-dom';
import { getActivitiesPagePath } from './get-route-path';

export function navigateToActivitiesPage({
    navigate,
    replace = false,
}: {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getActivitiesPagePath(), {
        replace,
    });
}
