import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../dum';
import { EXPENSE_RAW_MATERIAL_ADDITIONS, IExpense } from '../expense-form';
import { RawMaterialAdditionInput } from './raw-material-addition/raw-material-addition-input';
import { useCalculateRawMaterialAdditionTotal } from '../calculate-raw-material-additions-total';

const defaultValue: IExpense['expense_raw_material_additions'][number] = {
    amount: 0,
    raw_material_addition_id: null,
};

const AMOUNT: keyof IExpense['expense_raw_material_additions'][number] =
    'amount';

export function ExpenseRawMaterialAdditionsColumns() {
    const { calculateRawMaterialAdditionTotal } =
        useCalculateRawMaterialAdditionTotal();

    return (
        <RhColumns
            title={'Elementos'}
            name={EXPENSE_RAW_MATERIAL_ADDITIONS}
            defaultValue={defaultValue}
            items={[
                {
                    cellGrow: 2,
                    getField: (index) => {
                        return <RawMaterialAdditionInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${EXPENSE_RAW_MATERIAL_ADDITIONS}.${index}.${AMOUNT}`}
                                label={'Cantidad'}
                                disableGutters
                                onChange={() => {
                                    calculateRawMaterialAdditionTotal();
                                }}
                            />
                        );
                    },
                },
            ]}
        />
    );
}
