import React from 'react';
import { Box, styled, Tab, Tabs, tabsClasses } from '@mui/material';

interface ICustomTabsProps {
    items: TCustomTab[];
}

export type TCustomTab = {
    title: string;
    content: React.FunctionComponent<any>;
    isError?: boolean;
};

export function DTabs(props: ICustomTabsProps) {
    const { items } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                autoFocus={false}
                aria-label="visible arrows tabs example"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                {items.map((tab, index) => {
                    return (
                        <StyledTab
                            key={index}
                            label={tab.title}
                            isError={tab.isError}
                        />
                    );
                })}
            </Tabs>

            <Box
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {items.map((tab, index) => {
                    const Content = tab.content;
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            <Content />
                        </TabPanel>
                    );
                })}
            </Box>
        </>
    );
}

interface TabPanelProps {
    children: React.ReactNode | React.ReactNode[];
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return value === index ? (
        <Box sx={{ p: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
            {children}
        </Box>
    ) : null;
}

const StyledTab = styled(Tab, {
    shouldForwardProp(propName: string) {
        return propName !== 'isError';
    },
})<Omit<TCustomTab, 'content' | 'title'>>(({ theme, isError }) => ({
    '&.Mui-selected': {
        color: isError ? theme.palette.error.main : undefined,
    },
    '&.MuiTab-root': {
        color: isError ? theme.palette.error.main : undefined,
    },
}));
