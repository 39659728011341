import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage } from '../../../dum';
import { SUpsertResourceDialog } from '../../../smart';
import { ResourcesPage } from './resources/resources-page';
import { DFab } from '../../../dum/simple/button/d-fab';

const pages: Route[] = [
    {
        name: 'ResourcesPage',
        path: '/',
        component: <ResourcesPage />,
        title: 'Recursos',
        navbar: false,
    },
];

export function ResourcesHomePage() {
    const [resourceUpsertDialog, setResourceUpsertDialog] = useState(false);

    return (
        <DPage>
            <DFab
                onClick={() => {
                    setResourceUpsertDialog(true);
                }}
            />
            <ReactRouterRoutes>
                {pages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertResourceDialog
                open={resourceUpsertDialog}
                setOpen={setResourceUpsertDialog}
            />
        </DPage>
    );
}
