import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../dum';
import { IOrderAdjustment } from '../../order-adjustment-form';

const ARRAY_NAME: keyof IOrderAdjustment = 'order_adjustment_products';
const GROUPS_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'groups';
const KILOS_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'kilos';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'group_weight';

interface IGroupsInputProps {
    index: number;
}

export function GroupsInput({ index }: IGroupsInputProps) {
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${GROUPS_FIELD_NAME}`;
    const groupWeightValue = useWatch({
        name: `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
    });

    const formContext = useFormContext();

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'Grupos'}
            disableGutters
            onChange={(val) => {
                if (val && groupWeightValue) {
                    formContext.setValue(
                        `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`,
                        val * groupWeightValue,
                    );
                }
            }}
        />
    );
}
