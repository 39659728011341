import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { RhDefaultProps } from '../common/rh-default-props';
import { useRhCustomContext } from '../rh-form-wrapper';

interface IRhCheckboxProps extends RhDefaultProps {
    uncheckedIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    checkedIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    align?: 'left' | 'right';
}

export const RhCheckbox = ({
    name,
    label,
    checkedIcon,
    uncheckedIcon,
    align = 'left',
    helperText,
    disableGutters = false,
    disabled = false,
    onChange,
}: IRhCheckboxProps) => {
    const CheckedIcon = checkedIcon || CheckBoxIcon;
    const UncheckedIcon = uncheckedIcon || CheckBoxOutlineBlankIcon;

    const { control } = useFormContext();

    const { disabled: disabledFromContext } = useRhCustomContext();

    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <FormGroup
            sx={{
                display: 'flex',
                justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
                flexDirection: 'column',
                mb: !disableGutters ? 1 : 0,
            }}
        >
            <FormControl
                size={'small'}
                margin={'none'}
                disabled={disabledFromContext || disabled}
            >
                <FormControlLabel
                    inputRef={field.ref}
                    checked={field.value}
                    disabled={disabledFromContext || disabled}
                    control={
                        <Checkbox
                            disabled={disabledFromContext || disabled}
                            color={
                                Boolean(fieldState.error) ? 'error' : undefined
                            }
                            sx={{ py: 1 }}
                            checkedIcon={<CheckedIcon />}
                            icon={<UncheckedIcon />}
                        />
                    }
                    label={label || ''}
                    onChange={(e, checked) => {
                        field.onChange(checked);
                        if (onChange) {
                            onChange(checked);
                        }
                    }}
                    onBlur={field.onBlur}
                    value={field.value}
                />
                <FormHelperText sx={{ my: 0 }} error>
                    {fieldState.error?.message || helperText}
                </FormHelperText>
            </FormControl>
        </FormGroup>
    );
};
