import React from 'react';
import { IUser } from '../user-form';
import { useUserRolesQuery } from '../../../../../../../services';
import { RhMultipleAutocomplete } from '../../../../../../dum';

export function RolesInput() {
    const name: keyof IUser = 'roles';

    const { data } = useUserRolesQuery();

    const roles = data?.getRoles || [];

    return (
        <RhMultipleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            multipleValue={'id'}
            name={name}
            label={'Roles'}
            options={roles}
        />
    );
}
