import { Box } from '@mui/material';
import React from 'react';
import { ParentComponent } from './parent-component';

export function TestingPage() {
    return (
        <Box>
            <ParentComponent />
            <ParentComponent />
            <ParentComponent />
            <ParentComponent />
            <ParentComponent />
            <ParentComponent />
            <ParentComponent />
        </Box>
    );
}
