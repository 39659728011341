import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RHTextField } from '../../../../../../../dum';
import { IOrderProduction } from '../../order-production-form';

const ARRAY_NAME: keyof IOrderProduction = 'order_production_products';
const GROUPS_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'groups';
const KILOS_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'kilos';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'group_weight';

interface IGroupsInputProps {
    index: number;
}

export function GroupsInput({ index }: IGroupsInputProps) {
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${GROUPS_FIELD_NAME}`;

    const formContext = useFormContext();

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'Grupos'}
            disableGutters
            onChange={(inputValue) => {
                const groupWeightValue = formContext.getValues(
                    `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
                ) as IOrderProduction['order_production_products'][number]['group_weight'];

                if (inputValue && groupWeightValue) {
                    formContext.setValue(
                        `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`,
                        inputValue * groupWeightValue,
                    );
                }
            }}
        />
    );
}
