import * as React from 'react';
import { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './app.css';

// mui
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { pages } from './pages';
import { InopackDrawer } from './app/inopack-drawer/inopack-drawer';
import { NotFoundPage } from './pages/not-found/not-found-page';
import { DAppContent } from './dum';
import { useCurrentUserQuery } from '../services';
import { routeAuthFilter } from '../types';
import { InopackAppBar } from './app/inopack-app-bar/inopack-app-bar';

export default function App() {
    const theme = useTheme();
    const mdAndUp = useMediaQuery(theme.breakpoints.up('lg'));

    const [open, setOpen] = React.useState(false);

    const { data } = useCurrentUserQuery();

    const currentUser = data?.currentUser;

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const routeFilter = useMemo(() => {
        return currentUser ? routeAuthFilter(currentUser) : () => {};
    }, [currentUser]);

    return (
        <Box sx={{ display: 'flex' }}>
            {!mdAndUp ? <InopackAppBar toggleDrawer={toggleDrawer} /> : null}

            <InopackDrawer
                open={open}
                setOpen={setOpen}
                toggleDrawer={toggleDrawer}
                isDesktop={mdAndUp}
            />
            <DAppContent isDesktop={mdAndUp}>
                <Routes>
                    {pages
                        .filter(routeFilter)
                        .map(({ name, path, isNested, component: Elem }) => {
                            return (
                                <Route
                                    element={Elem}
                                    key={name}
                                    path={`${path}${isNested ? '/*' : ''}`}
                                />
                            );
                        })}
                    <Route
                        path="/"
                        element={<Navigate to="/machines" replace />}
                    />
                    <Route path={'*'} element={<NotFoundPage />} />
                </Routes>
            </DAppContent>
        </Box>
    );
}
