import React, { useMemo } from 'react';
import { GeneralSalesComparisonQuery } from '../../../../../services';
import {
    DBlock,
    DBlockHeader,
    DTable,
    DTableBody,
    DTableBodyCell,
    DTableBodyRow,
    DTableHead,
    DTableHeadCell,
    DTableHeadRow,
} from '../../../../dum';
import { colors, formatFloat } from '../../../../../helpers';
import { AccountPieChart } from './account-comparison/account-pie-chart';
import { Badge, Box } from '@mui/material';

interface IAccountComparison {
    data: GeneralSalesComparisonQuery | undefined;
    date1: string;
    date2: string;
}

type TAccount = NonNullable<GeneralSalesComparisonQuery>['getAccounts'][number];

type TAccountAnalysis = {
    date1Kilos: number;
    date1Total: number;
    date2Kilos: number;
    date2Total: number;
};

type TAccountInformation = TAccount & TAccountAnalysis;

export function AccountComparison({ data, date1, date2 }: IAccountComparison) {
    const { accounts, pieData1, pieData2 } = useMemo(() => {
        const dictionary: Record<any, any> = {};
        data?.getDate1AccountSalesSummary.sales.forEach((item) => {
            if (item.account_id && !dictionary[item.account_id]) {
                dictionary[item.account_id] = {
                    date1Kilos: item.kilos_sold,
                    date2Kilos: 0,
                    date1Total: item.total_with_tax,
                };
            } else if (item.account_id && dictionary[item.account_id]) {
                dictionary[item.account_id] = {
                    ...dictionary[item.account_id],
                    date1Kilos: item.kilos_sold,
                    date1Total: item.total_with_tax,
                };
            }
        });

        data?.getDate2AccountSalesSummary.sales.forEach((item) => {
            if (item.account_id && !dictionary[item.account_id]) {
                dictionary[item.account_id] = {
                    date2Kilos: item.kilos_sold,
                    date2Total: item.total_with_tax,
                    date1Kilos: 0,
                };
            } else if (item.account_id && dictionary[item.account_id]) {
                dictionary[item.account_id] = {
                    ...dictionary[item.account_id],
                    date2Kilos: item.kilos_sold,
                    date2Total: item.total_with_tax,
                };
            }
        });

        let accounts: TAccountInformation[] =
            data?.getAccounts.map((account) => {
                if (!dictionary[account.id]) {
                    return {
                        ...account,
                        date1Kilos: 0,
                        date2Kilos: 0,
                        date1Total: 0,
                        date2Total: 0,
                    };
                }
                const dictionaryItem = dictionary[account.id];

                return {
                    ...account,
                    ...dictionaryItem,
                };
            }) || [];

        accounts.sort((a, b) => {
            return b.date2Kilos - a.date2Kilos;
        });

        const reducedAccounts = accounts.reduce((acc, curr, index) => {
            // if (index > 9) {
            //     const last = acc[9];
            //     acc[9] = {
            //         ...acc[9],
            //         name: 'Otros',
            //         abbreviation: 'Otros',
            //         date1Kilos: last.date1Kilos + curr.date1Kilos,
            //         date2Kilos: last.date2Kilos + curr.date2Kilos,
            //     };
            // } else {
            //     acc.push(curr);
            // }

            acc.push(curr);

            return acc;
        }, [] as TAccountInformation[]);

        const pieData1: { name: string; value: number }[] = reducedAccounts.map(
            (account) => {
                return {
                    name: account.abbreviation,
                    value: account.date1Kilos,
                };
            },
        );

        const pieData2: { name: string; value: number }[] = reducedAccounts.map(
            (account) => {
                return {
                    name: account.abbreviation,
                    value: account.date2Kilos,
                };
            },
        );

        return {
            accounts: reducedAccounts || [],
            pieData1,
            pieData2,
        };
    }, [data]);

    return (
        <DBlock>
            <DBlockHeader subtitle={'Clientes'} />
            <DTable>
                <DTableHead>
                    <DTableHeadRow>
                        <DTableHeadCell width={30}>Cliente</DTableHeadCell>
                        <DTableHeadCell width={10}>Color</DTableHeadCell>
                        <DTableHeadCell width={20}>
                            {date1} Kilos
                        </DTableHeadCell>
                        <DTableHeadCell width={20}>{date1} $</DTableHeadCell>
                        <DTableHeadCell width={20}>
                            {date2} Kilos
                        </DTableHeadCell>
                        <DTableHeadCell width={20}>{date2} $</DTableHeadCell>
                        <DTableHeadCell width={20}>Diferencia</DTableHeadCell>
                    </DTableHeadRow>
                </DTableHead>
                <DTableBody>
                    {accounts.map((item, index) => {
                        return (
                            <DTableBodyRow key={item.id}>
                                <DTableBodyCell>
                                    {item.compound_name}
                                </DTableBodyCell>
                                <DTableBodyCell textAlign={'center'}>
                                    <Badge
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                color: colors[
                                                    index % colors.length
                                                ],
                                                backgroundColor:
                                                    colors[
                                                        index % colors.length
                                                    ],
                                            },
                                        }}
                                        badgeContent=" "
                                    />
                                </DTableBodyCell>
                                <DTableBodyCell textAlign={'right'}>
                                    {formatFloat(item.date1Kilos)} kg
                                </DTableBodyCell>
                                <DTableBodyCell textAlign={'right'}>
                                    ${formatFloat(item.date1Total)}
                                </DTableBodyCell>
                                <DTableBodyCell textAlign={'right'}>
                                    {formatFloat(item.date2Kilos)} kg
                                </DTableBodyCell>
                                <DTableBodyCell textAlign={'right'}>
                                    ${formatFloat(item.date2Total)}
                                </DTableBodyCell>
                                <DTableBodyCell textAlign={'right'}>
                                    {formatFloat(
                                        item.date2Kilos - item.date1Kilos,
                                    )}{' '}
                                    kg
                                </DTableBodyCell>
                            </DTableBodyRow>
                        );
                    })}
                </DTableBody>
            </DTable>
            <Box sx={{ display: 'flex' }}>
                <AccountPieChart pieData={pieData1} title={'Fecha 1'} />
                <AccountPieChart pieData={pieData2} title={'Fecha 2'} />
            </Box>
        </DBlock>
    );
}
