import React, { useMemo, useState } from 'react';
import { ProductInventoryPageQuery } from '../../../../../services';
import {
    Continued,
    CTableWrapper,
    DBlock,
    DBlockHeader,
    Discontinued,
    DTextField,
    EditIcon,
} from '../../../../dum';
import { formatDate, formatFloat, useString } from '../../../../../helpers';
import { SUpsertProductDialog } from '../../../../smart';
import { Box, IconButton } from '@mui/material';

interface IInventoryByProduct {
    productsInventory: ProductInventoryPageQuery['getProductsInventory'];
    isDetailed: boolean;
}

export function InventoryByProduct({
    productsInventory,
    isDetailed,
}: IInventoryByProduct) {
    const [open, setOpen] = useState(false);
    const [productId, setProductId] = useState<number | null>(null);

    const [filter, setFilter] = useString({
        KEY: InventoryByProduct.name + '_Filter',
    });

    const filteredProductsInventory = useMemo(() => {
        return productsInventory.filter((pi) => {
            return filter !== ''
                ? pi.product?.compound_description
                      .toLowerCase()
                      .includes(filter.toLowerCase())
                : true;
        });
    }, [productsInventory, filter]);

    return (
        <>
            <DBlock>
                <DBlockHeader subtitle={'Por producto'} />
                <Box sx={{ mb: 2 }}>
                    <DTextField
                        label={'Filtro'}
                        onChange={setFilter}
                        value={filter}
                    />
                </Box>
                <CTableWrapper
                    stickyHeader
                    items={[
                        {
                            title: 'Producto',
                            textAlign: 'left',
                            cellGrow: 10,
                            content: (item) => {
                                return isDetailed
                                    ? item.product?.compound_description
                                    : item.product?.external_description;
                            },
                        },
                        {
                            title: 'Kilos',
                            content: (item) => {
                                return formatFloat(item.kilos);
                            },
                            textAlign: 'right',
                        },
                        {
                            title: 'Grupos',
                            content: (item) => {
                                return formatFloat(item.groups);
                            },
                            textAlign: 'right',
                        },
                        {
                            title: 'Ultima actualización',
                            cellGrow: 5,
                            content: (item) => {
                                return item.last_update
                                    ? formatDate(item.last_update)
                                    : '-';
                            },
                        },
                        {
                            title: 'Estatus',
                            content: (item) => {
                                return item.product?.discontinued ? (
                                    <Discontinued color={'error'} />
                                ) : (
                                    <Continued color={'success'} />
                                );
                            },
                        },
                        {
                            title: 'Acciones',
                            textAlign: 'center',
                            content: (item) => {
                                return (
                                    <IconButton
                                        disabled={!!item.product_id}
                                        onClick={() => {
                                            setProductId(item?.product_id || 0);
                                            setOpen(true);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                );
                            },
                        },
                    ]}
                    data={filteredProductsInventory}
                />
            </DBlock>
            <SUpsertProductDialog
                open={open}
                setOpen={setOpen}
                productId={productId}
            />
        </>
    );
}
