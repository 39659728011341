import { RHTextField } from '../../../../../../dum';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { IExpense, ORDER_CODE, REQUIRE_ORDER_CODE } from '../expense-form';

export function OrderCodeInput() {
    const requireOrderCode = useWatch({
        name: REQUIRE_ORDER_CODE,
    }) as IExpense['require_supplement'];

    return (
        <RHTextField
            disabled={!requireOrderCode}
            name={ORDER_CODE}
            label={'Folio de la factura'}
        />
    );
}
