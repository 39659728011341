export function isBagProduct({
    order_production_type_id,
}: {
    order_production_type_id: number;
}) {
    return order_production_type_id === 1;
}

export function isRollProduct({
    order_production_type_id,
}: {
    order_production_type_id: number;
}) {
    return order_production_type_id === 2;
}

export function isPelletProduct({
    order_production_type_id,
}: {
    order_production_type_id: number;
}) {
    return order_production_type_id === 3;
}

export function isOthersProduct({
    order_production_type_id,
}: {
    order_production_type_id: number;
}) {
    return order_production_type_id === null;
}
