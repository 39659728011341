import React from 'react';
import { useResourceCategoryIdInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IResource } from '../resource-form';

export function ResourceCategoryInput() {
    const inputName: keyof IResource = 'resource_category_id';
    const { data } = useResourceCategoryIdInputQuery();

    const resourceCategories = data?.getResourceCategories || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Categoria'}
            options={resourceCategories}
        />
    );
}
