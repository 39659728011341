import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOrderSaleOrderRequestInputQuery } from '../../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../../dum';
import { IOrderSale } from '../../../order-sale-form';

const ACCOUNT_NAME: keyof IOrderSale = 'account';
const ORDER_SALE_PRODUCTS: keyof IOrderSale = 'order_sale_products';

export function CreateOrderRequestInput() {
    const inputName: keyof IOrderSale = 'order_request';
    const { data } = useOrderSaleOrderRequestInputQuery();

    const rhContext = useFormContext();

    const orderRequests: NonNullable<IOrderSale['order_request']>[] = data
        ? data.getOrderRequests
        : [];

    return (
        <RhSingleAutocomplete
            itemText={'order_code'}
            itemValue={'id'}
            name={inputName}
            label={'Pedido'}
            options={orderRequests}
            returnObject
            onChange={(val) => {
                const inputValue = val as IOrderSale['order_request'];

                rhContext.setValue(
                    ACCOUNT_NAME,
                    inputValue ? inputValue.account : null,
                );
                const orderSaleProducts: IOrderSale['order_sale_products'] =
                    inputValue?.order_request_remaining_products.map((osrp) => {
                        return {
                            groups: osrp.groups,
                            discount: 0,
                            product: osrp.product || null,
                            kilos: osrp.kilos,
                            kilo_price: osrp.kilo_price,
                            total: osrp.total,
                            group_weight: osrp.group_weight,
                            group_price: osrp.group_price,
                        };
                    }) || [];
                rhContext.setValue(ORDER_SALE_PRODUCTS, orderSaleProducts);
            }}
        />
    );
}
