import React from 'react';
import { useTransferTransferTypeInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { TRANSFER_TYPE_ID } from '../transfer-form';

export function TransferTypeInput() {
    const { data } = useTransferTransferTypeInputQuery();

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={TRANSFER_TYPE_ID}
            label={'Tipo de transferencia'}
            options={data?.getTransferTypes || []}
        />
    );
}
