import React, { useEffect } from 'react';
import { RHTextField } from '../../../../../../../dum';
import { IOrderRequest } from '../../order-request-form';
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetOrderRequestMaxOrderCodeQuery } from '../../../../../../../../services';

const ID_FIELD_NAME: keyof IOrderRequest = 'id';

export function OrderCodeInput() {
    const INPUT_NAME: keyof IOrderRequest = 'order_code';
    const orderRequestId = useWatch({
        name: ID_FIELD_NAME,
    }) as IOrderRequest['id'];
    const { data } = useGetOrderRequestMaxOrderCodeQuery();
    const formContext = useFormContext();

    useEffect(() => {
        if (!orderRequestId && data) {
            formContext.setValue(
                INPUT_NAME,
                data?.getOrderRequestMaxOrderCode + 1,
            );
        }
    }, [orderRequestId, data, formContext]);

    return <RHTextField name={INPUT_NAME} label={'Folio'} />;
}
