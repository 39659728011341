import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../dum';
import { IAccount } from '../account-form';

const ARRAY_NAME: keyof IAccount = 'account_contacts';

const defaultValue: IAccount['account_contacts'][number] = {
    first_name: '',
    last_name: '',
    cellphone: '',
    email: '',
};

const FIRST_NAME_FIELD_NAME: keyof IAccount['account_contacts'][number] =
    'first_name';
const LAST_NAME_FIELD_NAME: keyof IAccount['account_contacts'][number] =
    'last_name';
const CELLPHONE_FIELD_NAME: keyof IAccount['account_contacts'][number] =
    'cellphone';
const EMAIL_FIELD_NAME: keyof IAccount['account_contacts'][number] = 'email';

export function AccountContactsColumns() {
    return (
        <RhColumns
            title={'Contactos'}
            name={ARRAY_NAME}
            defaultValue={defaultValue}
            items={[
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${FIRST_NAME_FIELD_NAME}`}
                                label={'Nombre'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${LAST_NAME_FIELD_NAME}`}
                                label={'Apellidos'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    cellGrow: 2,
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${CELLPHONE_FIELD_NAME}`}
                                label={'Telefono'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    cellGrow: 3,
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${EMAIL_FIELD_NAME}`}
                                label={'Email'}
                                disableGutters
                            />
                        );
                    },
                },
            ]}
        />
    );
}
