import React from 'react';
import { Card } from './Card';
import { Box, Typography } from '@mui/material';

export function CardWithTitle({
    children,
    title,
    flexDirection = 'column',
    justifyContent = 'stretch',
    alignItems,
    ml,
    mr,
}: {
    children: React.ReactNode | React.ReactNode[];
    title: string;
    flexDirection?: React.CSSProperties['flexDirection'];
    justifyContent?: React.CSSProperties['justifyContent'];
    alignItems?: React.CSSProperties['alignItems'];
    ml?: number;
    mr?: number;
}) {
    return (
        <Card
            p={2}
            mb={2}
            ml={ml}
            mr={mr}
            justifyContent={'stretch'}
            flexDirection={'column'}
            alignItems={'stretch'}
        >
            <Typography variant={'subtitle1'}>{title}</Typography>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent,
                    flexDirection,
                    alignItems,
                }}
            >
                {children}
            </Box>
        </Card>
    );
}
