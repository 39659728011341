import { DDatePicker } from './d-date-picker';
import {
    MONTH_FORMAT,
    MONTH_HUMAN_FORMAT,
    MONTH_MASK,
    useNumber,
} from '../../../../helpers';
import React, { useState } from 'react';
import dayjs from 'dayjs';

interface IDMonth {
    setMonth: (month: number | null) => void;
    month: number | null;
    clearable?: boolean;
    disableGutters?: boolean;
    disabled?: boolean;
}

interface IUseDMonth {
    COMPONENT_KEY: string;
    defaultValue?: number | null;
}

export const useDMonth = function ({
    COMPONENT_KEY,
    defaultValue = null,
}: IUseDMonth) {
    const [month, setMonth] = useNumber({
        KEY: COMPONENT_KEY,
        defaultValue: defaultValue,
    });

    return {
        month,
        setMonth,
    };
};

export function DMonth({
    setMonth,
    month,
    clearable = true,
    disableGutters = true,
    disabled = false,
}: IDMonth) {
    const [localMonth, setLocalMonth] = useState<string | null>(() => {
        return month !== undefined && month !== null
            ? dayjs().utc().set('month', month).format(MONTH_FORMAT)
            : null;
    });

    return (
        <DDatePicker
            disabled={disabled}
            label={'Mes'}
            onChange={(val) => {
                setLocalMonth(val);
                setMonth(
                    val !== null
                        ? dayjs(val, MONTH_FORMAT).utc().month()
                        : null,
                );
            }}
            value={localMonth}
            views={['month']}
            mask={MONTH_MASK}
            inputFormat={MONTH_FORMAT}
            localFormat={MONTH_HUMAN_FORMAT}
            disableGutters={disableGutters}
            clearable={clearable}
        />
    );
}
