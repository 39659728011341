import * as React from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { RhDefaultProps } from '../common/rh-default-props';
import { useController, useFormContext } from 'react-hook-form';
import { useRhCustomContext } from '../rh-form-wrapper';

interface IRhAutocompleteProps<T, K> extends RhDefaultProps {
    itemText: keyof T;
    itemValue: keyof T;
    multipleValue: keyof K;
}

export function RhMultipleAutocomplete<T, K>({
    name,
    label,
    itemValue,
    itemText,
    multipleValue,
    options,
    helperText,
    disableGutters = false,
    onChange,
    disabled = false,
    ...rest
}: IRhAutocompleteProps<T, K> &
    Omit<AutocompleteProps<any, any, any, any>, 'renderInput'>) {
    const { control } = useFormContext();
    const { field, fieldState } = useController({
        name,
        control,
    });

    const { disabled: disabledFromContext } = useRhCustomContext();

    const [autocompleteValue, setAutocompleteValue] = useState<T[]>(() => {
        const fieldValues = field.value as K[];
        return options.filter((option) => {
            return fieldValues.find(
                (fieldValue) => fieldValue[multipleValue] === option[itemValue],
            );
        });
    });

    useEffect(() => {
        setAutocompleteValue(() => {
            const fieldValues = field.value as K[];
            return options.filter((option) => {
                return fieldValues.find(
                    (fieldValue) =>
                        fieldValue[multipleValue] === option[itemValue],
                );
            });
        });
    }, [options, itemValue, multipleValue, field.value]);

    return (
        <Autocomplete
            {...rest}
            disabled={disabledFromContext || disabled}
            isOptionEqualToValue={(option, value) => {
                return option === value;
            }}
            value={autocompleteValue}
            onChange={(event: any, val: T[]) => {
                field.onChange({
                    target: {
                        value: val.map((option) => {
                            return {
                                [multipleValue]: option[itemValue],
                            };
                        }),
                    },
                });
                if (onChange) {
                    onChange(val);
                }
            }}
            getOptionLabel={(val) => {
                return val[itemText];
            }}
            multiple
            options={options}
            disableClearable
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        disabled={disabledFromContext || disabled}
                        inputRef={field.ref}
                        sx={{ my: !disableGutters ? 2 : 0, width: '100%' }}
                        label={label}
                        error={Boolean(fieldState.error)}
                        helperText={
                            fieldState.error?.message || helperText || ''
                        }
                        margin={'dense'}
                        size={'small'}
                    />
                );
            }}
        />
    );
}
