import { ValidationError } from 'yup';

export function isEmployeeUnique(
    value: any,
    context: any,
): boolean | ValidationError {
    const { employee } = context.from[0].value;

    if (!employee) return true;

    const order_production_employees =
        context.from[1].value.order_production_employees;

    const results = order_production_employees.filter((pEmployee: any) => {
        const employee_id_2 = pEmployee.employee ? pEmployee.employee.id : null;
        return employee_id_2 === employee.id;
    });

    if (!(results.length <= 1)) {
        return context.createError({
            message: `El empleado no es unico`,
        });
    }

    return true;
}
