import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOrderAdjustmentProductInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderAdjustment } from '../../order-adjustment-form';

const ARRAY_NAME: keyof IOrderAdjustment = 'order_adjustment_products';
const PRODUCT_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'product';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'group_weight';
const KILOS_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'kilos';
const GROUPS_FIELD_NAME: keyof IOrderAdjustment['order_adjustment_products'][number] =
    'groups';

interface IProductsInput {
    index: number;
    initialValues: IOrderAdjustment;
}

export function OrderProductionProductInput({
    index,
    initialValues,
}: IProductsInput) {
    const { data: productsData } = useOrderAdjustmentProductInputQuery();
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${PRODUCT_FIELD_NAME}`;
    const formContext = useFormContext();

    return (
        <RhSingleAutocomplete
            itemText={'compound_description'}
            itemValue={'id'}
            returnObject
            options={productsData?.getProducts || []}
            name={INPUT_NAME}
            label={'Producto'}
            disableGutters
            onChange={(product) => {
                const foundInitialOrderAdjustmentProduct =
                    initialValues.order_adjustment_products.find((oap) => {
                        return oap.product?.id === product.id;
                    });
                if (foundInitialOrderAdjustmentProduct) {
                    formContext.setValue(
                        `${ARRAY_NAME}.${index}.id`,
                        foundInitialOrderAdjustmentProduct.id,
                    );
                }

                const orderAdjustmentProducts = formContext.getValues(
                    ARRAY_NAME,
                ) as IOrderAdjustment['order_adjustment_products'];

                orderAdjustmentProducts.forEach((o, i) => {
                    void formContext.trigger(
                        `${ARRAY_NAME}.${i}.${PRODUCT_FIELD_NAME}`,
                    );
                });

                if (productsData && !!product) {
                    const currentProduct = productsData.getProducts.find(
                        (p) => p.id === product.id,
                    );
                    if (currentProduct) {
                        formContext.setValue(
                            `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
                            currentProduct.current_group_weight,
                        );
                        if (product && product.current_group_weight) {
                            const groups =
                                (formContext.getValues(
                                    `${ARRAY_NAME}.${index}.${GROUPS_FIELD_NAME}`,
                                ) as IOrderAdjustment['order_adjustment_products'][number]['groups']) ||
                                0;
                            formContext.setValue(
                                `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`,
                                groups * product.current_group_weight,
                            );
                        }
                    }
                }
            }}
        />
    );
}
