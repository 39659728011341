import React from 'react';
import { useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../dum';
import { isCurrentGroupWeightRequired } from '../../validation/is-group-weight-required';
import { IProduct } from '../../product-form';

export function CurrentGroupWeightInput() {
    const inputName: keyof IProduct = 'current_group_weight';
    const orderProductionTypeIdName: keyof IProduct =
        'order_production_type_id';
    const orderProductionTypeId = useWatch({
        name: orderProductionTypeIdName,
    });

    if (
        !isCurrentGroupWeightRequired({
            order_production_type_id: orderProductionTypeId,
        })
    ) {
        return null;
    }

    return <RHTextField name={inputName} label={'Peso actual'} />;
}
