import React from 'react';
import { DPage } from '../page/d-page';
import { Typography } from '@mui/material';

interface IErrorCardProps {}

export function DErrorCard(props: IErrorCardProps) {
    return (
        <DPage centered>
            <Typography variant={'h3'}>Error</Typography>
        </DPage>
    );
}
