import { IOrderSale } from '../../order-sale-form';
import { ValidationError } from 'yup';
import { createError } from '../../../../../../../../helpers';

export function canGroupsBeChangedWithOrderAdjustmentProducts(
    value: any,
    context: any,
): ValidationError | boolean {
    const { groups, product } = context.from[0]
        .value as IOrderSale['order_sale_products'][number];
    const { order_adjustment_products } = context.from[1].value as IOrderSale;
    const orderAdjustmentProducts = order_adjustment_products?.filter(
        (rp) => rp?.product_id === product?.id,
    );

    if (!orderAdjustmentProducts || orderAdjustmentProducts.length === 0) {
        return true;
    }
    const adjustmentGroups =
        orderAdjustmentProducts?.reduce((acc, curr) => {
            return acc + curr.groups;
        }, 0) || 0;

    if (groups < adjustmentGroups) {
        return createError({
            context,
            message: `'${groups}' no puede ser inferior a '${adjustmentGroups}'`,
        });
    }

    return true;
}
