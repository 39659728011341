import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSpareAdjustmentPageQuery } from '../../../../../services';
import {
    DErrorCard,
    DPage,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    DeleteIcon,
    EditIcon,
} from '../../../../dum';
import {
    SDeleteSpareOperationDialog,
    SSpareAdjustmentFormDialog,
} from '../../../../smart';

type TSpareAdjustmentPageParams = {
    spareAdjustmentId: string;
};

export function SpareAdjustmentPage() {
    const { spareAdjustmentId } = useParams<TSpareAdjustmentPageParams>();
    const { data, loading, error } = useSpareAdjustmentPageQuery({
        variables: {
            SpareAdjustmentId: spareAdjustmentId
                ? Number(spareAdjustmentId)
                : 0,
        },
    });
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [formDialog, setFormDialog] = useState(false);

    if (loading) {
        return <DPageLoader />;
    }

    if (error) {
        return <DErrorCard />;
    }

    return (
        <DPage animated>
            <DPageHeader title={'Ajuste'}>
                <DRowButtons
                    rowItems={[
                        {
                            callback: () => {
                                setFormDialog(true);
                            },
                            icon: <EditIcon />,
                            tooltip: 'Editar ajuste',
                        },
                        {
                            color: 'error',
                            callback: () => {
                                setConfirmationDialog(true);
                            },
                            disabled: !data,
                            icon: <DeleteIcon />,
                            tooltip: 'Borrar ajuste',
                        },
                    ]}
                />
            </DPageHeader>
            <SDeleteSpareOperationDialog
                open={confirmationDialog}
                setOpen={setConfirmationDialog}
                spareOperation={data!.getSpareOperation}
            />
            <SSpareAdjustmentFormDialog
                open={formDialog}
                setOpen={setFormDialog}
                spareAdjustment={data!.getSpareOperation}
            />
            <Box>{data?.getSpareOperation.id}</Box>
        </DPage>
    );
}
