import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    AppBar as MuiAppBar,
    Badge,
    Box,
    Button,
    IconButton,
    Toolbar,
} from '@mui/material';
import React from 'react';
import inopackLogo from './logo.png';
import { useNavigate } from 'react-router-dom';
import { navigateToActivitiesPage } from '../../../services';
import { useDevState } from '../../../helpers';

interface IInopackAppBarProps {
    toggleDrawer: () => void;
}

export const InopackAppBar = (props: IInopackAppBarProps) => {
    const { toggleDrawer } = props;
    const navigate = useNavigate();
    const isDevEnvironment = useDevState();

    return (
        <MuiAppBar
            className={'no-printable-block'}
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor: isDevEnvironment ? 'secondary.main' : undefined,
            }}
        >
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <Box
                    sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
                >
                    <IconButton aria-label="open drawer" onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Button sx={{ p: 0, m: 0 }} size={'small'}>
                        <Box
                            component={'img'}
                            src={inopackLogo}
                            sx={{
                                maxWidth: '10rem',
                                marginLeft: '4rem',
                            }}
                        />
                    </Button>
                </Box>
                <Box>
                    <IconButton
                        onClick={async () => {
                            navigateToActivitiesPage({ navigate });
                        }}
                    >
                        <Badge badgeContent={0} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Box>
            </Toolbar>
        </MuiAppBar>
    );
};
