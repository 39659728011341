import { RhFormWrapper, RHTextField } from '../../../../../dum';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf, ValidationError } from 'yup';
import {
    queryIsEmailOccupied,
    useCreateUserMutation,
    useUpdateUserMutation,
} from '../../../../../../services';
import { useApolloClient } from '@apollo/client';
import { RolesInput } from './inputs/roles-input';

export interface IUser {
    id?: number | null;
    first_name: string;
    last_name: string;
    email: string;
    roles: { id: number }[];
}

export interface IUserWithPassword extends IUser {
    password_1?: string;
    password_2?: string;
}

interface IUserForm {
    user?: IUser | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function UserForm({ user, onSuccess, disabled = false }: IUserForm) {
    const [createMutation, { loading: createLoading }] =
        useCreateUserMutation();
    const [updateMutation, { loading: updateLoading }] =
        useUpdateUserMutation();

    const apolloClient = useApolloClient();

    const initialValues = useMemo<IUserWithPassword>(() => {
        return {
            id: user?.id || undefined,
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            email: user?.email || '',
            password_1: '',
            password_2: '',
            roles: user ? user.roles : [],
        };
    }, [user]);

    const validationSchema: SchemaOf<IUserWithPassword> = yup.object({
        id: yup.number().notRequired().nullable(),
        first_name: yup.string().required(`'Nombre' es obligatorio`),
        last_name: yup.string().required(`'Apellido' es obligatorio`),
        roles: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().required(),
                }),
            )
            .required(),
        email: yup
            .string()
            .required(`'Email' es obligatorio`)
            .test('unique', async function (value, context): Promise<
                boolean | ValidationError
            > {
                if (!!value) {
                    const result = await queryIsEmailOccupied(apolloClient, {
                        Email: value,
                        UserId: user?.id || null,
                    });
                    if (result.data.isEmailOccupied) {
                        return context.createError({
                            message: `El email ${value} se encuentra en uso`,
                        });
                    }
                    return true;
                } else {
                    return true;
                }
            }),
        password_1: yup.string().when('id', {
            is: (val: any) => !val,
            then: (schema) =>
                schema
                    .required(`'Contraseña' es obligatorio`)
                    .min(10, `'Contraseña' debe tener minimo 10 caracteres`),
            otherwise: (schema) =>
                schema.notRequired().test((val: any, ctx: any) => {
                    if (val !== '' && val.length < 10) {
                        return ctx.createError({
                            message: `'Contraseña' debe tener minimo 10 caracteres`,
                        });
                    }
                    return true;
                }),
        }),
        password_2: yup
            .string()
            .oneOf(
                [yup.ref('password_1'), null],
                `Las contraseñas deben de coincidir`,
            ),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={createLoading || updateLoading || disabled}
            onSubmit={async (data) => {
                try {
                    if (!user) {
                        await createMutation({
                            variables: {
                                CreateUserInput: {
                                    email: data.email,
                                    first_name: data.first_name,
                                    last_name: data.last_name,
                                    password: data.password_1!,
                                    roles: data.roles,
                                },
                            },
                        });
                    } else if (user && user.id) {
                        await updateMutation({
                            variables: {
                                UpdateUserInput: {
                                    id: user.id,
                                    first_name: data.first_name,
                                    last_name: data.last_name,
                                    email: data.email,
                                    password:
                                        data.password_1 !== undefined &&
                                        data.password_1 !== null &&
                                        data.password_1 !== ''
                                            ? data.password_1
                                            : undefined,
                                    roles: data.roles.map((role) => {
                                        return {
                                            id: role.id,
                                        };
                                    }),
                                },
                            },
                        });
                    }
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RHTextField name={'first_name'} label={'Nombre'} />
            <RHTextField name={'last_name'} label={'Apellidos'} />
            <RHTextField name={'email'} label={'Email'} />
            <RolesInput />
            <RHTextField name={'password_1'} label={'Contraseña'} />
            <RHTextField
                name={'password_2'}
                label={'Confirmación de la contraseña'}
            />
        </RhFormWrapper>
    );
}
