import React from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    clearMaintenanceModule,
    useUpsertSpareMutation,
} from '../../../../../../services';
import { DModal, RhFormWrapper, RHTextField } from '../../../../../dum';
import { SpareIcon } from '../../../../icons';
import { SSingleSpareCategoryAutocomplete } from './inputs/s-single-spare-category-autocomplete';

export interface ISpareForm {
    name: string;
    spare_category_id?: number | null;
}

interface ISpare extends ISpareForm {
    id?: number;
}

interface IMachinePartFormDialogProps {
    spare?: ISpare;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SSpareFormDialog = (props: IMachinePartFormDialogProps) => {
    const { open, setOpen, spare } = props;

    const [mutation, { loading }] = useUpsertSpareMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    const initialValues: ISpareForm = {
        name: spare?.name || '',
        spare_category_id: spare?.spare_category_id || null,
    };

    const validationSchema: SchemaOf<ISpareForm> = yup.object({
        name: yup.string().required(),
        spare_category_id: yup.number().required(),
    });

    return (
        <DModal
            icon={<SpareIcon />}
            title={`${!!spare?.id ? 'Editar' : 'Crear'} refacción`}
            open={open}
            setOpen={setOpen}
        >
            <RhFormWrapper
                onSubmit={async (data) => {
                    await mutation({
                        variables: {
                            SpareUpsertInput: {
                                id: spare?.id || null,
                                name: data?.name!,
                                spare_category_id: data?.spare_category_id!,
                            },
                        },
                    });
                    setOpen(false);
                    return;
                }}
                validationSchema={validationSchema}
                initialValues={initialValues}
                disabled={loading}
            >
                <RHTextField name={'name'} label={'Nombre'} />
                <SSingleSpareCategoryAutocomplete
                    disabled={!!spare?.id}
                    name={'spare_category_id'}
                    label={'Categoria'}
                />
            </RhFormWrapper>
        </DModal>
    );
};
