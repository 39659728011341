import React, { useEffect, useState } from 'react';
import { DATE_TIME_FORMAT, DATE_TIME_MASK } from '../../../../../../helpers';
import { DDatePicker } from '../../../inputs';
import { RhDefaultProps } from '../common/rh-default-props';
import { useController, useFormContext } from 'react-hook-form';
import { useRhCustomContext } from '../rh-form-wrapper';

interface IProps extends RhDefaultProps {}

export const RhDateTimeInput = ({
    name,
    label,
    helperText,
    disableGutters = false,
    onChange,
    disabled = false,
}: IProps) => {
    const [dateValue, setDateValue] = useState<string | null>(null);

    const { disabled: disabledFromContext } = useRhCustomContext();

    const { control } = useFormContext();
    const { field, fieldState } = useController({
        name,
        control,
    });

    useEffect(() => {
        setDateValue(field.value);
    }, [field.value]);

    return (
        <DDatePicker
            views={['day', 'hours', 'minutes', 'seconds']}
            mask={DATE_TIME_MASK}
            inputFormat={DATE_TIME_FORMAT}
            disableGutters={disableGutters}
            disabled={disabledFromContext || disabled}
            label={label}
            onChange={(val) => {
                field.onChange({ target: { value: val } });
                if (onChange) {
                    onChange(val);
                }
            }}
            inputRef={field.ref}
            value={dateValue}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message || helperText || ''}
        />
    );
};
