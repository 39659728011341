import React, { useEffect, useMemo, useState } from 'react';
import {
    Continued,
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    Discontinued,
    DPaginator,
    DTextField,
    EditIcon,
    usePaginator,
} from '../../../../../dum';
import { Box, IconButton } from '@mui/material';
import {
    MachinesPageQuery,
    useMachinesPageLazyQuery,
} from '../../../../../../services';
import { useBoolean, useNumber, useString } from '../../../../../../helpers';
import { DCheckbox } from '../../../../../dum/simple/inputs/d-checkbox';
import {
    SDeleteMachineDialog,
    SUpsertMachineDialog,
} from '../../../../../smart';

export function MachinesListBlock() {
    const [query, { data, previousData }] = useMachinesPageLazyQuery();
    const [machineId, setMachineId] = useState<number | null>(null);
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [orderProductionTypeId, setOrderProductionTypeId] = useNumber({
        KEY: MachinesListBlock.name + '_MachineTypeId',
    });
    const [filter, setFilter] = useString({
        KEY: MachinesListBlock.name + '_Filter',
    });

    const [branchId, setBranchId] = useNumber({
        KEY: MachinesListBlock.name + '_BranchId',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: MachinesListBlock.name + '_Paginator',
        },
        [branchId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                filter: filter,
                branchId: branchId,
                orderProductionTypeId: orderProductionTypeId,
            },
        });
    }, [query, skip, take, branchId, filter, orderProductionTypeId]);

    const [defaultCollapseOpen, setDefaultCollapseOpen] = useBoolean({
        KEY: MachinesListBlock.name + '_DefaultOpen',
        defaultValue: false,
    });

    const handleEditMachine = useMemo(() => {
        return (
            machine: MachinesPageQuery['paginatedMachines']['docs'][number],
        ) => {
            setMachineId(machine.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteMachine = useMemo(() => {
        return (
            machine: MachinesPageQuery['paginatedMachines']['docs'][number],
        ) => {
            setMachineId(machine.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DBlock>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ flex: 1, ml: 1 }}>
                    <DIdAutocomplete
                        id={branchId}
                        onChange={setBranchId}
                        itemText={'name'}
                        label={'Sucursal'}
                        options={data?.getBranches || []}
                    />
                </Box>
                <Box sx={{ flex: 1, ml: 1 }}>
                    <DIdAutocomplete
                        id={orderProductionTypeId}
                        onChange={setOrderProductionTypeId}
                        itemText={'name'}
                        label={'Tipo de orden de producción'}
                        options={data?.getOrderProductionTypes || []}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <CVerticalDropdown
                        items={[
                            {
                                component: (
                                    <DTextField
                                        value={filter}
                                        onChange={setFilter}
                                        label={'Filtro'}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        onChange={(val) => {
                                            setDefaultCollapseOpen(val);
                                        }}
                                        value={defaultCollapseOpen}
                                        label={'Abrir filas'}
                                        disableGutters
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>

            <DPaginator
                skip={skip}
                take={take}
                setSkip={setSkip}
                count={
                    data && data?.paginatedMachines.count >= 0
                        ? data?.paginatedMachines.count
                        : previousData?.paginatedMachines.count
                }
            />
            <CTableWrapper
                contextItems={[
                    {
                        title: 'Editar',
                        callback: (machine) => {
                            handleEditMachine(machine);
                        },
                    },
                    {
                        title: 'Borrar',
                        disable: (machine) => !machine.is_deletable,
                        callback: (machine) => {
                            handleDeleteMachine(machine);
                        },
                    },
                ]}
                items={[
                    {
                        title: 'Nombre',
                        content: (machine) => {
                            return machine.name;
                        },
                    },
                    {
                        title: 'Tipo de orden de producción',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (orderProduction) => {
                            return orderProduction.order_production_type?.name;
                        },
                    },
                    {
                        title: 'Sucursal',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (orderProduction) => {
                            return orderProduction.branch?.name;
                        },
                    },
                    {
                        title: 'Estatus',
                        textAlign: 'center',
                        content: (machine) => {
                            return !machine.discontinued ? (
                                <Continued color={'success'} />
                            ) : (
                                <Discontinued color={'error'} />
                            );
                        },
                    },
                    {
                        title: 'Acciones',
                        content: (machine) => {
                            return (
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        onClick={() => {
                                            handleEditMachine(machine);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!machine.is_deletable}
                                        onClick={() => {
                                            handleDeleteMachine(machine);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
                defaultOpenCollapse={defaultCollapseOpen}
                data={
                    data?.paginatedMachines.docs ||
                    previousData?.paginatedMachines.docs ||
                    []
                }
            />
            <SUpsertMachineDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                machineId={machineId}
            />
            <SDeleteMachineDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                machineId={machineId}
            />
        </DBlock>
    );
}
