import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage, DSpeedDial } from '../../../dum';
import { EmployeeIcon, SUpsertUserDialog } from '../../../smart';
import { UsersPage } from './users/users-page';

const pages: Route[] = [
    {
        name: 'UsersListPage',
        path: '/',
        component: <UsersPage />,
        title: 'Usuarios',
        navbar: false,
    },
];

export function UsersHomePage() {
    const [upsertOpen, setUpsertOpen] = useState(false);

    return (
        <DPage>
            <DSpeedDial
                items={[
                    {
                        icon: <EmployeeIcon />,
                        callback: () => {
                            setUpsertOpen(true);
                        },
                        text: 'Crear usuario',
                    },
                ]}
            />
            <ReactRouterRoutes>
                {pages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertUserDialog open={upsertOpen} setOpen={setUpsertOpen} />
        </DPage>
    );
}
