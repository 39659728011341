import { useFormContext, UseFormReturn } from 'react-hook-form';
import {
    IRawMaterialAddition,
    RAW_MATERIAL_ADDITION_ITEMS,
    RAW_MATERIAL_ADDITION_ITEMS_TOTAL,
    TOTAL,
} from './raw-material-addition-form';

function setTotal({
    rawMaterialAdditionItems,
    formContext,
    index,
}: {
    rawMaterialAdditionItems: IRawMaterialAddition['raw_material_addition_items'];
    formContext: UseFormReturn<any>;
    index?: number;
}) {
    if (rawMaterialAdditionItems && Array.isArray(rawMaterialAdditionItems)) {
        const itemsTotal = rawMaterialAdditionItems.reduce(
            (acc, rawMaterialAdditionItem, currentIndex) => {
                const amount =
                    Number.isNaN(rawMaterialAdditionItem.amount) ||
                    !rawMaterialAdditionItem.amount
                        ? 0
                        : rawMaterialAdditionItem.amount;
                const unitPrice =
                    Number.isNaN(rawMaterialAdditionItem.unit_price) ||
                    !rawMaterialAdditionItem.unit_price
                        ? 0
                        : rawMaterialAdditionItem.unit_price;

                const itemTotal = amount * unitPrice;

                if (index !== undefined && index === currentIndex) {
                    formContext.setValue(
                        `${RAW_MATERIAL_ADDITION_ITEMS}.${index}.${RAW_MATERIAL_ADDITION_ITEMS_TOTAL}`,
                        Math.round(itemTotal * 100) / 100,
                        {
                            shouldValidate: true,
                        },
                    );
                }

                return acc + itemTotal;
            },
            0,
        );

        formContext.setValue(TOTAL, Math.round(itemsTotal * 100) / 100, {
            shouldValidate: true,
        });
    }
}

export function useCalculateTotal() {
    const formContext = useFormContext();

    const calculateTotal = function (
        params: { index?: number } = { index: undefined },
    ) {
        const index =
            params && params.index !== undefined ? params.index : undefined;

        const rawMaterialAdditionItems = formContext.getValues(
            RAW_MATERIAL_ADDITION_ITEMS,
        ) as IRawMaterialAddition['raw_material_addition_items'];
        setTotal({
            rawMaterialAdditionItems,
            formContext,
            index,
        });
    };

    return {
        calculateTotal: calculateTotal,
    };
}
