import React, { useEffect } from 'react';
import { RHTextField } from '../../../../../../../dum';
import { IOrderSale } from '../../order-sale-form';
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetOrderSaleMaxOrderCodeLazyQuery } from '../../../../../../../../services';

const ID_FIELD_NAME: keyof IOrderSale = 'id';

export function OrderCodeInput() {
    const INPUT_NAME: keyof IOrderSale = 'order_code';
    const orderSaleId = useWatch({
        name: ID_FIELD_NAME,
    }) as IOrderSale['id'];
    const [query, { data }] = useGetOrderSaleMaxOrderCodeLazyQuery();
    const formContext = useFormContext();

    useEffect(() => {
        if (!orderSaleId) {
            void query();
        }
    }, [orderSaleId, query]);

    useEffect(() => {
        if (!orderSaleId && data) {
            formContext.setValue(
                INPUT_NAME,
                data?.getOrderSaleMaxOrderCode + 1,
            );
        }
    }, [orderSaleId, data, formContext]);

    return <RHTextField name={INPUT_NAME} label={'Folio'} />;
}
