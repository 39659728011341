import React from 'react';
import { RhColumns } from '../../../../../../dum';
import { OrderProductionProductInput } from './employee/employee-input';
import { OrderProductionIsLeaderInput } from './is-leader/is-leader-input';
import { IOrderProduction } from '../order-production-form';

const ARRAY_NAME: keyof IOrderProduction = 'order_production_employees';

const defaultValue: IOrderProduction['order_production_employees'][number] = {
    employee: null,
    is_leader: false,
};

export function OrderProductionEmployeesColumns() {
    return (
        <RhColumns
            title={'Empleados'}
            name={ARRAY_NAME}
            defaultValue={defaultValue}
            items={[
                {
                    cellGrow: 3,
                    getField: (index) => {
                        return <OrderProductionProductInput index={index} />;
                    },
                },
                {
                    cellGrow: 1,
                    getField: (index) => {
                        return <OrderProductionIsLeaderInput index={index} />;
                    },
                },
            ]}
        />
    );
}
