import { ValidationError } from 'yup';
import { IProduct } from '../product-form';

export function doesProductCategoryBelongToOrderProductionType(
    value: any,
    context: any,
): boolean | ValidationError {
    const { product_category, order_production_type_id } = context.from[0]
        .value as IProduct;

    if (!product_category || !order_production_type_id) return true;
    if (
        product_category.order_production_type_id !== order_production_type_id
    ) {
        return context.createError({
            message: `La categoria del producto no pertence a la orden producción`,
        });
    }

    return true;
}
