import { Box, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DCollapseButton } from '../button';
import { Card } from '../cards';

export function DSection({
    children,
    title = '',
    alwaysOpen = false,
}: {
    children: React.ReactNode | React.ReactNode[];
    title?: string;
    alwaysOpen?: boolean;
}) {
    const [open, setOpen] = useState(alwaysOpen);

    return (
        <Card
            flexDirection={'column'}
            mb={2}
            justifyContent={'stretch'}
            alignItems={'stretch'}
        >
            {title || !alwaysOpen ? (
                <Toolbar disableGutters variant={'dense'} color={'primary'}>
                    {!alwaysOpen ? (
                        <Box sx={{ ml: 2, mr: 1 }}>
                            <DCollapseButton open={open} setOpen={setOpen} />
                        </Box>
                    ) : null}

                    <Typography sx={{ ml: alwaysOpen ? 2 : undefined }}>
                        {title}
                    </Typography>
                </Toolbar>
            ) : null}

            {open ? <Box sx={{ my: 2, px: 2 }}>{children}</Box> : null}
        </Card>
    );
}
