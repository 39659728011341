import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';

interface ICustomRowButtonsProps {
    rowItems: {
        icon?: React.ReactElement<any, any>;
        tooltip?: string;
        callback?: () => void;
        disabled?: boolean;
        color?: 'primary' | 'error';
        href?: string;
    }[];
}

export function DRowButtons({ rowItems }: ICustomRowButtonsProps) {
    return (
        <Box>
            {rowItems.map(
                (
                    {
                        callback,
                        color = 'primary',
                        disabled,
                        icon,
                        tooltip,
                        href,
                    },
                    index,
                ) => {
                    return (
                        <Tooltip key={index} title={tooltip || ''} followCursor>
                            <IconButton
                                sx={{
                                    ml: index !== 0 ? 2 : 0,
                                    // bgcolor: 'secondary.main',
                                }}
                                size={'medium'}
                                color={color}
                                key={index}
                                href={href || ''}
                                onClick={(e) => {
                                    if (callback) {
                                        callback();
                                    }
                                }}
                                disabled={disabled}
                            >
                                {icon}
                            </IconButton>
                        </Tooltip>
                    );
                },
            )}
        </Box>
    );
}
