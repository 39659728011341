import { useFormContext, UseFormReturn } from 'react-hook-form';
import { IOrderRequest } from '../order-request-form';

const PRODUCTS_TOTAL_FIELD_NAME: keyof IOrderRequest = 'products_total';

function setProductsTotal({
    orderRequestProducts,
    formContext,
}: {
    orderRequestProducts: IOrderRequest['order_request_products'];
    formContext: UseFormReturn<any>;
}) {
    if (orderRequestProducts && Array.isArray(orderRequestProducts)) {
        const products =
            orderRequestProducts as IOrderRequest['order_request_products'];
        const productsTotal = products.reduce((acc, product) => {
            const kilos =
                Number.isNaN(product.kilos) || !product.kilos
                    ? 0
                    : product.kilos;
            const kiloPrice =
                Number.isNaN(product.kilo_price) || !product.kilo_price
                    ? 0
                    : product.kilo_price;
            const groups =
                Number.isNaN(product.groups) || !product.groups
                    ? 0
                    : product.groups;
            const groupPrice =
                Number.isNaN(product.group_price) || !product.group_price
                    ? 0
                    : product.group_price;

            const productTotal = kilos * kiloPrice + groups * groupPrice;

            return acc + productTotal;
        }, 0);

        formContext.setValue(
            PRODUCTS_TOTAL_FIELD_NAME,
            Math.round(productsTotal * 100) / 100,
            {
                shouldValidate: true,
            },
        );
    }
}

const ORDER_REQUEST_PRODUCTS_FIELD_NAME: keyof IOrderRequest =
    'order_request_products';

export function useCalculateProductsTotal() {
    const formContext = useFormContext<IOrderRequest>();

    const calculateProductsTotal = function () {
        const orderRequestProducts = formContext.getValues(
            ORDER_REQUEST_PRODUCTS_FIELD_NAME,
        ) as IOrderRequest['order_request_products'];
        setProductsTotal({
            orderRequestProducts,
            formContext,
        });
    };

    return {
        calculateProductsTotal,
    };
}
