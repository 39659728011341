import React from 'react';
import { useEmployeeEmployeeStatusInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IEmployee } from '../../employee-form';

export function EmployeeStatusInput() {
    const inputName: keyof IEmployee = 'employee_status_id';

    const { data } = useEmployeeEmployeeStatusInputQuery();

    const options = data?.getEmployeeStatuses || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Status'}
            options={options}
        />
    );
}
