import React, { useEffect, useMemo, useState } from 'react';
import {
    OrderAdjustmentsPageQuery,
    useOrderAdjustmentsPageLazyQuery,
} from '../../../../../services';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPage,
    DPageBody,
    DPageHeader,
    DPaginator,
    DYear,
    EditIcon,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../dum';
import {
    SDeleteOrderAdjustmentDialog,
    SUpsertOrderAdjustmentDialog,
} from '../../../../smart';
import { Box, IconButton } from '@mui/material';
import {
    formatDate,
    formatFloat,
    useBoolean,
    useNumber,
} from '../../../../../helpers';
import { DCheckbox } from '../../../../dum/simple/inputs/d-checkbox';

export function OrderAdjustmentsPage() {
    const [query, { data, previousData }] = useOrderAdjustmentsPageLazyQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [orderAdjustmentId, setOrderAdjustmentId] = useState<null | number>(
        null,
    );

    const [orderAdjustmentTypeId, setOrderAdjustmentTypeId] = useNumber({
        KEY: OrderAdjustmentsPage.name + '_OrderAdjustmentTypeId',
    });

    const { year, setYear } = useDYear({
        COMPONENT_KEY: OrderAdjustmentsPage.name + '_Year',
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: OrderAdjustmentsPage.name + '_Month',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: OrderAdjustmentsPage.name + '_Paginator',
        },
        [year, month, orderAdjustmentTypeId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                orderAdjustmentTypeId: orderAdjustmentTypeId,
            },
        });
    }, [query, skip, take, year, month, orderAdjustmentTypeId]);

    const [defaultCollapseOpen, setDefaultCollapseOpen] = useBoolean({
        KEY: OrderAdjustmentsPage.name + '_DefaultOpen',
        defaultValue: false,
    });

    const handleEditOrderAdjustment = useMemo(() => {
        return (
            orderAdjustment: OrderAdjustmentsPageQuery['paginatedOrderAdjustments']['docs'][number],
        ) => {
            setOrderAdjustmentId(orderAdjustment.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteOrderAdjustment = useMemo(() => {
        return (
            orderAdjustment: OrderAdjustmentsPageQuery['paginatedOrderAdjustments']['docs'][number],
        ) => {
            setOrderAdjustmentId(orderAdjustment.id);
            setDeleteOpen(true);
        };
    }, []);

    const orderAdjustments =
        data?.paginatedOrderAdjustments.docs ||
        previousData?.paginatedOrderAdjustments.docs ||
        [];

    const count =
        data && data?.paginatedOrderAdjustments.count >= 0
            ? data?.paginatedOrderAdjustments.count
            : previousData?.paginatedOrderAdjustments.count;

    return (
        <DPage animated>
            <DPageHeader title={'Ordenes de ajuste'} />
            <DPageBody disableGutters>
                <DBlock>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Box sx={{ flex: 1, mr: 1 }}>
                            <DYear setYear={setYear} year={year} />
                        </Box>
                        <Box sx={{ flex: 1, ml: 1 }}>
                            <DMonth setMonth={setMonth} month={month} />
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <CVerticalDropdown
                                items={[
                                    {
                                        component: (
                                            <DIdAutocomplete
                                                id={orderAdjustmentTypeId}
                                                onChange={(val) => {
                                                    setOrderAdjustmentTypeId(
                                                        val,
                                                    );
                                                }}
                                                itemText={'name'}
                                                label={'Tipo de ajuste'}
                                                options={
                                                    data?.getOrderAdjustmentTypes ||
                                                    []
                                                }
                                            />
                                        ),
                                    },
                                    {
                                        component: (
                                            <DCheckbox
                                                onChange={(val) => {
                                                    setDefaultCollapseOpen(val);
                                                }}
                                                value={defaultCollapseOpen}
                                                label={'Abrir filas'}
                                                disableGutters
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                    <DPaginator
                        skip={skip}
                        take={take}
                        setSkip={setSkip}
                        count={count}
                    />
                    <CTableWrapper
                        onClick={(adjustment) => {
                            handleEditOrderAdjustment(adjustment);
                        }}
                        contextItems={[
                            {
                                title: 'Editar',
                                callback: (orderAdjustment) => {
                                    handleEditOrderAdjustment(orderAdjustment);
                                },
                            },
                            {
                                title: 'Borrar',
                                disable: (orderAdjustment) => {
                                    return !orderAdjustment.is_deletable;
                                },
                                callback: (orderAdjustment) => {
                                    handleDeleteOrderAdjustment(
                                        orderAdjustment,
                                    );
                                },
                            },
                        ]}
                        items={[
                            {
                                title: 'Fecha',
                                cellGrow: 20,
                                textAlign: 'center',
                                content: (orderAdjustment) => {
                                    return formatDate(orderAdjustment.date);
                                },
                            },
                            {
                                title: 'Tipo de ajuste',
                                cellGrow: 20,
                                textAlign: 'center',
                                content: (orderAdjustment) => {
                                    return orderAdjustment.order_adjustment_type
                                        ?.name;
                                },
                            },
                            {
                                title: 'Acciones',
                                content: (orderAdjustment) => {
                                    return (
                                        <Box sx={{ display: 'flex' }}>
                                            <IconButton
                                                onClick={() => {
                                                    handleEditOrderAdjustment(
                                                        orderAdjustment,
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={
                                                    !orderAdjustment.is_deletable
                                                }
                                                onClick={() => {
                                                    handleDeleteOrderAdjustment(
                                                        orderAdjustment,
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        data={orderAdjustments}
                        defaultOpenCollapse={defaultCollapseOpen}
                        collapse={(orderAdjustment) => {
                            return (
                                <CTableWrapper
                                    padding={false}
                                    items={[
                                        {
                                            title: 'Producto',
                                            cellGrow: 40,
                                            content: (osp) =>
                                                osp.product
                                                    ?.compound_description,
                                        },
                                        {
                                            title: 'Grupos',
                                            textAlign: 'right',
                                            cellGrow: 10,
                                            content: (osp) =>
                                                formatFloat(osp.groups),
                                        },
                                        {
                                            title: 'Kilos',
                                            textAlign: 'right',
                                            cellGrow: 10,
                                            content: (osp) =>
                                                formatFloat(osp.kilos),
                                        },
                                    ]}
                                    data={
                                        orderAdjustment.order_adjustment_products
                                    }
                                />
                            );
                        }}
                    />
                    <SUpsertOrderAdjustmentDialog
                        open={upsertOpen}
                        setOpen={setUpsertOpen}
                        orderAdjustmentId={orderAdjustmentId}
                    />
                    <SDeleteOrderAdjustmentDialog
                        open={deleteOpen}
                        setOpen={setDeleteOpen}
                        orderAdjustmentId={orderAdjustmentId}
                    />
                </DBlock>
            </DPageBody>
        </DPage>
    );
}
