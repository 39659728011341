import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';

export function DCollapseButton({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (op: boolean) => void;
}) {
    return (
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
        >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
    );
}
