import { ValidationError } from 'yup';

export function doesEmployeeBelongToOrderProductionType(
    value: any,
    context: any,
): boolean | ValidationError {
    const { employee } = context.from[0].value;
    const { order_production_type_id } = context.from[1].value;

    if (!order_production_type_id || !employee) return true;

    if (!(employee.order_production_type_id === order_production_type_id)) {
        return context.createError({
            message: `El empleado no pertenece a la orden de producción`,
        });
    }

    return true;
}
