import {
    Box,
    Button,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer as MuiDrawer,
    Toolbar,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routeItems } from '../../pages';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DNavListItemLink } from '../../dum';
import { useCurrentUserQuery } from '../../../services';
import {
    isRouteGroup,
    routeNavbarAuthFilter,
    routeNavbarFilter,
} from '../../../types';
import inopackLogo from '../inopack-app-bar/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDevState } from '../../../helpers';

interface IDrawer {
    open: boolean;
    setOpen: (open: boolean) => void;
    toggleDrawer: () => void;
    isDesktop: boolean;
}

const drawerWidth: number = 240;

export const InopackDrawer = (props: IDrawer) => {
    const { open, setOpen, isDesktop } = props;

    const location = useLocation();

    const [activeList, setActiveList] = useState<string | null>(null);

    const { data } = useCurrentUserQuery();

    const currentUser = data?.currentUser;

    const isDevEnvironment = useDevState();

    const routeFilter = useMemo(() => {
        return currentUser
            ? routeNavbarAuthFilter(currentUser)
            : routeNavbarFilter();
    }, [currentUser]);

    useEffect(() => {
        const staticRouteGroup = routeItems.find((rg) => {
            return isRouteGroup(rg)
                ? rg.routes.find((route) => {
                      return location.pathname.includes(route.path);
                  })
                : location.pathname.includes(rg.path);
        });
        setActiveList(staticRouteGroup ? staticRouteGroup.title : null);
    }, [setActiveList, location]);

    return (
        <MuiDrawer
            className={'no-printable-block'}
            variant={isDesktop ? 'permanent' : 'temporary'}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Toolbar
                color={'primary'}
                sx={{
                    bgcolor: isDevEnvironment
                        ? 'secondary.main'
                        : 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: [1],
                }}
            >
                <Button sx={{ p: 0, m: 0 }} size={'small'}>
                    <Box
                        component={'img'}
                        src={inopackLogo}
                        sx={{
                            maxWidth: '10rem',
                        }}
                    />
                </Button>
                {/*<IconButton onClick={toggleDrawer}>*/}
                {/*    <ChevronLeftIcon />*/}
                {/*</IconButton>*/}
            </Toolbar>
            {routeItems.filter(routeFilter).map((rg, index) => {
                return (
                    <React.Fragment key={index}>
                        <List>
                            {isRouteGroup(rg) ? (
                                <ListItem
                                    onClick={() => {
                                        setActiveList(
                                            rg.title !== activeList
                                                ? rg.title
                                                : null,
                                        );
                                    }}
                                    button
                                >
                                    <ListItemText primary={rg.title} />
                                    {activeList === rg.title ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                            ) : (
                                <DNavListItemLink
                                    key={rg.name}
                                    primary={rg.title}
                                    exact={rg.exact || false}
                                    to={rg.path}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                />
                            )}

                            {isRouteGroup(rg) ? (
                                <Collapse in={activeList === rg.title}>
                                    <List>
                                        {rg.routes
                                            .filter(routeFilter)
                                            .map((route) => {
                                                return (
                                                    <DNavListItemLink
                                                        key={route.name}
                                                        primary={route.title}
                                                        exact={
                                                            route.exact || false
                                                        }
                                                        to={route.path}
                                                        onClick={() => {
                                                            setOpen(false);
                                                        }}
                                                    />
                                                );
                                            })}
                                    </List>
                                </Collapse>
                            ) : null}
                        </List>
                        <Divider />
                    </React.Fragment>
                );
            })}
            <List style={{ marginTop: `auto` }}>
                <ListItemButton
                    dense
                    onClick={async () => {
                        window.localStorage.removeItem('token');
                        window.location.reload();
                    }}
                >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Cerrar sesión</ListItemText>
                </ListItemButton>
            </List>
        </MuiDrawer>
    );
};
