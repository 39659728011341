import React from 'react';
import { CircularProgress } from '@mui/material';
import { DPage } from '../structure';

export function DPageLoader() {
    return (
        <DPage centered>
            <CircularProgress size={100} />
        </DPage>
    );
}
