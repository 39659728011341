import { DSection, RhDateTimeInput, RhFormWrapper } from '../../../../../dum';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { useUpsertTransferDialogMutation } from '../../../../../../services';
import {
    getDateFromString,
    getDateTimeFromString,
    getStringFromDate,
    getStringFromDateTime,
    transformToNumber,
} from '../../../../../../helpers';
import { ToAccountInput } from './to-account/to-account-input';
import { FromAccountInput } from './from-account/from-account-input';
import { TransferReceiptColumns } from './transfer-receipt-columns/transfer-receipt-columns';
import { TransferTypeInput } from './transfer-type/transfer-type-input';
import { AmountInput } from './amount/amount';

export interface ITransfer {
    id?: number | null;
    amount: number;
    transfer_type_id?: number | null;
    from_account?: {
        id?: number | null;
        name: string;
        is_client?: boolean;
        is_own?: boolean;
        is_supplier?: boolean;
    } | null;
    to_account?: {
        id?: number | null;
        name: string;
        is_client?: boolean;
        is_own?: boolean;
        is_supplier?: boolean;
    } | null;
    transferred: boolean;
    transferred_date?: string | null;
    expected_date?: string | null;
    transfer_receipts: {
        id?: number | null;
        order_sale?: {
            id?: number | null;
            products_total?: number | null;
        } | null;
        expense?: {
            id?: number | null;
            total_with_tax?: number | null;
        } | null;
        total?: number | null;
        amount: number;
    }[];
}

export const TO_ACCOUNT: keyof ITransfer = 'to_account';
export const TRANSFER_TYPE_ID: keyof ITransfer = 'transfer_type_id';
export const FROM_ACCOUNT: keyof ITransfer = 'from_account';
export const TRANSFER_RECEIPTS: keyof ITransfer = 'transfer_receipts';

interface ITransferForm {
    transfer?: ITransfer | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function TransferForm({
    transfer,
    onSuccess,
    disabled = false,
}: ITransferForm) {
    const [mutation, { loading }] = useUpsertTransferDialogMutation();

    const initialValues = useMemo<ITransfer>(() => {
        return {
            id: transfer?.id || null,
            amount: transfer?.amount || 0,
            from_account: transfer?.from_account || null,
            transfer_type_id: transfer?.transfer_type_id || null,
            to_account: transfer?.to_account || null,
            transferred_date:
                transfer && transfer.transferred_date
                    ? getDateTimeFromString(transfer.transferred_date)
                    : null,
            expected_date:
                transfer && transfer.expected_date
                    ? getDateFromString(transfer.expected_date)
                    : null,
            transferred: transfer?.transferred || false,
            transfer_receipts: transfer?.transfer_receipts.map((tr) => {
                return {
                    expense: tr.expense || null,
                    order_sale: tr.order_sale || null,
                    total: tr.order_sale
                        ? tr.order_sale.products_total
                        : tr.expense
                        ? tr.expense.total_with_tax
                        : 0,
                    amount: tr.amount,
                };
            }) || [
                {
                    order_sale_id: null,
                    expense_id: null,
                    amount: 0,
                    total: 0,
                },
            ],
        };
    }, [transfer]);

    const validationSchema: SchemaOf<Omit<ITransfer, ''>> = yup.object({
        id: yup.number().notRequired().nullable(),
        transfer_type_id: yup.mixed().nullable().required(),
        amount: yup.number().transform(transformToNumber).nullable().required(),
        from_account: yup.mixed().nullable().notRequired(),
        to_account: yup.mixed().nullable().notRequired(),
        transferred_date: yup.string().nullable().required(),
        expected_date: yup.string().nullable(),
        transferred: yup.boolean().required(),
        transfer_receipts: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    expense_id: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .notRequired(),
                    order_sale: yup.mixed().nullable().notRequired(),
                    expense: yup.mixed().nullable().notRequired(),
                    total: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .notRequired(),
                    amount: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(),
                }),
            )
            .required('Required'),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            TransferUpsertInput: {
                                id: transfer?.id || null,
                                amount: data?.amount || 0,
                                transfer_type_id:
                                    data?.transfer_type_id || null,
                                to_account_id: data?.to_account?.id || null,
                                from_account_id: data?.from_account?.id || null,
                                transferred_date: data.transferred_date
                                    ? getStringFromDateTime(
                                          data?.transferred_date,
                                      )
                                    : null,
                                expected_date: data.expected_date
                                    ? getStringFromDate(data?.expected_date)
                                    : null,
                                transferred: data.transferred || false,
                                transfer_receipts: data?.transfer_receipts.map(
                                    (tr) => {
                                        return {
                                            id: tr.id || null,
                                            order_sale_id:
                                                tr.order_sale?.id || null,
                                            expense_id: tr.expense?.id || null,
                                            amount: tr.amount || 0,
                                        };
                                    },
                                ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <DSection alwaysOpen>
                <RhDateTimeInput name={'transferred_date'} label={'Fecha'} />
                <TransferTypeInput />
                <FromAccountInput />
                <ToAccountInput />
                <TransferReceiptColumns />
                <AmountInput />
            </DSection>
        </RhFormWrapper>
    );
}
