import React, { useEffect, useMemo, useState } from 'react';
import {
    ResourcesPageQuery,
    ResourcesSortableFields,
    ColumnOrder,
    useResourcesPageLazyQuery,
} from '../../../../../services';
import {
    CTableWrapper,
    DBlock,
    DeleteIcon,
    DPage,
    DPageBody,
    DPageHeader,
    DPaginator,
    DTextField,
    EditIcon,
    SortedOrder,
    usePaginator,
} from '../../../../dum';
import {
    SDeleteResourceDialog,
    SUpsertResourceDialog,
} from '../../../../smart';
import { Box, IconButton } from '@mui/material';
import { useString } from '../../../../../helpers';

export function ResourcesPage() {
    const [query, { data, previousData }] = useResourcesPageLazyQuery();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [resourceId, setResourceId] = useState<number | null>(null);

    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [filter, setFilter] = useString({
        KEY: ResourcesPage.name + '_Filter',
    });

    const { setSkip, skip, take } = usePaginator(
        {
            KEY: ResourcesPage.name + '_Paginator',
        },
        [filter],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                filter: filter,
                sortField:
                    sortedIndex === 0 ? ResourcesSortableFields.Name : null,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
            },
        });
    }, [query, skip, take, filter, sortedOrder, sortedIndex]);

    const resources =
        data?.paginatedResources.docs ||
        previousData?.paginatedResources.docs ||
        [];

    const count =
        data && data?.paginatedResources.count >= 0
            ? data?.paginatedResources.count
            : previousData?.paginatedResources.count;

    const handleEditResource = useMemo(() => {
        return (
            resource: ResourcesPageQuery['paginatedResources']['docs'][number],
        ) => {
            setResourceId(resource.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteResource = useMemo(() => {
        return (
            resource: ResourcesPageQuery['paginatedResources']['docs'][number],
        ) => {
            setResourceId(resource.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DPage>
            <DPageHeader title={'Recurso'} />
            <DPageBody>
                <DBlock>
                    <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <DTextField
                                label={'Filtro'}
                                value={filter}
                                onChange={(value) => {
                                    setFilter(value || '');
                                }}
                            />
                        </Box>
                    </Box>

                    <DPaginator
                        skip={skip}
                        take={take}
                        setSkip={setSkip}
                        count={count}
                    />
                    <CTableWrapper
                        sortedIndex={sortedIndex}
                        setSortedIndex={setSortedIndex}
                        sortedOrder={sortedOrder}
                        setSortedOrder={setSortedOrder}
                        contextItems={[
                            {
                                title: 'Editar',
                                callback: (resource) => {
                                    handleEditResource(resource);
                                },
                            },
                            {
                                title: 'Borrar',
                                disable: (resource) => {
                                    return !resource.is_deletable;
                                },
                                callback: (resource) => {
                                    handleDeleteResource(resource);
                                },
                            },
                        ]}
                        items={[
                            {
                                title: 'Nombre',
                                cellGrow: 45,
                                sortable: true,
                                content: (resource) => {
                                    return resource.name;
                                },
                            },
                            {
                                title: 'Categoria',
                                cellGrow: 45,
                                content: (resource) => {
                                    return resource?.resource_category?.name;
                                },
                            },
                            {
                                title: 'Acciones',
                                textAlign: 'center',
                                content: (resource) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    handleEditResource(
                                                        resource,
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={
                                                    !resource.is_deletable
                                                }
                                                onClick={() => {
                                                    handleDeleteResource(
                                                        resource,
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        data={resources}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertResourceDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                resourceId={resourceId}
            />
            <SDeleteResourceDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                resourceId={resourceId}
            />
        </DPage>
    );
}
