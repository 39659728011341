import { useWatch } from 'react-hook-form';
import { ITransfer, TRANSFER_TYPE_ID } from './transfer-form';
import { useMemo } from 'react';

export function useTransferType() {
    const transfer_type_id = useWatch({
        name: TRANSFER_TYPE_ID,
    }) as ITransfer['transfer_type_id'];

    return useMemo(() => {
        return {
            is_own_transfer: transfer_type_id === 1,
            is_client_transfer: transfer_type_id === 2,
            is_supplier_transfer: transfer_type_id === 3,
            is_adjustment_transfer: transfer_type_id === 4,
        };
    }, [transfer_type_id]);
}
