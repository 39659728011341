import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteAccountMutation } from '../../../../../../services';

interface IDeleteAccountProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    accountId: number | null;
}

export function SDeleteAccountDialog({
    open,
    setOpen,
    accountId,
}: IDeleteAccountProps) {
    const [deleteMutation] = useDeleteAccountMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (accountId) {
                    try {
                        await deleteMutation({
                            variables: {
                                AccountId: accountId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
