import React from 'react';
import { DPage, DPageBody, DPageHeader } from '../../../../dum';
import { MachinesListBlock } from './blocks/machine-list';

export function MachinesPage() {
    return (
        <DPage animated>
            <DPageHeader title={'Maquinas'} />
            <DPageBody disableGutters>
                <MachinesListBlock />
            </DPageBody>
        </DPage>
    );
}
