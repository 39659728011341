import React, { useMemo, useState } from 'react';
import { useRawMaterialAdditionFormQuery } from '../../../../../../services';
import { DModal, DPageLoader } from '../../../../../dum';
import { ProductIcon } from '../../../../icons';
import { RawMaterialAdditionForm } from './raw-material-addition-form';

interface IRawMaterialAdditionFormDialog {
    open: boolean;
    setOpen: (open: boolean) => void;
    rawMaterialAdditionId?: number | null;
}

export const SUpsertRawMaterialAdditionDialog = (
    props: IRawMaterialAdditionFormDialog,
) => {
    const { open, setOpen, rawMaterialAdditionId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useRawMaterialAdditionFormQuery({
        skip: !rawMaterialAdditionId || !open,
        variables: {
            RawMaterialAdditionId: rawMaterialAdditionId!,
        },
    });

    const rawMaterialAddition = useMemo(() => {
        return rawMaterialAdditionId &&
            data &&
            data.getRawMaterialAddition &&
            open
            ? data.getRawMaterialAddition
            : undefined;
    }, [data, rawMaterialAdditionId, open]);

    return (
        <DModal
            icon={<ProductIcon />}
            title={`Entrega de almacén`}
            open={open}
            setOpen={setOpen}
            width={'60rem'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <RawMaterialAdditionForm
                    disabled={isModalClosing}
                    onSuccess={() => {
                        setOpen(false);
                        setIsModalClosing(true);
                    }}
                    rawMaterialAddition={rawMaterialAddition}
                />
            )}
        </DModal>
    );
};
