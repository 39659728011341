import React from 'react';
import { RHTextField } from '../../../../../../../dum';
import { CURRENT_GROUP_PRICE, CURRENT_KILO_PRICE } from '../../product-form';
import { useFormContext } from 'react-hook-form';

export function CurrentKiloPriceInput() {
    const rx = useFormContext();

    return (
        <RHTextField
            name={CURRENT_KILO_PRICE}
            label={'Precio'}
            onChange={() => {
                void rx.trigger(CURRENT_GROUP_PRICE);
            }}
        />
    );
}
