import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage, DPageHeader } from '../../../dum';
import { ExpensesPage } from './expenses/expenses-page';
import { DFab } from '../../../dum/simple/button/d-fab';
import { SUpsertExpenseDialog } from '../../../smart';

const routes: Route[] = [
    {
        name: 'ExpensesPage',
        path: '/',
        component: <ExpensesPage />,
        title: 'Administración',
        navbar: false,
    },
];

export function ExpensesHomePage() {
    const [expenseUpsertDialog, setExpensesUpsertDialog] = useState(false);

    return (
        <DPage>
            <DPageHeader title={'Gastos'} />
            <DFab
                onClick={() => {
                    setExpensesUpsertDialog(true);
                }}
            />
            <ReactRouterRoutes>
                {routes.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertExpenseDialog
                open={expenseUpsertDialog}
                setOpen={setExpensesUpsertDialog}
            />
        </DPage>
    );
}
