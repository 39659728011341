import React, { useMemo } from 'react';
import { ProductToProduce } from '../products-to-produce';
import { DBlock, DBlockHeader } from '../../../../dum';
import { ProductsToProduceTable } from '../components/products-to-produce-table';

interface IProductToProduceByTypeProps {
    productsToProduce: ProductToProduce[];
    useGroups: boolean;
}

type ProductToProduceByWidthCalibre = Omit<ProductToProduce, 'product'> & {
    width: number;
    calibre: number;
    product_category_id?: number | null;
    product_category_name: string;
};

export function ProductsToProduceByWidthCalibreCategory({
    productsToProduce,
    useGroups,
}: IProductToProduceByTypeProps) {
    const productsToProduceByWidthCalibre = useMemo<
        ProductToProduceByWidthCalibre[]
    >(() => {
        const temporaryProductsToProduceByWidthCalibre: ProductToProduceByWidthCalibre[] =
            [];

        productsToProduce.forEach((productToProduce) => {
            const prevValue = temporaryProductsToProduceByWidthCalibre.find(
                (prevVal) => {
                    return (
                        prevVal.calibre === productToProduce.product?.calibre &&
                        prevVal.width === productToProduce.product?.width &&
                        prevVal.product_category_id ===
                            productToProduce.product?.product_category_id
                    );
                },
            );
            if (!prevValue) {
                temporaryProductsToProduceByWidthCalibre.push({
                    width: productToProduce.product?.width || 0,
                    calibre: productToProduce.product?.calibre || 0,
                    product_category_id:
                        productToProduce.product?.product_category_id,
                    product_category_name:
                        productToProduce.product?.product_category?.name || '',
                    ...productToProduce,
                });
            } else {
                prevValue.kilos_to_produce += productToProduce.kilos_to_produce;
                prevValue.kilos_in_inventory +=
                    productToProduce.kilos_in_inventory;
                prevValue.kilos_requested += productToProduce.kilos_requested;
                prevValue.kilos_sold += productToProduce.kilos_sold;
                prevValue.optimized_request_products =
                    productToProduce.optimized_request_products.concat(
                        prevValue.optimized_request_products,
                    );
            }
        });

        return temporaryProductsToProduceByWidthCalibre.filter(
            (p) => p.kilos_requested !== 0,
        );
    }, [productsToProduce]);

    return (
        <DBlock>
            <DBlockHeader subtitle={'Por ancho, calibre y categoria'} />
            <ProductsToProduceTable
                useGroups={useGroups}
                items={[
                    {
                        title: 'Categoria del producto ',
                        textAlign: 'left',
                        content: (i) => {
                            return i.product_category_name;
                        },
                        cellGrow: 2,
                    },
                    {
                        title: 'Ancho',
                        content: (i) => {
                            return i.width;
                        },
                        textAlign: 'right',
                    },
                    {
                        title: 'Calibre',
                        content: (i) => {
                            return i.calibre;
                        },
                        textAlign: 'right',
                    },
                ]}
                data={productsToProduceByWidthCalibre}
            />
        </DBlock>
    );
}
