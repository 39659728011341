import { useFormContext, UseFormReturn } from 'react-hook-form';
import {
    IExpense,
    TAX,
    NON_TAX_RETAINED,
    SUBTOTAL,
    TOTAL_WITH_TAX,
    TAX_RETAINED,
} from './expense-form';
import { isNumber } from '../../../../../../helpers';

function setExpenseTotal({ formContext }: { formContext: UseFormReturn<any> }) {
    const tax = isNumber(formContext.getValues(TAX))
        ? Number(formContext.getValues(TAX))
        : 0;
    const taxRetained = isNumber(formContext.getValues(TAX_RETAINED))
        ? Number(formContext.getValues(TAX_RETAINED))
        : 0;

    const nonTaxRetained = isNumber(formContext.getValues(NON_TAX_RETAINED))
        ? Number(formContext.getValues(NON_TAX_RETAINED))
        : 0;
    const subtotal = isNumber(formContext.getValues(SUBTOTAL))
        ? Number(formContext.getValues(SUBTOTAL))
        : 0;

    const totalWithTax = subtotal + tax - taxRetained - nonTaxRetained;

    formContext.setValue(TOTAL_WITH_TAX, Math.round(totalWithTax * 100) / 100, {
        shouldValidate: true,
    });
}

export function useCalculateExpenseTotal() {
    const formContext = useFormContext<IExpense>();

    const calculateExpenseTotal = function () {
        setExpenseTotal({ formContext });
    };

    return {
        calculateExpenseTotal: calculateExpenseTotal,
    };
}
