import { RhFormWrapper, RHTextField } from '../../../../../dum';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { useUpsertResourceDialogMutation } from '../../../../../../services';
import { transformToNumber } from '../../../../../../helpers';
import { ResourceCategoryInput } from './resource-category-id/resource-category-id-input';

export interface IResource {
    id?: number | null;
    name: string;
    resource_category_id?: number | null;
}

interface IResourceForm {
    resource?: IResource | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function ResourceForm({
    resource,
    onSuccess,
    disabled = false,
}: IResourceForm) {
    const [mutation, { loading }] = useUpsertResourceDialogMutation();

    const initialValues = useMemo<IResource>(() => {
        return {
            id: resource?.id || undefined,
            name: resource?.name || '',
            resource_category_id: resource?.resource_category_id || null,
        };
    }, [resource]);

    const validationSchema: SchemaOf<IResource> = yup.object({
        id: yup.number().notRequired().nullable(),
        name: yup.string().required(`'Nombre' es obligatorio`),
        resource_category_id: yup
            .number()
            .transform(transformToNumber)
            .required(`'Categoria' es obligatorio`)
            .nullable(),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            ResourceUpsertInput: {
                                id: resource?.id || null,
                                name: data.name,
                                resource_category_id:
                                    data.resource_category_id || null,
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <RHTextField name={'name'} label={'Nombre'} />
            <ResourceCategoryInput />
        </RhFormWrapper>
    );
}
