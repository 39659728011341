import React from 'react';
import { RHTextField } from '../../../../../../../../dum';
import { useCalculateProductsTotal } from '../../../helpers/use-calculate-products-total';
import {
    GROUP_PRICE,
    KILO_PRICE,
    ORDER_SALE_PRODUCTS,
} from '../order-sale-products-columns';
import { useFormContext, useWatch } from 'react-hook-form';
import { IOrderSale } from '../../../order-sale-form';

export function GroupPriceInput({ index }: { index: number }) {
    const INPUT_NAME = `${ORDER_SALE_PRODUCTS}.${index}.${GROUP_PRICE}`;
    const { calculateProductsTotal } = useCalculateProductsTotal();
    const rx = useFormContext();
    const kiloPrice = useWatch({
        name: `${ORDER_SALE_PRODUCTS}.${index}.${KILO_PRICE}`,
    }) as IOrderSale['order_sale_products'][number]['kilo_price'];

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'$ del bulto'}
            disabled={Number(kiloPrice) !== 0}
            disableGutters
            onChange={() => {
                calculateProductsTotal({ index });
                void rx.trigger(
                    `${ORDER_SALE_PRODUCTS}.${index}.${KILO_PRICE}`,
                );
            }}
        />
    );
}
