import React, { useEffect } from 'react';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import {
    EXPENSE_RAW_MATERIAL_ADDITIONS,
    IExpense,
    RAW_MATERIAL_ADDITION_ID,
} from '../../expense-form';
import {
    ExpenseRawMaterialAdditionInputQuery,
    useExpenseRawMaterialAdditionInputLazyQuery,
} from '../../../../../../../../services';
import { useWatch } from 'react-hook-form';
import { ACCOUNT_ID } from '../../../raw-material-addition/raw-material-addition-form';

export function RawMaterialAdditionInput({ index }: { index: number }) {
    const [query, { data }] = useExpenseRawMaterialAdditionInputLazyQuery();
    const INPUT_NAME = `${EXPENSE_RAW_MATERIAL_ADDITIONS}.${index}.${RAW_MATERIAL_ADDITION_ID}`;
    const account_id: IExpense['account_id'] = useWatch({
        name: ACCOUNT_ID,
    });
    useEffect(() => {
        if (account_id) {
            void query({
                variables: {
                    account_id,
                },
            });
        }
    }, [account_id, query]);

    const rawMaterialAdditions: ExpenseRawMaterialAdditionInputQuery['getRawMaterialAdditions'] =
        account_id
            ? data?.getRawMaterialAdditions
                ? data?.getRawMaterialAdditions
                : []
            : [];

    return (
        <RhSingleAutocomplete
            itemText={'compound_name'}
            itemValue={'id'}
            options={rawMaterialAdditions}
            name={INPUT_NAME}
            label={'Entrega de almancén'}
            disableGutters
        />
    );
}
