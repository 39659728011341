import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { TOrderSale } from '../order-sales-page';
import { useEffect } from 'react';
import { formatDate, formatFloat } from '../../../../../../helpers';

export function PrintableReceipt({ orderSale }: { orderSale: TOrderSale }) {
    useEffect(() => {
        if (!!orderSale) {
            window.print();
        }
    }, [orderSale]);
    if (!orderSale) {
        return null;
    }
    return (
        <Box
            className={'printable-block'}
            sx={{
                lineHeight: 1,
                fontFamily: `'Open Sans', sans-serif`,
                fontSize: '12px',
            }}
        >
            <section slot="pdf-content">
                <Box
                    id="container"
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        m: 0,
                        py: '0px',
                        px: '0px',
                    }}
                >
                    {[1, 2].map((_, index) => {
                        return (
                            <Box
                                className={'sub_container'}
                                sx={{
                                    width: '100%',
                                    border: 0,
                                    borderBottom: index === 0 ? 1 : 0,
                                    borderColor: '#bbb',
                                    borderStyle: 'dashed',
                                    paddingBottom: index === 0 ? '4rem' : 0,
                                }}
                            >
                                <Box
                                    className="first_row"
                                    sx={{
                                        m: 0,
                                        pt: index === 0 ? 0 : 1,
                                        pb: 1,
                                        width: '100%',
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            display: 'inline-block',
                                        }}
                                    >
                                        <Typography
                                            variant={'h3'}
                                            sx={{
                                                m: 0,
                                                fontFamily: `'Open Sans', sans-serif`,
                                                fontSize: '14px',
                                                color: '#444',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Inopack
                                        </Typography>
                                    </Box>
                                    <Box
                                        className="highlight_number"
                                        sx={{
                                            position: 'absolute',
                                            right: 1,
                                            fontSize: '1.5em',
                                        }}
                                    >
                                        {orderSale.receipt_type_id === 2
                                            ? 'F'
                                            : 'N'}
                                    </Box>
                                </Box>
                                <Box
                                    className="second_row"
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'stretch',
                                        m: 0,
                                        mb: 0.5,
                                    }}
                                >
                                    <Box
                                        className="account_information"
                                        sx={{
                                            display: 'flex',
                                            alignSelf: 'start',
                                        }}
                                    >
                                        Cliente: {orderSale.account?.name}{' '}
                                    </Box>
                                    <div className="receipt_information">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'start',
                                                mb: 0.5,
                                            }}
                                        >
                                            <Box>
                                                Remision #{orderSale.order_code}
                                            </Box>
                                        </Box>
                                        <Box>
                                            Fecha de emision:{' '}
                                            {formatDate(orderSale.date)}
                                        </Box>
                                    </div>
                                </Box>
                                <Box className="items">
                                    <Table size={'small'} padding={'none'}>
                                        <TableHead
                                            sx={{
                                                display: 'table-row-group',
                                            }}
                                        >
                                            <TableRow
                                                sx={{
                                                    pageBreakInside: 'avoid',
                                                }}
                                            >
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: '#888',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Kilos
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: '#888',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Codigo
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: '#888',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Descripcion
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: '#888',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Precio
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: '#888',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Bultos
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: '#888',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Costo total
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderSale.order_sale_products.map(
                                                (saleProduct) => {
                                                    return (
                                                        <TableRow
                                                            key={saleProduct.id}
                                                        >
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {
                                                                    saleProduct.kilos
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    textAlign:
                                                                        'center',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {
                                                                    saleProduct
                                                                        .product
                                                                        ?.code
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {
                                                                    saleProduct
                                                                        .product
                                                                        ?.external_description
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {formatFloat(
                                                                    saleProduct.kilo_price ||
                                                                        saleProduct.group_price,
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {
                                                                    saleProduct.groups
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {formatFloat(
                                                                    saleProduct.total,
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                },
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Box className="fourth-row">
                                    <Box
                                        className="summary"
                                        sx={{
                                            width: '100%',
                                            marginTop: '15px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            alignContent: 'flex-start',
                                        }}
                                    >
                                        <Box
                                            className="observations"
                                            sx={{
                                                border: '1px solid grey',
                                                width: '480px',
                                                height: '40px',
                                                textAlign: 'left',
                                                verticalAlign: 'top',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    fontSize: '.70em',
                                                    px: 0.5,
                                                }}
                                            >
                                                Observaciones
                                            </Box>
                                        </Box>
                                        <Box className="quantity">
                                            <Box
                                                className="item"
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Box
                                                    className="title"
                                                    sx={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    TOTAL:
                                                </Box>
                                                <Box
                                                    sx={{
                                                        color: '#666',
                                                    }}
                                                >
                                                    {formatFloat(
                                                        orderSale.products_total,
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    className="footer"
                                    sx={{
                                        margin: 'auto',
                                        display: 'flex',

                                        justifyContent: 'space-around',
                                        marginTop: '30px',
                                    }}
                                >
                                    {[
                                        'Firma del cliente',
                                        'Autorizado por',
                                        'Firma del almacenista',
                                    ].map((e) => {
                                        return (
                                            <Box
                                                sx={{
                                                    width: '150px',
                                                    height: '40px',
                                                    borderTop: '1px solid grey',
                                                    py: 0.5,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {e}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </section>
        </Box>
    );
}
