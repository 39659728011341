import React, { useMemo } from 'react';
import { ProductToProduce } from '../products-to-produce';
import { DBlock, DBlockHeader, DTextField } from '../../../../dum';
import { ProductsToProduceTable } from '../components/products-to-produce-table';
import { useString } from '../../../../../helpers';
import { Box } from '@mui/material';

interface IProductToProduceByTypeProps {
    productsToProduce: ProductToProduce[];
    useGroups?: boolean;
}

export function ProductsToProduceByProduct({
    productsToProduce,
    useGroups = false,
}: IProductToProduceByTypeProps) {
    const [filter, setFilter] = useString({
        KEY: ProductsToProduceByProduct.name + '_Filter',
    });

    const filteredProductsToProduce = useMemo(() => {
        return productsToProduce.filter((p) => {
            return (
                p.kilos_requested !== 0 &&
                (filter !== ''
                    ? p.product?.compound_description
                          ?.toLowerCase()
                          .includes(filter.toLowerCase())
                    : true)
            );
        });
    }, [productsToProduce, filter]);

    return (
        <DBlock>
            <DBlockHeader subtitle={'Por producto'} />
            <Box sx={{ mb: 2 }}>
                <DTextField
                    label={'Filtro'}
                    onChange={setFilter}
                    value={filter}
                />
            </Box>
            <ProductsToProduceTable
                useGroups={useGroups}
                items={[
                    {
                        title: 'Producto',
                        textAlign: 'left',
                        content: (i) => {
                            return i.product?.external_description;
                        },
                        cellGrow: 20,
                    },
                    {
                        title: 'Tipo',
                        textAlign: 'left',
                        content: (i) => {
                            return i.product?.product_category?.name;
                        },
                        cellGrow: 20,
                    },
                ]}
                data={filteredProductsToProduce}
            />
        </DBlock>
    );
}
