import React, { useEffect, useMemo } from 'react';
import {
    TransferReceiptOrderSaleInputQuery,
    useTransferReceiptOrderSaleInputLazyQuery,
} from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { FROM_ACCOUNT, ITransfer } from '../../transfer-form';
import { useFormContext, useWatch } from 'react-hook-form';
import { AMOUNT, TOTAL, TRANSFER_RECEIPTS } from '../transfer-receipt-columns';
import { useCalculateTransferReceiptsTotal } from '../../use-calculate-transfer-receipt-total';

const ARRAY_NAME: keyof ITransfer = 'transfer_receipts';
const ORDER_SALE_NAME: keyof ITransfer['transfer_receipts'][number] =
    'order_sale';

interface IResourceInput {
    index: number;
}

export function OrderSalesInput({ index }: IResourceInput) {
    const [query, { data }] = useTransferReceiptOrderSaleInputLazyQuery();
    const { calculateTransferReceiptsTotal } =
        useCalculateTransferReceiptsTotal();
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${ORDER_SALE_NAME}`;
    const fromAccount = useWatch({
        name: FROM_ACCOUNT,
    }) as ITransfer['from_account'];
    const rx = useFormContext();

    useEffect(() => {
        if (fromAccount?.is_client) {
            void query({
                variables: {
                    account_id: fromAccount.id,
                },
            });
        }
    }, [query, fromAccount]);

    const sales: TransferReceiptOrderSaleInputQuery['getOrderSales'] =
        useMemo(() => {
            return data?.getOrderSales || [];
        }, [data]);

    return (
        <RhSingleAutocomplete
            itemText={'compound_order_code'}
            itemValue={'id'}
            options={sales}
            name={INPUT_NAME}
            label={'Venta'}
            disableGutters
            returnObject
            onChange={(val: typeof sales[number]) => {
                rx.setValue(
                    `${TRANSFER_RECEIPTS}.${index}.${TOTAL}`,
                    val.products_total || 0,
                    {
                        shouldValidate: true,
                    },
                );
                rx.setValue(
                    `${TRANSFER_RECEIPTS}.${index}.${AMOUNT}`,
                    val.products_total || 0,
                    {
                        shouldValidate: true,
                    },
                );
                calculateTransferReceiptsTotal({ index });
            }}
        />
    );
}
