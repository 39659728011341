import React, { useState } from 'react';
import { DPage, DPageBody, DPageHeader } from '../../../../dum';
import { SalesListBlock } from './blocks/sales-list-block';
import { PrintableReceipt } from './blocks/printable-receipt';
import { OrderSalesPageQuery } from '../../../../../services';

export type TOrderSale =
    | NonNullable<
          NonNullable<OrderSalesPageQuery['paginatedOrderSales']>['docs']
      >[number]
    | null;

export function OrderSalesPage() {
    const [selectedOrderSale, setSelectedOrderSale] =
        useState<TOrderSale>(null);

    return (
        <DPage>
            <DPageHeader title={'Ventas'} />
            <DPageBody disableGutters>
                <SalesListBlock setSelectedOrderSale={setSelectedOrderSale} />
                <PrintableReceipt orderSale={selectedOrderSale} />
            </DPageBody>
        </DPage>
    );
}
