import { ValidationError } from 'yup';

export function isProductUnique(
    value: any,
    context: any,
): boolean | ValidationError {
    const { product } = context.from[0].value;

    if (!product) return true;

    const order_production_products =
        context.from[1].value.order_adjustment_products;

    const product_id = product.id;

    const results = order_production_products.filter((pProduct: any) => {
        const product_id_2 = pProduct.product ? pProduct.product.id : null;
        return product_id === product_id_2;
    });

    if (!(results.length <= 1)) {
        return context.createError({
            message: 'El producto no es unico',
        });
    }

    return true;
}
