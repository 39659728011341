import React, { useMemo } from 'react';
import { ProductToProduce } from '../products-to-produce';
import {
    CTableWrapper,
    ICustomTableWrapperItem,
    ICustomTableWrapperProps,
} from '../../../../dum';
import { formatDate, formatFloat } from '../../../../../helpers';
import { useTheme } from '@mui/material';

type CommonProductToProduce = Omit<ProductToProduce, 'product' | 'product_id'>;

interface IProductsToProduceTable<T extends CommonProductToProduce>
    extends Pick<ICustomTableWrapperProps<T>, 'data' | 'items'> {
    useGroups?: boolean;
}

export function ProductsToProduceTable<T extends CommonProductToProduce>({
    items,
    data,
    useGroups = false,
}: IProductsToProduceTable<T>) {
    const theme = useTheme();
    const tableWrapperItems = useMemo(() => {
        const localItems: ICustomTableWrapperItem[] = [
            ...items,
            {
                title: `${useGroups ? 'Bultos' : 'Kilos'} solicitados (+)`,
                content: (i) => {
                    return formatFloat(
                        useGroups ? i.groups_requested : i.kilos_requested,
                    );
                },
                textAlign: 'right',
                cellSx: (i) => {
                    return {
                        color: theme.palette.success.main,
                    };
                },
            },
            {
                title: `${useGroups ? 'Bultos' : 'Kilos'} en inventario (-)`,
                content: (i) => {
                    return formatFloat(
                        useGroups
                            ? i.groups_in_inventory
                            : i.kilos_in_inventory,
                    );
                },
                textAlign: 'right',
                cellSx: (i) => {
                    return {
                        color: theme.palette.error.main,
                    };
                },
            },

            {
                title: `${useGroups ? 'Bultos' : 'Kilos'} entregados (-)`,
                content: (i) => {
                    return formatFloat(
                        useGroups ? i.groups_sold : i.kilos_sold,
                    );
                },
                textAlign: 'right',
                cellSx: (i) => {
                    return {
                        color: theme.palette.error.main,
                    };
                },
            },
            {
                title: `${useGroups ? 'Bultos' : 'Kilos'} por producir (=)`,
                content: (i) => {
                    return formatFloat(
                        useGroups ? i.groups_to_produce : i.kilos_to_produce,
                    );
                },
                textAlign: 'right',
            },
        ];
        return localItems;
    }, [useGroups, items, theme]);

    return (
        <CTableWrapper
            stickyHeader
            items={tableWrapperItems}
            data={data}
            collapse={(i) => {
                return (
                    <CTableWrapper
                        items={[
                            {
                                title: 'Pedido',
                                content: (i) => {
                                    return i.order_code;
                                },
                            },
                            {
                                title: 'Producto',
                                cellGrow: 10,
                                textAlign: 'left',
                                content: (i) => {
                                    return i.product?.external_description;
                                },
                            },
                            {
                                title: 'Cliente',
                                cellGrow: 10,
                                content: (i) => {
                                    return i.account_name;
                                },
                            },
                            {
                                title: 'Status',
                                cellGrow: 5,
                                content: (i) => {
                                    return i.order_request_status_name;
                                },
                            },
                            {
                                title: 'Fecha estimada de entrega',
                                cellGrow: 5,
                                content: (i) => {
                                    return i.order_request_estimated_delivery_date
                                        ? formatDate(
                                              i.order_request_estimated_delivery_date,
                                          )
                                        : '-';
                                },
                            },
                            {
                                title: `${
                                    useGroups ? 'Bultos' : 'Kilos'
                                } solicitados`,
                                content: (i) => {
                                    return formatFloat(
                                        useGroups
                                            ? i.order_request_groups
                                            : i.order_request_kilos,
                                    );
                                },
                                textAlign: 'right',
                            },
                            {
                                title: `${
                                    useGroups ? 'Bultos' : 'Kilos'
                                } vendidos`,
                                content: (i) => {
                                    return formatFloat(
                                        useGroups
                                            ? i.order_sale_delivered_groups
                                            : i.order_sale_delivered_kilos,
                                    );
                                },
                                textAlign: 'right',
                            },
                            {
                                title: `${
                                    useGroups ? 'Bultos' : 'Kilos'
                                } por vender`,
                                content: (i) => {
                                    return formatFloat(
                                        useGroups
                                            ? i.order_sale_remaining_groups
                                            : i.order_sale_remaining_kilos,
                                    );
                                },
                                textAlign: 'right',
                            },
                        ]}
                        data={i.optimized_request_products}
                    />
                );
            }}
        />
    );
}
