import { DAutocomplete, IDAutocompleteProps } from './d-autocomplete';
import { useMemo } from 'react';

interface DIdAutocompleteProps<T extends { id: number }>
    extends Omit<IDAutocompleteProps<T>, 'itemValue' | 'value' | 'onChange'> {
    id: number | null;
    onChange: (val: number | null) => void;
}

export function DIdAutocomplete<T extends { id: number }>({
    options,
    onChange,
    id,

    ...rest
}: DIdAutocompleteProps<T>) {
    const value = useMemo<T | null>(() => {
        return (
            options.find((option) => {
                return option.id === id;
            }) || null
        );
    }, [id, options]);
    return (
        <DAutocomplete
            {...rest}
            onChange={(val) => {
                onChange(val?.id || null);
            }}
            itemValue={'id'}
            value={value}
            options={options}
        />
    );
}
