import { useTransferType } from '../use-transfer-type';
import { RHTextField } from '../../../../../../dum';
import React from 'react';

export function AmountInput() {
    const transferType = useTransferType();

    return (
        <RHTextField
            disabled={
                transferType.is_client_transfer ||
                transferType.is_supplier_transfer
            }
            name={'amount'}
            label={'Cantidad transferida'}
        />
    );
}
