import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { RhSingleAutocomplete } from '../../../../../../../../dum';
import { IOrderSale } from '../../../order-sale-form';
import { useCalculateProductsTotal } from '../../../helpers/use-calculate-products-total';
import { useOrderSaleProductInputQuery } from '../../../../../../../../../services';

const ORDER_SALE_PRODUCTS: keyof IOrderSale = 'order_sale_products';
const ORDER_REQUEST_NAME: keyof IOrderSale = 'order_request';
const PRODUCT_FIELD_NAME: keyof IOrderSale['order_sale_products'][number] =
    'product';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderSale['order_sale_products'][number] =
    'group_weight';
const KILO_PRICE_FIELD_NAME: keyof IOrderSale['order_sale_products'][number] =
    'kilo_price';

const GROUPS_FIELD_NAME: keyof IOrderSale['order_sale_products'][number] =
    'groups';
const KILOS_FIELD_NAME: keyof IOrderSale['order_sale_products'][number] =
    'kilos';

interface IProductsInput {
    index: number;
}

export function OrderSaleProductInput({ index }: IProductsInput) {
    const INPUT_NAME = `${ORDER_SALE_PRODUCTS}.${index}.${PRODUCT_FIELD_NAME}`;
    const orderRequest = useWatch({
        name: ORDER_REQUEST_NAME,
    }) as IOrderSale['order_request'];
    const orderSaleProducts = useWatch({
        name: `${ORDER_SALE_PRODUCTS}`,
    }) as IOrderSale['order_sale_products'];

    const { calculateProductsTotal } = useCalculateProductsTotal();
    const { data: products } = useOrderSaleProductInputQuery();

    const formContext = useFormContext();

    const productsData = useMemo(() => {
        return orderRequest
            ? orderRequest.order_request_remaining_products.map(
                  (rp) => rp.product!,
              )
            : products
            ? products?.getProducts
            : [];
    }, [orderRequest, products]);

    return (
        <RhSingleAutocomplete
            itemText={'compound_description'}
            itemValue={'id'}
            returnObject
            options={productsData}
            name={INPUT_NAME}
            label={'Producto'}
            onChange={(val) => {
                const inputValue =
                    val as IOrderSale['order_sale_products'][number]['product'];

                if (orderRequest?.order_request_remaining_products) {
                    const orderRequestRemainingProduct =
                        orderRequest?.order_request_remaining_products.find(
                            (p) => p?.product?.id === inputValue?.id,
                        );
                    if (orderRequestRemainingProduct && inputValue) {
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
                            orderRequestRemainingProduct.product
                                ?.current_group_weight,
                        );
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${KILO_PRICE_FIELD_NAME}`,
                            orderRequestRemainingProduct.kilo_price,
                        );

                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${KILOS_FIELD_NAME}`,
                            orderRequestRemainingProduct.kilos,
                        );
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${GROUPS_FIELD_NAME}`,
                            orderRequestRemainingProduct.groups,
                        );
                    } else {
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
                            0,
                        );
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${KILO_PRICE_FIELD_NAME}`,
                            0,
                        );
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${KILOS_FIELD_NAME}`,
                            0,
                        );
                        formContext.setValue(
                            `${ORDER_SALE_PRODUCTS}.${index}.${GROUPS_FIELD_NAME}`,
                            0,
                        );
                    }
                }
                orderSaleProducts.forEach((_, i) => {
                    if (i !== index) {
                        void formContext.trigger(
                            `${ORDER_SALE_PRODUCTS}.${i}.${PRODUCT_FIELD_NAME}`,
                        );
                    }
                });
                calculateProductsTotal();
            }}
            disableGutters
        />
    );
}
