import React from 'react';
import { useWatch } from 'react-hook-form';
import { EditOrderRequestInput } from './inputs/edit-order-request-input';
import { CreateOrderRequestInput } from './inputs/create-order-request-input';
import { IOrderSale } from '../../order-sale-form';

const ID_NAME: keyof IOrderSale = 'id';

export function OrderRequestInput() {
    const id = useWatch({
        name: ID_NAME,
    }) as IOrderSale['id'];

    if (!id) {
        return <CreateOrderRequestInput />;
    }

    return <EditOrderRequestInput />;
}
