import {
    isBagProduct,
    isOthersProduct,
} from '../../../../../../../services/entities';

export function isLengthRequired({
    order_production_type_id,
}: {
    order_production_type_id?: number | null;
}): boolean {
    if (!order_production_type_id) {
        return false;
    }

    return (
        isBagProduct({ order_production_type_id }) ||
        isOthersProduct({ order_production_type_id })
    );
}
