import * as React from 'react';
import { ReactElement } from 'react';
import { CreateIcon } from '../icon/index';
import { Fab } from '@mui/material';

export interface IFabProps {
    onClick: () => void | Promise<void>;
    icon?: ReactElement<any, any>;
}

export function DFab({ onClick, icon = <CreateIcon /> }: IFabProps) {
    return (
        <Fab
            color={'primary'}
            sx={{ position: 'absolute', bottom: 32, right: 32 }}
            onClick={() => {
                onClick();
            }}
            tabIndex={1}
        >
            {icon}
        </Fab>
    );
}
