import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOrderRequestProductInputQuery } from '../../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../../dum';
import { IOrderRequest } from '../../../order-request-form';
import { useCalculateProductsTotal } from '../../../helpers/use-calculate-products-total';
import {
    GROUP_PRICE,
    GROUP_WEIGHT,
    KILO_PRICE,
    ORDER_REQUEST_PRODUCTS,
    PRODUCT,
} from '../order-request-products-columns';

export function OrderProductionProductInput({ index }: { index: number }) {
    const { data: productsData } = useOrderRequestProductInputQuery();
    const INPUT_NAME = `${ORDER_REQUEST_PRODUCTS}.${index}.${PRODUCT}`;
    const orderRequestProductRow = useWatch({
        name: `${ORDER_REQUEST_PRODUCTS}.${index}`,
    }) as IOrderRequest['order_request_products'][number];

    const formContext = useFormContext();

    const { calculateProductsTotal } = useCalculateProductsTotal();

    return (
        <RhSingleAutocomplete
            itemText={'compound_description'}
            itemValue={'id'}
            returnObject
            options={productsData?.getProducts || []}
            name={INPUT_NAME}
            label={'Producto'}
            disableGutters
            disabled={!!orderRequestProductRow.id}
            onChange={(product) => {
                if (productsData && !!product && !orderRequestProductRow.id) {
                    const currentProduct = productsData.getProducts.find(
                        (p) => p.id === product.id,
                    );

                    if (currentProduct) {
                        formContext.setValue(
                            `${ORDER_REQUEST_PRODUCTS}.${index}.${GROUP_PRICE}`,
                            currentProduct.current_group_price,
                        );
                        formContext.setValue(
                            `${ORDER_REQUEST_PRODUCTS}.${index}.${KILO_PRICE}`,
                            currentProduct.current_kilo_price,
                        );
                        formContext.setValue(
                            `${ORDER_REQUEST_PRODUCTS}.${index}.${GROUP_WEIGHT}`,
                            currentProduct.current_group_weight || 0,
                        );
                    }
                    calculateProductsTotal();
                }
            }}
        />
    );
}
