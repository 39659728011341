import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import {
    clearMaintenanceModule,
    navigateToSparesPage,
    useDeleteSpareMutation,
} from '../../../../../../services';
import { useNavigate } from 'react-router-dom';

interface ISDeleteSpareDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    spare: {
        id: number;
        name: string;
    };
}

export function SDeleteSpareDialog({
    open,
    setOpen,
    spare,
}: ISDeleteSpareDialogProps) {
    const navigate = useNavigate();
    const [deleteMutation] = useDeleteSpareMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                await deleteMutation({
                    variables: {
                        SpareId: spare.id,
                    },
                });
                navigateToSparesPage({ navigate });
            }}
        />
    );
}
