import { TextField } from '@mui/material';
import * as React from 'react';
import { IInputDefaultProps } from './i-input-default-props';

interface IDTextField extends IInputDefaultProps {
    label: string;
    helperText?: string;
    onChange: (value: string) => void;
    value: string;
    disabled?: boolean;
    disableGutters?: boolean;
}

export function DTextField({
    label,
    helperText,
    onChange,
    value,
    disabled,
    disableGutters = true,
    error,
}: IDTextField) {
    return (
        <TextField
            onChange={(e) => {
                onChange(e.target.value || '');
            }}
            value={value}
            disabled={disabled}
            sx={{
                my: !disableGutters ? 2 : 0,
                width: '100%',
                minWidth: '25rem',
            }}
            label={label}
            helperText={helperText || ''}
            margin={'dense'}
            size={'small'}
        />
    );
}
