import React from 'react';
import { Box, Container, Paper, Toolbar } from '@mui/material';

interface IContentLayoutProps {
    children: React.ReactNode | React.ReactNode[];
    isDesktop: boolean;
}

export function DAppContent({ children, isDesktop }: IContentLayoutProps) {
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {!isDesktop ? <Toolbar /> : null}
            <Container
                maxWidth="lg"
                sx={{
                    mt: 3,
                    mb: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Paper>
            </Container>
        </Box>
    );
}
