import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../../dum';
import { GroupsInput } from './groups/groups-input';
import { KiloPriceInput } from './kilo-price/kilo-price-input';
import { KilosInput } from './kilos/kilos-input';
import { OrderProductionProductInput } from './product/product-input';
import { useWatch } from 'react-hook-form';
import { IOrderRequest } from '../../order-request-form';
import { useCalculateProductsTotal } from '../../helpers/use-calculate-products-total';
import { GroupPriceInput } from './group-price/group-price-input';

export const ORDER_REQUEST_PRODUCTS: keyof IOrderRequest =
    'order_request_products';
const ORDER_SALE_SOLD_PRODUCTS_NAME: keyof IOrderRequest =
    'order_sale_sold_products';

export const GROUP_WEIGHT: keyof IOrderRequest['order_request_products'][number] =
    'group_weight';
export const KILO_PRICE: keyof IOrderRequest['order_request_products'][number] =
    'kilo_price';
export const GROUP_PRICE: keyof IOrderRequest['order_request_products'][number] =
    'group_price';
export const GROUPS: keyof IOrderRequest['order_request_products'][number] =
    'groups';
export const KILOS: keyof IOrderRequest['order_request_products'][number] =
    'kilos';
export const PRODUCT: keyof IOrderRequest['order_request_products'][number] =
    'product';

const defaultValue: IOrderRequest['order_request_products'][number] = {
    product: null,
    kilos: 0,
    groups: 0,
    group_weight: 0,
    kilo_price: 0,
    group_price: 0,
};

export function OrderRequestProductsColumns() {
    const orderSaleSoldProducts = useWatch({
        name: ORDER_SALE_SOLD_PRODUCTS_NAME,
    }) as IOrderRequest['order_sale_sold_products'];
    const arrayValue = useWatch({
        name: ORDER_REQUEST_PRODUCTS,
    }) as IOrderRequest['order_request_products'];

    const { calculateProductsTotal } = useCalculateProductsTotal();

    return (
        <RhColumns
            title={'Productos'}
            name={ORDER_REQUEST_PRODUCTS}
            defaultValue={defaultValue}
            disableDelete={(rowIndex) => {
                const row = arrayValue[rowIndex];
                const saleSoldProduct = orderSaleSoldProducts.find(
                    (soldProduct) =>
                        soldProduct.product_id === row?.product?.id,
                );
                return !!saleSoldProduct;
            }}
            onDelete={() => {
                calculateProductsTotal();
            }}
            items={[
                {
                    cellGrow: 3,
                    getField: (index) => {
                        return <OrderProductionProductInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <GroupsInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <GroupPriceInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ORDER_REQUEST_PRODUCTS}.${index}.${GROUP_WEIGHT}`}
                                label={'Peso'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return <KilosInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <KiloPriceInput index={index} />;
                    },
                },
            ]}
        />
    );
}
