import React from 'react';
import { RhCheckbox, RhFormWrapper, RHTextField } from '../../../../../dum';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    clearMaintenanceModule,
    useUpsertMachineMutation,
} from '../../../../../../services';
import { BranchInput } from './branch/branch-input';
import { OrderProductionTypeInput } from './order-production-type/order-production-type-input';

export interface IMachine {
    name: string;
    id?: number | null;
    branch_id?: number | null;
    order_production_type_id?: number | null;
    discontinued: boolean;
}

interface IMachineForm {
    machine?: IMachine | null;
    onSuccess: () => void;
    disabled: boolean;
}

export const MachineForm = (props: IMachineForm) => {
    const { machine, onSuccess, disabled } = props;

    const [mutation, { loading }] = useUpsertMachineMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    const initialValues: IMachine = {
        name: machine?.name || '',
        order_production_type_id: machine?.order_production_type_id || null,
        branch_id: machine ? machine.branch_id : null,
        discontinued: machine?.discontinued || false,
    };

    const validationSchema: SchemaOf<IMachine> = yup.object({
        id: yup.number().nullable().notRequired(),
        name: yup.string().required(),
        discontinued: yup.boolean().required(),
        branch_id: yup
            .number()
            .required(`'Sucursal' es obligatorio`)
            .nullable(),
        order_production_type_id: yup
            .number()
            .required(`'Orden de producción' es obligatorio`)
            .nullable(),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data, event) => {
                await mutation({
                    variables: {
                        MachineUpsertInput: {
                            id: machine?.id || null,
                            name: data.name,
                            branch_id: data.branch_id,
                            order_production_type_id:
                                data.order_production_type_id,
                            discontinued: data.discontinued,
                        },
                    },
                });
                onSuccess();
                return;
            }}
            onInvalid={async (errors) => {
                return;
            }}
        >
            <RHTextField name={'name'} label={'Nombre'} />
            <BranchInput />
            <OrderProductionTypeInput />
            <RhCheckbox name={'discontinued'} label={'Descontinuado'} />
        </RhFormWrapper>
    );
};
