import React, { useMemo } from 'react';
import { IRhColumnsItem, RhColumns, RHTextField } from '../../../../../../dum';
import { FROM_ACCOUNT, ITransfer, TO_ACCOUNT } from '../transfer-form';
import { OrderSalesInput } from './order-sale/order-sale-input';
import { ExpensesInput } from './expense/expense-input';
import { useCalculateTransferReceiptsTotal } from '../use-calculate-transfer-receipt-total';
import { useTransferType } from '../use-transfer-type';
import { useWatch } from 'react-hook-form';

const defaultValue: ITransfer['transfer_receipts'][number] = {
    order_sale: null,
    expense: null,
    total: 0,
    amount: 0,
};

export const TRANSFER_RECEIPTS: keyof ITransfer = 'transfer_receipts';
export const TOTAL: keyof ITransfer['transfer_receipts'][number] = 'total';
export const AMOUNT: keyof ITransfer['transfer_receipts'][number] = 'amount';
export const EXPENSE: keyof ITransfer['transfer_receipts'][number] = 'expense';

export function TransferReceiptColumns() {
    const transferType = useTransferType();

    const { calculateTransferReceiptsTotal } =
        useCalculateTransferReceiptsTotal();

    const toAccount = useWatch({
        name: TO_ACCOUNT,
    }) as ITransfer['to_account'];

    const fromAccount = useWatch({
        name: FROM_ACCOUNT,
    }) as ITransfer['from_account'];

    const rhColumnsItems: IRhColumnsItem[] = useMemo(() => {
        const items: IRhColumnsItem[] = [];

        if (
            (transferType.is_adjustment_transfer ||
                transferType.is_client_transfer) &&
            fromAccount?.is_client
        ) {
            items.push({
                getField: (index) => {
                    return <OrderSalesInput index={index} />;
                },
            });
        }

        if (
            (transferType.is_adjustment_transfer ||
                transferType.is_supplier_transfer) &&
            toAccount?.is_supplier
        ) {
            items.push({
                getField: (index) => {
                    return <ExpensesInput index={index} />;
                },
            });
        }

        items.push({
            getField(index) {
                return (
                    <RHTextField
                        name={`${TRANSFER_RECEIPTS}.${index}.${TOTAL}`}
                        label={
                            transferType.is_client_transfer
                                ? 'Total (iva incluido) de la venta'
                                : 'Total (iva incluido) del gasto'
                        }
                        disabled
                    />
                );
            },
        });

        items.push({
            getField(index) {
                return (
                    <RHTextField
                        name={`${TRANSFER_RECEIPTS}.${index}.${AMOUNT}`}
                        label={'Cantidad transferida'}
                        onChange={() => {
                            calculateTransferReceiptsTotal({
                                index,
                            });
                        }}
                    />
                );
            },
        });

        return items;
    }, [transferType, calculateTransferReceiptsTotal, toAccount, fromAccount]);

    return transferType.is_supplier_transfer ||
        transferType.is_client_transfer ||
        transferType.is_adjustment_transfer ? (
        <RhColumns
            title={`${transferType.is_client_transfer ? 'Ventas' : ''}${
                transferType.is_supplier_transfer ? 'Gastos' : ''
            }${transferType.is_adjustment_transfer ? 'Ajuste' : ''}`}
            name={TRANSFER_RECEIPTS}
            defaultValue={defaultValue}
            items={rhColumnsItems}
            onDelete={() => {
                calculateTransferReceiptsTotal();
            }}
            disableDelete={(index) => {
                if (
                    index === 0 &&
                    (transferType.is_supplier_transfer ||
                        transferType.is_client_transfer)
                ) {
                    return true;
                }
                return false;
            }}
        />
    ) : null;
}
