import React, { useEffect } from 'react';
import { useFromAccountIdInputLazyQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { ITransfer } from '../transfer-form';
import { useTransferType } from '../use-transfer-type';

export function FromAccountInput() {
    const inputName: keyof ITransfer = 'from_account';
    const [query, { data }] = useFromAccountIdInputLazyQuery();
    const transferType = useTransferType();

    useEffect(() => {
        if (transferType) {
            void query({
                variables: {
                    isClient:
                        transferType.is_client_transfer ||
                        transferType.is_adjustment_transfer,
                    isOwn:
                        transferType.is_own_transfer ||
                        transferType.is_supplier_transfer ||
                        transferType.is_adjustment_transfer,
                    isSupplier: transferType.is_adjustment_transfer,
                },
            });
        }
    }, [query, transferType]);

    return (
        <RhSingleAutocomplete
            itemText={'compound_name'}
            itemValue={'id'}
            name={inputName}
            label={'Retiro'}
            options={data?.getAccounts || []}
            returnObject
        />
    );
}
