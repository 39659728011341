import React from 'react';

import { MachinePageQuery } from '../../../../../../services';
import { DBlock, DBlockHeader, CTableWrapper } from '../../../../../dum';
import {
    MachinePartIcon,
    SMachinePartLink,
    SSpareLink,
} from '../../../../../smart';

interface IMachineUnassignedPartsListProps {
    unassigned_parts: NonNullable<
        MachinePageQuery['getMachine']
    >['unassigned_parts'];
}

export function MachineUnassignedPartsList({
    unassigned_parts,
}: IMachineUnassignedPartsListProps) {
    return (
        <DBlock>
            <DBlockHeader
                subtitle={'Partes sin asignar'}
                icon={<MachinePartIcon />}
                iconTooltip={'Parte'}
            />
            <CTableWrapper
                data={unassigned_parts}
                items={[
                    {
                        title: 'Parte de la maquina',
                        content: (part) => (
                            <SMachinePartLink
                                machineId={part.machine_id!}
                                machinePartId={part?.id}
                                partName={part.name}
                            />
                        ),
                    },
                    {
                        title: 'Refacción actual',
                        content: (part) => {
                            if (part.current_spare) {
                                return (
                                    <SSpareLink
                                        spareId={part.current_spare.id}
                                        spareName={part.current_spare.name}
                                    />
                                );
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Cantidad en inventario',
                        textAlign: 'right',
                        content: (part) => part.current_spare?.current_quantity,
                    },
                    {
                        title: 'Cantidad requerida',
                        textAlign: 'right',
                        content: (part) => part.current_spare_required_quantity,
                    },
                    {
                        title: 'Refaccións compatibles',
                        cellGrow: 2,
                        content: (part) =>
                            part.machine_compatibilities
                                .map(
                                    (machine_compatibility) =>
                                        machine_compatibility.spare?.name,
                                )
                                .join(', '),
                    },
                ]}
            />
        </DBlock>
    );
}
