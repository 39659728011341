import { NavigateFunction } from 'react-router-dom';
import {
    getMachinePartPagePath,
    getMachinePagePath,
    getMachineSectionPagePath,
    getMachinesPagePath,
    getSpareAdjustmentPagePath,
    getSpareAdjustmentsPagePath,
    getSpareCategoriesPagePath,
    getSpareCategoryPagePath,
    getSparesPagePath,
    getSpareTransactionsPagePath,
} from './get-route-path';

export function navigateToMachinePage({
    navigate,
    machineId,
    replace = false,
}: Parameters<typeof getMachinePagePath>[0] & {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(
        getMachinePagePath({
            machineId: machineId,
        }),
        {
            replace,
        },
    );
}

export function navigateToMachineSectionPage({
    navigate,
    machineId,
    machineSectionId,
}: Parameters<typeof getMachineSectionPagePath>[0] & {
    navigate: NavigateFunction;
}) {
    navigate(
        getMachineSectionPagePath({
            machineId,
            machineSectionId,
        }),
    );
}

export function navigateToMachinePartPage({
    navigate,
    machineId,
    machinePartId,
}: Parameters<typeof getMachinePartPagePath>[0] & {
    navigate: NavigateFunction;
}) {
    navigate(
        getMachinePartPagePath({
            machineId,
            machinePartId,
        }),
    );
}

export function navigateToSpareTransactionsPage({
    navigate,
}: {
    navigate: NavigateFunction;
}) {
    navigate(getSpareTransactionsPagePath());
}

export function navigateToSpareAdjustmentsPage({
    navigate,
    replace = false,
}: {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getSpareAdjustmentsPagePath(), { replace });
}

export function navigateToSpareCategoryPage({
    navigate,
    spareCategoryId,
}: Parameters<typeof getSpareCategoryPagePath>[0] & {
    navigate: NavigateFunction;
}) {
    navigate(
        getSpareCategoryPagePath({
            spareCategoryId,
        }),
    );
}

export function navigateToSparesPage({
    navigate,
}: {
    navigate: NavigateFunction;
}) {
    navigate(getSparesPagePath());
}

export function navigateToMachinesPage({
    navigate,
    replace = false,
}: {
    navigate: NavigateFunction;
    replace: boolean;
}) {
    navigate(getMachinesPagePath(), {
        replace,
    });
}

export function navigateToSpareAdjustmentPage({
    navigate,
    spareAdjustmentId,
}: Parameters<typeof getSpareAdjustmentPagePath>[0] & {
    navigate: NavigateFunction;
}) {
    navigate(
        getSpareAdjustmentPagePath({
            spareAdjustmentId,
        }),
    );
}

export function navigateToSpareCategoriesPage({
    navigate,
    replace = false,
}: {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getSpareCategoriesPagePath(), {
        replace,
    });
}
