import React, { useEffect, useMemo, useState } from 'react';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPaginator,
    DYear,
    EditIcon,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../../dum';
import { Box, IconButton } from '@mui/material';
import {
    OrderProductionsPageQuery,
    useOrderProductionsPageLazyQuery,
} from '../../../../../../services';
import {
    SDeleteOrderProductionDialog,
    SUpsertOrderProductionDialog,
} from '../../../../../smart';
import {
    formatDate,
    formatFloat,
    formatRelativeDate,
    useBoolean,
    useNumber,
} from '../../../../../../helpers';
import { DCheckbox } from '../../../../../dum/simple/inputs/d-checkbox';

export function OrderProductionsListBlock() {
    const [query, { data, previousData }] = useOrderProductionsPageLazyQuery();
    const [orderProductionId, setOrderProductionId] = useState<number | null>(
        null,
    );

    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [orderProductionTypeId, setOrderProductionTypeId] = useNumber({
        KEY: OrderProductionsListBlock.name + '_OrderProductionTypeId',
    });

    const [branchId, setBranchId] = useNumber({
        KEY: OrderProductionsListBlock.name + '_BranchId',
    });

    const { year, setYear } = useDYear({
        COMPONENT_KEY: OrderProductionsListBlock.name + '_Year',
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: OrderProductionsListBlock.name + '_Month',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: OrderProductionsListBlock.name + '_Paginator',
        },
        [branchId, month, year, orderProductionTypeId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                orderProductionTypeId: orderProductionTypeId,
                branchId: branchId,
            },
        });
    }, [query, skip, take, year, month, orderProductionTypeId, branchId]);

    const [defaultCollapseOpen, setDefaultCollapseOpen] = useBoolean({
        KEY: OrderProductionsListBlock.name + '_DefaultOpen',
        defaultValue: false,
    });

    const handleEditOrderProduction = useMemo(() => {
        return (
            orderProduction: OrderProductionsPageQuery['paginatedOrderProductions']['docs'][number],
        ) => {
            setOrderProductionId(orderProduction.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteOrderProduction = useMemo(() => {
        return (
            orderProduction: OrderProductionsPageQuery['paginatedOrderProductions']['docs'][number],
        ) => {
            setOrderProductionId(orderProduction.id);
            setDeleteOpen(true);
        };
    }, []);

    return (
        <DBlock>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ flex: 1, mr: 1 }}>
                    <DIdAutocomplete
                        id={orderProductionTypeId}
                        onChange={setOrderProductionTypeId}
                        itemText={'name'}
                        label={'Tipo de orden de producción'}
                        options={data?.getOrderProductionTypes || []}
                    />
                </Box>
                <Box sx={{ flex: 1, ml: 1 }}>
                    <DIdAutocomplete
                        id={branchId}
                        onChange={setBranchId}
                        itemText={'name'}
                        label={'Sucursal'}
                        options={data?.getBranches || []}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <CVerticalDropdown
                        items={[
                            {
                                component: (
                                    <DYear setYear={setYear} year={year} />
                                ),
                            },
                            {
                                component: (
                                    <DMonth setMonth={setMonth} month={month} />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        onChange={(val) => {
                                            setDefaultCollapseOpen(val);
                                        }}
                                        value={defaultCollapseOpen}
                                        label={'Abrir filas'}
                                        disableGutters
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>

            <DPaginator
                skip={skip}
                take={take}
                setSkip={setSkip}
                count={
                    data && data?.paginatedOrderProductions.count >= 0
                        ? data?.paginatedOrderProductions.count
                        : previousData?.paginatedOrderProductions.count
                }
            />
            <CTableWrapper
                onClick={(orderProduction) => {
                    handleEditOrderProduction(orderProduction);
                }}
                contextItems={[
                    {
                        title: 'Editar',
                        callback: (orderProduction) => {
                            handleEditOrderProduction(orderProduction);
                        },
                    },
                    {
                        title: 'Borrar',
                        callback: (orderProduction) => {
                            handleDeleteOrderProduction(orderProduction);
                        },
                    },
                ]}
                items={[
                    {
                        title: 'Ultima actualización',
                        content: (orderProduction) => {
                            return formatRelativeDate(
                                orderProduction.updated_at,
                            );
                        },
                    },
                    {
                        title: 'Fecha',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (orderProduction) => {
                            return formatDate(orderProduction.start_date);
                        },
                    },
                    {
                        title: 'Tipo de orden de producción',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (orderProduction) => {
                            return orderProduction.order_production_type?.name;
                        },
                    },
                    {
                        title: 'Sucursal',
                        cellGrow: 10,
                        textAlign: 'center',
                        content: (orderProduction) => {
                            return orderProduction.branch?.name;
                        },
                    },
                    {
                        title: 'Desperdicio',
                        textAlign: 'right',
                        content: (orderProduction) => {
                            return formatFloat(orderProduction.waste);
                        },
                    },
                    {
                        title: 'Empleados',
                        cellGrow: 15,
                        content: (orderProduction) => {
                            return orderProduction.order_production_employees
                                .map((ope) => ope.employee!.fullname)
                                .join(', ');
                        },
                    },
                    {
                        title: 'Acciones',
                        content: (orderProduction) => {
                            return (
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        onClick={() => {
                                            handleEditOrderProduction(
                                                orderProduction,
                                            );
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!orderProduction.is_deletable}
                                        onClick={() => {
                                            handleDeleteOrderProduction(
                                                orderProduction,
                                            );
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
                collapse={(orderProduction) => {
                    return (
                        <CTableWrapper
                            padding={false}
                            items={[
                                {
                                    title: 'Producto',
                                    cellGrow: 30,
                                    content: (osp) =>
                                        osp.product?.compound_description,
                                },
                                {
                                    title: 'Maquina',
                                    cellGrow: 30,
                                    content: (osp) => osp.machine?.name,
                                },
                                {
                                    title: 'Grupos',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) => formatFloat(osp.groups),
                                },
                                {
                                    title: 'Kilos',
                                    cellGrow: 10,
                                    textAlign: 'right',
                                    content: (osp) => formatFloat(osp.kilos),
                                },
                            ]}
                            data={orderProduction.order_production_products}
                        />
                    );
                }}
                defaultOpenCollapse={defaultCollapseOpen}
                data={
                    data?.paginatedOrderProductions.docs ||
                    previousData?.paginatedOrderProductions.docs ||
                    []
                }
            />
            <SUpsertOrderProductionDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                orderProductionId={orderProductionId}
            />
            <SDeleteOrderProductionDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                orderProductionId={orderProductionId}
            />
        </DBlock>
    );
}
