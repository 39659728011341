import React from 'react';
import { useExpenseReceiptTypeIdInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IExpense } from '../expense-form';

export function ExpenseReceiptTypeIdInput() {
    const inputName: keyof IExpense = 'receipt_type_id';
    const { data } = useExpenseReceiptTypeIdInputQuery();

    const receiptTypes = data?.getReceiptTypes || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Tipo de gasto'}
            options={receiptTypes}
        />
    );
}
