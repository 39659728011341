import { Button } from '@mui/material';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import React from 'react';
import { ProductionSummaryPageQuery } from '../../../../services';
import dayjs from 'dayjs';

export function ProductionSummaryExcelExportButton({
    production,
    waste,
    disabled,
}: {
    production?: ProductionSummaryPageQuery['getAllProductionSummary']['production'];
    waste?: ProductionSummaryPageQuery['getAllProductionSummary']['waste'];
    disabled: boolean;
}) {
    return (
        <Button
            disabled={disabled}
            onClick={async () => {
                const workbook = new Excel.Workbook();
                const productionWs = workbook.addWorksheet('Producción');
                productionWs.addRow([
                    'Año',
                    'Mes',
                    'Mes (#)',
                    'Tipo de orden de prod',
                    'Producto',
                    'Categoria',
                    'Ancho',
                    'Largo',
                    'Calibre',
                    'Maquina',
                    'Sucursal',
                    'Kilos',
                    'Horas',
                ]);
                production?.forEach((prd, index) => {
                    productionWs.addRow([
                        prd.year,
                        prd.month !== null && prd.month !== undefined
                            ? dayjs()
                                  .set('month', prd.month - 1)
                                  .format('MMMM')
                            : '',
                        prd.month,
                        prd.order_production_type_name,
                        prd.product_description,
                        prd.product_category_name,
                        prd.width,
                        prd.length,
                        prd.calibre,
                        prd.machine_name,
                        prd.branch_name,
                        prd.kilos,
                        prd.hours,
                    ]);

                    productionWs.columns.forEach((c, i) => {
                        let maxColumnLength = 6;
                        const column = productionWs.getColumn(i);
                        if (column) {
                            column.eachCell({ includeEmpty: true }, (cell) => {
                                maxColumnLength = Math.max(
                                    maxColumnLength,
                                    cell.value
                                        ? cell.value.toString().length
                                        : 0,
                                );
                            });

                            column.width = maxColumnLength;
                        }
                    });

                    productionWs.getColumn(6).alignment = {
                        horizontal: 'right',
                    };
                });

                const wasteWs = workbook.addWorksheet('Desperdicio');
                wasteWs.addRow([
                    'Año',
                    'Mes',
                    'Mes (#)',
                    'Tipo de orden de prod',
                    'Desperdicio',
                ]);
                waste?.forEach((wst, index) => {
                    productionWs.addRow([
                        wst.year,
                        wst.month !== null && wst.month !== undefined
                            ? dayjs()
                                  .set('month', wst.month - 1)
                                  .format('MMMM')
                            : '',
                        wst.month,
                        wst.order_production_type_name,
                        wst.waste,
                    ]);

                    wasteWs.columns.forEach((c, i) => {
                        let maxColumnLength = 6;
                        const column = productionWs.getColumn(i);
                        if (column) {
                            column.eachCell({ includeEmpty: true }, (cell) => {
                                maxColumnLength = Math.max(
                                    maxColumnLength,
                                    cell.value
                                        ? cell.value.toString().length
                                        : 0,
                                );
                            });

                            column.width = maxColumnLength;
                        }
                    });

                    productionWs.getColumn(6).alignment = {
                        horizontal: 'right',
                    };
                });

                const buffer = await workbook.xlsx.writeBuffer();

                FileSaver.saveAs(new Blob([buffer]), 'produccion.xlsx');
            }}
        >
            Descargar reporte
        </Button>
    );
}
