import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProductPageQuery } from '../../../../../services';
import {
    CTableWrapper,
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
    DRowButtons,
    EditIcon,
} from '../../../../dum';
import { SUpsertProductDialog } from '../../../../smart';
import { formatFloat } from '../../../../../helpers';

type TProductPageParams = {
    productId: string;
};

export function ProductPage() {
    const params = useParams<TProductPageParams>();
    const { data, loading, error } = useProductPageQuery({
        variables: {
            ProductId: params.productId ? Number(params.productId) : 0,
        },
        notifyOnNetworkStatusChange: true,
    });
    const [upsertDialog, setUpsertDialog] = useState(false);

    const orderProductionProductsOptimized = useMemo<any[]>(() => {
        const record: Record<string, any> = {};
        const arr: any[] = [];

        if (data) {
            const machines = data.getMachines;

            data.getProduct.order_production_products.forEach((orp) => {
                const key = String(orp.machine_id);
                if (!record[key]) {
                    record[key] = {
                        count: 1,
                        max_groups: orp.groups,
                        min_groups: orp.groups,
                        total_groups: orp.groups,
                        avg_groups: 0,
                        max_kilos: orp.kilos,
                        min_kilos: orp.kilos,
                        total_kilos: orp.kilos,
                        avg_kilos: 0,
                    };
                } else {
                    record[key].count = record[key].count + 1;
                    record[key].min_groups =
                        record[key].min_groups > orp.groups
                            ? orp.groups
                            : record[key].min_groups;
                    record[key].max_groups =
                        record[key].max_groups < orp.groups
                            ? orp.groups
                            : record[orp.machine_id].max_groups;
                    record[key].total_groups += orp.groups;
                    record[key].min_kilos =
                        record[key].min_kilos > orp.kilos
                            ? orp.kilos
                            : record[key].min_kilos;
                    record[key].max_kilos =
                        record[key].max_kilos < orp.kilos
                            ? orp.kilos
                            : record[key].max_kilos;
                    record[key].total_kilos += orp.kilos;
                }
            });

            for (const key in record) {
                const el = record[key];
                const machine = machines.find((machine) => {
                    return String(machine.id) === key;
                });
                arr.push({
                    ...el,
                    machine_name: machine ? machine.name : null,
                    avg_groups: el.total_groups / el.count,
                    avg_kilos: el.total_kilos / el.count,
                });
            }

            arr.sort((a, b) => {
                return b.count - a.count;
            });
        }

        return arr;
    }, [data]);

    if (loading) {
        return <DPageLoader />;
    }

    if (error || !data) {
        return <DErrorCard />;
    }
    const product = data.getProduct;

    return (
        <DPage animated>
            <DPageHeader title={product.compound_description}>
                <DRowButtons
                    rowItems={[
                        {
                            tooltip: 'Editar producto',
                            icon: <EditIcon />,
                            callback: () => {
                                setUpsertDialog(true);
                            },
                        },
                    ]}
                />
            </DPageHeader>
            <DPageBody>
                <CTableWrapper
                    items={[
                        {
                            title: 'Maquina',
                            content: (item) => {
                                return item.machine_name;
                            },
                        },
                        {
                            title: 'Conteo',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.count);
                            },
                        },
                        {
                            title: 'Min kilos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.min_kilos);
                            },
                        },
                        {
                            title: 'Max kilos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.max_kilos);
                            },
                        },
                        {
                            title: 'Promedio kilos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.avg_kilos);
                            },
                        },
                        {
                            title: 'Min grupos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.min_groups);
                            },
                        },
                        {
                            title: 'Max grupos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.max_groups);
                            },
                        },
                        {
                            title: 'Promedio grupos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.avg_groups);
                            },
                        },
                    ]}
                    data={orderProductionProductsOptimized}
                />
            </DPageBody>
            <SUpsertProductDialog
                open={upsertDialog}
                setOpen={setUpsertDialog}
                productId={product.id}
            />
        </DPage>
    );
}
