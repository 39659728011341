import React, { useMemo } from 'react';
import { ProductToProduce } from '../products-to-produce';
import { DBlock, DBlockHeader } from '../../../../dum';
import { ProductsToProduceTable } from '../components/products-to-produce-table';

interface IProductToProduceByCategoryProps {
    productsToProduce: ProductToProduce[];
    useGroups: boolean;
}

type ProductToProduceByProductCategory = Omit<ProductToProduce, 'product'> & {
    product_category_id?: number | null;
    product_category_name: string;
};

export function ProductsToProduceByProductCategory({
    productsToProduce,
    useGroups,
}: IProductToProduceByCategoryProps) {
    const productsToProduceByWidthCalibre = useMemo<
        ProductToProduceByProductCategory[]
    >(() => {
        const temporaryProductsToProduceByProductCategory: ProductToProduceByProductCategory[] =
            [];

        productsToProduce.forEach((productToProduce) => {
            const prevValue = temporaryProductsToProduceByProductCategory.find(
                (prevVal) => {
                    return (
                        prevVal.product_category_id ===
                        productToProduce.product?.product_category_id
                    );
                },
            );
            if (!prevValue) {
                temporaryProductsToProduceByProductCategory.push({
                    product_category_id:
                        productToProduce.product?.product_category_id,
                    product_category_name:
                        productToProduce.product?.product_category?.name || '',
                    ...productToProduce,
                });
            } else {
                prevValue.kilos_to_produce += productToProduce.kilos_to_produce;
                prevValue.groups_to_produce +=
                    productToProduce.groups_to_produce;
                prevValue.kilos_in_inventory +=
                    productToProduce.kilos_in_inventory;
                prevValue.groups_in_inventory +=
                    productToProduce.groups_in_inventory;
                prevValue.kilos_requested += productToProduce.kilos_requested;
                prevValue.groups_requested += productToProduce.groups_requested;
                prevValue.kilos_sold += productToProduce.kilos_sold;
                prevValue.groups_sold += productToProduce.groups_sold;
                prevValue.optimized_request_products =
                    productToProduce.optimized_request_products.concat(
                        prevValue.optimized_request_products,
                    );
            }
        });

        return temporaryProductsToProduceByProductCategory.filter((p) => {
            return p.kilos_requested !== 0;
        });
    }, [productsToProduce]);

    return (
        <DBlock>
            <DBlockHeader subtitle={'Por categoria'} />
            <ProductsToProduceTable
                useGroups={useGroups}
                items={[
                    {
                        title: 'Categoria del producto',
                        textAlign: 'left',
                        content: (i) => {
                            return i.product_category_name;
                        },
                        cellGrow: 5,
                    },
                ]}
                data={productsToProduceByWidthCalibre}
            />
        </DBlock>
    );
}
