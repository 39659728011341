import React from 'react';
import { useOrderSaleAccountIdInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderSale, ORDER_REQUEST } from '../../order-sale-form';
import { useFormContext } from 'react-hook-form';

export function AccountIdInput() {
    const inputName: keyof IOrderSale = 'account';
    const { data } = useOrderSaleAccountIdInputQuery();
    const rx = useFormContext();

    const accounts = data?.getAccounts || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Cliente'}
            options={accounts}
            returnObject
            onChange={() => {
                void rx.trigger(ORDER_REQUEST);
            }}
        />
    );
}
