import React, { useMemo, useState } from 'react';
import { useProductFormQuery } from '../../../../../../services';
import { DModal, DPageLoader } from '../../../../../dum';
import { ProductIcon } from '../../../../icons';
import { ProductForm } from './product-form';

interface ISProductFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    productId?: number | null;
}

export const SUpsertProductDialog = (props: ISProductFormDialogProps) => {
    const { open, setOpen, productId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useProductFormQuery({
        skip: !productId || !open,
        variables: {
            ProductId: productId!,
        },
    });

    const product = useMemo(() => {
        return productId && data && data.getProduct && open
            ? data.getProduct
            : undefined;
    }, [productId, data, open]);

    return (
        <DModal
            icon={<ProductIcon />}
            title={`Producto`}
            open={open}
            setOpen={setOpen}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <ProductForm
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                    product={product}
                    disabled={isModalClosing}
                />
            )}
        </DModal>
    );
};
