import { IOrderSale } from '../../order-sale-form';
import { ValidationError } from 'yup';

export function areGroupsAvailable(
    value: any,
    context: any,
): ValidationError | boolean {
    const { groups, product } = context.from[0]
        .value as IOrderSale['order_sale_products'][number];

    const orderSale = context.from[1].value as IOrderSale;
    const orderRequestRemainingProducts =
        orderSale.order_request?.order_request_remaining_products || [];

    const remainingProduct = orderRequestRemainingProducts.find((rProduct) => {
        return rProduct.product_id === product?.id;
    });

    if (!remainingProduct) return true;

    if (!(remainingProduct.groups - groups >= 0)) {
        return context.createError({
            message: `La cantidad de grupos sobrepasa la disponible (grupos disponibles: ${remainingProduct.groups})`,
        });
    }

    return true;
}
