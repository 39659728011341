import React from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    getDateFromString,
    getStringFromDate,
} from '../../../../../../helpers';
import {
    clearMaintenanceModule,
    useUpsertSpareAdjustmentMutation,
} from '../../../../../../services';
import {
    DModal,
    RhDateInput,
    RhFormWrapper,
    RHTextField,
} from '../../../../../dum';
import { SpareTransactionIcon } from '../../../../icons';
import { SpareTransactionsColumnsInput } from './inputs/spare-transactions-columns-input';

export interface ISpareAdjustmentForm {
    description: string;
    date?: string | null;
    spare_transactions: {
        spare_id?: number | null;
        quantity: number;
    }[];
}

interface ISpareAdjustment extends ISpareAdjustmentForm {
    id?: number;
}

interface IFormDialogProps {
    spareAdjustment?: ISpareAdjustment;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SSpareAdjustmentFormDialog = (props: IFormDialogProps) => {
    const { open, setOpen, spareAdjustment } = props;

    const [mutation, { loading }] = useUpsertSpareAdjustmentMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    const initialValues: ISpareAdjustmentForm = {
        description: spareAdjustment?.description || '',
        date: spareAdjustment?.date
            ? getDateFromString(spareAdjustment.date)
            : '',
        spare_transactions: spareAdjustment?.spare_transactions || [],
    };

    const validationSchema: SchemaOf<ISpareAdjustmentForm> = yup.object({
        description: yup.string().required(),
        date: yup.string().required().nullable(),
        spare_transactions: yup
            .array()
            .of(
                yup.object().shape({
                    spare_id: yup
                        .number()
                        .required('Spare is required')
                        .nullable()
                        .test(
                            'is-spares-unique',
                            () => {
                                return `La refacción seleccionada no es unica`;
                            },
                            (spareId: any, context: any) => {
                                const transactions =
                                    context.from[1].value.spare_transactions;
                                const repeated = transactions.filter(
                                    (transaction: { spare_id: number }) =>
                                        transaction.spare_id === spareId,
                                );
                                return repeated.length === 1;
                            },
                        ),
                    quantity: yup.number().required('Quantity is required'),
                }),
            )
            .required('Required'),
    });

    return (
        <DModal
            icon={<SpareTransactionIcon />}
            title={`${!!spareAdjustment?.id ? 'Editar' : 'Crear'} ajuste`}
            open={open}
            setOpen={setOpen}
        >
            <RhFormWrapper
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (data) => {
                    await mutation({
                        variables: {
                            SpareAdjustmentUpsertInput: {
                                id: spareAdjustment?.id || null,
                                description: data?.description!,
                                date: data?.date
                                    ? getStringFromDate(data.date)
                                    : null,
                                spare_transactions: data.spare_transactions.map(
                                    (transaction) => {
                                        return {
                                            spare_id: transaction.spare_id!,
                                            quantity: transaction.quantity,
                                        };
                                    },
                                ),
                            },
                        },
                    });
                    setOpen(false);
                    return;
                }}
                disabled={loading}
            >
                <RHTextField name={'description'} label={'Nombre'} />
                <RhDateInput name={'date'} label={'Fecha'} />
                <SpareTransactionsColumnsInput />
            </RhFormWrapper>
        </DModal>
    );
};
