import React from 'react';
import { useOrderRequestAccountInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderRequest } from '../../order-request-form';

export function AccountInput() {
    const inputName: keyof IOrderRequest = 'account_id';
    const { data } = useOrderRequestAccountInputQuery();

    const accounts = data?.getAccounts || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Cliente'}
            options={accounts}
        />
    );
}
