import React from 'react';
import { IMachinePartForm } from '../s-machine-part-form-dialog';
import { SMultipleSparesAutocomplete } from '../../../../../inputs';

export function CompatibleSparesInput() {
    const name: keyof IMachinePartForm = 'machine_compatibilities';

    return (
        <SMultipleSparesAutocomplete
            multipleValue={'spare_id'}
            name={name}
            label={'Refaccións compatibles'}
        />
    );
}
