import { Box } from '@mui/material';
import React from 'react';

interface IComponentProps {
    children: React.ReactNode;
    disableGutters?: boolean;
    centered?: boolean;
}

const centeredSxProps = {
    justifyContent: 'center',
    alignItems: 'center',
};

export function DPageBody({
    children,
    centered,
    disableGutters = false,
}: IComponentProps) {
    const positioningSxProps = centered ? centeredSxProps : {};

    return (
        <Box
            sx={{
                px: !disableGutters ? 16 : 0,
                ...positioningSxProps,
                flex: 1,
                flexDirection: 'column',
                display: 'flex',
            }}
        >
            {children}
        </Box>
    );
}
