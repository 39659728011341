import React, { useMemo } from 'react';
import {
    OrderProductionMachineInputQuery,
    useOrderProductionMachineInputQuery,
} from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderProduction } from '../../order-production-form';
import { useFormContext, useWatch } from 'react-hook-form';

const ARRAY_NAME: keyof IOrderProduction = 'order_production_products';
const MACHINE_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'machine';
const PRODUCT_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'product';
const ORDER_PRODUCTION_TYPE_FIELD_NAME: keyof IOrderProduction =
    'order_production_type_id';
const BRANCH_FIELD_NAME: keyof IOrderProduction = 'branch_id';
const ID_FIELD_NAME: keyof IOrderProduction = 'id';

interface IProductsInput {
    index: number;
}

export function OrderProductionMachineInput({ index }: IProductsInput) {
    const id: IOrderProduction['id'] = useWatch({
        name: ID_FIELD_NAME,
    });

    const { data: machinesData } = useOrderProductionMachineInputQuery({
        variables: {
            excludeDiscontinued: !id,
        },
    });

    const INPUT_NAME = `${ARRAY_NAME}.${index}.${MACHINE_FIELD_NAME}`;
    const formContext = useFormContext();

    const orderProductionTypeId: IOrderProduction['order_production_type_id'] =
        useWatch({
            name: ORDER_PRODUCTION_TYPE_FIELD_NAME,
        });
    const branchId: IOrderProduction['branch_id'] = useWatch({
        name: BRANCH_FIELD_NAME,
    });

    const filteredMachines: OrderProductionMachineInputQuery['getMachines'] =
        useMemo(() => {
            return (
                machinesData?.getMachines?.filter((machine) => {
                    return (
                        machine.order_production_type_id ===
                            orderProductionTypeId &&
                        machine.branch_id === branchId
                    );
                }) || []
            );
        }, [machinesData, orderProductionTypeId, branchId]);

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            returnObject
            options={filteredMachines}
            name={INPUT_NAME}
            label={'Maquina'}
            disableGutters
            onChange={(inputValue) => {
                if (machinesData && !!inputValue) {
                    const orderProductionProducts = formContext.getValues(
                        ARRAY_NAME,
                    ) as IOrderProduction['order_production_products'];
                    if (orderProductionProducts) {
                        orderProductionProducts.forEach((p, i) => {
                            void formContext.trigger(
                                `${ARRAY_NAME}.${i}.${PRODUCT_FIELD_NAME}`,
                            );
                            void formContext.trigger(
                                `${ARRAY_NAME}.${i}.${MACHINE_FIELD_NAME}`,
                            );
                        });
                    }
                }
            }}
        />
    );
}
