import React, { useEffect, useMemo, useState } from 'react';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    DMonth,
    DPaginator,
    DTextField,
    DYear,
    EditIcon,
    SortedOrder,
    useDMonth,
    useDYear,
    usePaginator,
} from '../../../../../dum';
import { Badge, Box, IconButton } from '@mui/material';
import {
    PdfIcon,
    SDeleteOrderSaleDialog,
    SUpsertOrderSale,
} from '../../../../../smart';
import {
    ColumnOrder,
    OrderSalesPageQuery,
    OrderSalesSortableFields,
    useOrderSalesPageLazyQuery,
} from '../../../../../../services';
import {
    formatDate,
    formatFloat,
    useBoolean,
    useNumber,
    useString,
} from '../../../../../../helpers';
import { TOrderSale } from '../order-sales-page';
import { DCheckbox } from '../../../../../dum/simple/inputs/d-checkbox';

interface ISalesListBlock {
    setSelectedOrderSale: (orderSale: TOrderSale) => void;
}

export function SalesListBlock({ setSelectedOrderSale }: ISalesListBlock) {
    const [query, { data, previousData }] = useOrderSalesPageLazyQuery();
    const [{ open, disabled }, setUpsertDialogOptions] = useState({
        open: false,
        disabled: false,
    });
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [orderSaleId, setOrderSaleId] = useState<null | number>(null);
    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [filter, setFilter] = useString({
        KEY: SalesListBlock.name + '_Filter',
    });

    const [orderSaleReceiptTypeId, setOrderSaleReceiptTypeId] = useNumber({
        KEY: SalesListBlock.name + '_OrderSaleReceiptTypeId',
    });

    const [orderSaleStatusId, setOrderSaleStatusId] = useNumber({
        KEY: SalesListBlock.name + '_OrderSaleStatusId',
    });

    const [accountId, setAccountId] = useNumber({
        KEY: SalesListBlock.name + '_AccountId',
    });

    const { year, setYear } = useDYear({
        COMPONENT_KEY: SalesListBlock.name + '_Year',
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: SalesListBlock.name + '_Month',
    });

    const { take, setSkip, skip } = usePaginator(
        {
            KEY: SalesListBlock.name + '_Paginator',
        },
        [
            month,
            year,
            accountId,
            orderSaleStatusId,
            orderSaleReceiptTypeId,
            filter,
        ],
    );

    const [defaultCollapseOpen, setDefaultCollapseOpen] = useBoolean({
        KEY: SalesListBlock.name + '_DefaultOpen',
        defaultValue: false,
    });

    const [noSupplement, setNoSupplement] = useBoolean({
        KEY: SalesListBlock.name + '_NoSupplement',
        defaultValue: false,
    });

    const [noCreditNote, setNoCreditNote] = useBoolean({
        KEY: SalesListBlock.name + '_NoCreditNote',
        defaultValue: false,
    });

    const [isTransferComplete, setIsTransferComplete] = useBoolean({
        KEY: SalesListBlock.name + '_isTransferComplete',
        defaultValue: false,
    });

    const handleEditOrderSale = useMemo(() => {
        return (
            orderSale: OrderSalesPageQuery['paginatedOrderSales']['docs'][number],
        ) => {
            setUpsertDialogOptions({
                open: true,
                disabled: false,
            });
            setOrderSaleId(orderSale.id);
        };
    }, []);

    const handleDeleteOrderSale = useMemo(() => {
        return (
            orderSale: OrderSalesPageQuery['paginatedOrderSales']['docs'][number],
        ) => {
            setDeleteOpen(true);
            setOrderSaleId(orderSale.id);
        };
    }, []);

    const handlePrintOrderSale = useMemo(() => {
        return (
            orderSale: OrderSalesPageQuery['paginatedOrderSales']['docs'][number],
        ) => {
            setSelectedOrderSale({
                ...orderSale,
            });
        };
    }, [setSelectedOrderSale]);

    // const authToken = getString('token');

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                year: year,
                month: month,
                filter: filter,
                sortField:
                    sortedIndex === 0
                        ? OrderSalesSortableFields.OrderRequest
                        : sortedIndex === 1
                        ? OrderSalesSortableFields.OrderCode
                        : sortedIndex === 2
                        ? OrderSalesSortableFields.Date
                        : null,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
                orderSaleReceiptTypeId: orderSaleReceiptTypeId,
                accountId: accountId,
                orderSaleStatusId: orderSaleStatusId,
                noSupplement: noSupplement,
                noCreditNote: noCreditNote,
                isTransferComplete: isTransferComplete,
            },
        });
    }, [
        year,
        month,
        query,
        skip,
        take,
        filter,
        sortedIndex,
        sortedOrder,
        accountId,
        orderSaleReceiptTypeId,
        orderSaleStatusId,
        noSupplement,
        noCreditNote,
        isTransferComplete,
    ]);

    const orderSales =
        data?.paginatedOrderSales.docs ||
        previousData?.paginatedOrderSales.docs ||
        [];

    const count =
        data && data?.paginatedOrderSales.count >= 0
            ? data?.paginatedOrderSales.count
            : previousData?.paginatedOrderSales.count;

    return (
        <DBlock>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <DTextField
                        label={'Filtro'}
                        helperText={
                            'Filtro para el folio del pedido, el folio de la venta o la factura de la venta.'
                        }
                        value={filter}
                        onChange={(val) => {
                            setFilter(val);
                        }}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <CVerticalDropdown
                        items={[
                            {
                                component: (
                                    <DYear setYear={setYear} year={year} />
                                ),
                            },
                            {
                                component: (
                                    <DMonth setMonth={setMonth} month={month} />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={orderSaleReceiptTypeId}
                                        onChange={setOrderSaleReceiptTypeId}
                                        itemText={'name'}
                                        label={'Tipo de venta'}
                                        options={data?.getReceiptTypes || []}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={accountId}
                                        onChange={setAccountId}
                                        itemText={'name'}
                                        label={'Cliente'}
                                        options={data?.getAccounts || []}
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DIdAutocomplete
                                        id={orderSaleStatusId}
                                        onChange={setOrderSaleStatusId}
                                        itemText={'name'}
                                        label={'Estatus'}
                                        options={
                                            data?.getOrderSaleStatuses || []
                                        }
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        align={'left'}
                                        disableGutters
                                        onChange={(val) => {
                                            setNoSupplement(val);
                                        }}
                                        value={noSupplement}
                                        label={
                                            'Facturas faltantes por emitir complemento'
                                        }
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        align={'left'}
                                        disableGutters
                                        onChange={(val) => {
                                            setNoCreditNote(val);
                                        }}
                                        value={noCreditNote}
                                        label={
                                            'Facturas faltantes por emitir nota de credito'
                                        }
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        onChange={(val) => {
                                            setDefaultCollapseOpen(val);
                                        }}
                                        value={defaultCollapseOpen}
                                        label={'Abrir filas'}
                                        disableGutters
                                    />
                                ),
                            },
                            {
                                component: (
                                    <DCheckbox
                                        align={'left'}
                                        disableGutters
                                        onChange={(val) => {
                                            setIsTransferComplete(val);
                                        }}
                                        value={isTransferComplete}
                                        label={'Pendientes por transferir'}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>
            <DPaginator
                skip={skip}
                setSkip={setSkip}
                take={take}
                count={count}
            />
            <CTableWrapper
                onClick={(sale) => {
                    handleEditOrderSale(sale);
                }}
                sortedIndex={sortedIndex}
                setSortedIndex={setSortedIndex}
                sortedOrder={sortedOrder}
                setSortedOrder={setSortedOrder}
                contextItems={[
                    {
                        title: 'Editar',
                        disable: (orderSale) => {
                            return !orderSale.is_editable;
                        },
                        callback: (orderSale) => {
                            handleEditOrderSale(orderSale);
                        },
                    },
                    {
                        title: 'Borrar',
                        disable: (orderSale) => {
                            return !orderSale.is_deletable;
                        },
                        callback: (orderSale) => {
                            handleDeleteOrderSale(orderSale);
                        },
                    },
                    {
                        title: 'Imprimir pdf',
                        callback: (orderSale) => {
                            handlePrintOrderSale(orderSale);
                        },
                    },
                ]}
                items={[
                    {
                        cellGrow: 10,
                        title: 'Pedido',
                        sortable: true,
                        textAlign: 'right',
                        content: (orderSale) => {
                            return orderSale?.order_request?.order_code;
                        },
                    },
                    {
                        cellGrow: 10,
                        title: 'Folio (Factura)',
                        sortable: true,
                        textAlign: 'right',
                        content: (orderSale) => {
                            return orderSale.invoice_code === 0
                                ? orderSale.order_code
                                : `${orderSale.order_code} F-${orderSale.invoice_code}`;
                        },
                    },
                    {
                        cellGrow: 20,
                        title: 'Fecha',
                        sortable: true,
                        content: (orderSale) => {
                            return formatDate(orderSale.date);
                        },
                    },
                    {
                        cellGrow: 10,
                        title: 'Estatus',
                        content: (orderSale) => {
                            return orderSale.order_sale_status?.name;
                        },
                    },
                    {
                        cellGrow: 10,
                        title: 'Cliente',
                        content: (orderSale) => {
                            console.log(orderSale);
                            return orderSale?.account?.name;
                        },
                    },
                    {
                        cellGrow: 10,
                        title: 'Total (menos descuentos)',
                        textAlign: 'right',
                        content: (orderSale) => {
                            return `${formatFloat(orderSale?.products_total)}`;
                        },
                    },
                    {
                        cellGrow: 10,
                        title: 'Total (IVA incluido)',
                        textAlign: 'right',
                        content: (orderSale) => {
                            return `${formatFloat(orderSale?.total_with_tax)}`;
                        },
                    },
                    {
                        cellGrow: 10,
                        title: 'Transferencias',
                        textAlign: 'right',
                        content: (orderSale) => {
                            return (
                                <Box>
                                    {orderSale.total_with_tax -
                                        orderSale?.transfer_receipts_total ===
                                    0 ? (
                                        <Badge
                                            sx={{ mr: 1 }}
                                            variant={'dot'}
                                            badgeContent={' '}
                                            color={'success'}
                                        />
                                    ) : (
                                        formatFloat(
                                            orderSale?.transfer_receipts_total,
                                        )
                                    )}{' '}
                                </Box>
                            );
                        },
                    },
                    {
                        title: 'Acciones',
                        content: (orderSale) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'no-wrap',
                                    }}
                                >
                                    <IconButton
                                        disabled={!orderSale.is_editable}
                                        onClick={() => {
                                            handleEditOrderSale(orderSale);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>

                                    <IconButton
                                        disabled={!orderSale.is_deletable}
                                        onClick={() => {
                                            handleDeleteOrderSale(orderSale);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => {
                                            handlePrintOrderSale(orderSale);
                                            // window.open(
                                            //     `${window.location.protocol}//${apiUrl}/files/orderSales/${authToken}/${orderSale.id}.pdf?download=false`,
                                            //     '_blank',
                                            // );
                                        }}
                                    >
                                        <PdfIcon />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                ]}
                defaultOpenCollapse={defaultCollapseOpen}
                collapse={(orderSale) => {
                    return (
                        <CTableWrapper
                            padding={false}
                            items={[
                                {
                                    title: 'Producto',
                                    cellGrow: 60,
                                    content: (osp) => {
                                        return osp.product
                                            ?.compound_description;
                                    },
                                },
                                {
                                    title: 'Grupos',
                                    cellGrow: 20,
                                    textAlign: 'right',
                                    content: (osp) => formatFloat(osp.groups),
                                },
                                {
                                    title: 'Kilos',
                                    cellGrow: 20,
                                    textAlign: 'right',
                                    content: (osp) => formatFloat(osp.kilos),
                                },
                                {
                                    title: 'Precio',
                                    cellGrow: 20,
                                    textAlign: 'right',
                                    content: (osp) =>
                                        formatFloat(osp.kilo_price),
                                },
                                {
                                    title: 'Total',
                                    cellGrow: 20,
                                    textAlign: 'right',
                                    content: (osp) => {
                                        return formatFloat(osp.total);
                                    },
                                },
                            ]}
                            data={orderSale.order_sale_products}
                        />
                    );
                }}
                data={orderSales}
            />
            <SUpsertOrderSale
                open={open}
                setOpen={(o) => {
                    setUpsertDialogOptions({
                        open: o,
                        disabled: false,
                    });
                }}
                orderSaleId={orderSaleId}
                disabled={disabled}
            />

            <SDeleteOrderSaleDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                orderSaleId={orderSaleId}
            />
        </DBlock>
    );
}
