import React from 'react';
import { useWatch } from 'react-hook-form';
import {
    CREDIT_NOTE_CODE,
    IOrderSale,
    REQUIRE_CREDIT_NOTE,
} from '../../order-sale-form';
import { RHTextField } from '../../../../../../../dum';

export function CreditNoteCodeInput() {
    const requireCreditNote = useWatch({
        name: REQUIRE_CREDIT_NOTE,
    }) as IOrderSale['require_credit_note'];

    return (
        <RHTextField
            disabled={!requireCreditNote}
            name={CREDIT_NOTE_CODE}
            label={'Folio de la nota de credito'}
        />
    );
}
