import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { ResourceForm } from './resource-form';
import { useResourceDialogQuery } from '../../../../../../services';

interface ISResourceFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    resourceId?: number | null;
    disabled?: boolean;
}

export const SUpsertResourceDialog = (props: ISResourceFormDialogProps) => {
    const { open, setOpen, resourceId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useResourceDialogQuery({
        skip: !resourceId || !open,
        variables: {
            ResourceId: resourceId!,
        },
    });

    const resource = useMemo(() => {
        return resourceId && data && data.getResource && open
            ? data.getResource
            : undefined;
    }, [resourceId, data, open]);

    return (
        <DModal
            title={`Recurso`}
            open={open}
            setOpen={setOpen}
            width={'800px'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <ResourceForm
                    disabled={isModalClosing}
                    resource={resource}
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                />
            )}
        </DModal>
    );
};
