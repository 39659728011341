import React, { useEffect, useMemo, useState } from 'react';
import {
    Continued,
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DeleteIcon,
    DIdAutocomplete,
    Discontinued,
    DPage,
    DPageBody,
    DPageHeader,
    DPaginator,
    DTextField,
    EditIcon,
    SortedOrder,
    usePaginator,
    ViewIcon,
} from '../../../../dum';
import { SDeleteProductDialog, SUpsertProductDialog } from '../../../../smart';
import {
    ColumnOrder,
    navigateToProductPage,
    ProductsPageQuery,
    ProductsSortableFields,
    useProductsPageLazyQuery,
} from '../../../../../services';
import { Box, IconButton } from '@mui/material';
import {
    formatRelativeDate,
    useBoolean,
    useNumber,
    useString,
} from '../../../../../helpers';
import { DCheckbox } from '../../../../dum/simple/inputs/d-checkbox';
import { useNavigate } from 'react-router-dom';

export function ProductsPage() {
    const [query, { data, previousData }] = useProductsPageLazyQuery();
    const navigate = useNavigate();
    const [upsertOpen, setUpsertOpen] = useState(false);
    const [deleteOpen, setDeleteOPen] = useState(false);
    const [productId, setProductId] = useState<number | null>(null);
    const [sortedIndex, setSortedIndex] = useState<number | null>(null);
    const [sortedOrder, setSortedOrder] = useState<SortedOrder>(null);

    const [includeDiscontinued, setIncludeDiscontinued] = useBoolean({
        KEY: ProductsPage.name + '_IncludeDiscontinued',
        defaultValue: true,
    });

    const [productCategoryId, setProductCategoryId] = useNumber({
        KEY: ProductsPage.name + '_ProductCategoryId',
        defaultValue: null,
    });

    const [filter, setFilter] = useString({
        KEY: ProductsPage.name + '_Filter',
    });

    const { setSkip, skip, take } = usePaginator(
        {
            KEY: ProductsPage.name + '_Paginator',
        },
        [filter, includeDiscontinued, productCategoryId],
    );

    useEffect(() => {
        void query({
            variables: {
                skip: skip,
                take: take,
                filter: filter,
                productCategoryId: productCategoryId,
                includeDiscontinued: includeDiscontinued,
                sortField:
                    sortedIndex === 1
                        ? ProductsSortableFields.ExternalDescription
                        : sortedIndex === 2
                        ? ProductsSortableFields.InternalDescription
                        : sortedIndex === 3
                        ? ProductsSortableFields.OrderProductionTypeId
                        : sortedIndex === 4
                        ? ProductsSortableFields.ProductCategoryId
                        : null,
                sortOrder:
                    sortedOrder === 'asc'
                        ? ColumnOrder.Asc
                        : sortedOrder === 'desc'
                        ? ColumnOrder.Desc
                        : null,
            },
        });
    }, [
        query,
        skip,
        take,
        filter,
        productCategoryId,
        includeDiscontinued,
        sortedOrder,
        sortedIndex,
    ]);

    const products =
        data?.paginatedProducts.docs ||
        previousData?.paginatedProducts.docs ||
        [];

    const count =
        data && data?.paginatedProducts.count >= 0
            ? data?.paginatedProducts.count
            : previousData?.paginatedProducts.count;

    const handleEditProduct = useMemo(() => {
        return (
            product: ProductsPageQuery['paginatedProducts']['docs'][number],
        ) => {
            setProductId(product.id);
            setUpsertOpen(true);
        };
    }, []);

    const handleDeleteProduct = useMemo(() => {
        return (
            product: ProductsPageQuery['paginatedProducts']['docs'][number],
        ) => {
            setProductId(product.id);
            setDeleteOPen(true);
        };
    }, []);

    const handleViewProduct = useMemo(() => {
        return (
            product: ProductsPageQuery['paginatedProducts']['docs'][number],
        ) => {
            navigateToProductPage({
                navigate,
                productId: product.id,
            });
        };
    }, [navigate]);

    return (
        <DPage>
            <DPageHeader title={'Productos'} />
            <DPageBody disableGutters>
                <DBlock>
                    <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <DTextField
                                label={'Filtro'}
                                value={filter}
                                onChange={(value) => {
                                    setFilter(value || '');
                                }}
                            />
                        </Box>
                        <CVerticalDropdown
                            items={[
                                {
                                    component: (
                                        <DIdAutocomplete
                                            id={productCategoryId}
                                            onChange={setProductCategoryId}
                                            itemText={'name'}
                                            label={'Categoría del producto'}
                                            options={
                                                data?.getProductCategories || []
                                            }
                                        />
                                    ),
                                },
                                {
                                    component: (
                                        <DCheckbox
                                            align={'left'}
                                            disableGutters
                                            onChange={(val) => {
                                                setIncludeDiscontinued(val);
                                            }}
                                            value={includeDiscontinued}
                                            label={'Incluir descontinuados'}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Box>

                    <DPaginator
                        skip={skip}
                        take={take}
                        setSkip={setSkip}
                        count={count}
                    />
                    <CTableWrapper
                        onClick={(product) => {
                            handleEditProduct(product);
                        }}
                        sortedIndex={sortedIndex}
                        setSortedIndex={setSortedIndex}
                        sortedOrder={sortedOrder}
                        setSortedOrder={setSortedOrder}
                        contextItems={[
                            {
                                title: 'Ver',
                                callback: (product) => {
                                    handleViewProduct(product);
                                },
                            },
                            {
                                title: 'Editar',
                                callback: (product) => {
                                    handleEditProduct(product);
                                },
                            },
                            {
                                title: 'Borrar',
                                disable: (product) => {
                                    return !product.is_deletable;
                                },
                                callback: (product) => {
                                    handleDeleteProduct(product);
                                },
                            },
                        ]}
                        items={[
                            {
                                title: 'Ultima actualización',
                                cellGrow: 20,
                                content: (product) => {
                                    return formatRelativeDate(
                                        product.updated_at,
                                    );
                                },
                            },
                            {
                                title: 'Descripción externa',
                                cellGrow: 20,
                                content: (product) => {
                                    return product.external_description;
                                },
                                sortable: true,
                            },
                            {
                                title: 'Descripción interna',
                                cellGrow: 20,
                                content: (product) => {
                                    return product.internal_description;
                                },
                                sortable: true,
                            },
                            {
                                title: 'Tipo de orden de producción',
                                cellGrow: 20,
                                content: (product) => {
                                    return product.order_production_type?.name;
                                },
                                sortable: true,
                            },
                            {
                                title: 'Categoría',
                                cellGrow: 20,
                                content: (product) => {
                                    return product.product_category?.name;
                                },
                                sortable: true,
                            },
                            {
                                title: 'Material',
                                cellGrow: 20,
                                content: (product) => {
                                    return product.product_material?.name;
                                },
                            },
                            {
                                title: 'Estatus',
                                textAlign: 'center',
                                content: (product) => {
                                    return !product.discontinued ? (
                                        <Continued color={'success'} />
                                    ) : (
                                        <Discontinued color={'error'} />
                                    );
                                },
                            },
                            {
                                title: 'Acciones',
                                textAlign: 'center',
                                content: (product) => {
                                    return (
                                        <Box sx={{ display: 'flex' }}>
                                            <IconButton
                                                onClick={() => {
                                                    handleViewProduct(product);
                                                }}
                                            >
                                                <ViewIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    handleEditProduct(product);
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={!product.is_deletable}
                                                onClick={() => {
                                                    handleDeleteProduct(
                                                        product,
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        data={products}
                    />
                </DBlock>
            </DPageBody>
            <SUpsertProductDialog
                open={upsertOpen}
                setOpen={setUpsertOpen}
                productId={productId}
            />
            <SDeleteProductDialog
                open={deleteOpen}
                setOpen={setDeleteOPen}
                productId={productId}
            />
        </DPage>
    );
}
