import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage } from '../../../dum';
import { SUpsertOrderRequestDialog } from '../../../smart';
import { OrderRequestsPage } from './order-requests/order-requests-page';
import { DFab } from '../../../dum/simple/button/d-fab';

const productsPages: Route[] = [
    {
        name: 'OrderRequestsPage',
        path: '/',
        component: <OrderRequestsPage />,
        title: 'Pedidos',
        navbar: false,
    },
];

export function OrderRequestsHomePage() {
    const [orderRequestUpsertDialog, setOrderRequestUpsertDialog] =
        useState(false);

    return (
        <DPage>
            <DFab
                onClick={() => {
                    setOrderRequestUpsertDialog(true);
                }}
            />
            <ReactRouterRoutes>
                {productsPages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SUpsertOrderRequestDialog
                open={orderRequestUpsertDialog}
                setOpen={setOrderRequestUpsertDialog}
            />
        </DPage>
    );
}
