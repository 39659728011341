import React from 'react';
import { TableRow } from '@mui/material';

interface ICustomTableHeaderRowProps {
    children: React.ReactNode;
}

export function DTableHeadRow({ children }: ICustomTableHeaderRowProps) {
    return <TableRow sx={{ fontWeight: 'bold' }}>{children}</TableRow>;
}
