import { RhCheckbox, RhFormWrapper, RHTextField } from '../../../../../dum';
import { OrderProductionTypeInput } from './inputs/order-production-type/order-production-type-input';
import { CodeInput } from './inputs/code/code-input';
import { CurrentGroupWeightInput } from './inputs/current-group-weight/current-group-weight';
import { CurrentKiloPriceInput } from './inputs/current-kilo-price/current-kilo-price';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { isWidthRequired } from './inputs/measurements-input/width/width-validation';
import { isLengthRequired } from './validation/is-length-required';
import { isCurrentGroupWeightRequired } from './validation/is-group-weight-required';
import { isCalibreRequired } from './validation/is-calibre-required';
import { useUpsertProductDialogMutation } from '../../../../../../services';
import { transformToNumber } from '../../../../../../helpers';
import { ProductCategoryInput } from './inputs/product-category/product-category-input';
import { ProductMaterialInput } from './inputs/product-material/product-material-input';
import { doesProductCategoryBelongToOrderProductionType } from './validation/does-product-category-belong-to-order-production-type';
import { Box } from '@mui/material';
import { MeasurementsInput } from './inputs/measurements-input/measurements-input';
import { CurrentGroupPriceInput } from './inputs/current-group-price/current-group-price';
import { isKiloPriceAndGroupPriceDifferentThanZero } from './validation/is-kilo-price-and-group-price-different-than-zero';

export interface IProduct {
    id?: number | null;
    code: string;
    internal_description?: string;
    external_description: string;
    discontinued: boolean;
    order_production_type_id?: number | null;
    product_category?: {
        id?: number | null;
        name: string;
        order_production_type_id?: number | null;
    } | null;
    width?: number | null;
    length?: number | null;
    current_group_weight?: number | null;
    current_kilo_price?: number | null;
    current_group_price?: number | null;
    calibre?: number | null;
    product_material_id?: number | null;
}

export const ID: keyof IProduct = 'id';
export const CODE: keyof IProduct = 'code';
export const INTERNAL_DESCRIPTION: keyof IProduct = 'internal_description';
export const EXTERNAL_DESCRIPTION: keyof IProduct = 'external_description';
export const DISCONTINUED: keyof IProduct = 'discontinued';
export const ORDER_PRODUCTION_TYPE_ID: keyof IProduct =
    'order_production_type_id';
export const PRODUCT_CATEGORY: keyof IProduct = 'product_category';
export const WIDTH: keyof IProduct = 'width';
export const LENGTH: keyof IProduct = 'length';
export const CURRENT_GROUP_WEIGHT: keyof IProduct = 'current_group_weight';
export const CURRENT_KILO_PRICE: keyof IProduct = 'current_kilo_price';
export const CURRENT_GROUP_PRICE: keyof IProduct = 'current_group_price';
export const CALIBRE: keyof IProduct = 'calibre';
export const PRODUCT_MATERIAL_ID: keyof IProduct = 'product_material_id';

interface IProductForm {
    product?: IProduct | null;
    onSuccess: () => void;
    disabled?: boolean;
}

export function ProductForm({
    product,
    onSuccess,
    disabled = false,
}: IProductForm) {
    const [mutation, { loading }] = useUpsertProductDialogMutation();

    const initialValues = useMemo<IProduct>(() => {
        return {
            id: product?.id || undefined,
            internal_description: product?.internal_description || '',
            external_description: product?.external_description || '',
            discontinued: product?.discontinued || false,
            code: product?.code || '',
            order_production_type_id: product?.order_production_type_id || null,
            product_category: product?.product_category || null,
            width: product?.width || 0,
            length: product?.length || 0,
            current_group_weight: product?.current_group_weight || 0,
            current_kilo_price: product?.current_kilo_price || 0,
            current_group_price: product?.current_group_price || 0,
            calibre: product?.calibre || 0,
            product_material_id: product?.product_material_id || null,
        };
    }, [product]);

    const validationSchema: SchemaOf<IProduct> = yup.object({
        id: yup.number().notRequired().nullable(),
        external_description: yup
            .string()
            .required(`'Descripción externa' es obligatorio`),
        internal_description: yup.string().notRequired(),
        discontinued: yup.boolean().required(),
        code: yup.string().required(`'Codigo' es obligatorio`),
        order_production_type_id: yup.number().notRequired().nullable(),
        product_material_id: yup.number().notRequired().nullable(),
        product_category: yup
            .mixed()
            .nullable()
            .required(`'Categoria del producto' es obligatorio`)
            .test(
                'do-product-catgory-belong-to-order-production-type',
                doesProductCategoryBelongToOrderProductionType,
            ),
        width: yup.mixed().when('order_production_type_id', {
            is: (val: any) => {
                return (
                    typeof val === 'number' &&
                    isWidthRequired({ order_production_type_id: val })
                );
            },
            then: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Ancho' es obligatorio`),
            otherwise: yup.mixed().nullable().notRequired(),
        }),
        length: yup.mixed().when('order_production_type_id', {
            is: (val: any) => {
                return (
                    typeof val === 'number' &&
                    isLengthRequired({ order_production_type_id: val })
                );
            },
            then: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Largo' es obligatorio`),
            otherwise: yup.mixed().nullable().notRequired(),
        }),
        current_group_weight: yup.mixed().when('order_production_type_id', {
            is: (val: any) => {
                return (
                    typeof val === 'number' &&
                    isCurrentGroupWeightRequired({
                        order_production_type_id: val,
                    })
                );
            },
            then: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Peso actual' es obligatorio`),
            otherwise: yup.mixed().nullable().notRequired(),
        }),
        current_kilo_price: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Precio por kilo' es obligatorio`)
            .test(
                'is-kilo-price-and-group-price-different-than-zero',
                isKiloPriceAndGroupPriceDifferentThanZero,
            ),
        current_group_price: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Precio por grupo' es obligatorio`)
            .test(
                'is-kilo-price-and-group-price-different-than-zero',
                isKiloPriceAndGroupPriceDifferentThanZero,
            ),
        calibre: yup.mixed().when('order_production_type_id', {
            is: (val: any) => {
                return (
                    typeof val === 'number' &&
                    isCalibreRequired({ order_production_type_id: val })
                );
            },
            then: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Calibre' es obligatorio`),
            otherwise: yup.mixed().nullable().notRequired(),
        }),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            ProductUpsertInput: {
                                id: product?.id || null,
                                internal_description:
                                    data.internal_description || '',
                                external_description: data.external_description,
                                discontinued: data.discontinued,
                                calibre: data.calibre
                                    ? Number(data.calibre)
                                    : 0,
                                code: data.code,
                                current_group_weight: data.current_group_weight
                                    ? Number(data.current_group_weight)
                                    : 0,
                                current_kilo_price: data.current_kilo_price
                                    ? Number(data.current_kilo_price)
                                    : 0,
                                length: data.length ? Number(data.length) : 0,
                                width: data.width ? Number(data.width) : 0,
                                order_production_type_id:
                                    data.order_production_type_id,
                                product_category_id:
                                    data?.product_category?.id || null,
                                product_material_id:
                                    data?.product_material_id || null,
                                current_group_price:
                                    data?.current_group_price || 0,
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.error(errors);
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        mr: 1,
                    }}
                >
                    <RHTextField
                        name={'external_description'}
                        label={'Descripción externa'}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        ml: 1,
                    }}
                >
                    <RHTextField
                        name={'internal_description'}
                        label={'Descripción interna'}
                    />
                </Box>
            </Box>
            <CodeInput />
            <OrderProductionTypeInput />
            <RhCheckbox name={'discontinued'} label={'Descontinuado'} />
            <ProductCategoryInput />
            <ProductMaterialInput />
            <MeasurementsInput />
            <CurrentGroupWeightInput />
            <CurrentKiloPriceInput />
            <CurrentGroupPriceInput />
        </RhFormWrapper>
    );
}
