import React, { useMemo, useState } from 'react';
import { DModal, DPageLoader } from '../../../../../dum';
import { OrderRequestIcon } from '../../../../icons';
import { OrderRequestForm } from './order-request-form';
import { useOrderRequestDialogQuery } from '../../../../../../services';

interface ISOrderRequestFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderRequestId?: number | null;
}

export const SUpsertOrderRequestDialog = (
    props: ISOrderRequestFormDialogProps,
) => {
    const { open, setOpen, orderRequestId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);
    const { data, loading } = useOrderRequestDialogQuery({
        skip: !orderRequestId || !open,
        variables: {
            OrderRequestId: orderRequestId!,
        },
    });

    const orderRequest = useMemo(() => {
        return orderRequestId && data && data.getOrderRequest && open
            ? data.getOrderRequest
            : undefined;
    }, [orderRequestId, data, open]);

    return (
        <DModal
            icon={<OrderRequestIcon />}
            title={`Pedido`}
            open={open}
            setOpen={setOpen}
            width={'60rem'}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <OrderRequestForm
                    disabled={
                        isModalClosing &&
                        orderRequest &&
                        !orderRequest?.is_editable
                    }
                    orderRequest={orderRequest}
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                />
            )}
        </DModal>
    );
};
