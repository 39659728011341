import React from 'react';
import { DRouterLink } from '../../../../dum';
import { getSpareAdjustmentPagePath } from '../../../../../services/navigation/maintenance/get-route-path';

interface ISSpareAdjustmentLinkProps {
    spareAdjustmentId: number;
    spareAdjustmentName: string;
}

export function SSpareAdjustmentLink({
    spareAdjustmentId,
    spareAdjustmentName,
}: ISSpareAdjustmentLinkProps) {
    return (
        <DRouterLink
            to={getSpareAdjustmentPagePath({
                spareAdjustmentId: spareAdjustmentId,
            })}
        >
            {spareAdjustmentName}
        </DRouterLink>
    );
}
