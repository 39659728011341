import React from 'react';
import { useRawMaterialAdditionResourceInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';

export function ResourceInput({ name }: { name: string }) {
    const { data } = useRawMaterialAdditionResourceInputQuery();

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={name}
            label={'Recurso'}
            options={data?.getResources || []}
        />
    );
}
