export function doesEmployeeBelongToBranch(value: any, context: any) {
    const { employee } = context.from[0].value;
    const { branch_id } = context.from[1].value;

    if (!branch_id || !employee) return true;

    if (!(employee.branch_id === branch_id)) {
        return context.createError({
            message: `El empleado no pertenece a la sucursal`,
        });
    }

    return true;
}
