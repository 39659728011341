import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    MachineSectionPageQuery,
    navigateToMachinePartPage,
} from '../../../../../../services';
import { CTableWrapper, DBlock, DBlockHeader } from '../../../../../dum';
import {
    MachinePartIcon,
    SMachinePartFormDialog,
    SSpareLink,
} from '../../../../../smart';
import { CVerticalDropdown } from '../../../../../dum/complex';

type TMachinePart =
    MachineSectionPageQuery['getMachineSection']['machine_parts'][number];

interface IMachinePartSectionProps {
    machinePart: TMachinePart;
}

export function MachinePartBlock({ machinePart }: IMachinePartSectionProps) {
    const navigate = useNavigate();
    const [partDialog, setPartDialog] = useState(false);

    return (
        <DBlock key={machinePart.id}>
            <DBlockHeader
                subtitle={machinePart.name}
                icon={<MachinePartIcon />}
                iconTooltip={'Parte'}
            >
                <CVerticalDropdown
                    items={[
                        {
                            component: 'Ver',
                            callback: () => {
                                if (
                                    machinePart &&
                                    machinePart.machine_section?.machine_id
                                ) {
                                    navigateToMachinePartPage({
                                        navigate,
                                        machinePartId: machinePart.id,
                                        machineId:
                                            machinePart.machine_section
                                                .machine_id,
                                    });
                                }
                            },
                        },
                        {
                            component: 'Editar',
                            callback: () => {
                                setPartDialog(true);
                            },
                        },
                    ]}
                />
            </DBlockHeader>
            <CTableWrapper
                items={[
                    {
                        title: 'Refacción actual',
                        content: (compatibility) => {
                            if (compatibility.spare) {
                                return (
                                    <SSpareLink
                                        spareId={compatibility.spare.id}
                                        spareName={compatibility.spare.name}
                                    />
                                );
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Cantidad en inventario',
                        textAlign: 'right',
                        content: (compatibility) =>
                            compatibility.spare?.current_quantity,
                    },
                    {
                        title: 'Cantidad products-total requerida en partes',
                        textAlign: 'right',
                        content: (compatibility) =>
                            compatibility.spare?.total_required_quantity,
                    },
                    {
                        title: 'Es la refacción seleccionada del parte?',
                        content: (compatibility) =>
                            compatibility.is_current_spare ? 'si' : 'no',
                    },
                ]}
                data={machinePart.machine_compatibilities}
            />
            <SMachinePartFormDialog
                open={partDialog}
                setOpen={setPartDialog}
                machinePart={machinePart}
            />
        </DBlock>
    );
}
