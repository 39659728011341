import React, { useState } from 'react';
import {
    Route as ReactRouterRoute,
    Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { Route } from '../../../../types';
import { DPage, DSpeedDial, ICustomSpeedDialProps } from '../../../dum';
import {
    SpareAdjustmentIcon,
    SSpareAdjustmentFormDialog,
} from '../../../smart';
import { SpareAdjustmentPage } from './spare-adjustment/spare-adjustment-page';
import { SpareAdjustmentsPage } from './spare-adjustments/spare-adjustments-page';
import { SpareTransactionsPage } from './spare-transactions/spare-transactions-page';

const spareTransactionsPages: Route[] = [
    {
        name: 'SpareTransactionsPage',
        path: '/',
        component: <SpareTransactionsPage />,
        title: 'Transacciones',
        navbar: false,
    },
    {
        name: 'SpareAdjustmentsPage',
        path: '/spareAdjustments',
        component: <SpareAdjustmentsPage />,
        title: 'Ajustes',
        navbar: false,
    },
    {
        name: 'SpareAdjustmentPage',
        path: '/spareAdjustments/:spareAdjustmentId',
        component: <SpareAdjustmentPage />,
        title: 'Ajuste',
        navbar: false,
    },
];

export function SpareTransactionsHomePage() {
    const [adjustmentDialog, setAdjustmentDialog] = useState(false);

    const actions: ICustomSpeedDialProps['items'] = [
        {
            icon: <SpareAdjustmentIcon />,
            text: 'Nuevo ajuste',
            callback: () => {
                setAdjustmentDialog(true);
            },
        },
    ];

    return (
        <DPage>
            <DSpeedDial items={actions} />
            <ReactRouterRoutes>
                {spareTransactionsPages.map((page, index) => {
                    return (
                        <ReactRouterRoute
                            path={page.path}
                            element={page.component}
                            key={index}
                        />
                    );
                })}
            </ReactRouterRoutes>
            <SSpareAdjustmentFormDialog
                open={adjustmentDialog}
                setOpen={setAdjustmentDialog}
            />
        </DPage>
    );
}
