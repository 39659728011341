import React from 'react';
import { useRawMaterialAdditionAccountInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IRawMaterialAddition } from '../raw-material-addition-form';

export function AccountInput() {
    const { data } = useRawMaterialAdditionAccountInputQuery();
    const INPUT_NAME: keyof IRawMaterialAddition = 'account_id';

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Cuenta'}
            options={data?.getAccounts || []}
        />
    );
}
