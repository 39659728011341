import React, { useEffect, useMemo, useState } from 'react';
import {
    DateGroupBy,
    ProductionSummaryEntitiesGroup,
    useProductionSummaryPageLazyQuery,
} from '../../../../services';
import {
    CTableWrapper,
    CVerticalDropdown,
    DAutocomplete,
    DBlock,
    DBlockHeader,
    DIdAutocomplete,
    DMonth,
    DPage,
    DPageBody,
    DPageHeader,
    DYear,
    ICustomTableWrapperItem,
    useDMonth,
    useDYear,
} from '../../../dum';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
    formatFloat,
    getDateRange,
    isDayRange,
    isMonthRange,
} from '../../../../helpers';
import { ProductionSummaryExcelExportButton } from './production-summary-export-button';

type ProductionSummaryEntityGroupOption = {
    text: string;
    value: ProductionSummaryEntitiesGroup;
};

const productionSummaryEntityGroupOptions: ProductionSummaryEntityGroupOption[] =
    [
        {
            text: 'Maquina',
            value: ProductionSummaryEntitiesGroup.Machine,
        },
        {
            text: 'Categoria del producto',
            value: ProductionSummaryEntitiesGroup.ProductCategory,
        },
    ];

export function ProductionSummary() {
    const [entityGroupOption, setEntityGroupOption] =
        useState<ProductionSummaryEntityGroupOption | null>(
            productionSummaryEntityGroupOptions[0],
        );
    const [orderProductionTypeId, setOrderProductionTypeId] = useState<
        number | null
    >(1);

    const [branchId, setBranchId] = useState<number | null>(1);

    const { year, setYear } = useDYear({
        COMPONENT_KEY: ProductionSummary.name + '_Year',
        defaultValue: dayjs().year(),
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: ProductionSummary.name + '_Month',
        defaultValue: null,
    });

    const [query, { data, loading }] = useProductionSummaryPageLazyQuery();

    useEffect(() => {
        if (entityGroupOption && orderProductionTypeId && branchId && year) {
            void query({
                variables: {
                    entityGroup: entityGroupOption.value,
                    branchId: branchId,
                    orderProductionTypeId: orderProductionTypeId,
                    month: month,
                    year: year,
                    dateGroupBy:
                        month !== null && month !== undefined
                            ? DateGroupBy.Day
                            : DateGroupBy.Month,
                },
            });
        }
    }, [
        year,
        month,
        entityGroupOption,
        orderProductionTypeId,
        branchId,
        query,
    ]);

    const tableData = useMemo<Record<any, any>[]>(() => {
        if (data && year && entityGroupOption && orderProductionTypeId) {
            const {
                getProductionSummary: {
                    production: productions,
                    waste: wastes,
                },
            } = data;
            const entities: { id: number }[] =
                entityGroupOption.value === 'machine'
                    ? data.getMachines
                    : data.getProductCategories;

            const entityLookup: { total: 0; waste: 0 } & Record<
                string,
                number
            > = entities.reduce(
                (acc, entity) => {
                    return { ...acc, [String(entity.id)]: 0 };
                },
                { total: 0, waste: 0 },
            );

            const dateRange = getDateRange({
                month: typeof month === 'number' ? month : null,
                year,
                content: entityLookup,
            });

            productions
                .filter((production) => {
                    return branchId === production.branch_id;
                })
                .forEach((production) => {
                    const dateRangeItem = dateRange.find((item) => {
                        if (isDayRange(item)) {
                            return (
                                Number(item.day) === production.day &&
                                Number(item.month) === production.month &&
                                Number(item.year) === production.year
                            );
                        } else if (isMonthRange(item)) {
                            return (
                                Number(item.month) === production.month &&
                                Number(item.year) === production.year
                            );
                        } else {
                            return false;
                        }
                    });

                    if (dateRangeItem) {
                        if (
                            production['order_production_type_id'] ===
                            orderProductionTypeId
                        ) {
                            dateRangeItem[
                                String(
                                    production[
                                        entityGroupOption.value === 'machine'
                                            ? 'machine_id'
                                            : 'product_category_id'
                                    ],
                                )
                            ] += production.kilos;
                            dateRangeItem.total += production.kilos;
                        }
                    }
                });

            wastes.forEach((waste) => {
                const dateRangeItem = dateRange.find((item) => {
                    if (isDayRange(item)) {
                        return (
                            Number(item.day) === waste.day &&
                            Number(item.month) === waste.month &&
                            Number(item.year) === waste.year
                        );
                    } else if (isMonthRange(item)) {
                        return (
                            Number(item.month) === waste.month &&
                            Number(item.year) === waste.year
                        );
                    } else {
                        return false;
                    }
                });

                if (dateRangeItem) {
                    dateRangeItem.waste += waste.waste;
                }
            });

            return dateRange;
        }

        return [];
    }, [data, year, month, entityGroupOption, orderProductionTypeId, branchId]);

    const tableItems = useMemo<ICustomTableWrapperItem[]>(() => {
        const items: ICustomTableWrapperItem[] = [];

        if (year && entityGroupOption && data && orderProductionTypeId) {
            if (month !== undefined && month !== null) {
                items.push({
                    title: 'Dia',
                    content: (item) => {
                        return item.day;
                    },
                });
            } else {
                items.push({
                    title: 'Mes',
                    content: (item) => {
                        return dayjs()
                            .set('month', item.month - 1)
                            .format('MMMM');
                    },
                });
            }

            items.push({
                title: 'Total',
                textAlign: 'right',
                content: (item) => {
                    return formatFloat(item.total);
                },
            });
            items.push({
                title: 'Desperdicio',
                textAlign: 'right',
                content: (item) => {
                    return formatFloat(item.waste);
                },
            });

            if (entityGroupOption.value === 'machine') {
                data.getMachines
                    .filter((machine) => {
                        return (
                            machine.order_production_type_id ===
                            orderProductionTypeId
                        );
                    })
                    .forEach((machine) => {
                        items.push({
                            title: machine.name,
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item[`${machine.id}`]);
                            },
                        });
                    });
            } else if (entityGroupOption.value === 'productCategory') {
                data.getProductCategories
                    .filter((pc) => {
                        return (
                            pc.order_production_type_id ===
                            orderProductionTypeId
                        );
                    })
                    .forEach((pc) => {
                        items.push({
                            title: pc.name,
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item[`${pc.id}`]);
                            },
                        });
                    });
            }
        }

        return items;
    }, [year, month, data, entityGroupOption, orderProductionTypeId]);

    return (
        <DPage>
            <DPageHeader title={'Resumen de producción'} />
            <DPageBody disableGutters>
                <DBlock>
                    <DBlockHeader>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ mr: 2 }}>
                                <DYear setYear={setYear} year={year} />
                            </Box>
                            <Box sx={{ mr: 2 }}>
                                <DMonth setMonth={setMonth} month={month} />
                            </Box>
                            <Box>
                                <ProductionSummaryExcelExportButton
                                    disabled={!data || loading}
                                    production={
                                        data?.getAllProductionSummary.production
                                    }
                                    waste={data?.getAllProductionSummary.waste}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ alignSelf: 'start' }}>
                            <CVerticalDropdown
                                items={[
                                    {
                                        component: (
                                            <DAutocomplete
                                                value={entityGroupOption}
                                                label={
                                                    'Ordenamiento por entidad'
                                                }
                                                options={
                                                    productionSummaryEntityGroupOptions
                                                }
                                                itemText={'text'}
                                                itemValue={'value'}
                                                onChange={(item) => {
                                                    setEntityGroupOption(item);
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        component: (
                                            <DIdAutocomplete
                                                id={orderProductionTypeId}
                                                onChange={
                                                    setOrderProductionTypeId
                                                }
                                                itemText={'name'}
                                                label={
                                                    'Tipo de orden de producción'
                                                }
                                                options={
                                                    data?.getOrderProductionTypes ||
                                                    []
                                                }
                                            />
                                        ),
                                    },
                                    {
                                        component: (
                                            <DIdAutocomplete
                                                id={branchId}
                                                onChange={setBranchId}
                                                itemText={'name'}
                                                label={'Sucursal'}
                                                options={
                                                    data?.getBranches || []
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </Box>
                    </DBlockHeader>
                    <CTableWrapper items={tableItems} data={tableData} />
                </DBlock>
            </DPageBody>
        </DPage>
    );
}
