import React from 'react';
import { RHTextField } from '../../../../../../../../dum';
import { useCalculateProductsTotal } from '../../../helpers/use-calculate-products-total';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    GROUP_PRICE,
    KILO_PRICE,
    ORDER_REQUEST_PRODUCTS,
} from '../order-request-products-columns';
import { IOrderRequest } from '../../../order-request-form';

export function GroupPriceInput({ index }: { index: number }) {
    const INPUT_NAME = `${ORDER_REQUEST_PRODUCTS}.${index}.${GROUP_PRICE}`;
    const rx = useFormContext();
    const kiloPrice = useWatch({
        name: `${ORDER_REQUEST_PRODUCTS}.${index}.${KILO_PRICE}`,
    }) as IOrderRequest['order_request_products'][number]['kilo_price'];

    const { calculateProductsTotal } = useCalculateProductsTotal();

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'$ del bulto'}
            disableGutters
            disabled={Number(kiloPrice) !== 0}
            onChange={() => {
                calculateProductsTotal();
                void rx.trigger(
                    `${ORDER_REQUEST_PRODUCTS}.${index}.${KILO_PRICE}`,
                );
            }}
        />
    );
}
