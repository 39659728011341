import React from 'react';
import { useEmployeeOrderProductionTypeInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IEmployee } from '../../employee-form';

export function OrderProductionTypeInput() {
    const inputName: keyof IEmployee = 'order_production_type_id';

    const { data } = useEmployeeOrderProductionTypeInputQuery();

    const orderProductionTypes = data?.getOrderProductionTypes || [];

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Tipo de orden de produccion'}
            options={orderProductionTypes}
        />
    );
}
