import { Box, Typography } from '@mui/material';
import React from 'react';
import { DAvatarIcon } from '../../icon';
import { DTooltip } from '../../tooltip';

interface ICustomPageHeaderLayout {
    children?: React.ReactNode | React.ReactNode[];
    icon?: React.ReactElement<any, any>;
    subtitle?: string;
    iconTooltip?: string;
}

export function DBlockHeader(props: ICustomPageHeaderLayout) {
    const { children, icon, subtitle, iconTooltip } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
            }}
        >
            {subtitle ? (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {icon ? (
                            <DTooltip text={iconTooltip}>
                                <DAvatarIcon size={'small'}>{icon}</DAvatarIcon>
                            </DTooltip>
                        ) : null}
                        <Box sx={{ ml: icon ? 1 : 2 }}>
                            <Typography variant={'h6'}>{subtitle}</Typography>
                        </Box>
                    </Box>
                </Box>
            ) : null}
            {children}
        </Box>
    );
}
