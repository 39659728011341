import React from 'react';
import {
    Cell,
    Pie,
    PieChart,
    PieLabelRenderProps,
    Tooltip,
    TooltipProps,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import { colors, formatFloat } from '../../../../../../helpers';

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
}: PieLabelRenderProps) => {
    if (
        innerRadius === undefined ||
        outerRadius === undefined ||
        cx === undefined ||
        cy === undefined ||
        percent === undefined
    ) {
        return undefined;
    }
    innerRadius = Number(innerRadius);
    outerRadius = Number(outerRadius);
    cx = Number(cx);
    cy = Number(cy);
    percent = Number(percent);

    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="black"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active && payload && payload.length) {
        return (
            <Box bgcolor="primary.main" sx={{ borderRadius: 1, px: 1 }}>
                <Typography>
                    {`${payload[0].name} ${formatFloat(payload[0].value)}`}
                </Typography>
            </Box>
        );
    }

    return null;
};

interface IAccountPieChartProps {
    title: string;
    pieData: {
        name: string;
        value: number;
    }[];
}

export function AccountPieChart({ pieData, title }: IAccountPieChartProps) {
    return (
        <Box sx={{ position: 'relative' }}>
            <Typography
                sx={{
                    position: 'absolute',
                    top: '10%',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                }}
            >
                {title}
            </Typography>
            <PieChart width={400} height={400}>
                <Pie
                    data={pieData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={20}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                    labelLine={true}
                >
                    {pieData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                        />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </Box>
    );
}
