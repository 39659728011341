import { ValidationError } from 'yup';
import { IOrderRequest } from '../order-request-form';

export function isKiloPriceAndGroupPriceDifferentThanZero(
    value: any,
    context: any,
): boolean | ValidationError {
    const { kilo_price, group_price } = context.from[0]
        .value as IOrderRequest['order_request_products'][number];

    const isKiloPriceNumber = !Number.isNaN(Number(kilo_price));
    const isGroupPriceNumber = !Number.isNaN(Number(group_price));

    if (isKiloPriceNumber && isGroupPriceNumber) {
        if (Number(group_price) !== 0 && Number(kilo_price) !== 0) {
            return context.createError({
                message: `Solo se puede escoger un precio por producto`,
            });
        }
    }

    return true;
}
