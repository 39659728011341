import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    navigateToSpareAdjustmentPage,
    SpareAdjustmentsPageQuery,
} from '../../../../../../services';
import { DBlock, DBlockHeader, CTableWrapper } from '../../../../../dum';
import {
    SpareTransactionIcon,
    SDeleteSpareOperationDialog,
    SSpareAdjustmentFormDialog,
    SSpareLink,
} from '../../../../../smart';
import { CVerticalDropdown } from '../../../../../dum/complex';

type TSpareAdjustment =
    SpareAdjustmentsPageQuery['getSpareAdjustments'][number];

interface ISpareAdjustmentBlockProps {
    spareAdjustment: TSpareAdjustment;
}

export function SpareAdjustmentBlock({
    spareAdjustment,
}: ISpareAdjustmentBlockProps) {
    const [spareAdjustmentDialog, setSpareAdjustmentDialog] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const navigate = useNavigate();

    return (
        <DBlock key={spareAdjustment.id}>
            <DBlockHeader
                subtitle={spareAdjustment.description}
                icon={<SpareTransactionIcon />}
                iconTooltip={'Ajuste'}
            >
                <CVerticalDropdown
                    items={[
                        {
                            component: 'Ver',
                            callback: () => {
                                navigateToSpareAdjustmentPage({
                                    navigate,
                                    spareAdjustmentId: spareAdjustment.id,
                                });
                            },
                        },
                        {
                            component: 'Editar',
                            callback: () => {
                                setSpareAdjustmentDialog(true);
                            },
                        },
                        {
                            component: 'Eliminar',
                            color: 'error',
                            callback: () => {
                                setConfirmationDialog(true);
                            },
                        },
                    ]}
                />
            </DBlockHeader>
            {spareAdjustment.spare_transactions.length > 0 ? (
                <CTableWrapper
                    items={[
                        {
                            title: 'Refacción',
                            content: (transaction) => {
                                if (transaction.spare) {
                                    return (
                                        <SSpareLink
                                            spareId={transaction.spare.id}
                                            spareName={transaction.spare.name}
                                        />
                                    );
                                }
                                return '-';
                            },
                        },
                        {
                            title: 'Cantidad agregada',
                            textAlign: 'right',
                            content: (transaction) => transaction.quantity,
                        },
                    ]}
                    data={spareAdjustment.spare_transactions}
                />
            ) : null}
            <SSpareAdjustmentFormDialog
                open={spareAdjustmentDialog}
                setOpen={setSpareAdjustmentDialog}
                spareAdjustment={spareAdjustment}
            />
            <SDeleteSpareOperationDialog
                open={confirmationDialog}
                setOpen={setConfirmationDialog}
                spareOperation={spareAdjustment}
            />
        </DBlock>
    );
}
