import React from 'react';
import { useWatch } from 'react-hook-form';
import { RHTextField } from '../../../../../../../../dum';
import { IOrderRequest } from '../../../order-request-form';
import { useCalculateProductsTotal } from '../../../helpers/use-calculate-products-total';

const ARRAY_NAME: keyof IOrderRequest = 'order_request_products';
const KILOS_FIELD_NAME: keyof IOrderRequest['order_request_products'][number] =
    'kilos';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderRequest['order_request_products'][number] =
    'group_weight';

interface IKilosInputProps {
    index: number;
}

export function KilosInput({ index }: IKilosInputProps) {
    const INPUT_NAME = `${ARRAY_NAME}.${index}.${KILOS_FIELD_NAME}`;
    const groupWeightValue = useWatch({
        name: `${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`,
    });

    const { calculateProductsTotal } = useCalculateProductsTotal();

    return (
        <RHTextField
            name={INPUT_NAME}
            label={'Kilos'}
            disableGutters
            disabled={!!groupWeightValue}
            onChange={() => {
                calculateProductsTotal();
            }}
        />
    );
}
