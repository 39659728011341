import React from 'react';
import { useWatch } from 'react-hook-form';
import {
    IOrderSale,
    REQUIRE_SUPPLEMENT,
    SUPPLEMENT_CODE,
} from '../../order-sale-form';
import { RHTextField } from '../../../../../../../dum';

export function SupplementCodeInput() {
    const requireSupplement = useWatch({
        name: REQUIRE_SUPPLEMENT,
    }) as IOrderSale['require_supplement'];

    return (
        <RHTextField
            disabled={!requireSupplement}
            name={SUPPLEMENT_CODE}
            label={'Folio del complemento'}
        />
    );
}
