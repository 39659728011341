import React from 'react';
import { useProductInventoryPageQuery } from '../../../../services';
import { DPage, DPageBody, DPageHeader, DPageLoader } from '../../../dum';
import { InventoryByProduct } from './blocks/inventory-by-product';
import { InventoryByTotals } from './blocks/inventory-by-totals';
import { InventoryByProductCategory } from './blocks/inventory-by-product-category';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { useBoolean } from '../../../../helpers';

export function ProductInventory() {
    const { data, loading } = useProductInventoryPageQuery();

    const [isDetailed, setIsDetailed] = useBoolean({
        KEY: ProductInventory.name + 'IsDetailed',
        defaultValue: false,
    });

    const productsInventory =
        data?.getProductsInventory.filter((pi) => {
            return pi.product?.order_production_type_id === 1;
        }) || [];

    return (
        <DPage>
            <DPageHeader title={'Inventario'}>
                <FormControl>
                    <FormControlLabel
                        labelPlacement={'end'}
                        control={
                            <Switch
                                onChange={() => {
                                    setIsDetailed(!isDetailed);
                                }}
                                checked={isDetailed}
                            />
                        }
                        label={'Descripción detallada'}
                    />
                </FormControl>
            </DPageHeader>
            <DPageBody disableGutters>
                {loading ? (
                    <DPageLoader />
                ) : (
                    <>
                        <InventoryByProduct
                            isDetailed={isDetailed}
                            productsInventory={productsInventory}
                        />
                        <InventoryByTotals
                            productsInventory={productsInventory}
                        />
                        <InventoryByProductCategory
                            productsInventory={productsInventory}
                        />
                    </>
                )}
            </DPageBody>
        </DPage>
    );
}
