import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../dum';
import { GroupsInput } from './groups/groups-input';
import { KilosInput } from './kilos/kilos-input';
import { OrderProductionMachineInput } from './machine/machine-input';
import { OrderProductionProductInput } from './product/product-input';
import { IOrderProduction } from '../order-production-form';

const ARRAY_NAME: keyof IOrderProduction = 'order_production_products';
const GROUP_WEIGHT_FIELD_NAME: keyof IOrderProduction['order_production_products'][number] =
    'group_weight';
const HOURS: keyof IOrderProduction['order_production_products'][number] =
    'hours';

const defaultValue: IOrderProduction['order_production_products'][number] = {
    product: null,
    machine: null,
    kilos: 0,
    groups: 0,
    group_weight: 0,
    hours: 8,
};

export function OrderProductionProductsColumns() {
    return (
        <RhColumns
            title={'Transacciones'}
            name={ARRAY_NAME}
            defaultValue={defaultValue}
            items={[
                {
                    cellGrow: 2,
                    getField: (index) => {
                        return <OrderProductionMachineInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ARRAY_NAME}.${index}.${HOURS}`}
                                label={'Horas'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    cellGrow: 3,
                    getField: (index) => {
                        return <OrderProductionProductInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <GroupsInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ARRAY_NAME}.${index}.${GROUP_WEIGHT_FIELD_NAME}`}
                                label={'Peso'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return <KilosInput index={index} />;
                    },
                },
            ]}
        />
    );
}
