import React from 'react';
import { DConfirmationModal } from '../../../../../dum';
import { useDeleteResourcesMutation } from '../../../../../../services';

interface IDeleteResourcesProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    resourceId: number | null;
}

export function SDeleteResourceDialog({
    open,
    setOpen,
    resourceId,
}: IDeleteResourcesProps) {
    const [deleteMutation] = useDeleteResourcesMutation();

    return (
        <DConfirmationModal
            open={open}
            setOpen={setOpen}
            callback={async () => {
                if (resourceId) {
                    try {
                        await deleteMutation({
                            variables: {
                                ResourceId: resourceId,
                            },
                        });
                        setOpen(false);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }}
        />
    );
}
