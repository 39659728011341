import React from 'react';
import { RHTextField } from '../../../../../../../dum';
import { TOTAL_WITH_TAX } from '../../order-sale-form';

export function TotalInput() {
    return (
        <RHTextField
            name={TOTAL_WITH_TAX}
            label={'Total (IVA incluido)'}
            disabled
        />
    );
}
