import React from 'react';
import { Link, useTheme } from '@mui/material';
import {
    Link as ReactRouterDomLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';

interface ICustomLinkProps {
    children: string | React.ReactNode | React.ReactNode[];
    to: string;
    onClick?: () => void;
}

export function DRouterLink(props: ICustomLinkProps) {
    const { to, children } = props;
    const theme = useTheme();

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
                function Link(linkProps, ref) {
                    return (
                        <ReactRouterDomLink ref={ref} to={to} {...linkProps} />
                    );
                },
            ),
        [to],
    );

    return (
        <Link
            component={CustomLink}
            onClick={props.onClick}
            sx={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
            }}
        >
            {children}
        </Link>
    );
}
