import { ValidationError } from 'yup';

export function doesMachineBelongToBranch(
    value: any,
    context: any,
): boolean | ValidationError {
    const { machine } = context.from[0].value;
    const { branch_id } = context.from[1].value;

    if (!branch_id || !machine) return true;

    if (!(machine.branch_id === branch_id)) {
        return context.createError({
            message: `La maquina no pertenece a la sucursal`,
        });
    }

    return true;
}
