import { useApolloClient } from '@apollo/client';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf, ValidationError } from 'yup';
import {
    getDateFromString,
    getStringFromDate,
    transformToNumber,
} from '../../../../../../helpers';
import {
    queryIsOrderRequestCodeOccupied,
    useUpsertOrderRequestMutation,
} from '../../../../../../services';
import {
    CardContainer,
    CardWithTitle,
    RhDateInput,
    RhFormWrapper,
    RHTextField,
} from '../../../../../dum';
import { AccountInput } from './inputs/account/account-input';
import { OrderRequestProductsColumns } from './inputs/order-request-products/order-request-products-columns';
import { OrderRequestStatusInput } from './inputs/order-request-status/order-request-status-input';
import { OrderCodeInput } from './inputs/order_code/order-code-input';
import { ProductsTotalInput } from './inputs/products-total/products-total-input';
import { isProductUnique } from './validation/is-product-unique';
import { areRequestProductsMoreThanProductsSold } from './validation/are-request-products-more-than-products-sold';
import { isKiloPriceAndGroupPriceDifferentThanZero } from './validation/is-kilo-price-and-group-price-different-than-zero';

export interface IOrderRequest {
    id?: number | null;
    date?: string | null;
    order_code: number;
    order_request_status_id?: number | null;
    account_id?: number | null;
    products_total: number;
    estimated_delivery_date?: string | null;
    notes?: string;
    order_request_products: {
        id?: number | null;
        kilos: number;
        kilo_price: number;
        group_price: number;
        product?: {
            id: number;
            compound_description: string;
            current_group_weight?: number | null;
        } | null;
        groups: number;
        group_weight?: number | null;
    }[];
    order_sale_sold_products: {
        id?: number | null;
        kilos: number;
        kilo_price: number;
        product_id?: number | null;
        groups: number;
        group_weight?: number | null;
    }[];
}

interface TOrderRequest extends IOrderRequest {
    id?: number | null;
}

interface ISOrderRequestFormDialogProps {
    onSuccess: () => void;
    orderRequest?: TOrderRequest;
    disabled?: boolean;
}

export const OrderRequestForm = (props: ISOrderRequestFormDialogProps) => {
    const { orderRequest, onSuccess, disabled = false } = props;

    const apolloClient = useApolloClient();

    const [mutation, { loading }] = useUpsertOrderRequestMutation();

    const initialValues = useMemo<IOrderRequest>(() => {
        return {
            id: orderRequest?.id || undefined,
            order_code: orderRequest?.order_code || 0,
            notes: orderRequest?.notes || '',
            date: orderRequest?.date
                ? getDateFromString(orderRequest.date)
                : null,
            estimated_delivery_date: orderRequest?.estimated_delivery_date
                ? getDateFromString(orderRequest.estimated_delivery_date)
                : null,
            products_total: orderRequest?.products_total
                ? orderRequest.products_total
                : 0,
            account_id: orderRequest?.account_id
                ? orderRequest.account_id
                : null,
            order_request_status_id:
                orderRequest && orderRequest?.order_request_status_id
                    ? orderRequest.order_request_status_id
                    : 1,
            order_request_products: orderRequest?.order_request_products || [],
            order_sale_sold_products:
                orderRequest?.order_sale_sold_products || [],
        };
    }, [orderRequest]);

    const validationSchema: SchemaOf<IOrderRequest> = yup.object({
        id: yup.number().notRequired().nullable(),
        order_code: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Folio' es obligatorio`)
            .test('unique', async function (value, context): Promise<
                boolean | ValidationError
            > {
                if (!!value) {
                    const result = await queryIsOrderRequestCodeOccupied(
                        apolloClient,
                        {
                            OrderCode: value,
                            OrderRequestId: orderRequest?.id || null,
                        },
                    );
                    if (result.data.isOrderRequestCodeOccupied) {
                        return context.createError({
                            message: `El folio ${value} esta uso`,
                        });
                    }
                    return true;
                } else {
                    return true;
                }
            }),
        date: yup.string().required(`'Fecha' es obligatorio`).nullable(),
        estimated_delivery_date: yup.string().notRequired().nullable(),
        order_request_status_id: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Status' es obligatorio`),
        products_total: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Total de productos' es obligatorio`),
        account_id: yup
            .number()
            .transform(transformToNumber)
            .nullable()
            .required(`'Cliente' es obligatorio`),
        notes: yup.string(),
        order_request_products: yup
            .array()
            .min(1, `'Productos' es obligatorio`)
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    product: yup
                        .mixed()
                        .required(`'Producto' es obligatorio`)
                        .nullable()
                        .test('is-product-unique', isProductUnique),
                    kilos: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .min(0, `'Kilos' no puede ser menor a 0`)
                        .required(`'Kilos' es obligatorio`)
                        .test(
                            'are-request-products-more-than-products-sold',
                            areRequestProductsMoreThanProductsSold,
                        ),
                    kilo_price: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Precio por kilo' es requerido`)
                        .test(
                            'is-kilo-price-and-group-price-different-than-zero',
                            isKiloPriceAndGroupPriceDifferentThanZero,
                        ),
                    group_price: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .required(`'Precio por grupo' es requerido`)
                        .test(
                            'is-kilo-price-and-group-price-different-than-zero',
                            isKiloPriceAndGroupPriceDifferentThanZero,
                        ),
                    groups: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .min(0, `'Grupos' no puede ser menor a 0`)
                        .required(`'Grupos' es obligatorio`)
                        .test(
                            'are-request-products-more-than-products-sold',
                            areRequestProductsMoreThanProductsSold,
                        ),
                    group_weight: yup
                        .number()
                        .transform(transformToNumber)
                        .nullable()
                        .min(0, `'Peso' no puede ser menor a 0`)
                        .required('`Peso` es obligatiorio')
                        .nullable(),
                }),
            ),
        order_sale_sold_products: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().notRequired().nullable(),
                    product_id: yup.number().required(),
                    kilos: yup.number().required(),
                    kilo_price: yup.number().required(),
                    groups: yup.number().required().nullable(),
                    group_weight: yup.number().required().nullable(),
                }),
            )
            .required(),
    });

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            OrderRequestInput: {
                                id: orderRequest?.id || null,
                                date: data.date
                                    ? getStringFromDate(data.date)
                                    : null,
                                estimated_delivery_date:
                                    data.estimated_delivery_date
                                        ? getStringFromDate(
                                              data.estimated_delivery_date,
                                          )
                                        : null,
                                order_request_status_id:
                                    data.order_request_status_id!,
                                notes: data.notes || '',
                                account_id: data.account_id!,
                                order_code: data.order_code,
                                order_request_products:
                                    data.order_request_products.map(
                                        (requestProduct) => {
                                            return {
                                                id: requestProduct.id || null,
                                                kilos: requestProduct.kilos,
                                                kilo_price:
                                                    requestProduct.kilo_price,
                                                product_id:
                                                    requestProduct.product!.id,
                                                group_weight:
                                                    requestProduct.group_weight!,
                                                groups: requestProduct.groups,
                                                group_price:
                                                    requestProduct.group_price,
                                            };
                                        },
                                    ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (errors) => {
                console.log(errors);
            }}
        >
            <CardContainer>
                <CardWithTitle title={'Datos obligatorios'} mr={1}>
                    <RhDateInput name={'date'} label={'Fecha de emisión'} />
                    <OrderCodeInput />
                    <AccountInput />
                    <OrderRequestStatusInput />
                </CardWithTitle>
                <CardWithTitle title={'Datos opcionales'} ml={1}>
                    <RhDateInput
                        name={'estimated_delivery_date'}
                        label={'Fecha estimada de entrega'}
                    />
                    <RHTextField name={'notes'} label={'Notas'} rows={3} />
                </CardWithTitle>
            </CardContainer>
            <CardWithTitle title={'Productos'}>
                <OrderRequestProductsColumns />
                <ProductsTotalInput />
            </CardWithTitle>
        </RhFormWrapper>
    );
};
