import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    MachinePageQuery,
    navigateToMachineSectionPage,
} from '../../../../../../services';
import {
    CTableWrapper,
    CVerticalDropdown,
    DBlock,
    DBlockHeader,
} from '../../../../../dum';

import {
    MachineSectionIcon,
    SMachinePartLink,
    SMachineSectionFormDialog,
    SSpareLink,
} from '../../../../../smart';

interface IMachineSectionListProps {
    machine_section: NonNullable<
        MachinePageQuery['getMachine']
    >['machine_sections'][number];
}

export function MachineSectionList({
    machine_section,
}: IMachineSectionListProps) {
    const navigate = useNavigate();
    const [formDialogOpen, setFormDialogOpen] = useState(false);

    return (
        <DBlock>
            <DBlockHeader
                subtitle={machine_section.name}
                icon={<MachineSectionIcon />}
                iconTooltip={'Seccion'}
            >
                <CVerticalDropdown
                    items={[
                        {
                            component: 'Ver',
                            callback: () => {
                                if (machine_section.machine_id) {
                                    navigateToMachineSectionPage({
                                        navigate,
                                        machineId: machine_section.machine_id,
                                        machineSectionId: machine_section.id,
                                    });
                                }
                            },
                        },
                        {
                            component: 'Editar',
                            callback: () => {
                                setFormDialogOpen(true);
                            },
                        },
                        {
                            component: 'Eliminar',
                            color: 'error',
                            disabled: !machine_section.is_deletable,
                        },
                    ]}
                />
            </DBlockHeader>
            <CTableWrapper
                data={machine_section.machine_parts}
                items={[
                    {
                        title: 'Parte de la maquina',
                        content: (part) => {
                            if (machine_section && machine_section.machine_id) {
                                return (
                                    <SMachinePartLink
                                        machineId={machine_section.machine_id}
                                        machinePartId={part.id}
                                        partName={part.name}
                                    />
                                );
                            }
                        },
                    },
                    {
                        title: 'Refacción actual',
                        content: (part) => {
                            if (part.current_spare) {
                                return (
                                    <SSpareLink
                                        spareId={part.current_spare.id}
                                        spareName={part.current_spare.name}
                                    />
                                );
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Cantidad en inventario',
                        textAlign: 'right',
                        content: (part) => part.current_spare?.current_quantity,
                    },
                    {
                        title: 'Cantidad requerida',
                        textAlign: 'right',
                        content: (part) => part.current_spare_required_quantity,
                    },
                    {
                        title: 'Refaccións compatibles',
                        cellGrow: 2,
                        content: (part) =>
                            part.machine_compatibilities
                                .map(
                                    (machine_compatibility) =>
                                        machine_compatibility.spare?.name,
                                )
                                .join(', '),
                    },
                ]}
            />
            <SMachineSectionFormDialog
                open={formDialogOpen}
                setOpen={setFormDialogOpen}
                machineSection={machine_section}
            />
        </DBlock>
    );
}
