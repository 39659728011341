import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOrderProductionTypeInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IProduct } from '../../product-form';

const ID_KEY_NAME: keyof IProduct = 'id';
const PRODUCT_CATEGORY_FIELD_NAME: keyof IProduct = 'product_category';

export function OrderProductionTypeInput() {
    const inputName: keyof IProduct = 'order_production_type_id';
    const id = useWatch({ name: ID_KEY_NAME });

    const { data } = useOrderProductionTypeInputQuery();

    const formContext = useFormContext();

    const orderProductionTypes = data?.getOrderProductionTypes || [];

    return (
        <RhSingleAutocomplete
            disabled={!!id}
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Tipo de orden de produccion'}
            options={orderProductionTypes}
            onChange={() => {
                const productCategory = formContext.getValues(
                    PRODUCT_CATEGORY_FIELD_NAME,
                ) as IProduct['product_category'];
                if (productCategory) {
                    void formContext.trigger(PRODUCT_CATEGORY_FIELD_NAME);
                }
            }}
        />
    );
}
