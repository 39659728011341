import React from 'react';
import { useOrderAdjustmentOrderAdjustmentTypeInputQuery } from '../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../dum';
import { IOrderAdjustment } from '../order-adjustment-form';

export function OrderAdjustmentTypeInput() {
    const { data } = useOrderAdjustmentOrderAdjustmentTypeInputQuery();
    const INPUT_NAME: keyof IOrderAdjustment = 'order_adjustment_type_id';

    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={INPUT_NAME}
            label={'Tipo de ajuste'}
            options={data?.getOrderAdjustmentTypes || []}
        />
    );
}
