import React from 'react';
import {
    CTableWrapper,
    DBlock,
    DBlockHeader,
    DErrorCard,
    DPage,
    DPageBody,
    DPageHeader,
    DPageLoader,
} from '../../../../dum';
import { SSpareCategoryLink } from '../../../../smart';
import { useSpareCategoriesPageQuery } from '../../../../../services';

export function SpareCategoriesPage() {
    const { data, loading, error } = useSpareCategoriesPageQuery();

    if (loading) return <DPageLoader />;
    if (error) return <DErrorCard />;

    const spareCategories = data!.getSpareCategories;

    return (
        <DPage animated>
            <DPageHeader title={'Categorias'} />
            <DPageBody>
                <DBlock>
                    <DBlockHeader subtitle={'Lista de categorias'} />
                    <CTableWrapper
                        items={[
                            {
                                title: 'Nombre',
                                content: (category) => {
                                    return (
                                        <SSpareCategoryLink
                                            spareCategoryId={category.id}
                                            spareCategoryName={category.name}
                                        />
                                    );
                                },
                            },
                        ]}
                        data={spareCategories}
                    />
                </DBlock>
            </DPageBody>
        </DPage>
    );
}
