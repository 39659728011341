import { NavigateFunction } from 'react-router-dom';
import {
    getEmployeePagePath,
    getEmployeesPagePath,
    getOrderAdjustmentPagePath,
    getOrderProductionPagePath,
    getProductPagePath,
    getProductsPagePath,
} from './get-route-path';

export function navigateToProductPage({
    navigate,
    productId,
}: Parameters<typeof getProductPagePath>[0] & {
    navigate: NavigateFunction;
}) {
    navigate(
        getProductPagePath({
            productId,
        }),
    );
}

export function navigateToProductsPage({
    navigate,
    replace = false,
}: {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getProductsPagePath(), {
        replace,
    });
}

export function navigateToOrderProduction({
    navigate,
    replace = false,
    order_production_id,
}: Parameters<typeof getOrderProductionPagePath>[0] & {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getOrderProductionPagePath({ order_production_id }), {
        replace: replace,
    });
}

export function navigateToOrderAdjustment({
    navigate,
    replace = false,
    order_adjustment_id,
}: Parameters<typeof getOrderAdjustmentPagePath>[0] & {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getOrderAdjustmentPagePath({ order_adjustment_id }), {
        replace: replace,
    });
}

export function navigateToEmployeePage({
    navigate,
    employeeId,
    replace = false,
}: Parameters<typeof getEmployeePagePath>[0] & {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(
        getEmployeePagePath({
            employeeId,
        }),
        {
            replace,
        },
    );
}

export function navigateToEmployeesPage({
    navigate,
    replace = false,
}: {
    navigate: NavigateFunction;
    replace?: boolean;
}) {
    navigate(getEmployeesPagePath(), {
        replace,
    });
}
