import React, { useMemo, useState } from 'react';
import { useEmployeeFormQuery } from '../../../../../../services';
import { DModal, DPageLoader } from '../../../../../dum';
import { EmployeeIcon } from '../../../../icons';
import { EmployeeForm } from './employee-form';

interface ISEmployeeFormDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    employeeId?: number | null;
}

export const SUpsertEmployeeDialog = (props: ISEmployeeFormDialogProps) => {
    const { open, setOpen, employeeId } = props;
    const [isModalClosing, setIsModalClosing] = useState(false);

    const { data, loading } = useEmployeeFormQuery({
        skip: !employeeId || !open,
        variables: {
            EmployeeId: employeeId!,
        },
    });

    const employee = useMemo(() => {
        return employeeId && data && data.getEmployee && open
            ? data.getEmployee
            : undefined;
    }, [employeeId, data, open]);

    return (
        <DModal
            icon={<EmployeeIcon />}
            title={`Empleado`}
            open={open}
            setOpen={setOpen}
            onExited={() => {
                setIsModalClosing(false);
            }}
        >
            {loading ? (
                <DPageLoader />
            ) : (
                <EmployeeForm
                    onSuccess={() => {
                        setIsModalClosing(true);
                        setOpen(false);
                    }}
                    employee={employee}
                    disabled={isModalClosing}
                />
            )}
        </DModal>
    );
};
