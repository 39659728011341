import React from 'react';
import { DRouterLink } from '../../../../dum';
import { getSparePagePath } from '../../../../../services/navigation/maintenance/get-route-path';

interface ISSpareLinkProps {
    spareId: number;
    spareName: string;
}

export function SSpareLink({ spareId, spareName }: ISSpareLinkProps) {
    return (
        <DRouterLink
            to={getSparePagePath({
                spareId: spareId,
            })}
        >
            {spareName}
        </DRouterLink>
    );
}
