import { Box, SxProps } from '@mui/material';
import React from 'react';
import { DAnimatedBox } from '../animated-box/d-animated-box';

interface ICustomPageProps {
    children: React.ReactNode;
    centered?: boolean;
    animated?: boolean;
}

const centeredSxProps = {
    justifyContent: 'center',
    alignItems: 'center',
};

const defaultSxProps: SxProps = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
};

export function DPage({
    children,
    centered,
    animated = false,
}: ICustomPageProps) {
    const positioningSxProps = centered ? centeredSxProps : {};

    if (animated) {
        return (
            <DAnimatedBox
                sx={{
                    ...defaultSxProps,
                    ...positioningSxProps,
                }}
            >
                {children}
            </DAnimatedBox>
        );
    } else {
        return (
            <Box
                sx={{
                    ...defaultSxProps,
                    ...positioningSxProps,
                }}
            >
                {children}
            </Box>
        );
    }
}
