import React from 'react';
import { IMachinePartForm } from '../s-machine-part-form-dialog';
import { SSingleSparesAutocomplete } from '../../../../../inputs';

export function CurrentSpareInput() {
    const name: keyof IMachinePartForm = 'current_spare_id';

    return (
        <SSingleSparesAutocomplete
            name={name}
            label={'Refacción seleccionada'}
        />
    );
}
