export function getProductPagePath({
    productId,
}: {
    productId: number;
}): string {
    return `/products/${productId}`;
}

export function getProductsPagePath(): string {
    return `/products`;
}

export function getOrderProductionPagePath({
    order_production_id,
}: {
    order_production_id: number;
}): string {
    return `/orderProductionProducts/${order_production_id}`;
}

export function getOrderAdjustmentsPagePath(): string {
    return `/orderAdjustments`;
}

export function getOrderAdjustmentPagePath({
    order_adjustment_id,
}: {
    order_adjustment_id: number;
}): string {
    return `/orderAdjustments/${order_adjustment_id}`;
}

export function getEmployeePagePath({
    employeeId,
}: {
    employeeId: number;
}): string {
    return `/employees/${employeeId}`;
}

export function getEmployeesPagePath(): string {
    return `/employees`;
}
