import { ValidationError } from 'yup';

export function isThereOnlyOneEmployeeLeader(
    value: any,
    context: any,
): boolean | ValidationError {
    const { is_leader } = context.from[0].value;

    const order_production_employees =
        context.from[1].value.order_production_employees;

    const results = order_production_employees.filter((pEmployee: any) => {
        return pEmployee.is_leader;
    });

    if (results.length > 1 && is_leader) {
        return context.createError({
            message: `Solo puede haber un empleado lider por orden de producción`,
        });
    }

    return true;
}
