import { ValidationError } from 'yup';

export function isMachineProductUnique(
    value: any,
    context: any,
): boolean | ValidationError {
    const { machine, product } = context.from[0].value;

    if (!machine || !product) return true;

    const order_production_products =
        context.from[1].value.order_production_products;

    const machine_id = machine.id;
    const product_id = product.id;

    const results = order_production_products.filter((pProduct: any) => {
        const machine_id_2 = pProduct.machine ? pProduct.machine.id : null;
        const product_id_2 = pProduct.product ? pProduct.product.id : null;
        return machine_id === machine_id_2 && product_id === product_id_2;
    });

    if (!(results.length <= 1)) {
        return context.createError({
            message: `La máquina y el producto no son unicos`,
        });
    }

    return true;
}
