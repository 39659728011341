import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';

export interface IDAutocompleteProps<T> {
    itemText: keyof T;
    itemValue: keyof T;
    label: string;
    helperText?: string;
    onChange?: (val: T | null) => void;
    value: T | null;
    options: T[];
    disabled?: boolean;
    disableClearable?: boolean;
    disableGutters?: boolean;
}

export function DAutocomplete<T>({
    itemValue,
    itemText,
    value,
    options,
    onChange,
    disabled = false,
    helperText,
    disableClearable = false,
    label,
    disableGutters = true,
    ...rest
}: IDAutocompleteProps<T>) {
    return (
        <Autocomplete
            {...rest}
            disabled={disabled}
            isOptionEqualToValue={(option, value: T) => {
                return option[itemValue] === value[itemValue];
            }}
            value={value}
            onChange={(event, val) => {
                if (onChange && !Array.isArray(val)) {
                    onChange(val);
                }
            }}
            getOptionLabel={(val) => {
                if (!val) return '';
                if (typeof val === 'string' || typeof val === 'number')
                    return String(val);
                return String(val[itemText]);
            }}
            options={options}
            disableClearable={disableClearable}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        disabled={disabled}
                        sx={{
                            my: !disableGutters ? 2 : 0,
                            width: '100%',
                            minWidth: '25rem',
                        }}
                        label={label}
                        helperText={helperText || ''}
                        margin={'dense'}
                        size={'small'}
                    />
                );
            }}
        />
    );
}
