import React from 'react';
import { useOrderRequestOrderRequestStatusInputQuery } from '../../../../../../../../services';
import { RhSingleAutocomplete } from '../../../../../../../dum';
import { IOrderRequest } from '../../order-request-form';

// const ORDER_REQUEST_ID: keyof IOrderRequest = 'id';
export function OrderRequestStatusInput() {
    const inputName: keyof IOrderRequest = 'order_request_status_id';
    const { data: orderRequestsStatusesData } =
        useOrderRequestOrderRequestStatusInputQuery();
    // const orderRequestId = useWatch({
    //     name: ORDER_REQUEST_ID,
    // }) as IOrderRequest['id'];
    //
    // const { data: userData } = useCurrentUserQuery();
    //
    // const userHasPermissions = userData?.currentUser.role_ids.some((roleId) => {
    //     return roleId === RoleId.Super || roleId === RoleId.Admin;
    // });

    const orderRequestStatuses =
        orderRequestsStatusesData?.getOrderRequestStatuses || [];

    //  disabled={!userHasPermissions && !orderRequestId}
    return (
        <RhSingleAutocomplete
            itemText={'name'}
            itemValue={'id'}
            name={inputName}
            label={'Status'}
            options={orderRequestStatuses}
        />
    );
}
