import React from 'react';
import { DModal, RhFormWrapper, RHTextField } from '../../../../../dum';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import {
    clearMaintenanceModule,
    useUpsertMachinePartMutation,
} from '../../../../../../services';
import { CompatibleSparesInput } from './inputs/compatible-spares-input';
import { CurrentSpareInput } from './inputs/current-spare-input';
import { SSingleMachineAutocomplete } from '../../../../inputs';
import { MachineSectionInput } from './inputs/machine-section-input';
import { MachinePartIcon } from '../../../../icons';

export interface IMachinePartForm {
    name: string;
    machine_section_id?: number | null;
    machine_id?: number | null;
    current_spare_id?: number | null;
    current_spare_required_quantity?: number | null;
    machine_compatibilities: { spare_id?: number | null }[];
}

interface IMachinePart extends IMachinePartForm {
    id?: number;
    machine_section?: { machine_id?: number | null } | null;
}

interface IMachinePartFormDialogProps {
    machinePart?: IMachinePart;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SMachinePartFormDialog = (props: IMachinePartFormDialogProps) => {
    const { open, setOpen, machinePart } = props;

    const [mutation, { loading }] = useUpsertMachinePartMutation({
        update: (cache) => {
            clearMaintenanceModule(cache);
        },
    });

    const initialValues: IMachinePartForm = {
        name: machinePart?.name || '',
        machine_compatibilities: machinePart?.machine_compatibilities || [],
        machine_section_id: machinePart?.machine_section_id || null,
        machine_id:
            machinePart?.machine_section?.machine_id ||
            machinePart?.machine_id ||
            null,
        current_spare_id: machinePart?.current_spare_id || null,
        current_spare_required_quantity:
            machinePart?.current_spare_required_quantity || 0,
    };

    const validationSchema: SchemaOf<IMachinePartForm> = yup.object({
        machine_compatibilities: yup
            .array()
            .of(
                yup.object().shape({
                    spare_id: yup.number().required(),
                }),
            )
            .required(),
        name: yup.string().required(),
        machine_section_id: yup.number().notRequired().nullable(),
        machine_id: yup.number().required(),
        current_spare_id: yup.number().required(),
        current_spare_required_quantity: yup.number().required(),
    });

    return (
        <DModal
            icon={<MachinePartIcon />}
            title={`${!!machinePart?.id ? 'Editar' : 'Crear'} parte`}
            open={open}
            setOpen={setOpen}
        >
            <RhFormWrapper
                onSubmit={async (data) => {
                    await mutation({
                        variables: {
                            MachinePartUpsetInput: {
                                id: machinePart?.id || null,
                                name: data.name,
                                machine_compatibilities:
                                    data.machine_compatibilities.map(
                                        (compat) => {
                                            return {
                                                spare_id: compat.spare_id,
                                            };
                                        },
                                    ),
                                machine_section_id: data.machine_section_id,
                                machine_id: data.machine_id!,
                                current_spare_id: data.current_spare_id,
                                current_spare_required_quantity:
                                    data.current_spare_required_quantity,
                            },
                        },
                    });
                    setOpen(false);
                    return;
                }}
                validationSchema={validationSchema}
                initialValues={initialValues}
                disabled={loading}
            >
                <RHTextField name={'name'} label={'Nombre'} />
                <SSingleMachineAutocomplete
                    disabled={!!machinePart?.id}
                    name={'machine_id'}
                    label={'Maquina'}
                />
                <MachineSectionInput disabled={!!machinePart?.id} />
                <CompatibleSparesInput />
                <CurrentSpareInput />
                <RHTextField
                    name={'current_spare_required_quantity'}
                    label={'Cantidad requerida'}
                />
            </RhFormWrapper>
        </DModal>
    );
};
